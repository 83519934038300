import { useState } from "react";
import { Tooltip, Stack, Typography, IconButton } from "@mui/material";
import { VisibilityOutlined } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { StyleSheet, css } from "aphrodite";

import { useAppSelector } from "../../store/hooks";
import BillingQueryResult from "../../models/queries/BillingQueryResult.model";
import formatNumberToCurrencyNoSymbol from "../../util/formaters/formatNumberToCurrencyNoSymbol";
import BillingQueryDetailsModal from "../modals/queries/BillingQueryDetailsModal";

import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "../../styles/style.css";
import Colors from "../../constants/Colors";

interface Props {
  results: BillingQueryResult[];
}

const BillingQueryResultTable = (props: Props) => {
  const { results } = props;
  const configTenant = useAppSelector((state) => state.tenantConfiguration);

  const [openModal, setOpenModal] = useState(false);
  const [modalId, setModalId] = useState("");

  const zeroCurrency = "0,00";

  let qtde = results.length;

  const handleModalToggle = (
    ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    id: string
  ) => {
    ev.preventDefault();
    setModalId(id);
    setOpenModal(true);
  };

  if (results.length === 0) {
    return null;
  }

  return (
    <>
      <BillingQueryDetailsModal
        openModal={openModal}
        invoiceId={modalId}
        toggle={() => setOpenModal(!openModal)}
      />
      <Table className={css(styles.table)}>
        <Thead className="table_head">
          <Tr>
            <Th>Nota Fiscal</Th>
            <Th>Pedido {configTenant.nomeSistemaIntegrado}</Th>
            <Th>Dt. Emissão</Th>
            <Th>Dt. Cancelamento</Th>
            <Th>Cliente</Th>
            <Th>Status</Th>
            <Th>Vr. Bruto</Th>
            <Th>Vr. Desc</Th>
            <Th>Vr. Líquido</Th>
            <Th>IPI</Th>
            <Th>Ação</Th>
          </Tr>
        </Thead>
        <Tbody>
          {results.map((result) => {
            var parity = qtde % 2;
            qtde = qtde - 1;
            return (
              <Tr
                key={result.id}
                className={parity === 1 ? "odd_row" : "even_row"}
              >
                <Td className="table_number_cell">{result.id}</Td>
                <Td className="table_number_cell">
                  {result.numeroPedido ? result.numeroPedido : " "}
                </Td>
                <Td className="table_actions">{result.emissao.data}</Td>
                <Td className="table_actions">
                  {result.cancelamento.data ? result.cancelamento.data : ""}
                </Td>
                <Td className="table_text">{result.cliente.nome}</Td>
                <Td className="table_text">
                  <Stack
                    direction={"row"}
                    width={"100%"}
                    height={"100%"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Tooltip arrow title={result.status}>
                      <div>
                        <IconButton
                          disabled
                          sx={{
                            "&:disabled": {
                              fontSize: 10,
                              backgroundColor:
                                result.status === "Faturada"
                                  ? Colors.primary
                                  : "red",
                              width: "40%",
                              height: "40%",
                            },
                          }}
                        />
                      </div>
                    </Tooltip>
                  </Stack>
                </Td>
                <Td className="table_number_cell">
                  {result.totalBruto
                    ? formatNumberToCurrencyNoSymbol(result.totalBruto)
                    : zeroCurrency}
                </Td>
                <Td className="table_number_cell">
                  {result.desconto
                    ? formatNumberToCurrencyNoSymbol(result.desconto)
                    : "0,00"}
                </Td>
                <Td className="table_number_cell">
                  {formatNumberToCurrencyNoSymbol(result.totalNotaFiscal)}
                </Td>
                <Td className="table_actions">
                  {result.ipi ? (
                    <CheckIcon color="success" fontSize="inherit" />
                  ) : (
                    "-"
                  )}
                </Td>
                <Td className="table_actions">
                  <Stack
                    direction={"row"}
                    height={"100%"}
                    width={"100%"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Tooltip arrow title="Ir para detalhes">
                      <a
                        href="#"
                        onClick={(ev) => handleModalToggle(ev, result.id)}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <VisibilityOutlined color="action" fontSize="inherit" />
                      </a>
                    </Tooltip>
                  </Stack>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </>
  );
};

const styles = StyleSheet.create({
  table: {
    fontSize: 13,
    display: "table",
  },
  editButton: {
    color: "#ff6600",
  },
  numberCell: {
    justifyContent: "flex-end",
  },
});

export default BillingQueryResultTable;
