import { useEffect, useState } from "react";
import { Card, Typography, CardContent, Grid, TextField } from "@mui/material";

import { Badge } from "@mui/icons-material";

import isValidCnpj from "../../../util/validators/validateCnpj";
import formatCnpj from "../../../util/formaters/formatCnpj";
import isValidCPF from "../../../util/validators/validateCpf";
import formatCpf from "../../../util/formaters/formatCpf";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { clientActions } from "../../../store/client/client-slice";
import Colors from "../../../constants/Colors";
import Pessoa from "../../../models/client/person.model";
import { clientFormErrorActions } from "../../../store/error/client-form-error-slice";
import { CAMPO_OBRIGATORIO } from "../../../util/string/utilStrings";

interface Props {
  person: Pessoa;
  dados: {
    cpf: string;
    rg: string;
    cnpj: string;
    inscricaoEstadual: string;
    inscricaoMunicipal: string;
  };
  readOnly: boolean;
}

const DocumentsCard = (props: Props) => {
  const { dados, readOnly } = props;
  const [cpf, setCpf] = useState<string>(dados.cpf);
  const [rg, setRg] = useState<string>(dados.rg);
  const [cnpj, setCnpj] = useState<string>(dados.cnpj);
  const [inscricaoEstadual, setInscricaoEstadual] = useState<string>(
    dados.inscricaoEstadual
  );
  const [inscricaoMunicipal, setInscricaoMunicipal] = useState<string>(
    dados.inscricaoMunicipal
  );
  const [cpfHelperText, setCpfHelperText] = useState<string>("");
  const [cnpjHelperText, setCnpjHelperText] = useState<string>("");

  const isNotReadOnly = useAppSelector(
    (state) => state.tenantConfiguration.cadastroCliente.permiteEdicao
  );

  const isReadOnly = useAppSelector(
    (state) => state.readability.isClientReadOnly
  );

  const cnpjError = useAppSelector((state) => state.clientFormError.cnpjError);
  const cpfError = useAppSelector((state) => state.clientFormError.cpfError);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (cpfError && cpf.length === 0) {
      setCpfHelperText(CAMPO_OBRIGATORIO);
    } else if (cpfError && cpf.length > 0) {
      setCpfHelperText("O CPF informado é inválido");
    } else {
      setCpfHelperText("");
    }
  }, [cpf.length, cpfError, setCpfHelperText]);

  useEffect(() => {
    if (cnpjError && cnpj.length === 0) {
      setCnpjHelperText(CAMPO_OBRIGATORIO);
    } else if (cnpjError && cnpj.length > 0) {
      setCnpjHelperText("O CNPJ informado é inválido");
    } else {
      setCnpjHelperText("");
    }
  }, [cnpj.length, cnpjError, setCnpjHelperText]);

  const cpfValidation = () => {
    const isValid: boolean = isValidCPF(cpf);
    if (!isValid) dispatch(clientFormErrorActions.cpfErrorSetter(true));
    if (isValid) {
      dispatch(clientFormErrorActions.cpfErrorSetter(false));
      let formattedCpf: string = formatCpf(cpf);
      dispatch(clientActions.changeCpf(formattedCpf));
      setCpf(formattedCpf);
    }
  };

  const cnpjValidation = () => {
    const isValid: boolean = isValidCnpj(cnpj);
    if (!isValid) dispatch(clientFormErrorActions.cnpjErrorSetter(true));
    if (isValid) {
      dispatch(clientFormErrorActions.cnpjErrorSetter(false));
      let formattedCnpj: string = formatCnpj(cnpj);
      dispatch(clientActions.changeCnpj(formattedCnpj));
      setCnpj(formattedCnpj);
    }
  };

  return (
    <Card>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <Typography
              variant="body2"
              color={Colors.titleWithBackgroudColor}
              sx={{
                backgroundColor: Colors.titleRowWithBackgroundColor,
                pl: 1,
                pt: 0.75,
                mt: -1,
              }}
            >
              <Badge fontSize={"inherit"} /> Documentos
            </Typography>
          </Grid>

          {props.person === "F" ? (
            <>
              <Grid item xs={6} md={4}>
                <TextField
                  // inputProps={{ readOnly: !isNotReadOnly }}
                  onBlur={() => cpfValidation()}
                  required
                  error={cpfError}
                  fullWidth
                  placeholder="CPF"
                  value={cpf}
                  type="text"
                  onChange={(ev) => {
                    setCpf(ev.target.value);
                  }}
                  size={"small"}
                  color="success"
                  label="CPF"
                  helperText={cpfHelperText}
                  disabled={readOnly || isReadOnly}
                />
              </Grid>
              <Grid item xs={6} md={8}>
                <TextField
                  disabled={isReadOnly}
                  // inputProps={{ readOnly: !isNotReadOnly }}
                  fullWidth
                  placeholder="RG"
                  value={rg}
                  type="text"
                  onChange={(ev) => {
                    setRg(ev.target.value);
                    dispatch(clientActions.changeRg(ev.target.value));
                  }}
                  size={"small"}
                  color="success"
                  label="RG"
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} md={12}>
                <TextField
                  // inputProps={{ readOnly: !isNotReadOnly }}
                  onBlur={() => cnpjValidation()}
                  required
                  error={cnpjError}
                  fullWidth
                  placeholder="CNPJ"
                  value={cnpj}
                  type="text"
                  onChange={(ev) => {
                    setCnpj(ev.target.value);
                  }}
                  size={"small"}
                  color="success"
                  label="CNPJ"
                  disabled={readOnly || isReadOnly}
                  helperText={cnpjHelperText}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  disabled={isReadOnly}
                  // inputProps={{ readOnly: !isNotReadOnly }}
                  fullWidth
                  placeholder="Inscrição estadual"
                  value={inscricaoEstadual}
                  type="text"
                  onChange={(ev) => {
                    setInscricaoEstadual(ev.target.value);
                    dispatch(
                      clientActions.changeInscricaoEstadual(ev.target.value)
                    );
                  }}
                  size={"small"}
                  color="success"
                  label="Inscrição estadual"
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  disabled={isReadOnly}
                  // inputProps={{ readOnly: !isNotReadOnly }}
                  fullWidth
                  placeholder="Inscrição municipal"
                  value={inscricaoMunicipal}
                  type="text"
                  onChange={(ev) => {
                    setInscricaoMunicipal(ev.target.value);
                    dispatch(
                      clientActions.changeInscricaoMunicipal(ev.target.value)
                    );
                  }}
                  size={"small"}
                  color="success"
                  label="Inscrição municipal"
                />
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DocumentsCard;
