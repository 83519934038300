import { useState } from 'react';
import {
  Grid,
  Stack,
  TextField,
  IconButton,
  Pagination,
  PaginationItem,
} from '@mui/material';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Add, Search } from '@mui/icons-material';

import { useGetClientsQuery } from '../../api/clientsApi';
import { useAppSelector } from '../../store/hooks';

import BeforeInsertDialog from '../../components/modals/client/BeforeInsertDialog';
import ClientCard from '../../components/cards/client/ClientCard';
import SearchFeedback from '../../components/feedback/SearchFeedback';
import Spinner from '../../components/layout/Spinner';
import ClientsListingTable from '../../components/tables/ClientsListingTable';

import Colors from '../../constants/Colors';

const ClientListPage = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get('page') || '1');
  const filter = query.get('filtro');
  let pageNumber = page;
  const { data, isError, isFetching, isLoading, error } = useGetClientsQuery({
    pageNumber,
    filter,
  });
  const [searchValue, setSearchValue] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const visualization = useAppSelector(
    (state) => state.visualization.visualization
  );

  const searchClients = () => {
    if (searchValue.trim()) {
      navigate(
        `/clientes?page=1&filtro=${encodeURIComponent(searchValue.trim())}`
      );
    } else {
      navigate(`/clientes?page=1`);
    }
  };

  const renderState = () => {
    if (data && data.clientes.length > 0) {
      if (visualization === 'CARD') {
        return data?.clientes.map((client) => {
          return (
            <>
              <Grid item xs={12} sm={6} md={4} key={client.id}>
                <ClientCard key={client.id} client={client} />
              </Grid>
            </>
          );
        });
      } else {
        return (
          <Grid item xs={12} md={12}>
            <ClientsListingTable clients={data ? data.clientes : []} />
          </Grid>
        );
      }
    } else {
      return <SearchFeedback />;
    }
  };

  if (isFetching || isLoading) {
    return <Spinner />;
  }

  if (isError || error) {
    return <h2>Erro ao carregar dados</h2>;
  }

  return (
    <>
      <BeforeInsertDialog
        open={openDialog}
        toggle={() => setOpenDialog(!openDialog)}
      />
      <Grid container spacing={1} alignItems={'center'}>
        <Grid item xs={12} md={12}>
          <Stack direction={'row'} alignItems={'center'}>
            <Stack
              width={'100%'}
              direction={'row'}
              justifyContent={'left'}
              spacing={1}
            >
              <TextField
                size='small'
                value={searchValue}
                placeholder={'Pesquisar'}
                type='search'
                onChange={(ev) => setSearchValue(ev.target.value)}
                style={{ width: '52%' }}
                color='success'
                label='Pesquisar'
                onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    searchClients();
                  }
                }}
              />
              <IconButton
                onClick={() => searchClients()}
                sx={{
                  backgroundColor: Colors.primary,
                  '&:focus': {
                    backgroundColor: Colors.primary,
                  },
                  '&:hover': {
                    backgroundColor: 'green',
                  },
                  boxShadow: 3,
                }}
              >
                <Search sx={{ color: 'white' }} />
              </IconButton>
            </Stack>
            <IconButton
              onClick={() => setOpenDialog(true)}
              focusRipple
              sx={{
                backgroundColor: Colors.primary,
                '&:focus': {
                  backgroundColor: Colors.primary,
                },
                '&:hover': {
                  backgroundColor: 'green',
                },
                boxShadow: 3,
              }}
            >
              <Add sx={{ color: 'white' }} />
            </IconButton>
          </Stack>
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={2} mb={visualization === 'CARD' ? 2 : 0}>
        {renderState()}
      </Grid>
      {data && data?.totalPages > 1 && (
        <Pagination
          showFirstButton
          showLastButton
          page={page}
          count={data?.totalPages}
          sx={{
            '& .MuiPaginationItem-root': {
              '&.Mui-selected': {
                background: Colors.mainLayoutAtiveBackground,
                color: 'black',
              },
            },
          }}
          renderItem={(item) => (
            <PaginationItem
              component={Link}
              to={`/clientes${
                filter
                  ? `?page=${item.page}&filtro=${filter}`
                  : `?page=${item.page}`
              }`}
              {...item}
            />
          )}
        />
      )}
    </>
  );
};
export default ClientListPage;
