import {
  Card,
  Typography,
  CardContent,
  CardActionArea,
  Grid,
} from '@mui/material';
import axios from 'axios';

import { useAppDispatch } from '../../../store/hooks';
import { orderActions } from '../../../store/order/order-slice';
import { Client } from '../../../models/client/client.model';
import SummarizedClient from '../../../models/client/summarizedClient.model';
import { returnApiUrl } from '../../../store/order/order-async-actions';

import Colors from '../../../constants/Colors';

interface Props {
  client: Client;
  closeModal: () => void;
}

const OrderClientCard = (props: Props): JSX.Element => {
  const {
    apelido,
    categoria,
    nome,
    tipoPessoaId: pessoa,
    cnpjCpf,
    rg,
  } = props.client;
  const dispatch = useAppDispatch();

  const selectOrderClient = async () => {
    try {
      const API_URL = returnApiUrl();
      const response = await axios.get(
        `${API_URL}/cliente/resumo/${encodeURIComponent(props.client.id)}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      const resData: SummarizedClient = response.data;
      dispatch(orderActions.selectOrderClient(resData));
      props.closeModal();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Card
      sx={{
        height: '100%',
        marginTop: '2%',
        marginBottom: '2%',
        boxShadow: 3,
      }}
    >
      <CardActionArea onClick={selectOrderClient} sx={{ height: '100%' }}>
        <CardContent>
          <Typography
            variant='body2'
            fontWeight={'bold'}
            color={Colors.primary}
            fontSize={16}
          >
            {apelido}
          </Typography>
          <Typography variant='body2'>{nome}</Typography>
          <Typography variant='body2'>{categoria}</Typography>
          {pessoa === 'J' ? (
            <Typography variant='body2' color={'gray'}>
              {cnpjCpf}
            </Typography>
          ) : (
            <Grid container>
              <Grid item xs={6} md={6}>
                <Typography variant='body2' color={'gray'}>
                  {cnpjCpf}
                </Typography>
              </Grid>
              <Grid item xs={6} md={6} textAlign={'right'}>
                <Typography variant='body2' color={'gray'}>
                  {rg ? rg : ''}
                </Typography>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default OrderClientCard;
