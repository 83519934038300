import { Grid } from "@mui/material";

// import { useAppSelector } from '../../store/hooks';
import OrderProtocolCard from "../cards/order/OrderProtocolCard";
import OrderStatusCard from "../cards/order/OrderStatusCard";
import OrderDeadline from "../cards/order/OrderDeadline";
import OrderTypeCard from "../cards/order/OrderTypeCard";
import OrderObservationCard from "../cards/order/OrderObservationCard";
import OrderReserveCard from "../cards/order/OrderReserveCard";

const OrderHeader = () => {
  return (
    <Grid
      container
      justifyContent={"center"}
      alignItems={"center"}
      width={"100%"}
    >
      <Grid
        container
        mt={1}
        spacing={1}
        justifyContent={"center"}
        alignItems={"stretch"}
        width={{
          md: "100%",
          xs: "100%",
        }}
      >
        <OrderProtocolCard />
        <OrderStatusCard />
        <OrderDeadline />
        <OrderTypeCard />
        <OrderReserveCard />
        <OrderObservationCard />
      </Grid>
    </Grid>
  );
};

export default OrderHeader;
