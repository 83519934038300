import { StyleSheet, css } from "aphrodite";
import { Link, useMatch } from "react-router-dom";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import GroupIcon from "@mui/icons-material/GroupOutlined";
import LogoutIcon from "@mui/icons-material/LogoutOutlined";
import CartIcon from "@mui/icons-material/ShoppingCartOutlined";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Divider from "@mui/material/Divider";
import { FiTarget } from "react-icons/fi";
import { IoAppsOutline, IoReceiptOutline } from "react-icons/io5";
import { MdEditNote } from "react-icons/md";

import logoIvitech_colorido from "../../assets/imgs/logoivitech_colorido.png";
import ivitech_reduced from "../../assets/imgs/ivitech_reduced.png";

import "../../assets/styles/drawer.css";

interface Props {
  open: boolean;
  openDialog: boolean;
  openQuerySubMenu: boolean;
  toggleDialog: () => void;
  toggleQueryMenu: () => void;
}

const DrawerItemsList = (props: Props) => {
  const { open, openDialog, openQuerySubMenu, toggleDialog, toggleQueryMenu } =
    props;
  return (
    <>
      <List style={{ marginTop: "-0.5rem" }}>
        <Link to={"/produtos"} className={css(styles.noDecoration)}>
          <ListItemButton
            selected={!!useMatch("/produtos/*")}
            key={"Produtos"}
            sx={{
              minHeight: 15,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 1.4 : "auto",
                ml: open ? "" : -0.2,
                justifyContent: "center",
              }}
            >
              <IoAppsOutline size={20} />
            </ListItemIcon>
            <ListItemText primary={"Produtos"} sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </Link>
        <Link to={"/clientes"} className={css(styles.noDecoration)}>
          <ListItemButton
            selected={!!useMatch("/clientes/*")}
            key={"Clientes"}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 1.4 : "auto",
                ml: open ? "" : -0.2,
                justifyContent: "center",
              }}
            >
              <GroupIcon />
            </ListItemIcon>
            <ListItemText primary={"Clientes"} sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </Link>
        <Link to={"/vendas"} className={css(styles.noDecoration)}>
          <ListItemButton
            selected={!!useMatch("/vendas/*")}
            key={"Vendas"}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 1.4 : "auto",
                ml: open ? "" : -0.2,
                justifyContent: "center",
              }}
            >
              <CartIcon />
            </ListItemIcon>
            <ListItemText primary={"Vendas"} sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </Link>
        <ListItemButton
          onClick={toggleQueryMenu}
          selected={!!useMatch("/consultas/*")}
          key={"Consultas"}
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 1.4 : "auto",
              ml: open ? "" : -0.2,
              justifyContent: "center",
            }}
          >
            <QueryStatsIcon />
          </ListItemIcon>
          <ListItemText primary={"Consultas"} sx={{ opacity: open ? 1 : 0 }} />
          {openQuerySubMenu ? (
            <ExpandLess style={{ width: 20 }} color="action" />
          ) : (
            <ExpandMore style={{ width: 20 }} color="action" />
          )}
        </ListItemButton>
        {openQuerySubMenu && <Divider />}
        <Collapse in={openQuerySubMenu} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Link
              to={"/consultas/pedidos"}
              className={css(styles.noDecoration)}
            >
              <ListItemButton
                sx={{
                  minHeight: 15,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                selected={!!useMatch("/consultas/pedidos/*")}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 1.4 : "auto",
                    ml: open ? "" : 0.2,
                    justifyContent: "center",
                  }}
                >
                  <MdEditNote size={20} />
                </ListItemIcon>
                <ListItemText
                  primary="Pedidos"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </Link>
            <Link
              to={"/consultas/faturamentos"}
              className={css(styles.noDecoration)}
            >
              <ListItemButton
                sx={{
                  minHeight: 15,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                selected={!!useMatch("/consultas/faturamentos/*")}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 1.4 : "auto",
                    ml: open ? "" : -0.2,
                    justifyContent: "center",
                  }}
                >
                  <IoReceiptOutline size={20} />
                </ListItemIcon>
                <ListItemText
                  primary="Faturamento"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </Link>
            <Link to={"/consultas/metas"} className={css(styles.noDecoration)}>
              <ListItemButton
                sx={{
                  minHeight: 15,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                selected={!!useMatch("/consultas/metas/*")}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 1.4 : "auto",
                    ml: open ? "" : -0.2,
                    justifyContent: "center",
                  }}
                >
                  <FiTarget size={20} />
                </ListItemIcon>
                <ListItemText primary="Metas" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </Link>
          </List>
        </Collapse>
        <Divider />
        <ListItemButton
          key={"Sair"}
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
          onClick={toggleDialog}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 1.4 : "auto",
              ml: open ? "" : -0.2,
              justifyContent: "center",
            }}
          >
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary={"Sair"} sx={{ opacity: open ? 1 : 0 }} />
        </ListItemButton>
      </List>

      <div>
        <Link to={"https://ivitech.com.br"} target="_blank">
          <img
            src={open ? logoIvitech_colorido : ivitech_reduced}
            style={{
              position: "absolute",
              bottom: 20,
              left: open ? "25%" : "15%",
              width: open ? "6.5rem" : "2.5rem",
            }}
          />
        </Link>
      </div>
    </>
  );
};

const styles = StyleSheet.create({
  noDecoration: {
    textDecoration: "none",
    color: "inherit",
  },
});

export default DrawerItemsList;
