import { Modal, Card, CardContent } from '@mui/material';

import ProductPriceTable from '../../../models/product/productPriceTable.model';
import OrderTableCard from '../../cards/order/OrderTableCard';

interface Props {
  tables: ProductPriceTable[];
  openModal: boolean;
  toggle: () => void;
}

const OrderTableSelectionModal = (props: Props) => {
  return (
    <Modal
      open={props.openModal}
      onClose={props.toggle}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Card
        sx={{
          height: '70vh',
          overflowY: 'scroll',
          width: { md: '25%', xs: '70%' },
        }}
      >
        <CardContent>
          {props.tables.map((table) => {
            return (
              <OrderTableCard
                key={table.id}
                id={table.id}
                nome={table.nome}
                toggle={props.toggle}
              />
            );
          })}
        </CardContent>
      </Card>
    </Modal>
  );
};

export default OrderTableSelectionModal;
