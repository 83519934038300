import { Modal, Alert, AlertTitle, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface Props {
  openModal: boolean;
}

const ClientMutationSuccess = (props: Props) => {
  const navigate = useNavigate();

  const handleEnterPress = (ev: React.KeyboardEvent<HTMLDivElement>) => {
    if (ev.key === 'Enter') {
      navigate('/clientes');
    }
  };

  return (
    <Modal
      open={props.openModal}
      onClose={() => navigate('/clientes')}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onKeyPress={(ev) => handleEnterPress(ev)}
    >
      <Alert
        sx={{
          width: { md: '35%', sm: '65%', xs: '80%' },
          alignItems: 'center',
        }}
        action={
          <Button
            variant='outlined'
            color='success'
            onClick={() => navigate('/clientes')}
          >
            Ok
          </Button>
        }
      >
        <AlertTitle>Sucesso</AlertTitle>
        Operação realizada com sucesso!
      </Alert>
    </Modal>
  );
};

export default ClientMutationSuccess;
