import { CircularProgress } from '@mui/material';
import { StyleSheet, css } from 'aphrodite';
import Colors from '../../constants/Colors';

const Spinner = (): JSX.Element => {
  return (
    <div className={css(styles.spinner)}>
      <CircularProgress sx={{ color: Colors.primary }} size={70} />
    </div>
  );
};

const styles = StyleSheet.create({
  spinner: {
    display: 'flex',
    flexGrow: 1,
    position: 'fixed',
    top: '30%',
    left: '50%',
    height: '100vh',
  },
});

export default Spinner;
