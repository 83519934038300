interface Address {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  codigoCidade: string;
}

const getAddressFromCep = async (cep: string): Promise<Address> => {
  return new Promise<Address>(async (resolve, reject) => {
    try {
      const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
      const resData = await response.json();
      if (resData.erro) {
        reject('Não foi possível encontrar endereço com o CEP informado');
      } else {
        const address: Address = {
          cep: resData.cep,
          logradouro: resData.logradouro,
          complemento: resData.complemento,
          bairro: resData.bairro,
          localidade: resData.localidade,
          uf: resData.uf,
          codigoCidade: resData.ibge,
        };
        resolve(address);
      }
    } catch (err) {
      reject('Não foi possível encontrar endereço com o CEP informado');
    }
  });
};

export default getAddressFromCep;
