import { Typography, Grid } from '@mui/material';
import { SentimentVeryDissatisfied } from '@mui/icons-material';

const NoClientFound = (): JSX.Element => {
  return (
    <Grid
      container
      width={'100%'}
      height={'50vh'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Grid
        item
        xs={12}
        md={4}
        height={'20%'}
        display={'inline-flex'}
        textAlign={'center'}
        justifyContent={'center'}
        alignItems={'center'}
        bgcolor={'#ffcccb'}
        borderRadius={5}
        boxShadow={1}
        color={'#ba000d'}
      >
        <Typography>Cliente não encontrado{'  '}</Typography>
        <Typography></Typography>
        <Typography>
          <SentimentVeryDissatisfied />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default NoClientFound;
