import { useState, useEffect } from 'react';
import {
  Modal,
  Card,
  CardContent,
  TextField,
  Typography,
  Stack,
  Grid,
  Button,
} from '@mui/material';

import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import { orderActions } from '../../../store/order/order-slice';
import formatNumberToCurrency from '../../../util/formaters/formatNumberToCurrency';

import Colors from '../../../constants/Colors';

interface Props {
  openModal: boolean;
  toggle: () => void;
}

const OrderIncreaseModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const order = useAppSelector((state) => state.order.order);
  const [percentageOrValue, setPercentageOrValue] = useState<
    'PERCENTAGE' | 'VALUE'
  >('VALUE');
  const [percentage, setPercentage] = useState(0);
  const [percentageString, setPercentageString] = useState('');
  const [value, setValue] = useState(0);
  const [valueString, setValueString] = useState('');
  const [validityErrors, setValidityErrors] = useState({
    increasePercentageError: false,
    increaseValueError: false,
  });

  useEffect(() => {
    if (percentageOrValue === 'PERCENTAGE') {
      setValue(0);
      setValueString('');
    } else {
      setPercentage(0);
      setPercentageString('');
    }
  }, [
    percentageOrValue,
    setValue,
    setValueString,
    setPercentage,
    setPercentageString,
  ]);

  const handleIncreaseChange = (
    ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: 'PERCENTAGE' | 'VALUE'
  ) => {
    if (ev.target.value === '') {
      if (type === 'PERCENTAGE') {
        if (validityErrors.increasePercentageError) {
          let errors = { ...validityErrors };
          errors.increasePercentageError = false;
          setValidityErrors({ ...errors });
        }
        setPercentage(0);
        setPercentageString(ev.target.value);
      } else {
        if (validityErrors.increaseValueError) {
          let errors = { ...validityErrors };
          errors.increaseValueError = false;
          setValidityErrors({ ...errors });
        }
        setValue(0);
        setValueString(ev.target.value);
      }
      return;
    }
    let floatValue = 0;
    let validForParsing = ev.target.value.match(/^(\d+)(?:(\.|,)\d{1,2})?$/);

    if (validForParsing) {
      if (ev.target.value.includes(',')) {
        let newValue = ev.target.value.replace(',', '.');
        floatValue = parseFloat(newValue);
      } else {
        floatValue = parseFloat(ev.target.value);
      }
      if (type === 'PERCENTAGE') {
        if (validityErrors.increasePercentageError) {
          let errors = { ...validityErrors };
          errors.increasePercentageError = false;
          setValidityErrors({ ...errors });
        }
        setPercentage(floatValue);
        setPercentageString(ev.target.value);
      } else {
        if (validityErrors.increaseValueError) {
          let errors = { ...validityErrors };
          errors.increaseValueError = false;
          setValidityErrors({ ...errors });
        }
        setValue(floatValue);
        setValueString(ev.target.value);
      }
    } else {
      if (type === 'PERCENTAGE') {
        let errors = { ...validityErrors };
        errors.increasePercentageError = true;
        setPercentageString(ev.target.value);
        setValidityErrors({ ...errors });
      } else {
        let errors = { ...validityErrors };
        errors.increaseValueError = true;
        setValueString(ev.target.value);
        setValidityErrors({ ...errors });
      }
    }
  };

  const resetFields = () => {
    setPercentageOrValue('VALUE');
    setPercentage(0);
    setPercentageString('');
    setValue(0);
    setValueString('');
  };

  const critiques = () => {
    const hasErrors =
      validityErrors.increasePercentageError ||
      validityErrors.increaseValueError;

    if (
      (percentageOrValue === 'VALUE' && !value) ||
      (percentageOrValue === 'PERCENTAGE' && !percentage) ||
      hasErrors
    ) {
      return true;
    }

    return false;
  };

  const applyIncrease = () => {
    if (critiques()) {
      return;
    }
    if (value) {
      dispatch(
        orderActions.setOrderGlobalIncrease({ value, type: percentageOrValue })
      );
    } else {
      dispatch(
        orderActions.setOrderGlobalIncrease({
          value: percentage,
          type: percentageOrValue,
        })
      );
    }
    props.toggle();
  };

  return (
    <Modal
      open={props.openModal}
      onClose={props.toggle}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Card sx={{ height: '75vh', width: '30%', p: 2 }}>
        <CardContent>
          <Typography variant='body1'>
            Acréscimo sobre o valor total dos produtos
          </Typography>
          <Typography variant='body2'>
            O acréscimo informado será somado aos acréscimos já aplicados aos
            produtos
          </Typography>
          <Stack
            spacing={1}
            borderTop={1}
            borderBottom={1}
            mt={1}
            mb={4}
            borderColor={'gray'}
            pt={1}
            pb={1}
          >
            <Typography variant='body1' textAlign={'center'}>
              Outros acréscimos
            </Typography>
            <Stack justifyContent={'space-between'} direction={'row'}>
              <Typography variant={'body2'}>Itens</Typography>
              <Typography variant={'body2'}>
                {formatNumberToCurrency(
                  order.totais.acrescimos.itens.total
                    ? order.totais.acrescimos.itens.total
                    : 0
                )}
              </Typography>
            </Stack>
            <Stack justifyContent={'space-between'} direction={'row'}>
              <Typography variant={'body2'} fontWeight={'Bold'}>
                Total
              </Typography>
              <Typography variant={'body2'} fontWeight={'Bold'}>
                {formatNumberToCurrency(
                  order.totais.acrescimos.total
                    ? order.totais.acrescimos.total
                    : 0
                )}
              </Typography>
            </Stack>
          </Stack>
          <Grid
            container
            mb={2}
            mt={1}
            border={1}
            borderRadius={2}
            borderColor={'gray'}
            overflow={'hidden'}
            alignItems={'center'}
          >
            <Grid item xs={6} md={6}>
              <Button
                onClick={() => setPercentageOrValue('PERCENTAGE')}
                fullWidth
                sx={{
                  backgroundColor:
                    percentageOrValue === 'PERCENTAGE' ? Colors.primary : '',
                  color: percentageOrValue === 'PERCENTAGE' ? 'white' : 'black',
                  '&:hover': {
                    backgroundColor:
                      percentageOrValue === 'PERCENTAGE' ? Colors.primary : '',
                    color:
                      percentageOrValue === 'PERCENTAGE' ? 'white' : 'black',
                  },
                  height: '100%',
                }}
              >
                Em Percentual
              </Button>
            </Grid>
            <Grid item xs={6} md={6}>
              <Button
                onClick={() => setPercentageOrValue('VALUE')}
                fullWidth
                style={{ height: '100%' }}
                sx={{
                  backgroundColor:
                    percentageOrValue === 'VALUE' ? Colors.primary : '',
                  color: percentageOrValue === 'VALUE' ? 'white' : 'black',
                  '&:hover': {
                    backgroundColor:
                      percentageOrValue === 'VALUE' ? Colors.primary : '',
                    color: percentageOrValue === 'VALUE' ? 'white' : 'black',
                  },
                }}
              >
                Em dinheiro
              </Button>
            </Grid>
          </Grid>
          {percentageOrValue === 'PERCENTAGE' && (
            <TextField
              fullWidth
              error={validityErrors.increasePercentageError}
              size='small'
              label={'%'}
              value={percentageString}
              onChange={(ev) => handleIncreaseChange(ev, percentageOrValue)}
              helperText={
                validityErrors.increasePercentageError
                  ? 'O valor informado é inválido'
                  : ''
              }
            />
          )}
          {percentageOrValue === 'VALUE' && (
            <TextField
              fullWidth
              error={validityErrors.increaseValueError}
              size='small'
              label={'R$'}
              value={valueString}
              onChange={(ev) => handleIncreaseChange(ev, percentageOrValue)}
              helperText={
                validityErrors.increaseValueError
                  ? 'O valor informado é inválido'
                  : ''
              }
            />
          )}
          <Stack mt={6} justifyContent={'space-between'} direction={'row'}>
            <Button
              onClick={() => {
                resetFields();
                props.toggle();
              }}
            >
              Cancelar
            </Button>
            <Button
              sx={{ color: Colors.primary }}
              onClick={() => applyIncrease()}
            >
              Ok
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default OrderIncreaseModal;
