import OrderQueriesParams from '../../models/requisitions/PaginatedOrderQueryRequisition.model';

const ORDER_QUERIES_PARAMS_INITIALIZER: OrderQueriesParams = {
  cliente: '',
  emissaoFinal: '',
  emissaoInicial: '',
  fasePedidoId: [],
  page: 1,
  pedidoId: '',
  previsaoFinal: '',
  previsaoInicial: '',
  situacaoId: [],
};

export default ORDER_QUERIES_PARAMS_INITIALIZER;
