interface ErrorState {
  state: boolean;
  message: string;
  cep: string;
}

const validateCep = (cep: string): ErrorState => {
  cep = cep.replace(/\D/g, '');
  if (cep.length > 8 || cep.length < 7) {
    let error: ErrorState = {
      state: true,
      message: 'A quantidade de dígitos do CEP informado é invalida',
      cep: cep,
    };
    return error;
  } else if (cep.length === 7) {
    let formattedCep: string = '';
    formattedCep = `0${cep.substring(0, 1)}.${cep.substring(
      1,
      4
    )}-${cep.substring(4, 7)}`;
    let error: ErrorState = {
      state: false,
      message: '',
      cep: formattedCep,
    };
    return error;
  } else {
    let formattedCep: string = '';
    formattedCep = `${cep.substring(0, 2)}.${cep.substring(
      2,
      5
    )}-${cep.substring(5, 8)}`;
    let error: ErrorState = {
      state: false,
      message: '',
      cep: formattedCep,
    };
    return error;
  }
};

export default validateCep;
