import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import OrdersQueriesParams from '../../models/requisitions/PaginatedOrderQueryRequisition.model';
import ORDER_QUERIES_PARAMS_INITIALIZER from '../../util/initializers/OrderQueriesParamsInitializer';

const initialState: OrdersQueriesParams = ORDER_QUERIES_PARAMS_INITIALIZER;

const orderQueryParamsSlice = createSlice({
  initialState,
  name: 'orderQueryParams',
  reducers: {
    changeNumber(state: OrdersQueriesParams, action: PayloadAction<string>) {
      state.pedidoId = action.payload;
    },
    changeInicialEmission(
      state: OrdersQueriesParams,
      action: PayloadAction<string>
    ) {
      state.emissaoInicial = action.payload;
    },
    changeFinalEmission(
      state: OrdersQueriesParams,
      action: PayloadAction<string>
    ) {
      state.emissaoFinal = action.payload;
    },
    changeInicialPrevision(
      state: OrdersQueriesParams,
      action: PayloadAction<string>
    ) {
      state.previsaoInicial = action.payload;
    },
    changeFinalPrevision(
      state: OrdersQueriesParams,
      action: PayloadAction<string>
    ) {
      state.previsaoFinal = action.payload;
    },
    changeClientId(state: OrdersQueriesParams, action: PayloadAction<string>) {
      state.cliente = action.payload;
    },
    changePhase(state: OrdersQueriesParams, action: PayloadAction<string[]>) {
      state.fasePedidoId = [...action.payload];
    },
    changeSituation(
      state: OrdersQueriesParams,
      action: PayloadAction<string[]>
    ) {
      state.situacaoId = [...action.payload];
    },
    changePage(state: OrdersQueriesParams, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setQueryState(
      state: OrdersQueriesParams,
      action: PayloadAction<OrdersQueriesParams>
    ) {
      state.cliente = action.payload.cliente;
      state.emissaoFinal = action.payload.emissaoFinal;
      state.emissaoInicial = action.payload.emissaoInicial;
      state.previsaoFinal = action.payload.previsaoFinal;
      state.previsaoInicial = action.payload.previsaoInicial;
      state.page = action.payload.page;
      state.pedidoId = action.payload.pedidoId;
      state.situacaoId = [...action.payload.situacaoId];
      state.fasePedidoId = [...action.payload.fasePedidoId];
    },
    resetOrderQuery() {
      return {
        ...initialState,
      };
    },
  },
});

export const orderQueryParamsActions = orderQueryParamsSlice.actions;

export default orderQueryParamsSlice;
