import PaymentConditions from '../../models/order/paymentConditions.model';

const PAYMENT_CONDITION_INITIALIZER: PaymentConditions = {
  acrescimoPerc: 0,
  descontoPerc: 0,
  id: '',
  inativo: false,
  nome: '',
  parcelas: 0,
  prazoMedio: 0,
  tipoDocumentoTitulo: {
    codigo: '',
    nome: '',
  },
};

export default PAYMENT_CONDITION_INITIALIZER;
