import { useEffect } from 'react';
import { Grid, Pagination } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useGetOrdersWithParamsQuery } from '../../api/ordersApi';
import { orderSearchActions } from '../../store/search/order-search-slice';
import OrderSearch from '../../models/requisitions/PaginatedOrderSearchRequisition.model';
import OrdersListingTable from '../../components/tables/OrdersListingTable';
import OrderSummaryCard from '../../components/cards/order/OrderSummaryCard';
import Colors from '../../constants/Colors';

import Spinner from '../../components/layout/Spinner';
import SearchFeedback from '../../components/feedback/SearchFeedback';

const ListSales = (): JSX.Element => {
  const visualization = useAppSelector(
    (state) => state.visualization.visualization
  );
  const searchParams = useAppSelector((state) => state.orderSearch);
  const { data, isError, isFetching, isLoading } =
    useGetOrdersWithParamsQuery(searchParams);

  const dispatch = useAppDispatch();

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    dispatch(orderSearchActions.changePage(value));
  };

  useEffect(() => {
    if (!!localStorage.getItem('orderSearchParams')) {
      let newParams: any = localStorage.getItem('orderSearchParams');
      let formattedParams = JSON.parse(newParams) as OrderSearch;
      // console.log(formattedParams);
      dispatch(orderSearchActions.setSearchState(formattedParams));
    }
  }, [dispatch]);

  if (isFetching || isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return <h2>Erro ao carrgar dados dos pedidos</h2>;
  }

  if (data && data.pedidos.length === 0) {
    return <SearchFeedback />;
  }

  return (
    <>
      {visualization === 'CARD' && (
        <Grid container spacing={2}>
          {data?.pedidos.map((order) => {
            return <OrderSummaryCard order={order} />;
          })}
        </Grid>
      )}
      {visualization === 'TABLE' && (
        <OrdersListingTable orders={data ? data.pedidos : []} />
      )}
      {data && data?.totalPages > 1 && (
        <Pagination
          size='small'
          showFirstButton
          showLastButton
          page={searchParams.page}
          count={data?.totalPages}
          sx={{
            '& .MuiPaginationItem-root': {
              '&.Mui-selected': {
                background: Colors.mainLayoutAtiveBackground,
                color: 'black',
              },
            },
            width: '100%',
          }}
          onChange={handleChange}
        />
      )}
    </>
  );
};

export default ListSales;
