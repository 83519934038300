import { useState, useEffect } from 'react';
import { Grid, Card, CardContent, Stack, Typography } from '@mui/material';

import formatNumberToCurrency from '../../../util/formaters/formatNumberToCurrency';
import BillingQueryResult from '../../../models/queries/BillingQueryResult.model';

interface Props {
  results: BillingQueryResult[];
}

const BillingsQueryResultsTotalizerCard = (props: Props) => {
  const { results } = props;
  const [numberOfResults, setNumberOfResults] = useState(results.length);
  const [resultsTotal, setResultsTotal] = useState(0);

  useEffect(() => {
    setNumberOfResults(results.length);
    const total = results.reduce((acc, currValue) => {
      return acc + currValue.totalNotaFiscal;
    }, 0);
    setResultsTotal(total);
  }, [results, setNumberOfResults, setResultsTotal]);

  return (
    <Grid item xs={12} md={12}>
      <Card sx={{ width: '100%', boxShadow: 2 }}>
        <CardContent
          sx={{
            mt: -0.5,
            padding: 0.5,
            '&:last-child': {
              paddingBottom: 0.25,
            },
          }}
        >
          <Stack
            direction={'row'}
            width={'100%'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Typography variant='body2' fontWeight={'Bold'} mt={1}>
              Total de notas : {numberOfResults}
            </Typography>
            <Typography variant='body2' fontWeight={'Bold'} mt={1}>
              Valor total das notas :{' '}
              {resultsTotal ? formatNumberToCurrency(resultsTotal) : 'R$ 0,00'}
            </Typography>
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default BillingsQueryResultsTotalizerCard;
