import { Card, CardContent, Typography, Grid } from "@mui/material";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { StyleSheet, css } from "aphrodite";

import { useAppSelector } from "../../../store/hooks";
import Colors from "../../../constants/Colors";
import { LocalOffer } from "@mui/icons-material";
import ProductPrice from "../../../models/product/productPrice.model";
import formatNumberToCurrency from "../../../util/formaters/formatNumberToCurrency";

interface Props {
  precoproduto: ProductPrice[];
  percentIpi: number;
}

const ProductPriceCard = (props: Props): JSX.Element => {
  const tabelaPadraoId = useAppSelector(
    (state) => state.tenantConfiguration.pedidoVenda.tabelaPrecoPadraoId
  );
  const { precoproduto } = props;
  const { percentIpi } = props;

  let qtde = precoproduto.length;
  return (
    <Card sx={{ boxShadow: 3 }}>
      <CardContent>
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            md={12}
            sx={{ backgroundColor: Colors.titleRowWithBackgroundColor }}
            alignItems={"center"}
          >
            <Typography variant="body2" color={Colors.titleWithBackgroudColor}>
              <LocalOffer fontSize={"inherit"} /> Preços
            </Typography>
          </Grid>
          {percentIpi ? (
            <Table className={css(styles.table)}>
              <Thead className="table_head">
                <Tr>
                  <Th>TABELA</Th>
                  <Th>PREÇO</Th>
                  <Th>IPI</Th>
                  <Th>PREÇO + IPI</Th>
                </Tr>
              </Thead>
              <Tbody>
                {precoproduto.map((table) => {
                  let parity = qtde % 2;
                  qtde = qtde - 1;

                  let formatValue =
                    Math.round(table.preco * (percentIpi / 100) * 100) / 100;
                  let totalIPI =
                    Math.round((table.preco + formatValue) * 100) / 100;

                  return (
                    <Tr
                      className={
                        table.tabelaPrecoId === tabelaPadraoId
                          ? "main_row"
                          : parity === 1
                          ? "odd_row"
                          : "even_row"
                      }
                    >
                      <Td className="table_text">{table.nome}</Td>
                      <Td style={{ textAlign: "right" }} className="table_text">
                        {formatNumberToCurrency(table.preco)}
                      </Td>
                      <Td style={{ textAlign: "right" }} className="table_text">
                        {formatNumberToCurrency(formatValue)}
                      </Td>
                      <Td style={{ textAlign: "right" }} className="table_text">
                        {formatNumberToCurrency(totalIPI)}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          ) : (
            precoproduto.map((table) => {
              console.log(table);
              if (table.tabelaPrecoId === tabelaPadraoId) {
                return (
                  <Grid
                    container
                    mt={1}
                    sx={{ backgroundColor: Colors.defaultPriceTable }}
                  >
                    <Grid item xs={6} md={6}>
                      <Typography variant="body2" fontWeight={"bold"}>
                        {table.nome}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={6} textAlign={"end"}>
                      <Typography variant="body2" fontWeight={"bold"}>
                        {formatNumberToCurrency(table.preco)}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              } else {
                return (
                  <Grid container mt={1}>
                    <Grid item xs={6} md={6}>
                      <Typography variant="body2">{table.nome}</Typography>
                    </Grid>
                    <Grid item xs={6} md={6} textAlign={"end"}>
                      <Typography variant="body2">
                        {formatNumberToCurrency(table.preco)}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              }
            })
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

const styles = StyleSheet.create({
  table: {
    fontSize: 13,
    display: "table",
  },
});

export default ProductPriceCard;
