import { useState } from 'react';
import { Tooltip, Stack, Typography, IconButton } from '@mui/material';
import { VisibilityOutlined } from '@mui/icons-material';

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { StyleSheet, css } from 'aphrodite';

import { useAppSelector } from '../../store/hooks';
import formatNumberToCurrencyNoSymbol from '../../util/formaters/formatNumberToCurrencyNoSymbol';

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import '../../styles/style.css';
import Colors from '../../constants/Colors';
import Goal from '../../models/queries/Goals.model';
import formatNumberToCurrency from '../../util/formaters/formatNumberToCurrency';

interface Props {
  results: Goal[];
}

const GoalsQueryResultTable = (props: Props) => {
  const { results } = props;

  let qtde = results.length;

  if (results.length === 0) {
    return null;
  }

  return (
    <>
      <Table className={css(styles.table)}>
        <Thead className='table_head'>
          <Tr>
            <Th>Meta</Th>
            <Th>Descrição</Th>
            <Th>Vigência</Th>
            <Th>Vr. Meta</Th>
            <Th>Vr. Realizado</Th>
            <Th>% Realizado</Th>
          </Tr>
        </Thead>
        <Tbody>
          {results.map((result) => {
            var parity = qtde % 2;
            qtde = qtde - 1;
            return (
              <Tr
                key={result.meta}
                className={parity === 1 ? 'odd_row' : 'even_row'}
              >
                <Td className='table_number_cell'>{result.meta}</Td>
                <Td className='table_text'>{result.nome}</Td>
                <Td className='table_actions'>
                  {result.vigenciaInicio} a {result.vigenciaFim}
                </Td>
                <Td className='table_number_cell'>
                  {result.valorMeta
                    ? formatNumberToCurrency(result.valorMeta)
                    : 'R$ 0,00'}
                </Td>
                <Td className='table_number_cell'>
                  {result.venda.valorVenda
                    ? formatNumberToCurrency(result.venda.valorVenda)
                    : 'R$ 0,00'}
                </Td>
                <Td className='table_number_cell'>
                  {result.venda.percentual
                    ? formatNumberToCurrencyNoSymbol(result.venda.percentual)
                    : '0,00'}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </>
  );
};

const styles = StyleSheet.create({
  table: {
    fontSize: 13,
    display: 'table',
  },
});

export default GoalsQueryResultTable;
