import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Button } from '@mui/material';
import { Search } from '@mui/icons-material';

import { useAppSelector } from '../../store/hooks';
import OrderQueryParamsCard from '../../components/cards/queries/OrdersQueryParamsCard';

const OrdersQueriesFiltersPage = () => {
  const navigate = useNavigate();
  const [initialEmissionError, setInitialEmissionError] = useState(false);
  const [finalEmissionError, setFinalEmissionError] = useState(false);
  const [initialPrevisionError, setInicialPrevisionError] = useState(false);
  const [finalPrevisionError, setFinalPrevisionError] = useState(false);

  const queryParams = useAppSelector((state) => state.orderQueryParams);

  const navigateToResult = () => {
    const hasErrors =
      initialEmissionError ||
      finalEmissionError ||
      initialPrevisionError ||
      finalPrevisionError;

    if (hasErrors) return;

    const orderSearchParams = JSON.stringify(queryParams);
    localStorage.setItem('orderQueryParams', orderSearchParams);
    navigate('/consultas/pedidos/resultado');
  };

  return (
    <Grid
      container
      justifyContent={'center'}
      alignItems={'center'}
      width={'100%'}
    >
      <Grid
        container
        spacing={2}
        justifyContent={'center'}
        alignItems={'center'}
        width={{
          md: '40%',
          xs: '95%',
        }}
      >
        <OrderQueryParamsCard
          finalEmissionError={finalEmissionError}
          inicialEmissionError={initialEmissionError}
          finalPrevisionError={finalPrevisionError}
          inicialPrevisionError={initialPrevisionError}
          toggleInicialEmissionError={(errorValue: boolean) =>
            setInitialEmissionError(errorValue)
          }
          toggleFinalEmissionError={(errorValue: boolean) =>
            setFinalEmissionError(errorValue)
          }
          toggleInicialPrevisionError={(errorValue: boolean) =>
            setInicialPrevisionError(errorValue)
          }
          toggleFinalPrevisionError={(errorValue: boolean) =>
            setFinalPrevisionError(errorValue)
          }
        />
        <Grid item xs={12} md={12} textAlign={'center'}>
          <Button onClick={navigateToResult} variant={'outlined'}>
            <Search />
            Consultar
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OrdersQueriesFiltersPage;
