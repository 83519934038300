import { Card, CardContent, Typography } from '@mui/material';
import Colors from '../../../constants/Colors';

interface Props {
  id: string;
  name: string;
  unit: string;
}

const ProductDetailCard = (props: Props): JSX.Element => {
  const { id, name, unit } = props;

  return (
    <Card sx={{ boxShadow: 2 }}>
      <CardContent>
        <Typography
          variant='body2'
          fontWeight={'bold'}
          color={Colors.primary}
          fontSize={16}
          mb={2}
        >
          {id}
        </Typography>
        <Typography
          variant='body2'
          color={'gray'}
          fontSize={11}
          fontWeight={'bold'}
        >
          NOME
        </Typography>
        <Typography variant='body2' mb={1}>
          {name}
        </Typography>
        <Typography
          variant='body2'
          color={'gray'}
          fontSize={11}
          fontWeight={'bold'}
        >
          UM
        </Typography>
        <Typography variant='body2' mb={1}>
          {unit}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ProductDetailCard;
