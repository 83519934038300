import { createApi } from '@reduxjs/toolkit/query/react';

import { Client } from '../models/client/client.model';
import FullClient from '../models/client/fullClient.model';
import SummarizedClient from '../models/client/summarizedClient.model';
import PaginatedRequisition from '../models/requisitions/PaginatedRequisition';

import dynamicBaseQuery from './dynamicBaseQuery';

interface GetClients {
  clientes: Client[];
  totalPages: number;
}

export const clientsApi = createApi({
  reducerPath: 'clientsApi',
  tagTypes: ['Clients'],

  refetchOnMountOrArgChange: true,
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    getClients: builder.query<GetClients, PaginatedRequisition>({
      query: (reqParams: PaginatedRequisition) => {
        let req;
        if (!reqParams.filter) {
          req = { page: `${reqParams.pageNumber}` };
        } else {
          req = { page: `${reqParams.pageNumber}`, filtro: reqParams.filter };
        }
        // console.log(req);
        return {
          url: `/cliente`,
          params: req,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      providesTags: ['Clients'],
    }),
    getFullClients: builder.query<FullClient[], void>({
      query: () => '/clientes',
      providesTags: ['Clients'],
    }),
    getClient: builder.query<FullClient, string>({
      query: (id: string) => ({
        url: `/cliente/${id}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }),
      providesTags: ['Clients'],
    }),
    getSummarizedClient: builder.query<SummarizedClient, string>({
      query: (id: string) => ({
        url: `/cliente/resumo/${id}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }),
      providesTags: ['Clients'],
    }),
    mutateClient: builder.mutation<void, FullClient>({
      query: (client: FullClient) => ({
        url: '/cliente',
        method: 'POST',
        body: client,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }),
      invalidatesTags: ['Clients'],
    }),
  }),
});

export const {
  useGetClientsQuery,
  useGetFullClientsQuery,
  useGetClientQuery,
  useGetSummarizedClientQuery,
  useMutateClientMutation,
} = clientsApi;
