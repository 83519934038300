import SummarizedClient from '../../models/client/summarizedClient.model';

const SUMMARIZED_CLIENT_INITIALIZER: SummarizedClient = {
  id: '',
  nome: '',
  apelido: '',
  cnpjCpf: '',
  tipoPessoaId: 'F',
  categoria: '',
  rg: '',
  endereco: {
    uf: '',
    cep: '',
    numero: '',
    localidade: '',
    logradouro: '',
    complemento: '',
    predioProprio: false,
    sublocalidade: '',
    pontoReferencia: '',
    tempoResidencia: 0,
  },
};

export default SUMMARIZED_CLIENT_INITIALIZER;
