import OrderSituation from '../../models/order/orderSituation.model';
// import SITUACAO_PEDIDO from '../../data/situacaoPedidoData';

const ORDER_SITUATION_INITIALIZER: OrderSituation = {
  cor: 'red',
  id: '-2',
  nome: 'Em digitação',
  permiteEdicao: true,
};

export default ORDER_SITUATION_INITIALIZER;
