import { useState, useEffect } from "react";
import _ from "lodash";
import {
  Button,
  Grid,
  TextField,
  IconButton,
  CircularProgress,
} from "@mui/material";
import {
  LocalOfferOutlined,
  Add,
  Search,
  CoPresentOutlined,
} from "@mui/icons-material";

import Spinner from "../layout/Spinner";

import { orderActions } from "../../store/order/order-slice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useGetAllTablesQuery } from "../../api/productPriceTableApi";
import { Product } from "../../models/product/product.model";

import Colors from "../../constants/Colors";
import OrderTableSelectionModal from "../modals/order/OrderTableSelectionModal";
import ProductPriceTable from "../../models/product/productPriceTable.model";
import OrderItem from "../../models/order/orderItem.model";
import OrderItemsTable from "../tables/OrderItemsTable";
import OrderTotalsCard from "../cards/order/OrderTotalsCard";
import roundAbnt from "../../util/formaters/roundAbnt";
import { percentageOf } from "../../util/calculations/utilCalculations";
import ItemDuplicityAlertModal from "../modals/alert/ItemDuplicityAlertModal";
import { searchItem } from "../../store/order/order-async-actions";
import formatDiscountValueString from "../../util/formaters/formatDiscountValueString";
import OrderItemChoosingHelperModal from "../modals/order/OrderItemChoosingHelperModal";
import formatNumberToCurrencyNoSymbol from "../../util/formaters/formatNumberToCurrencyNoSymbol";
import validateNullDiscount from "../../util/validators/validateNullDiscount";

type Dirty = "PERCENTAGE" | "VALUE" | null;

const OrderItems = () => {
  const dispatch = useAppDispatch();
  const [table, setTable] = useState<ProductPriceTable>({
    id: "",
    nome: "",
    condicoesPagamentoIds: [],
    descontoMaximoPerc: 0,
    exibirTabela: true,
    fator: 1.0,
    inativo: false,
    tabelaBaseId: "-1",
  });
  const [unitPrice, setUnitPrice] = useState<number>(0);
  const [finalPrice, setFinalPrice] = useState<number>(0);
  const [percentIpi, setPercentIpi] = useState<number>(0);
  const [valIpi, setValIpi] = useState<number>(0);
  const [finalPriceString, setFinalPriceString] = useState("");
  const [finalPriceHelperText, setFinalPriceHelperText] = useState("");
  const [balance, setBalance] = useState<number>(0);
  const [referenceHelperText, setReferenceHelperText] = useState("");
  const [productId, setProductId] = useState("");
  const [productName, setProductName] = useState("");
  const [subtotal, setSubtotal] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [quantityString, setQuantityString] = useState("");
  const [quantityHelperText, setQuantityHelperText] = useState("");
  const [discountHelperText, setDiscountHelperText] = useState("");
  const [discountValue, setDiscountValue] = useState(0);
  const [discountValueString, setDiscountValueString] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [discountPercentageString, setDiscountPercentageString] = useState("");
  const [dirty, setDirty] = useState<Dirty>(null);
  const [finalPriceDirty, setFinalPriceDirty] = useState(false);
  const [increaseValue, setIncreaseValue] = useState(0);
  const [increaseValueString, setIncreaseValueString] = useState("");
  const [increasePercentage, setIncreasePercentage] = useState(0);
  const [increasePercentageString, setIncreasePercentageString] = useState("");
  const [validityErrors, setValidityErrors] = useState({
    quantityError: false,
    finalPriceError: false,
    discountValueError: false,
    discountPercentageError: false,
    increaseValueError: false,
    increasePercentageError: false,
    referenceError: false,
    grade2Error: false,
    grade3Error: false,
  });
  const [grade2, setGrade2] = useState("");
  const [grade3, setGrade3] = useState("");
  const [productData, setProductData] = useState<Product | undefined>(
    undefined
  );
  const [isProductError, setIsProductError] = useState(false);
  const [isProductLoading, setIsProductLoading] = useState(false);
  const [isProductSuccess, setIsProductSuccess] = useState(false);

  const selectedTable = useAppSelector(
    (state) => state.order.order.tabelaPrecoId
  );
  const pedidoVendaConfig = useAppSelector(
    (state) => state.tenantConfiguration
  );
  // const isReadOnly = !useAppSelector(
  //   (state) => state.order.orderSituation.permiteEdicao
  // );

  const isReadOnly = useAppSelector(
    (state) => state.readability.isOrderReadOnly
  );

  const {
    data: allTablesData,
    isError: isAllTablesError,
    isFetching: isAllTablesFetching,
    isLoading: isAllTablesLoading,
    error,
  } = useGetAllTablesQuery();
  const [openModal, setOpenModal] = useState(false);
  const [openDuplicityModal, setOpenDuplicityModal] = useState(false);
  const [openItemChoosingHelperModal, setOpenItemChoosingHelperModal] =
    useState(false);

  const orderItems = useAppSelector((state) => state.order.order.itens);
  // console.log(orderItems);

  //React.KeyboardEvent<HTMLButtonElement>

  const searchItemInDatabase = async (id: string) => {
    setIsProductError(false);
    setIsProductSuccess(false);
    setIsProductLoading(true);
    try {
      const response = await searchItem(id);
      setProductData(response);
      setIsProductSuccess(true);
      setIsProductLoading(false);
      setProductId(id);
    } catch (err) {
      setIsProductLoading(false);
      setIsProductError(true);
    }
  };

  useEffect(() => {
    if (isProductError) {
      setReferenceHelperText(
        "Não foi possível encontrar produto com a referência"
      );
    } else {
      setReferenceHelperText("");
    }
    setOpenItemChoosingHelperModal(isProductError);
  }, [
    isProductError,
    productData,
    setReferenceHelperText,
    setOpenItemChoosingHelperModal,
  ]);

  useEffect(() => {
    if (allTablesData) {
      let currentTable = allTablesData.filter(
        (element) => element.id === selectedTable
      );
      dispatch(orderActions.setFullPriceTable({ ...currentTable[0] }));
      setTable({ ...currentTable[0] });
    }
  }, [selectedTable, allTablesData]);

  // useEffect(() => {
  //   // console.log('Product Data: ', productData);
  //   if (productData && !_.isEmpty(productData)) {
  //     setQuantity(0);
  //     setQuantityString('');
  //     setDiscountHelperText('');
  //     setDiscountPercentage(0);
  //     setDiscountPercentageString('');
  //     setDiscountValue(0);
  //     setDiscountValueString('');
  //     let errors = { ...validityErrors };
  //     errors = _.mapValues(errors, (error) => (error = false));
  //     setValidityErrors({ ...errors });
  //     let currentTable = productData.precoProduto.filter(
  //       (el) => el.tabelaPrecoId === selectedTable
  //     );
  //     if (currentTable.length === 0) {
  //       setUnitPrice(productData.preco);
  //       setFinalPrice(productData.preco);
  //       setSubtotal(productData.preco);
  //     } else {
  //       setUnitPrice(currentTable[0].preco);
  //       setSubtotal(currentTable[0].preco);
  //       setFinalPrice(currentTable[0].preco);
  //     }
  //     let reducedBalance = productData.saldoProduto.reduce((acc, curValue) => {
  //       return acc + curValue.quantidade;
  //     }, 0);
  //     setBalance(reducedBalance);
  //   }
  // }, [productData, selectedTable]);

  useEffect(() => {
    console.log(productData);
    setProductName(productData ? productData.nome : "");
    if (productData && !_.isEmpty(productData)) {
      setQuantity(0);
      setQuantityString("");
      setDiscountHelperText("");
      setQuantityHelperText("");
      setDiscountPercentage(0);
      setDiscountPercentageString("");
      setDiscountValue(0);
      setDiscountValueString("");
      setFinalPriceDirty(false);
      setDirty(null);
      setPercentIpi(productData.percentualIpi / 100);
      let errors = { ...validityErrors };
      errors = _.mapValues(errors, (error) => (error = false));
      setValidityErrors({ ...errors });
      let currentTable = productData.precoProduto.filter(
        (el) => el.tabelaPrecoId === selectedTable
      );
      if (currentTable.length === 0) {
        setUnitPrice(productData.preco);
        setSubtotal(productData.preco);
        setFinalPrice(productData.preco);
        setValIpi(
          Math.round(
            productData.preco * (productData.percentualIpi / 100) * 100
          ) / 100
        );
        setFinalPriceString(formatNumberToCurrencyNoSymbol(productData.preco));
      } else {
        setUnitPrice(currentTable[0].preco);
        setSubtotal(currentTable[0].preco);
        setFinalPrice(currentTable[0].preco);
        setValIpi(
          Math.round(
            currentTable[0].preco * (productData.percentualIpi / 100) * 100
          ) / 100
        );
        setFinalPriceString(
          formatNumberToCurrencyNoSymbol(currentTable[0].preco)
        );
      }
      let reducedBalance = productData.saldoProduto.reduce((acc, curValue) => {
        return acc + curValue.quantidade;
      }, 0);
      setBalance(reducedBalance);
      if (!productData.grade) {
        setGrade2("");
        setGrade3("");
      }
    }
  }, [isProductSuccess, productData, selectedTable]);

  useEffect(() => {
    if (finalPriceDirty) {
      setSubtotal(finalPrice * quantity);
      return;
    }
    let firstSubtotal = roundAbnt(quantity * unitPrice);
    if (
      quantity &&
      !discountPercentage &&
      !discountValue &&
      !increasePercentage &&
      !increaseValue
    ) {
      setSubtotal(firstSubtotal);
    } else if (quantity && discountPercentage) {
      let newPrice =
        unitPrice - roundAbnt(unitPrice * (discountPercentage / 100));
      let newValue = newPrice * quantity;
      setFinalPrice(newPrice);
      setFinalPriceString(formatNumberToCurrencyNoSymbol(newPrice));
      setSubtotal(roundAbnt(newValue));
    } else if (quantity && discountValue) {
      let newPrice = unitPrice - discountValue;
      let newValue = newPrice * quantity;
      setFinalPrice(newPrice);
      setFinalPriceString(formatNumberToCurrencyNoSymbol(newPrice));
      setSubtotal(roundAbnt(newValue));
    } else if (quantity && increasePercentage) {
      let newValue =
        firstSubtotal + roundAbnt((firstSubtotal * increasePercentage) / 100);
      setSubtotal(parseFloat(newValue.toFixed(2)));
    } else if (quantity && increaseValue) {
      let newValue = firstSubtotal + increaseValue;
      setSubtotal(parseFloat(newValue.toFixed(2)));
    } else {
      setFinalPriceDirty(false);
      setDirty(null);
      setDiscountPercentage(0);
      setDiscountPercentageString("");
      setIncreasePercentage(0);
      setIncreasePercentageString("");
      setDiscountValue(0);
      setDiscountValueString("");
      setIncreaseValue(0);
      setIncreaseValueString("");
      setSubtotal(unitPrice);
      setFinalPrice(unitPrice);
      setFinalPriceString(formatNumberToCurrencyNoSymbol(unitPrice));
    }
  }, [
    quantity,
    unitPrice,
    setSubtotal,
    discountPercentage,
    discountValue,
    increasePercentage,
    increaseValue,
  ]);

  const handleProductIdChange = (
    ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    ev.preventDefault();
    setProductId(ev.target.value);
  };

  const handleQuantityChange = (
    ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (ev.target.value === "") {
      if (validityErrors.quantityError) {
        let errors = { ...validityErrors };
        errors.quantityError = false;
        setValidityErrors({ ...errors });
      }
      setQuantity(0);
      setQuantityString(ev.target.value);
      setQuantityHelperText("");
      return;
    }
    if (ev.target.value === "0") {
      if (!validityErrors.quantityError) {
        let errors = { ...validityErrors };
        errors.quantityError = true;
        setValidityErrors({ ...errors });
      }
      setQuantity(0);
      setQuantityString(ev.target.value);
      setQuantityHelperText("Quantidade informada inválida");
      return;
    }

    if (ev.target.value.match(/^(\d+)(?:(\.|,)\d{1,3})?$/)) {
      let newQuantity = parseFloat(ev.target.value);
      if (newQuantity > balance) {
        if (!validityErrors.quantityError) {
          let errors = { ...validityErrors };
          errors.quantityError = true;
          setValidityErrors({ ...errors });
        }
        setQuantityString(ev.target.value);
        setQuantityHelperText(
          `A quantidade informada (${newQuantity.toLocaleString("pt-Br", {
            minimumFractionDigits: 3,
          })})
           é superior ao estoque (${balance.toLocaleString("pt-Br", {
             minimumFractionDigits: 3,
           })})
          `
        );
      } else {
        if (validityErrors.quantityError) {
          let errors = { ...validityErrors };
          errors.quantityError = false;
          setValidityErrors({ ...errors });
        }
        if (ev.target.value.includes(",")) {
          let newValue = ev.target.value.replace(",", ".");
          setQuantity(parseFloat(newValue));
        } else {
          setQuantity(parseFloat(ev.target.value));
        }
        setQuantityHelperText("");
      }
    } else {
      let errors = { ...validityErrors };
      errors.quantityError = true;
      setValidityErrors({ ...errors });
      setQuantityHelperText("Quantidade informada inválida");
    }
    setQuantityString(ev.target.value);
  };

  const handleDiscountChange = (
    ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: "percentage" | "value"
  ) => {
    setFinalPriceHelperText("");
    setFinalPriceDirty(false);
    if (type === "percentage" && discountValue) {
      setDiscountValue(0);
      setDiscountValueString("");
    }

    if (type === "value" && discountPercentage) {
      setDiscountPercentage(0);
      setDiscountPercentageString("");
    }

    if (ev.target.value === "") {
      if (type === "percentage") {
        if (validityErrors.discountPercentageError) {
          let errors = { ...validityErrors };
          errors.discountPercentageError = false;
          setValidityErrors({ ...errors });
        }
        if (!discountValue) {
          setFinalPrice(unitPrice);
          setFinalPriceString(formatNumberToCurrencyNoSymbol(unitPrice));
        }
        setDiscountPercentage(0);
        setDiscountPercentageString(ev.target.value);
      } else {
        if (validityErrors.discountValueError) {
          let errors = { ...validityErrors };
          errors.discountValueError = false;
          setValidityErrors({ ...errors });
        }
        if (!discountPercentage) {
          setFinalPrice(unitPrice);
          setFinalPriceString(formatNumberToCurrencyNoSymbol(unitPrice));
        }
        setDiscountValue(0);
        setDiscountValueString(ev.target.value);
      }
      setDirty(null);
      setDiscountHelperText("");
      return;
    }
    if (validateNullDiscount(ev.target.value)) {
      if (type === "percentage") {
        if (validityErrors.discountPercentageError) {
          let errors = { ...validityErrors };
          errors.discountPercentageError = false;
          setValidityErrors({ ...errors });
        }
      } else {
        if (validityErrors.discountValueError) {
          let errors = { ...validityErrors };
          errors.discountValueError = false;
          setValidityErrors({ ...errors });
        }
      }
      setFinalPrice(unitPrice);
      setDiscountValue(0);
      setDiscountValueString(ev.target.value);
      setDiscountPercentage(0);
      setDiscountPercentageString(ev.target.value);
      setFinalPriceString(formatNumberToCurrencyNoSymbol(unitPrice));
      setDirty(null);
      setDiscountHelperText("");
      return;
    }

    let floatValue = 0;
    let validForParsing = ev.target.value.match(/^(\d+)(?:(\.|,)\d{1,2})?$/);

    if (validForParsing) {
      if (ev.target.value.includes(",")) {
        let newValue = ev.target.value.replace(",", ".");
        floatValue = parseFloat(newValue);
      } else {
        floatValue = parseFloat(ev.target.value);
      }
      if (type === "percentage") {
        setDirty("PERCENTAGE");
        if (floatValue > table.descontoMaximoPerc || floatValue > 100) {
          let errors = { ...validityErrors };
          errors.discountPercentageError = true;
          setDiscountPercentageString(ev.target.value);
          setValidityErrors({ ...errors });
          setDiscountHelperText(
            `O desconto informado é maior que o desconto máximo da tabela (${table.descontoMaximoPerc})%`
          );
        } else {
          if (validityErrors.discountPercentageError) {
            let errors = { ...validityErrors };
            errors.discountPercentageError = false;
            setValidityErrors({ ...errors });
          }
          setDiscountPercentage(floatValue);
          setDiscountPercentageString(ev.target.value);
          setDiscountHelperText("");
        }
      } else {
        setDirty("VALUE");
        if (
          floatValue >= roundAbnt(unitPrice) ||
          percentageOf(floatValue, roundAbnt(unitPrice)) >
            roundAbnt(table.descontoMaximoPerc)
        ) {
          let errors = { ...validityErrors };
          errors.discountValueError = true;
          setDiscountValueString(ev.target.value);
          setValidityErrors({ ...errors });
          setDiscountHelperText(
            `O desconto informado (${percentageOf(
              floatValue,
              roundAbnt(unitPrice)
            )}%) é maior que o desconto máximo da tabela (${
              table.descontoMaximoPerc
            }%)`
          );
        } else {
          if (validityErrors.discountValueError) {
            let errors = { ...validityErrors };
            errors.discountValueError = false;
            setValidityErrors({ ...errors });
          }
          setDiscountHelperText("");
          setDiscountValue(floatValue);
          setDiscountValueString(ev.target.value);
        }
      }
    } else {
      if (type === "percentage") {
        setDirty("PERCENTAGE");
        let errors = { ...validityErrors };
        errors.discountPercentageError = true;
        setDiscountPercentageString(ev.target.value);
        setValidityErrors({ ...errors });
        setDiscountHelperText("O desconto informado é inválido");
      } else {
        setDirty("VALUE");
        let errors = { ...validityErrors };
        errors.discountValueError = true;
        setDiscountValueString(ev.target.value);
        setValidityErrors({ ...errors });
        setDiscountHelperText("O desconto informado é inválido");
      }
    }
  };

  const handleFinalPriceChange = (
    ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setDirty("PERCENTAGE");
    setFinalPriceDirty(true);
    let floatValue = 0;
    let validForParsing = ev.target.value.match(/^(\d+)(?:(\.|,)\d{1,2})?$/);
    if (!validForParsing) {
      if (!validityErrors.finalPriceError) {
        let errors = { ...validityErrors };
        errors.finalPriceError = true;
        setValidityErrors({ ...errors });
      }
      setFinalPriceHelperText("Preço informado inválido");
      setFinalPriceString(ev.target.value);
      return;
    }

    floatValue = parseFloat(ev.target.value.replace(",", "."));

    if (floatValue > unitPrice) {
      if (!validityErrors.finalPriceError) {
        let errors = { ...validityErrors };
        errors.finalPriceError = true;
        setValidityErrors({ ...errors });
      }
      setFinalPriceHelperText("Preço informado superior ao preço bruto");
      setFinalPriceString(ev.target.value);
      return;
    }

    let discountInValue = unitPrice - floatValue;
    let discountInPercentage = percentageOf(discountInValue, unitPrice);

    if (discountInPercentage > table.descontoMaximoPerc) {
      if (!validityErrors.finalPriceError) {
        let errors = { ...validityErrors };
        errors.finalPriceError = true;
        setValidityErrors({ ...errors });
      }
      setFinalPriceHelperText(`O desconto concedido (${discountInPercentage}%)
      é maior que o desconto máximo da tabela(${table.descontoMaximoPerc}%)`);
      setFinalPriceString(ev.target.value);
      return;
    }

    let realDiscountPercentage = (discountInValue / unitPrice) * 100;
    realDiscountPercentage = parseFloat(realDiscountPercentage.toFixed(8));

    if (validityErrors.finalPriceError) {
      let errors = { ...validityErrors };
      errors.finalPriceError = false;
      setValidityErrors({ ...errors });
    }
    setFinalPriceHelperText("");
    setFinalPriceString(ev.target.value);
    setFinalPrice(floatValue);
    setDiscountValueString(formatNumberToCurrencyNoSymbol(discountInValue));
    setDiscountValue(discountInValue);
    setDiscountPercentageString(discountInPercentage.toLocaleString());
    setDiscountPercentage(realDiscountPercentage);

    return;
  };

  // const handleIncreaseChange = (
  //   ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  //   type: 'percentage' | 'value'
  // ) => {
  //   if (ev.target.value === '') {
  //     if (type === 'percentage') {
  //       if (validityErrors.increasePercentageError) {
  //         let errors = { ...validityErrors };
  //         errors.increasePercentageError = false;
  //         setValidityErrors({ ...errors });
  //       }
  //       if (quantity) {
  //         setSubtotal(quantity * unitPrice);
  //       } else {
  //         setSubtotal(unitPrice);
  //       }
  //       setIncreasePercentage(0);
  //       setIncreasePercentageString(ev.target.value);
  //     } else {
  //       if (validityErrors.increaseValueError) {
  //         let errors = { ...validityErrors };
  //         errors.increaseValueError = false;
  //         setValidityErrors({ ...errors });
  //       }
  //       if (quantity) {
  //         setSubtotal(quantity * unitPrice);
  //       } else {
  //         setSubtotal(unitPrice);
  //       }
  //       setIncreaseValue(0);
  //       setIncreaseValueString(ev.target.value);
  //     }
  //     return;
  //   }
  //   let floatValue = 0;
  //   let validForParsing = ev.target.value.match(/^(\d+)(?:(\.|,)\d{1,2})?$/);

  //   if (validForParsing) {
  //     if (ev.target.value.includes(',')) {
  //       let newValue = ev.target.value.replace(',', '.');
  //       floatValue = parseFloat(newValue);
  //     } else {
  //       floatValue = parseFloat(ev.target.value);
  //     }
  //     if (type === 'percentage') {
  //       if (validityErrors.increasePercentageError) {
  //         let errors = { ...validityErrors };
  //         errors.increasePercentageError = false;
  //         setValidityErrors({ ...errors });
  //       }
  //       setIncreasePercentage(floatValue);
  //       setIncreasePercentageString(ev.target.value);
  //     } else {
  //       if (validityErrors.increaseValueError) {
  //         let errors = { ...validityErrors };
  //         errors.increaseValueError = false;
  //         setValidityErrors({ ...errors });
  //       }
  //       setIncreaseValue(floatValue);
  //       setIncreaseValueString(ev.target.value);
  //     }
  //   } else {
  //     if (type === 'percentage') {
  //       let errors = { ...validityErrors };
  //       errors.increasePercentageError = true;
  //       setIncreasePercentageString(ev.target.value);
  //       setValidityErrors({ ...errors });
  //     } else {
  //       let errors = { ...validityErrors };
  //       errors.increaseValueError = true;
  //       setIncreaseValueString(ev.target.value);
  //       setValidityErrors({ ...errors });
  //     }
  //   }
  // };

  const handleGrade2Change = (
    ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    ev.preventDefault();
    if (validityErrors.grade2Error) {
      let errors = { ...validityErrors };
      errors.grade2Error = false;
      setValidityErrors({ ...errors });
    }
    setGrade2(ev.target.value);
  };

  const handleGrade3Change = (
    ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    ev.preventDefault();
    if (validityErrors.grade3Error) {
      let errors = { ...validityErrors };
      errors.grade3Error = false;
      setValidityErrors({ ...errors });
    }
    const re = /^[0-9\b]+$/;
    if (ev.target.value === "" || re.test(ev.target.value)) {
      setGrade3(ev.target.value);
    }
  };

  const focusFirstField = () => {
    document.getElementById("ref-textfield")?.focus();
  };

  const resetFields = () => {
    setProductId("");
    setProductName("");
    setQuantity(0);
    setQuantityString("");
    setSubtotal(0);
    setGrade2("");
    setGrade3("");
    setUnitPrice(0);
    setBalance(0);
    setDiscountPercentage(0);
    setDiscountPercentageString("");
    setDiscountValue(0);
    setDiscountValueString("");
    setIncreasePercentage(0);
    setIncreasePercentageString("");
    setIncreaseValue(0);
    setIncreaseValueString("");
    setProductData(undefined);
  };

  const critiques = (): boolean => {
    const hasErrors = Object.values(validityErrors).some(
      (error) => error === true
    );

    if (productId === "") {
      // console.log('productId: ', productId);
      return true;
    }

    if (productData && productData.grade) {
      if (!grade2) {
        if (!validityErrors.grade2Error) {
          let errors = { ...validityErrors };
          errors.grade2Error = true;
          setValidityErrors({ ...errors });
        }
      }
      if (!grade3) {
        if (!validityErrors.grade3Error) {
          let errors = { ...validityErrors };
          errors.grade3Error = true;
          setValidityErrors({ ...errors });
        }
      }
      if (!grade2 || !grade3) {
        return true;
      }
    }
    if (isProductError) {
      // console.log('isProductError: ', isProductError);
      return true;
    }
    if (quantity === 0) {
      // console.log('quantity: ', quantity);
      return true;
    }
    if (hasErrors) {
      // console.log('hasErrors: ', hasErrors);
      return true;
    }

    return false;
  };

  const addItemToOrder = () => {
    const newItem: OrderItem = {
      grade: {
        grade2,
        grade3,
      },
      id: new Date().getTime(),
      produtoId: productData ? productData.codigo : "",
      quantidade: quantity,
      totais: {
        acrescimos: {
          condicaoPagamento: {
            percentual: null,
            total: null,
            valor: null,
          },
          item: {
            percentual: increasePercentage,
            valor: increaseValue,
            total: increasePercentage
              ? roundAbnt(
                  (roundAbnt(unitPrice * quantity) * increasePercentage) / 100
                )
              : roundAbnt(increaseValue),
          },
          total: increasePercentage
            ? roundAbnt(
                (roundAbnt(unitPrice * quantity) * increasePercentage) / 100
              )
            : roundAbnt(increaseValue),
        },
        descontos: {
          condicaoPagamento: {
            percentual: null,
            total: null,
            valor: null,
          },
          item: {
            percentual: discountPercentage,
            valor: discountValue,
            total: discountValue
              ? roundAbnt(discountValue * quantity)
              : (unitPrice - finalPrice) * quantity,
          },
          total: discountValue
            ? roundAbnt(discountValue * quantity)
            : (unitPrice - finalPrice) * quantity,
        },
        produtos: roundAbnt(unitPrice * quantity),
        total: subtotal,
      },
      unitarioFinal: finalPrice,
      unitario: unitPrice,
      percentualIpi: percentIpi,
      valorIpi: valIpi,
    };
    dispatch(
      orderActions.addItemDetail({
        id: productData ? productData.codigo : "",
        nome: productData ? productData.nome : "",
        unidadeMedida: productData ? productData.unidadeMedida : "",
      })
    );
    setOpenDuplicityModal(false);
    dispatch(orderActions.addOrderItem(newItem));
    resetFields();
    focusFirstField();
  };

  const beforeInsertItem = () => {
    if (critiques()) return;
    if (pedidoVendaConfig.pedidoVenda.aceitaItemDuplicado) {
      addItemToOrder();
      return;
    }
    const isAlreadyInCart = orderItems.filter(
      (item) => item.produtoId.toUpperCase() === productId.toUpperCase()
    );
    if (isAlreadyInCart.length === 0) {
      addItemToOrder();
      return;
    }
    if (isAlreadyInCart.length > 0 && productData && !productData.grade) {
      setOpenDuplicityModal(true);
      return;
    }
    if (isAlreadyInCart.length > 0 && productData && productData.grade) {
      const isGradeInCart = isAlreadyInCart.filter((item) => {
        return (
          item.grade.grade2?.toUpperCase() === grade2.toUpperCase() &&
          item.grade.grade3?.toUpperCase() === grade3.toUpperCase()
        );
      });
      if (isGradeInCart.length === 0) {
        addItemToOrder();
        return;
      } else {
        setOpenDuplicityModal(true);
        return;
      }
    }
  };

  const cancelItemAddition = () => {
    resetFields();
    setOpenDuplicityModal(false);
  };

  const handleEnterPress = (ev: React.KeyboardEvent<HTMLDivElement>) => {
    if (ev.key === "Enter" && !!productId) {
      searchItemInDatabase(productId);
    }
  };

  if (isAllTablesError) {
    // console.log(isAllTablesError, error);
    return <h2>Erro no carregamento dos dados</h2>;
  }

  if (isAllTablesFetching || isAllTablesLoading) {
    return <Spinner />;
  }

  return (
    <>
      <OrderTableSelectionModal
        tables={allTablesData ? allTablesData : []}
        openModal={openModal}
        toggle={() => setOpenModal(!openModal)}
      />
      <ItemDuplicityAlertModal
        addItem={() => addItemToOrder()}
        openModal={openDuplicityModal}
        cancelAddition={() => cancelItemAddition()}
      />
      <OrderItemChoosingHelperModal
        addProduct={searchItemInDatabase}
        filterParam={productId}
        openModal={openItemChoosingHelperModal}
        toggle={() => setOpenItemChoosingHelperModal(false)}
      />
      <Grid
        container
        alignItems={"center"}
        width={"100%"}
        justifyContent={"center"}
        mt={1}
        spacing={1}
      >
        {!isReadOnly && (
          <Grid item xs={2} md={2} textAlign={"center"}>
            <Button
              sx={{ color: Colors.primary, boxShadow: 1 }}
              onClick={() => setOpenModal(true)}
            >
              <LocalOfferOutlined />
              {table.nome}
            </Button>
          </Grid>
        )}
        {!isReadOnly && (
          <Grid item xs={12} md={12} mt={1}>
            <Grid
              container
              spacing={1}
              textAlign={"left"}
              justifyContent={"left"}
              alignItems={"start"}
            >
              <Grid
                item
                xs={10}
                sm={4}
                md={
                  pedidoVendaConfig &&
                  pedidoVendaConfig.grade &&
                  productData &&
                  productData.grade
                    ? 2
                    : 4
                }
              >
                <TextField
                  id="ref-textfield"
                  type={"search"}
                  onKeyPress={handleEnterPress}
                  error={
                    isProductError || (productData && _.isEmpty(productData))
                  }
                  size={"small"}
                  label={"Referência"}
                  fullWidth
                  value={productId}
                  onChange={(ev) => handleProductIdChange(ev)}
                  helperText={referenceHelperText}
                />
              </Grid>
              {isProductLoading ? (
                <Grid item xs={2} md={2}>
                  <CircularProgress size={16} />
                </Grid>
              ) : (
                <Grid item xs={0.5} md={0.5}>
                  <IconButton
                    disabled={!productId}
                    tabIndex={-1}
                    onClick={() => searchItemInDatabase(productId)}
                    sx={{
                      marginTop: -0.2,
                      backgroundColor: Colors.primary,
                      "&:focus": {
                        backgroundColor: Colors.primary,
                      },
                      "&:hover": {
                        backgroundColor: "green",
                      },
                      "&:disabled": {
                        backgroundColor: Colors.primary,
                      },
                      boxShadow: 1,
                    }}
                  >
                    <Search sx={{ color: "white" }} />
                  </IconButton>
                </Grid>
              )}
              {pedidoVendaConfig &&
              pedidoVendaConfig.grade &&
              productData &&
              productData.grade &&
              productId ? (
                <>
                  <Grid item xs={10} sm={1.5} md={1.5}>
                    <TextField
                      id={"grade2-textfield"}
                      onKeyPress={(ev) => {
                        if (ev.key === "Enter") {
                          beforeInsertItem();
                        }
                      }}
                      error={validityErrors.grade2Error}
                      size={"small"}
                      label={pedidoVendaConfig.grade.nomeGrade2}
                      fullWidth
                      value={grade2}
                      onChange={(ev) => handleGrade2Change(ev)}
                      inputProps={{
                        maxLength: pedidoVendaConfig.grade.tamanhoMaximoGrade2,
                      }}
                      helperText={
                        validityErrors.grade2Error ? "Preencha este campo" : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={10} sm={1.5} md={1.5}>
                    <TextField
                      onKeyPress={(ev) => {
                        if (ev.key === "Enter") {
                          beforeInsertItem();
                        }
                      }}
                      error={validityErrors.grade3Error}
                      size={"small"}
                      label={pedidoVendaConfig.grade.nomeGrade3}
                      fullWidth
                      value={grade3}
                      onChange={(ev) => handleGrade3Change(ev)}
                      inputProps={{
                        maxLength: pedidoVendaConfig.grade.tamanhoMaximoGrade3,
                      }}
                      helperText={
                        validityErrors.grade3Error ? "Preencha este campo" : ""
                      }
                    />
                  </Grid>
                </>
              ) : null}
              {productData &&
                !_.isEmpty(productData) &&
                !isProductError &&
                !isProductLoading &&
                productId && (
                  <>
                    <Grid
                      item
                      xs={12}
                      md={
                        pedidoVendaConfig &&
                        pedidoVendaConfig.grade &&
                        productData &&
                        productData.grade
                          ? 6.5
                          : 7.5
                      }
                    >
                      <TextField
                        fullWidth
                        multiline
                        size="small"
                        disabled
                        value={productName}
                        label={"Descrição"}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={productData.percentualIpi !== undefined ? 1.5 : 2}
                    >
                      <TextField
                        id={"quantidade-textfield"}
                        onKeyPress={(ev) => {
                          if (ev.key === "Enter") {
                            beforeInsertItem();
                          }
                        }}
                        type={"text"}
                        size={"small"}
                        label={"Quantidade"}
                        value={!quantityString ? "" : quantityString}
                        error={validityErrors.quantityError}
                        onChange={(ev) => handleQuantityChange(ev)}
                        fullWidth
                        helperText={quantityHelperText}
                        sx={{ marginTop: 0.5 }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={productData.percentualIpi !== undefined ? 1 : 1.5}
                    >
                      <TextField
                        disabled
                        fullWidth
                        size={"small"}
                        label={"U.M."}
                        value={productData ? productData.unidadeMedida : ""}
                        sx={{ marginTop: 0.5 }}
                      />
                    </Grid>
                    {productData.percentualIpi !== undefined ? (
                      <Grid item xs={12} md={1}>
                        <TextField
                          fullWidth
                          disabled={true}
                          size={"small"}
                          label={"Valor IPI"}
                          error={validityErrors.discountPercentageError}
                          value={valIpi.toLocaleString("pt-Br", {
                            minimumFractionDigits: 2,
                          })}
                          sx={{ marginTop: 0.5 }}
                        />
                      </Grid>
                    ) : (
                      ""
                    )}
                    {productData.percentualIpi !== undefined ? (
                      <Grid item xs={12} md={1.5}>
                        <TextField
                          fullWidth
                          size={"small"}
                          label={"Pr. + IPI"}
                          disabled
                          value={
                            productData
                              ? (finalPrice + valIpi).toLocaleString("pt-Br", {
                                  minimumFractionDigits: 2,
                                })
                              : ""
                          }
                          sx={{ marginTop: 0.5 }}
                        />
                      </Grid>
                    ) : (
                      ""
                    )}

                    <Grid
                      item
                      xs={12}
                      md={productData.percentualIpi !== undefined ? 1.5 : 2}
                    >
                      <TextField
                        fullWidth
                        size={"small"}
                        label={"Pr. Bruto R$"}
                        disabled
                        value={
                          productData
                            ? unitPrice.toLocaleString("pt-Br", {
                                minimumFractionDigits: 2,
                              })
                            : ""
                        }
                        sx={{ marginTop: 0.5 }}
                      />
                    </Grid>
                    {!!quantity && (
                      <>
                        <Grid item xs={12} md={1}>
                          <TextField
                            onKeyPress={(ev) => {
                              if (ev.key === "Enter") {
                                beforeInsertItem();
                              }
                            }}
                            fullWidth
                            disabled={dirty === "VALUE"}
                            size={"small"}
                            label={"Desc %"}
                            error={validityErrors.discountPercentageError}
                            value={discountPercentageString}
                            onChange={(ev) =>
                              handleDiscountChange(ev, "percentage")
                            }
                            helperText={
                              validityErrors.discountPercentageError
                                ? discountHelperText
                                : ""
                            }
                            sx={{ marginTop: 0.5 }}
                          />
                        </Grid>
                        <Grid item xs={12} md={1}>
                          <TextField
                            onKeyPress={(ev) => {
                              if (ev.key === "Enter") {
                                beforeInsertItem();
                              }
                            }}
                            onBlur={() =>
                              setDiscountValueString(
                                formatDiscountValueString(discountValueString)
                              )
                            }
                            fullWidth
                            disabled={dirty === "PERCENTAGE"}
                            size={"small"}
                            label={"Desc R$"}
                            error={validityErrors.discountValueError}
                            value={discountValueString}
                            onChange={(ev) => handleDiscountChange(ev, "value")}
                            helperText={
                              validityErrors.discountValueError
                                ? discountHelperText
                                : ""
                            }
                            sx={{ marginTop: 0.5 }}
                          />
                        </Grid>
                        {/* <Grid item xs={12} md={1.25}>
                        <TextField
                          onKeyPress={(ev) => {
                        if (ev.key === 'Enter') {
                          beforeInsertItem();
                        }
                      }}
                          disabled={
                            !!increaseValue ||
                            !!discountPercentage ||
                            !!discountValue
                          }
                          size={'small'}
                          label={'Acresc %'}
                          error={validityErrors.increasePercentageError}
                          value={increasePercentageString}
                          onChange={(ev) =>
                            handleIncreaseChange(ev, 'percentage')
                          }
                          helperText={
                            validityErrors.increasePercentageError
                              ? 'Acréscimo inválido'
                              : ''
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={1.25}>
                        <TextField
                          onKeyPress={(ev) => {
                        if (ev.key === 'Enter') {
                          beforeInsertItem();
                        }
                      }}
                          disabled={
                            !!increasePercentage ||
                            !!discountPercentage ||
                            !!discountValue
                          }
                          size={'small'}
                          label={'Acresc R$'}
                          error={validityErrors.increaseValueError}
                          value={increaseValueString}
                          onChange={(ev) => handleIncreaseChange(ev, 'value')}
                          helperText={
                            validityErrors.increaseValueError
                              ? 'Acréscimo inválido'
                              : ''
                          }
                        />
                      </Grid> */}
                        <Grid
                          item
                          xs={12}
                          md={productData.percentualIpi !== undefined ? 1.5 : 2}
                        >
                          <TextField
                            fullWidth
                            error={validityErrors.finalPriceError}
                            size={"small"}
                            label={"Pr. Final R$"}
                            value={finalPriceString}
                            onChange={handleFinalPriceChange}
                            helperText={finalPriceHelperText}
                            onBlur={() =>
                              setFinalPriceString(
                                formatNumberToCurrencyNoSymbol(finalPrice)
                              )
                            }
                            sx={{ marginTop: 0.5 }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={productData.percentualIpi !== undefined ? 1.5 : 2}
                        >
                          <TextField
                            fullWidth
                            size={"small"}
                            label={"Subtotal R$"}
                            disabled
                            value={
                              productData
                                ? subtotal.toLocaleString("pt-Br", {
                                    minimumFractionDigits: 2,
                                  })
                                : ""
                            }
                            sx={{ marginTop: 0.5 }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={0.5}
                          sx={{ justifyContent: "flex-end" }}
                        >
                          <IconButton
                            sx={{
                              marginTop: 0.3,
                              backgroundColor: Colors.primary,
                              "&:focus": {
                                backgroundColor: Colors.primary,
                              },
                              "&:hover": {
                                backgroundColor: "green",
                              },
                              boxShadow: 1,
                            }}
                            onKeyPress={(ev) => {
                              if (ev.key === "Enter") {
                                beforeInsertItem();
                              }
                            }}
                            onClick={() => beforeInsertItem()}
                          >
                            <Add sx={{ color: "white" }} />
                          </IconButton>
                        </Grid>
                      </>
                    )}
                  </>
                )}
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} md={12} mt={1}>
          <OrderTotalsCard />
        </Grid>
        <Grid item xs={12} md={12}>
          <OrderItemsTable priceTable={table} />
        </Grid>
      </Grid>
    </>
  );
};

export default OrderItems;
