import FullClient from '../../models/client/fullClient.model';

const FULL_CLIENT_INITIALIZER: FullClient = {
  id: '',
  tipoPessoaId: 'F',
  apelido: '',
  categoriaId: '',
  dataNascimento: '',
  documentacao: {
    rg: '',
    cnpjCpf: '',
    inscricaoEstadual: '',
    inscricaoMunicipal: '',
  },
  email: {
    comercial: '',
    financeiro: '',
    principal: '',
  },
  endereco: {
    padrao: {
      sublocalidade: '',
      cep: '',
      localidade: '',
      codigoCidade: '',
      complemento: '',
      logradouro: '',
      numero: '',
      preenchimentoManual: false,
      uf: '',
    },
    entrega: {
      sublocalidade: '',
      cep: '',
      localidade: '',
      codigoCidade: '',
      complemento: '',
      logradouro: '',
      numero: '',
      preenchimentoManual: false,
      uf: '',
    },
  },
  fone: {
    celular: '',
    principal: '',
    secundario: '',
  },
  inativo: false,
  nome: '',
  origem: 'WEB',
  roleId: localStorage.getItem('userId') as string,
};

export default FULL_CLIENT_INITIALIZER;
