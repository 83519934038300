import { useState, useEffect } from 'react';
import { LocalizationProvider, DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { format, isValid } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  TextField,
  Autocomplete,
  Tooltip,
} from '@mui/material';
import { Search } from '@mui/icons-material';

import { billingParamsActions } from '../../../store/queries/billings-query-params-slice';
import { useAppSelector, useAppDispatch } from '../../../store/hooks';

import Colors from '../../../constants/Colors';

interface InvoiceSituation {
  id: string;
  title: string;
}

interface Props {
  inicialEmissionError: boolean;
  finalEmissionError: boolean;
  inicialCancellationError: boolean;
  finalCancellationError: boolean;
  toggleInicialEmissionError: (errorValue: boolean) => void;
  toggleInicialCancellationError: (errorValue: boolean) => void;
  toggleFinalEmissionError: (errorValue: boolean) => void;
  toggleFinalCancellationError: (errorValue: boolean) => void;
}

const SITUATIONS: InvoiceSituation[] = [
  { id: 'Faturada', title: 'Faturada' },
  { id: 'Cancelada', title: 'Cancelada' },
];

const BillingsQueryParamsCard = (props: Props) => {
  const {
    finalCancellationError,
    finalEmissionError,
    inicialCancellationError,
    inicialEmissionError,
    toggleFinalCancellationError,
    toggleFinalEmissionError,
    toggleInicialCancellationError,
    toggleInicialEmissionError,
  } = props;

  const [inicialEmissionDate, setInicialEmissionDate] = useState<Date | null>(
    new Date(new Date().setDate(new Date().getDate() - 7))
  );
  const [finalEmissionDate, setFinalEmissionDate] = useState<Date | null>(
    new Date()
  );
  const [inicialEmissionErrorMessage, setInicialEmissionErrorMessage] =
    useState('');
  const [finalEmissionErrorMessage, setFinalEmissionErrorMessage] =
    useState('');
  const [inicialCancellationDate, setInicialCancellationDate] =
    useState<Date | null>(null);
  const [finalCancellationDate, setFinalCancellationDate] =
    useState<Date | null>(null);
  const [inicialCancellationErrorMessage, setInicialCancellationErrorMessage] =
    useState('');
  const [finalCancellationErrorMessage, setFinalCancellationErrorMessage] =
    useState('');
  const dispatch = useAppDispatch();

  const {
    cancelamentoFinal,
    cancelamentoInicial,
    cliente,
    emissaoFinal,
    emissaoInicial,
    notaFiscalId,
  } = useAppSelector((state) => state.billingQueryParams);

  const [selectedSituations, setSelectedSituations] = useState<
    InvoiceSituation[]
  >([]);

  const handleInicialEmissionDateChange = (date: Date | null): void => {
    let formattedDate = '';
    if (!date || !isValid(date) || date > new Date()) {
      if (!inicialEmissionError) toggleInicialEmissionError(true);
      setInicialEmissionErrorMessage('A data informada é inválida');
      return;
    } else if (finalEmissionDate && finalEmissionDate < date) {
      if (!inicialEmissionError) toggleInicialEmissionError(true);
      setInicialEmissionErrorMessage(
        'A data inicial deve ser menor que a data final'
      );
      return;
    } else {
      if (inicialEmissionError) toggleInicialEmissionError(false);
      formattedDate = format(date, 'dd/MM/yyyy');
      setInicialEmissionDate(date);
      setInicialEmissionErrorMessage('');
      dispatch(billingParamsActions.changeInicialEmission(formattedDate));
    }
  };
  const handleInicialCancellationDateChange = (date: Date | null): void => {
    let formattedDate = '';
    if (!date) {
      if (inicialCancellationError) toggleInicialCancellationError(false);
      setInicialCancellationDate(date);
      setInicialCancellationErrorMessage('');
      dispatch(billingParamsActions.changeInicialCancellation(formattedDate));
    } else if (!isValid(date)) {
      if (!inicialCancellationError) toggleInicialCancellationError(true);
      setInicialCancellationErrorMessage('A data informada é inválida');
      return;
    } else if (finalCancellationDate && finalCancellationDate < date) {
      if (!inicialCancellationError) toggleInicialCancellationError(true);
      setInicialCancellationErrorMessage(
        'A data inicial deve ser menor que a data final'
      );
      return;
    } else {
      if (inicialCancellationError) toggleInicialCancellationError(false);
      formattedDate = format(date, 'dd/MM/yyyy');
      setInicialCancellationDate(date);
      setInicialCancellationErrorMessage('');
      dispatch(billingParamsActions.changeInicialCancellation(formattedDate));
    }
  };
  const handleFinalEmissionDateChange = (date: Date | null): void => {
    let formattedDate = '';
    if (!date || !isValid(date) || date > new Date()) {
      if (!finalEmissionError) toggleFinalEmissionError(true);
      setFinalEmissionErrorMessage('A data informada é inválida');
      return;
    } else if (inicialEmissionDate && date < inicialEmissionDate) {
      if (!finalEmissionError) toggleFinalEmissionError(true);
      setFinalEmissionErrorMessage(
        'A data inicial deve ser menor que a data final'
      );
      return;
    } else {
      if (finalEmissionError) toggleFinalEmissionError(false);
      formattedDate = format(date, 'dd/MM/yyyy');
      setFinalEmissionDate(date);
      setFinalEmissionErrorMessage('');
      dispatch(billingParamsActions.changeFinalEmission(formattedDate));
    }
  };
  const handleFinalCancellationDateChange = (date: Date | null): void => {
    let formattedDate = '';
    if (!date) {
      if (finalCancellationError) toggleFinalEmissionError(false);
      setFinalCancellationDate(date);
      setFinalCancellationErrorMessage('');
      dispatch(billingParamsActions.changeFinalCancellation(formattedDate));
    } else if (!isValid(date)) {
      if (!finalCancellationError) toggleFinalCancellationError(true);
      setFinalCancellationErrorMessage('A data informada é inválida');
      return;
    } else if (inicialCancellationDate && date < inicialCancellationDate) {
      if (!finalCancellationError) toggleFinalCancellationError(true);
      setFinalCancellationErrorMessage(
        'A data inicial deve ser menor que a data final'
      );
      return;
    } else {
      if (finalCancellationError) toggleFinalCancellationError(false);
      formattedDate = format(date, 'dd/MM/yyyy');
      setFinalCancellationDate(date);
      setFinalCancellationErrorMessage('');
      dispatch(billingParamsActions.changeFinalCancellation(formattedDate));
    }
  };

  // useEffect(() => {
  //   console.log(
  //     emissaoInicial,
  //     emissaoFinal,
  //     cancelamentoInicial,
  //     cancelamentoFinal
  //   );
  // }, [emissaoInicial, emissaoFinal, cancelamentoInicial, cancelamentoFinal]);

  useEffect(() => {
    if (!emissaoInicial && inicialEmissionDate) {
      let formattedDate = format(inicialEmissionDate, 'dd/MM/yyyy');
      dispatch(billingParamsActions.changeInicialEmission(formattedDate));
    }
    if (!cancelamentoInicial && inicialCancellationDate) {
      let formattedDate = format(inicialCancellationDate, 'dd/MM/yyyy');
      dispatch(billingParamsActions.changeInicialCancellation(formattedDate));
    }
    if (!emissaoFinal && finalEmissionDate) {
      let formattedDate = format(finalEmissionDate, 'dd/MM/yyyy');
      dispatch(billingParamsActions.changeFinalEmission(formattedDate));
    }
    if (!cancelamentoFinal && finalCancellationDate) {
      let formattedDate = format(finalCancellationDate, 'dd/MM/yyyy');
      dispatch(billingParamsActions.changeFinalCancellation(formattedDate));
    }
  }, [
    emissaoFinal,
    emissaoInicial,
    cancelamentoInicial,
    cancelamentoFinal,
    inicialEmissionDate,
    inicialCancellationDate,
    finalEmissionDate,
    finalCancellationDate,
    dispatch,
  ]);

  const handleSituationSelection = (
    ev: React.SyntheticEvent<Element, Event>,
    values: InvoiceSituation[]
  ) => {
    const newValues = values.map((value) => value.id);

    dispatch(billingParamsActions.changeSituation(newValues));
    setSelectedSituations([...values]);
  };

  return (
    <Grid item xs={12} md={12}>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography
                sx={{
                  backgroundColor: Colors.titleRowWithBackgroundColor,
                  pl: 1,
                  pt: 0.75,
                  mt: -1,
                }}
                variant='body2'
                color={Colors.titleWithBackgroudColor}
              >
                <Search fontSize={'inherit'} /> Filtros
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Tooltip
                arrow
                title={`Caso deseje informar mais de um número de nota fiscal,
                digite-os separando por vírgula
              `}
              >
                <TextField
                  id={'nota-fiscal-numero'}
                  fullWidth
                  label={'Número'}
                  value={notaFiscalId}
                  size={'small'}
                  onChange={(ev) =>
                    dispatch(
                      billingParamsActions.changeInvoiceId(ev.target.value)
                    )
                  }
                />
              </Tooltip>
            </Grid>
            <Grid item xs={6} md={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                <DatePicker
                  label={'Emissão Inicial'}
                  maxDate={new Date()}
                  value={inicialEmissionDate}
                  onChange={(value) => handleInicialEmissionDateChange(value)}
                  allowSameDateSelection={true}
                  cancelText={'Cancelar'}
                  PopperProps={{
                    popperOptions: {
                      placement: 'right',
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      helperText={inicialEmissionErrorMessage}
                      error={inicialEmissionError}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: 'dd/mm/aaaa',
                      }}
                    />
                  )}
                  InputProps={{
                    size: 'small',
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6} md={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                <DatePicker
                  label={'Emissão Final'}
                  maxDate={new Date()}
                  value={finalEmissionDate}
                  onChange={(value) => handleFinalEmissionDateChange(value)}
                  allowSameDateSelection={true}
                  cancelText={'Cancelar'}
                  PopperProps={{
                    popperOptions: {
                      placement: 'left',
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      helperText={finalEmissionErrorMessage}
                      error={finalEmissionError}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: 'dd/mm/aaaa',
                      }}
                    />
                  )}
                  InputProps={{
                    size: 'small',
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6} md={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                <DatePicker
                  label={'Cancelamento Inicial'}
                  value={inicialCancellationDate}
                  onChange={(value) =>
                    handleInicialCancellationDateChange(value)
                  }
                  allowSameDateSelection={true}
                  cancelText={'Cancelar'}
                  PopperProps={{
                    popperOptions: {
                      placement: 'right',
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      helperText={inicialCancellationErrorMessage}
                      error={inicialCancellationError}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: 'dd/mm/aaaa',
                      }}
                    />
                  )}
                  InputProps={{
                    size: 'small',
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6} md={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                <DatePicker
                  label={'Cancelamento Final'}
                  value={finalCancellationDate}
                  onChange={(value) => handleFinalCancellationDateChange(value)}
                  allowSameDateSelection={true}
                  cancelText={'Cancelar'}
                  PopperProps={{
                    popperOptions: {
                      placement: 'left',
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      helperText={finalCancellationErrorMessage}
                      error={finalCancellationError}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: 'dd/mm/aaaa',
                      }}
                    />
                  )}
                  InputProps={{
                    size: 'small',
                  }}
                />
              </LocalizationProvider>
            </Grid>
            {/* <Grid item xs={12} md={12}>
              <TextField type={'date'} size={'small'} />
            </Grid> */}
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                label={'Cliente'}
                value={cliente}
                size={'small'}
                onChange={(ev) =>
                  dispatch(billingParamsActions.changeClient(ev.target.value))
                }
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Autocomplete
                size='small'
                multiple
                id='situacoes'
                options={SITUATIONS}
                getOptionLabel={(option) => option.title}
                filterSelectedOptions
                value={selectedSituations}
                onChange={(ev, value) => handleSituationSelection(ev, value)}
                noOptionsText={'Sem opções'}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label='Situação'
                    placeholder='Selecione'
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default BillingsQueryParamsCard;
