import { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  Grid,
  TextField,
  Stack,
  IconButton,
  Pagination,
  PaginationItem,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { useGetProductsQuery } from "../../api/productsApi";
import { useAppSelector } from "../../store/hooks";

import Spinner from "../../components/layout/Spinner";
import ProductCard from "../../components/cards/product/ProductCard";
import ProductDetailModal from "../../components/modals/product/ProductDetailModal";
import SearchFeedback from "../../components/feedback/SearchFeedback";
import ProductsListingTable from "../../components/tables/ProductsListingTable";
import Colors from "../../constants/Colors";

const ProductsListPage = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get("page") || "1");
  const filter = query.get("filtro");
  let pageNumber = page;
  const [searchValue, setSearchValue] = useState<string>("");
  const [modalId, setModalId] = useState<string>("");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { data, isError, isFetching, isLoading, error } = useGetProductsQuery({
    pageNumber,
    filter,
  });
  const visualization = useAppSelector(
    (state) => state.visualization.visualization
  );

  const searchProducts = () => {
    if (searchValue.trim()) {
      navigate(
        `/produtos?page=1&filtro=${encodeURIComponent(searchValue.trim())}`
      );
    } else {
      navigate(`/produtos?page=1`);
    }
  };

  const prepareModal = (id: string): void => {
    setModalId(id);
    setOpenModal(true);
  };

  const renderState = () => {
    if (data && data.produtos.length > 0) {
      if (visualization === "CARD") {
        return data?.produtos.map((product) => {
          return (
            <>
              <Grid item xs={12} md={4} key={product.codigo}>
                <ProductCard
                  key={product.codigo}
                  produto={product}
                  toggle={prepareModal}
                />
              </Grid>
            </>
          );
        });
      } else {
        return (
          <Grid item xs={12} md={12}>
            <ProductsListingTable
              products={data ? data.produtos : []}
              toggle={prepareModal}
            />
          </Grid>
        );
      }
    } else {
      return <SearchFeedback />;
    }
  };

  if (isLoading || isFetching) {
    return <Spinner />;
  }

  if (isError || error) {
    <h2>Erro ao carregar Produtos</h2>;
  }

  return (
    <>
      <ProductDetailModal
        openModal={openModal}
        id={modalId}
        toggle={() => setOpenModal(!openModal)}
      />
      <Stack
        width={"100%"}
        mb={3}
        direction={"row"}
        justifyContent={"left"}
        spacing={1}
      >
        <TextField
          size="small"
          value={searchValue}
          placeholder={"Pesquisar"}
          type="search"
          onChange={(ev) => setSearchValue(ev.target.value)}
          style={{ width: "50%" }}
          color="success"
          label="Pesquisar"
          onKeyPress={(ev) => {
            if (ev.key === "Enter") {
              searchProducts();
            }
          }}
        />
        <IconButton
          onClick={() => searchProducts()}
          sx={{
            backgroundColor: Colors.primary,
            "&:focus": {
              backgroundColor: Colors.primary,
            },
            "&:hover": {
              backgroundColor: "green",
            },
            boxShadow: 3,
          }}
        >
          <Search sx={{ color: "white" }} />
        </IconButton>
      </Stack>
      <Grid container spacing={2} mb={visualization === "CARD" ? 2 : 0}>
        {renderState()}
      </Grid>
      {data && data?.totalPages > 1 && (
        <Pagination
          showFirstButton
          showLastButton
          page={page}
          count={data?.totalPages}
          sx={{
            "& .MuiPaginationItem-root": {
              "&.Mui-selected": {
                background: Colors.mainLayoutAtiveBackground,
                color: "black",
              },
            },
          }}
          renderItem={(item) => (
            <PaginationItem
              component={Link}
              to={`/produtos${
                filter
                  ? `?page=${item.page}&filtro=${filter}`
                  : `?page=${item.page}`
              }`}
              {...item}
            />
          )}
        />
      )}
    </>
  );
};

export default ProductsListPage;
