import Order from "../../models/order/order.model";

const ORDER_INITIALIZER: Order = {
  clienteId: "",
  condicaoPagamentoId: "",
  emissao: {
    data: "",
    hora: "",
  },
  id: "",
  itens: [],
  owner: "",
  reserva: false,
  observacao: "",
  previsao: {
    data: "",
    hora: "",
  },
  sistemaIntegrado: {
    dataEmissaoNota: null,
    fasePedidoId: null,
    notaFiscal: null,
    numeroPedido: null,
  },
  situacaoId: null,
  tabelaPrecoId: "",
  tipoId: "",
  totais: {
    acrescimos: {
      cabecalho: {
        percentual: null,
        total: null,
        valor: null,
      },
      condicaoPagamento: {
        percentual: null,
        total: null,
        valor: null,
      },
      itens: {
        total: null,
      },
      total: null,
    },
    descontos: {
      cabecalho: {
        percentual: null,
        total: null,
        valor: null,
      },
      condicaoPagamento: {
        percentual: null,
        total: null,
        valor: null,
      },
      itens: {
        total: null,
      },
      total: 0,
    },
    produtos: 0,
    totalIpi: 0,
    total: 0,
  },
  transmissao: {
    data: "",
    hora: "",
  },
  vendedorId: "",
};

export default ORDER_INITIALIZER;
