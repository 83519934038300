import { useLayoutEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
  FormHelperText,
} from '@mui/material';
import { GroupOutlined } from '@mui/icons-material';
import { LocalizationProvider, DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { format, isValid } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { useGetCategoriesQuery } from '../../../api/categoryApi';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { clientActions } from '../../../store/client/client-slice';

import { CAMPO_OBRIGATORIO } from '../../../util/string/utilStrings';
import Colors from '../../../constants/Colors';
import Pessoa from '../../../models/client/person.model';
import { clientFormErrorActions } from '../../../store/error/client-form-error-slice';

interface Props {
  pessoa: Pessoa;
  dados: {
    apelido: string;
    nome: string;
    categoria: string;
    nascimento: string;
  };
}

const formatDate = (date: string): Date => {
  if (!date || date.length === 0 || date === '') {
    return new Date();
  }
  let formattedDate: any = date.split('/');
  formattedDate = `${formattedDate[2]}-${formattedDate[1]}-${formattedDate[0]}`;
  return new Date(formattedDate);
};

const GeneralInformationCard = (props: Props) => {
  const { dados } = props;
  const { data } = useGetCategoriesQuery();
  const [apelido, setApelido] = useState<string>(dados.apelido);
  const [nome, setNome] = useState<string>(dados.nome);
  const [categoria, setCategoria] = useState<string>(dados.categoria);
  // const [nascimento, setNascimento] = useState<string>('');
  const [nascimento, setNascimento] = useState<Date | null>(
    formatDate(dados.nascimento)
  );
  const dispatch = useAppDispatch();

  const isNotReadOnly = useAppSelector(
    (state) => state.tenantConfiguration.cadastroCliente.permiteEdicao
  );

  const isReadOnly = useAppSelector(
    (state) => state.readability.isClientReadOnly
  );

  const nameError = useAppSelector((state) => state.clientFormError.nameError);
  const categoryError = useAppSelector(
    (state) => state.clientFormError.categoryError
  );
  const dateError = useAppSelector(
    (state) => state.clientFormError.datePickerError
  );

  useLayoutEffect(() => {
    if (data && data.length === 1) {
      setCategoria(data[0].id);
      dispatch(clientActions.changeCategoria(data[0].id));
    }
  }, [data, setCategoria, dispatch]);

  const handleChange = (ev: SelectChangeEvent<string>) => {
    ev.preventDefault();
    setCategoria(ev.target.value);
    dispatch(clientFormErrorActions.categoryErrorSetter(false));
    dispatch(clientActions.changeCategoria(ev.target.value));
  };

  const handleBlur = () => {
    if (nome) {
      dispatch(clientFormErrorActions.nameErrorSetter(false));
    }
    if (categoria && categoryError) {
      dispatch(clientFormErrorActions.categoryErrorSetter(false));
    }
  };

  const handleDateChange = (date: Date | null): void => {
    let formattedDate = '';
    if (!date) {
      if (dateError)
        dispatch(clientFormErrorActions.datePickerErrorSetter(false));
      dispatch(clientActions.changeNascimento(formattedDate));
      return;
    } else if (
      !isValid(date) ||
      date < new Date('1900-01-01') ||
      date > new Date()
    ) {
      if (!dateError)
        dispatch(clientFormErrorActions.datePickerErrorSetter(true));
      return;
    } else {
      if (dateError)
        dispatch(clientFormErrorActions.datePickerErrorSetter(false));
      formattedDate = format(date, 'dd/MM/yyyy');
      dispatch(clientActions.changeNascimento(formattedDate));
    }
  };

  return (
    <Card>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <Typography
              sx={{
                backgroundColor: Colors.titleRowWithBackgroundColor,
                pl: 1,
                pt: 0.75,
                mt: -1,
              }}
              variant='body2'
              color={Colors.titleWithBackgroudColor}
            >
              <GroupOutlined fontSize={'inherit'} /> Informações Gerais
            </Typography>
          </Grid>
          <Grid item xs={12} md={props.pessoa === 'F' ? 4 : 5}>
            <TextField
              disabled={isReadOnly}
              // inputProps={{ readOnly: !isNotReadOnly }}
              fullWidth
              placeholder='Apelido'
              value={apelido}
              type='text'
              onChange={(ev) => {
                setApelido(ev.target.value);
                dispatch(clientActions.changeNickname(ev.target.value));
              }}
              size={'small'}
              color='success'
              label='Apelido'
            />
          </Grid>
          <Grid item xs={12} md={props.pessoa === 'F' ? 4 : 5}>
            <TextField
              disabled={isReadOnly}
              // inputProps={{ readOnly: !isNotReadOnly }}
              onBlur={() => handleBlur()}
              error={nameError}
              fullWidth
              required
              placeholder='Nome'
              value={nome}
              type='text'
              onChange={(ev) => {
                setNome(ev.target.value);
                dispatch(clientActions.changeNome(ev.target.value));
              }}
              size={'small'}
              color='success'
              label='Nome'
              helperText={nameError ? CAMPO_OBRIGATORIO : ''}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <FormControl fullWidth color='success' error={categoryError}>
              <InputLabel
                id='categoria-select-label'
                color='success'
                error={categoryError}
              >
                Categoria *
              </InputLabel>
              <Select
                disabled={isReadOnly}
                // inputProps={{ readOnly: !isNotReadOnly }}
                labelId='categoria-select-label'
                fullWidth
                value={categoria}
                label='Categoria *'
                onChange={(ev) => handleChange(ev)}
                size={'small'}
                color='success'
                error={categoryError}
              >
                {data?.map((category) => {
                  return (
                    <MenuItem key={category.id} value={category.id}>
                      {category.nome}
                    </MenuItem>
                  );
                })}
              </Select>
              {categoryError && (
                <FormHelperText sx={{ color: Colors.muiDanger }}>
                  {CAMPO_OBRIGATORIO}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          {props.pessoa === 'F' && (
            <Grid item xs={12} md={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                <DatePicker
                  disabled={isReadOnly}
                  // readOnly={!isNotReadOnly}
                  minDate={new Date('1900-01-01')}
                  maxDate={new Date()}
                  label={'Nascimento'}
                  value={nascimento}
                  onChange={(date) => {
                    setNascimento(date);
                    handleDateChange(date);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={
                        dateError ? 'A data informada é inválida' : ''
                      }
                      error={dateError}
                      color='success'
                      inputProps={{
                        ...params.inputProps,
                        placeholder: 'dd/mm/aaaa',
                        readOnly: isReadOnly,
                      }}
                    />
                  )}
                  InputProps={{
                    size: 'small',
                    readOnly: true,
                  }}
                />
              </LocalizationProvider>
            </Grid>
            //
            //   <TextField
            //     placeholder='Nascimento'
            //     value={nascimento}
            //     type='text'
            //     onChange={(ev) => {
            //       setNascimento(ev.target.value);
            //       dispatch(clientActions.changeNascimento(ev.target.value));
            //     }}
            //     size={'small'}
            //     color='success'
            //     label='Nascimento'
            //   />
            // </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};
export default GeneralInformationCard;
