import { Grid, Card, CardContent, Typography, TextField } from "@mui/material";
import { NoteAltOutlined } from "@mui/icons-material";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import Colors from "../../../constants/Colors";
import { orderActions } from "../../../store/order/order-slice";

const OrderObservationCard = () => {
  const observation = useAppSelector((state) => state.order.order.observacao);
  // const isReadOnly = !useAppSelector(
  //   (state) => state.order.orderSituation.permiteEdicao
  // );

  const isReadOnly = useAppSelector(
    (state) => state.readability.isOrderReadOnly
  );

  const dispatch = useAppDispatch();

  const handleObservationChange = (
    ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    ev.preventDefault();
    dispatch(orderActions.setObservation(ev.target.value));
  };

  return (
    <Grid item xs={12} md={9}>
      <Card sx={{ justifyContent: "center" }}>
        <CardContent>
          <Grid
            container
            spacing={1}
            direction={"row"}
            justifyContent={"right"}
          >
            <Grid item xs={12} md={12}>
              <Typography
                sx={{
                  backgroundColor: Colors.titleRowWithBackgroundColor,
                  pl: 1,
                  pt: 0.75,
                  mt: -1,
                }}
                variant="body2"
                color={Colors.titleWithBackgroudColor}
              >
                <NoteAltOutlined fontSize={"inherit"} /> Observação
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                disabled={isReadOnly}
                type={"text"}
                multiline
                fullWidth
                color="success"
                value={observation}
                size={"small"}
                onChange={(ev) => handleObservationChange(ev)}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default OrderObservationCard;
