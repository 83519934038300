import { useEffect, useLayoutEffect } from 'react';
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useMatch,
} from 'react-router-dom';

import { useAppDispatch, useAppSelector } from './store/hooks';
import { clientActions } from './store/client/client-slice';
import { clientFormErrorActions } from './store/error/client-form-error-slice';
import MainLayout from './components/layout/MainLayout';
import AuthContainer from './components/authContainer/AuthContainer';
import LoginPage from './pages/login/LoginPage';
import MainPage from './pages/main/MainPage';
import SalesPage from './pages/sales/SalesPage';
import ListSales from './pages/sales/ListSales';
import ClientListPage from './pages/clients/ClientListPage';
import ProductsListPage from './pages/products/ProductsListPage';
import SalesManagementPage from './pages/sales/SalesManagementPage';
import ClientManagementPage from './pages/clients/ClientManagementPage';
import GoalsQueriesResultsPage from './pages/queries/GoalsQueriesResultsPage';
import OrdersQueriesFiltersPage from './pages/queries/OrdersQueriesFiltersPage';
import OrdersQueriesResultsPage from './pages/queries/OrdersQueriesResultsPage';
import BillingQueriesResultsPage from './pages/queries/BillingQueriesResultsPage';
import BillingsQueriesFiltersPage from './pages/queries/BillingsQueriesFiltersPage';
import { citiesActions } from './store/address/cities-slice';
import { orderActions } from './store/order/order-slice';
import { readabilityActions } from './store/readability/readability-slice';

function App() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const matchedClients = useMatch('/clientes');
  const matchedSales = useMatch('/vendas');
  const matchedSalesList = useMatch('/vendas/pesquisar');
  const API_URL = useAppSelector((state) => state.apiUrl.apiUrl);

  useEffect(() => {
    if (!!matchedClients) {
      dispatch(clientActions.resetClient());
      dispatch(clientFormErrorActions.resetErrors());
      dispatch(citiesActions.resetCities());
    }
  }, [location, matchedClients, dispatch]);

  useEffect(() => {
    if (!!matchedSales) {
      localStorage.removeItem('orderSearchParams');
    }
  }, [location, matchedSales]);

  useEffect(() => {
    if (!!matchedSales || !!matchedSalesList) {
      dispatch(orderActions.resetOrder());
      dispatch(readabilityActions.resetReadability());
    }
  }, [location, matchedSales, dispatch]);

  return (
    <Routes>
      <Route path='/' element={<LoginPage />} />
      <Route element={<MainLayout />}>
        <Route
          path='/main'
          element={
            <AuthContainer>
              <MainPage />
            </AuthContainer>
          }
        />
        <Route
          path='/produtos'
          element={
            <AuthContainer>
              <ProductsListPage />
            </AuthContainer>
          }
        />
        <Route
          path='/clientes'
          element={
            <AuthContainer>
              <ClientListPage />
            </AuthContainer>
          }
        />
        <Route
          path='/clientes/novo'
          element={
            <AuthContainer>
              <ClientManagementPage />
            </AuthContainer>
          }
        />
        <Route
          path='/clientes/detalhes'
          element={
            <AuthContainer>
              <ClientManagementPage />
            </AuthContainer>
          }
        />
        <Route
          path='/clientes/editar'
          element={
            <AuthContainer>
              <ClientManagementPage />
            </AuthContainer>
          }
        />
        <Route
          path='/vendas'
          element={
            <AuthContainer>
              <SalesPage />
            </AuthContainer>
          }
        />
        <Route
          path='/vendas/pesquisar'
          element={
            <AuthContainer>
              <ListSales />
            </AuthContainer>
          }
        />
        <Route
          path='/vendas/criar'
          element={
            <AuthContainer>
              <SalesManagementPage />
            </AuthContainer>
          }
        />
        <Route
          path='/vendas/detalhes'
          element={
            <AuthContainer>
              <SalesManagementPage />
            </AuthContainer>
          }
        />
        <Route
          path='/consultas'
          element={
            <AuthContainer>
              <OrdersQueriesFiltersPage />
            </AuthContainer>
          }
        />
        <Route
          path='/consultas/pedidos'
          element={
            <AuthContainer>
              <OrdersQueriesFiltersPage />
            </AuthContainer>
          }
        />
        <Route
          path='/consultas/pedidos/resultado'
          element={
            <AuthContainer>
              <OrdersQueriesResultsPage />
            </AuthContainer>
          }
        />
        <Route
          path='/consultas/metas'
          element={
            <AuthContainer>
              <GoalsQueriesResultsPage />
            </AuthContainer>
          }
        />
        <Route
          path='/consultas/faturamentos'
          element={
            <AuthContainer>
              <BillingsQueriesFiltersPage />
            </AuthContainer>
          }
        />
        <Route
          path='/consultas/faturamentos/resultado'
          element={
            <AuthContainer>
              <BillingQueriesResultsPage />
            </AuthContainer>
          }
        />
      </Route>
      <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  );
}

export default App;
