import { useState, useEffect } from 'react';
import { Modal, Card, CardContent, Typography } from '@mui/material';

import { useGetPaymentConditionsQuery } from '../../../api/paymentConditionsApi';
import { useAppSelector } from '../../../store/hooks';
import PaymentConditions from '../../../models/order/paymentConditions.model';

import Spinner from '../../layout/Spinner';
import OrderPaymentConditionCard from '../../cards/order/OrderPaymentConditionCard';

interface Props {
  openModal: boolean;
  toggle: () => void;
}

const OrderPaymentConditionSelectionModal = (props: Props) => {
  const selectedTable = useAppSelector(
    (state) => state.order.selectedPriceTable
  );
  const { data, isError, isFetching, isLoading } =
    useGetPaymentConditionsQuery();

  const [currentConditions, setCurrentConditions] = useState<
    PaymentConditions[]
  >([]);

  useEffect(() => {
    if (data && selectedTable) {
      let filteredConditions = data.filter((condition) => {
        return selectedTable.condicoesPagamentoIds.includes(condition.id);
      });
      if (filteredConditions.length === 0) {
        setCurrentConditions(data);
      } else {
        setCurrentConditions(filteredConditions);
      }
    }
  }, [selectedTable, data]);

  if (isFetching || isLoading) {
    return (
      <Modal
        open={props.openModal}
        onClose={props.toggle}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Card
          sx={{
            height: '70vh',
            overflowY: 'scroll',
            width: { md: '25%', xs: '70%' },
          }}
        >
          <CardContent>
            <Spinner />
          </CardContent>
        </Card>
      </Modal>
    );
  }

  if (isError) {
    return (
      <Modal
        open={props.openModal}
        onClose={props.toggle}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Card
          sx={{
            height: '70vh',
            overflowY: 'scroll',
            width: { md: '25%', xs: '70%' },
          }}
        >
          <CardContent>
            <Typography variant='body2'>
              Erro ao carregar dados das condições.
            </Typography>
          </CardContent>
        </Card>
      </Modal>
    );
  }

  return (
    <Modal
      open={props.openModal}
      onClose={props.toggle}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Card
        sx={{
          height: '70vh',
          overflowY: 'scroll',
          width: { md: '25%', xs: '70%' },
        }}
      >
        <CardContent>
          {currentConditions.map((condition) => {
            return (
              <OrderPaymentConditionCard
                key={condition.id}
                condition={condition}
                toggle={() => props.toggle()}
              />
            );
          })}
        </CardContent>
      </Card>
    </Modal>
  );
};

export default OrderPaymentConditionSelectionModal;
