import { useEffect } from "react";
import { Grid, Card, CardContent, Typography } from "@mui/material";
import { ArticleOutlined } from "@mui/icons-material";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import Colors from "../../../constants/Colors";
import formatDateToProtocol from "../../../util/formaters/formatDateToProtocol";
import formatDateToString from "../../../util/formaters/formatDateToString";
import { orderActions } from "../../../store/order/order-slice";

const OrderProtocolCard = () => {
  const userId = useAppSelector((state) => state.auth.userId);
  const order = useAppSelector((state) => state.order.order);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!order.id) {
      dispatch(orderActions.setOrderId(formatDateToProtocol(userId)));
    }
  }, [order.id, userId, dispatch]);

  useEffect(() => {
    if (!order.emissao.data) {
      dispatch(orderActions.setOrderEmissao(formatDateToString()));
    }
  }, [order.emissao.data, dispatch]);

  return (
    <Grid item xs={12} md={3}>
      <Card sx={{ height: "100%" }}>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <Typography
                sx={{
                  backgroundColor: Colors.titleRowWithBackgroundColor,
                  pl: 1,
                  pt: 0.75,
                  mt: -1,
                }}
                variant="body2"
                color={Colors.titleWithBackgroudColor}
              >
                <ArticleOutlined fontSize={"inherit"} /> Protocolo
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="h6">{order.id}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} display={"flex"}>
            <Typography variant="body2">{"Digitado em\xa0"}</Typography>
            <Typography
              variant="body2"
              color={Colors.primary}
              fontWeight={"bold"}
            >
              {order.emissao.data}
            </Typography>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};
export default OrderProtocolCard;
