import { Stack, IconButton, Typography } from '@mui/material';

const OrderQueryTooltip = () => {
  return (
    <Stack spacing={1}>
      <Stack direction={'row'} alignItems={'flex-start'} spacing={2}>
        <IconButton
          disabled
          sx={{
            '&:disabled': {
              fontSize: 10,
              backgroundColor: 'black',
              border: 1,
              borderWidth: 1,
              borderColor: 'white',
              width: '1rem',
              height: '1rem',
              borderRadius: 0,
            },
          }}
        />
        <Typography variant='body2'>
          - Sem desconto global, ou apenas desconto do cabeçalho
        </Typography>
      </Stack>
      <Stack direction={'row'} alignItems={'flex-start'} spacing={2}>
        <IconButton
          disabled
          sx={{
            '&:disabled': {
              fontSize: 10,
              backgroundColor: 'blue',
              border: 1,
              borderWidth: 1,
              borderColor: 'white',
              width: '1rem',
              height: '1rem',
              borderRadius: 0,
            },
          }}
        />
        <Typography variant='body2'>
          - Desconto dado apenas nos itens
        </Typography>
      </Stack>
      <Stack direction={'row'} alignItems={'flex-start'} spacing={2}>
        <IconButton
          disabled
          sx={{
            '&:disabled': {
              fontSize: 10,
              backgroundColor: 'red',
              border: 1,
              borderWidth: 1,
              borderColor: 'white',
              width: '1rem',
              height: '1rem',
              borderRadius: 0,
            },
          }}
        />
        <Typography variant='body2'>
          - Desconto dado no cabeçalho e nos itens
        </Typography>
      </Stack>
    </Stack>
  );
};

export default OrderQueryTooltip;
