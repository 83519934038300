import { useState } from 'react';
import {
  Card,
  Typography,
  CardContent,
  Grid,
  TextField,
  CardActionArea,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LocationOn } from '@mui/icons-material';

import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import ClientAdressModal from '../../modals/client/ClientAddressModal';

import Colors from '../../../constants/Colors';
import Endereco from '../../../models/address/address.model';
import { CAMPO_OBRIGATORIO } from '../../../util/string/utilStrings';
import { clientFormErrorActions } from '../../../store/error/client-form-error-slice';

interface Props {
  enderecoPrincipalObjeto: Endereco;
  enderecoEntregaObjeto: Endereco;
  enderecoPrincipal: string;
  enderecoEntrega: string;
}

const AddressCard = (props: Props) => {
  const {
    enderecoPrincipalObjeto,
    enderecoEntregaObjeto,
    enderecoPrincipal,
    enderecoEntrega,
  } = props;
  const [enderecoPrincipalCompleto, setEnderecoPrincipalCompleto] =
    useState<string>(enderecoPrincipal);
  const [enderecoParaEntregaCompleto, setEndercoParaEntregaCompleto] =
    useState<string>(enderecoEntrega);
  const [openModalPrincipal, setOpenModalPrincipal] = useState<boolean>(false);
  const [openModalEntrega, setOpenModalEntrega] = useState<boolean>(false);

  const mainAddressError = useAppSelector(
    (state) => state.clientFormError.mainAddressError
  );

  const isNotReadOnly = useAppSelector(
    (state) => state.tenantConfiguration.cadastroCliente.permiteEdicao
  );

  const isReadOnly = useAppSelector(
    (state) => state.readability.isClientReadOnly
  );

  const dispatch = useAppDispatch();

  const CustomDisabledInputWithoutError = styled(TextField)(() => ({
    '.MuiInputBase-input.Mui-disabled': {
      WebkitTextFillColor:
        !!enderecoPrincipalCompleto || !!enderecoParaEntregaCompleto
          ? 'black'
          : 'darkgray',
      color:
        !!enderecoPrincipalCompleto || !!enderecoParaEntregaCompleto
          ? 'black'
          : 'darkgray',
    },
  }));

  const CustomDisabledInput = styled(TextField)(() => ({
    '.MuiInputBase-input.Mui-disabled': {
      WebkitTextFillColor:
        !!enderecoPrincipalCompleto || !!enderecoParaEntregaCompleto
          ? 'black'
          : 'darkgray',
      color:
        !!enderecoPrincipalCompleto || !!enderecoParaEntregaCompleto
          ? 'black'
          : 'darkgray',
    },
    '.MuiOutlinedInput-root.Mui-disabled': {
      ' & > fieldset': {
        borderColor: mainAddressError ? Colors.muiDanger : 'darkgray',
      },
    },
  }));

  const handleSetAddress = (address: string): void => {
    if (mainAddressError) {
      dispatch(clientFormErrorActions.mainAddressErrorSetter(false));
    }
    setEndercoParaEntregaCompleto(address);
  };

  return (
    <>
      <ClientAdressModal
        open={openModalPrincipal}
        toggle={() => setOpenModalPrincipal(!openModalPrincipal)}
        setAddress={(address: string) => setEnderecoPrincipalCompleto(address)}
        addressType={'Principal'}
        endereco={{ ...enderecoPrincipalObjeto }}
      />
      <ClientAdressModal
        open={openModalEntrega}
        toggle={() => setOpenModalEntrega(!openModalEntrega)}
        setAddress={handleSetAddress}
        addressType={'para Entrega'}
        endereco={{ ...enderecoEntregaObjeto }}
      />
      <Card>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <Typography
                sx={{
                  backgroundColor: Colors.titleRowWithBackgroundColor,
                  pl: 1,
                  pt: 0.75,
                  mt: -1,
                }}
                variant='body2'
                color={Colors.titleWithBackgroudColor}
              >
                <LocationOn fontSize={'inherit'} /> Endereços
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              {isReadOnly ? (
                <TextField
                  sx={{
                    borderColor: mainAddressError ? Colors.muiDanger : '',
                  }}
                  error={mainAddressError}
                  required
                  multiline={true}
                  fullWidth
                  disabled={true}
                  placeholder='Principal'
                  value={enderecoPrincipalCompleto}
                  type='text'
                  size={'small'}
                  label='Principal'
                  helperText={mainAddressError ? CAMPO_OBRIGATORIO : ''}
                />
              ) : (
                <CardActionArea onClick={() => setOpenModalPrincipal(true)}>
                  <CustomDisabledInput
                    sx={{
                      borderColor: mainAddressError ? Colors.muiDanger : '',
                    }}
                    error={mainAddressError}
                    required
                    multiline={true}
                    fullWidth
                    disabled={true}
                    placeholder='Principal'
                    value={enderecoPrincipalCompleto}
                    type='text'
                    size={'small'}
                    label='Principal'
                    helperText={mainAddressError ? CAMPO_OBRIGATORIO : ''}
                  />
                </CardActionArea>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              {isReadOnly ? (
                <TextField
                  multiline={true}
                  disabled={true}
                  fullWidth
                  placeholder='Para entrega'
                  value={enderecoParaEntregaCompleto}
                  type='text'
                  size={'small'}
                  color='success'
                  label='Para entrega'
                />
              ) : (
                <CardActionArea onClick={() => setOpenModalEntrega(true)}>
                  <CustomDisabledInputWithoutError
                    multiline={true}
                    disabled={true}
                    fullWidth
                    placeholder='Para entrega'
                    value={enderecoParaEntregaCompleto}
                    type='text'
                    size={'small'}
                    color='success'
                    label='Para entrega'
                  />
                </CardActionArea>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default AddressCard;
