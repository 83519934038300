import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import FullClient from '../../models/client/fullClient.model';
import Endereco from '../../models/address/address.model';

import FULL_CLIENT_INITIALIZER from '../../util/initializers/FullClientInitializer';

const initialState: FullClient = FULL_CLIENT_INITIALIZER;

const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    changeNickname(state: FullClient, action: PayloadAction<string>) {
      state.apelido = action.payload;
    },
    changeNome(state: FullClient, action: PayloadAction<string>) {
      state.nome = action.payload;
    },
    changePessoa(state: FullClient, action: PayloadAction<'F' | 'J'>) {
      state.tipoPessoaId = action.payload;
    },
    changeCategoria(state: FullClient, action: PayloadAction<string>) {
      state.categoriaId = action.payload;
    },
    changeNascimento(state: FullClient, action: PayloadAction<string>) {
      state.dataNascimento = action.payload;
    },
    changeCnpj(state: FullClient, action: PayloadAction<string>) {
      state.documentacao.cnpjCpf = action.payload;
      state.id = action.payload;
    },
    changeInscricaoEstadual(state: FullClient, action: PayloadAction<string>) {
      state.documentacao.inscricaoEstadual = action.payload;
    },
    changeInscricaoMunicipal(state: FullClient, action: PayloadAction<string>) {
      state.documentacao.inscricaoMunicipal = action.payload;
    },
    changeCpf(state: FullClient, action: PayloadAction<string>) {
      state.documentacao.cnpjCpf = action.payload;
      state.id = action.payload;
    },
    changeRg(state: FullClient, action: PayloadAction<string>) {
      state.documentacao.rg = action.payload;
    },
    changeTelefonePrincipal(state: FullClient, action: PayloadAction<string>) {
      state.fone.principal = action.payload;
    },
    changeTelefoneSecundario(state: FullClient, action: PayloadAction<string>) {
      state.fone.secundario = action.payload;
    },
    changeCelular(state: FullClient, action: PayloadAction<string>) {
      state.fone.celular = action.payload;
    },
    changeEmailPrincipal(state: FullClient, action: PayloadAction<string>) {
      state.email.principal = action.payload;
    },
    changeEmailComercial(state: FullClient, action: PayloadAction<string>) {
      state.email.comercial = action.payload;
    },
    changeEmailFinanceiro(state: FullClient, action: PayloadAction<string>) {
      state.email.financeiro = action.payload;
    },
    changeEnderecoPrincipal(
      state: FullClient,
      action: PayloadAction<Endereco>
    ) {
      state.endereco.padrao = { ...action.payload };
    },
    changeEnderecoEntrega(state: FullClient, action: PayloadAction<Endereco>) {
      state.endereco.entrega = { ...action.payload };
    },
    setFullClient(state: FullClient, action: PayloadAction<FullClient>) {
      state.id = action.payload.id;
      state.tipoPessoaId = action.payload.tipoPessoaId;
      state.apelido = action.payload.apelido;
      state.categoriaId = action.payload.categoriaId;
      state.fone.celular = action.payload.fone.celular;
      state.documentacao.cnpjCpf = action.payload.documentacao.cnpjCpf;
      state.email.comercial = action.payload.email.comercial;
      state.email.financeiro = action.payload.email.financeiro;
      state.email.principal = action.payload.email.principal;
      state.endereco = {
        padrao: { ...action.payload.endereco.padrao },
        entrega: { ...action.payload.endereco.entrega },
      };
      state.documentacao.inscricaoEstadual =
        action.payload.documentacao.inscricaoEstadual;
      state.documentacao.inscricaoMunicipal =
        action.payload.documentacao.inscricaoMunicipal;
      state.dataNascimento = action.payload.dataNascimento;
      state.nome = action.payload.nome;
      state.documentacao.rg = action.payload.documentacao.rg;
      state.fone.principal = action.payload.fone.principal;
      state.fone.secundario = action.payload.fone.secundario;
    },
    resetClient() {
      return {
        ...initialState,
      };
    },
  },
});

export const clientActions = clientSlice.actions;

export default clientSlice;
