import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import BillingQueryParams from '../../models/requisitions/PaginatedBillingQueryRequisition.model';
import BILLING_QUERY_PARAMS from '../../util/initializers/BillingQueriesParamsInitializer';

const initialState = BILLING_QUERY_PARAMS;

const billingQueryParamsSlice = createSlice({
  initialState,
  name: 'billingQueryParams',
  reducers: {
    changeInvoiceId(state: BillingQueryParams, action: PayloadAction<string>) {
      state.notaFiscalId = action.payload;
    },
    changeClient(state: BillingQueryParams, action: PayloadAction<string>) {
      state.cliente = action.payload;
    },
    changeInicialEmission(
      state: BillingQueryParams,
      action: PayloadAction<string>
    ) {
      state.emissaoInicial = action.payload;
    },
    changeFinalEmission(
      state: BillingQueryParams,
      action: PayloadAction<string>
    ) {
      state.emissaoFinal = action.payload;
    },
    changeInicialCancellation(
      state: BillingQueryParams,
      action: PayloadAction<string>
    ) {
      state.cancelamentoInicial = action.payload;
    },
    changeFinalCancellation(
      state: BillingQueryParams,
      action: PayloadAction<string>
    ) {
      state.cancelamentoFinal = action.payload;
    },
    changePage(state: BillingQueryParams, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    changeSituation(
      state: BillingQueryParams,
      action: PayloadAction<string[]>
    ) {
      state.situacao = [...action.payload];
    },
    setQueryState(
      state: BillingQueryParams,
      action: PayloadAction<BillingQueryParams>
    ) {
      // console.log('Entrou no setQueryState: ', state, action.payload);
      state.cancelamentoFinal = action.payload.cancelamentoFinal;
      state.cancelamentoInicial = action.payload.cancelamentoInicial;
      state.emissaoFinal = action.payload.emissaoFinal;
      state.emissaoInicial = action.payload.emissaoInicial;
      state.cliente = action.payload.cliente;
      state.notaFiscalId = action.payload.notaFiscalId;
      state.page = action.payload.page;
      state.situacao = action.payload.situacao;
    },
    resetState() {
      return {
        ...initialState,
      };
    },
  },
});

export const billingParamsActions = billingQueryParamsSlice.actions;

export default billingQueryParamsSlice;
