import { useState } from 'react';
import { Card, Typography, CardContent, Grid, TextField } from '@mui/material';
import { LocalPhone } from '@mui/icons-material';

import formatPhoneNumber from '../../../util/formaters/formatPhoneNumber';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { clientActions } from '../../../store/client/client-slice';
import Colors from '../../../constants/Colors';
import { CAMPO_OBRIGATORIO } from '../../../util/string/utilStrings';
import { clientFormErrorActions } from '../../../store/error/client-form-error-slice';

interface Props {
  dados: {
    telefonePrincipal: string;
    telefoneSecundario: string;
    celular: string;
  };
}

const PhonesCard = (props: Props): JSX.Element => {
  const { dados } = props;
  const [telefonePrincipal, setTelefonePrincipal] = useState<string>(
    dados.telefonePrincipal
  );
  const [telefoneSecundario, setTelefoneSecundario] = useState<string>(
    dados.telefoneSecundario
  );
  const [celular, setCelular] = useState<string>(dados.celular);

  const isNotReadOnly = useAppSelector(
    (state) => state.tenantConfiguration.cadastroCliente.permiteEdicao
  );

  const isReadOnly = useAppSelector(
    (state) => state.readability.isClientReadOnly
  );

  const mainPhoneError = useAppSelector(
    (state) => state.clientFormError.mainPhoneError
  );

  const pessoa = useAppSelector((state) => state.client.tipoPessoaId);

  const dispatch = useAppDispatch();

  const returnFormattedTelefonePrincipal = (): void => {
    const formattedNumber = formatPhoneNumber(telefonePrincipal);
    setTelefonePrincipal(formattedNumber);
    dispatch(clientActions.changeTelefonePrincipal(formattedNumber));
    if (mainPhoneError) {
      dispatch(clientFormErrorActions.mainPhoneErrorSetter(false));
    }
  };
  const returnFormattedTelefoneSecundario = (): void => {
    const formattedNumber = formatPhoneNumber(telefoneSecundario);
    setTelefoneSecundario(formattedNumber);
    dispatch(clientActions.changeTelefoneSecundario(formattedNumber));
  };
  const returnFormattedCelular = (): void => {
    const formattedNumber = formatPhoneNumber(celular);
    setCelular(formattedNumber);
    dispatch(clientActions.changeCelular(formattedNumber));
  };

  return (
    <Card>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <Typography
              sx={{
                backgroundColor: Colors.titleRowWithBackgroundColor,
                pl: 1,
                pt: 0.75,
                mt: -1,
              }}
              variant='body2'
              color={Colors.titleWithBackgroudColor}
            >
              <LocalPhone fontSize={'inherit'} /> Telefones
            </Typography>
          </Grid>
          <Grid item xs={12} md={pessoa === 'F' ? 4 : 6}>
            <TextField
              disabled={isReadOnly}
              // inputProps={{ readOnly: !isNotReadOnly }}
              onBlur={() => returnFormattedTelefonePrincipal()}
              fullWidth
              required
              placeholder='Telefone Principal'
              value={telefonePrincipal}
              type='text'
              onChange={(ev) => setTelefonePrincipal(ev.target.value)}
              size={'small'}
              color='success'
              label='Telefone Principal'
              error={mainPhoneError}
              helperText={mainPhoneError ? CAMPO_OBRIGATORIO : ''}
            />
          </Grid>
          <Grid item xs={12} md={pessoa === 'F' ? 4 : 6}>
            <TextField
              disabled={isReadOnly}
              // inputProps={{ readOnly: !isNotReadOnly }}
              onBlur={() => returnFormattedTelefoneSecundario()}
              fullWidth
              placeholder='Telefone secundário'
              value={telefoneSecundario}
              type='text'
              onChange={(ev) => setTelefoneSecundario(ev.target.value)}
              size={'small'}
              color='success'
              label='Telefone secundário'
            />
          </Grid>
          <Grid item xs={12} md={pessoa === 'F' ? 4 : 6}>
            <TextField
              disabled={isReadOnly}
              // inputProps={{ readOnly: !isNotReadOnly }}
              onBlur={() => returnFormattedCelular()}
              fullWidth
              placeholder='Celular'
              value={celular}
              type='text'
              onChange={(ev) => {
                setCelular(ev.target.value);
                dispatch(clientActions.changeCelular(ev.target.value));
              }}
              size={'small'}
              color='success'
              label='Celular'
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PhonesCard;
