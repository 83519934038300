import { createApi } from '@reduxjs/toolkit/query/react';

import OrderSummary from '../models/order/orderSummary.model';
import BillingQueryResult from '../models/queries/BillingQueryResult.model';
import OrderQueriesParams from '../models/requisitions/PaginatedOrderQueryRequisition.model';
import BillingQueryParams from '../models/requisitions/PaginatedBillingQueryRequisition.model';
import BillingQueryDetails from '../models/queries/BillingQueryDetails.model';
import GoalsQueryResult from '../models/queries/GoalsQueryResult.model';
import dynamicBaseQuery from './dynamicBaseQuery';

export interface GetOrdersQuery {
  pedidos: OrderSummary[];
  totalPages: number;
}

export interface GetBillingsQuery {
  notasFiscais: BillingQueryResult[];
  totalPages: number;
}

export const queriesApi = createApi({
  reducerPath: 'queriesApi',
  tagTypes: ['Queries'],
  refetchOnMountOrArgChange: true,
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    getOrdersQueriesWithParams: builder.query<
      GetOrdersQuery,
      OrderQueriesParams
    >({
      query: (params: OrderQueriesParams) => {
        if (params.cliente.includes('/')) {
          params.cliente.replace('/', '%2f');
        }
        return {
          url: `/pedido`,
          params: params,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
    }),
    getBillingsQueriesWithParams: builder.query<
      GetBillingsQuery,
      BillingQueryParams
    >({
      query: (params: BillingQueryParams) => {
        if (params.cliente.includes('/')) {
          params.cliente.replace('/', '%2f');
        }
        return {
          url: '/notafiscal',
          params: params,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
    }),
    getBillingDetails: builder.query<BillingQueryDetails, string>({
      query: (id: string) => ({
        url: `/notafiscal/custom/${id}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }),
    }),
    getGoals: builder.query<GoalsQueryResult, string>({
      query: (id: string) => ({
        url: `/meta/vendedor/${id}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }),
    }),
  }),
});

export const {
  useGetOrdersQueriesWithParamsQuery,
  useGetBillingsQueriesWithParamsQuery,
  useGetBillingDetailsQuery,
  useGetGoalsQuery,
} = queriesApi;
