import Order from '../../../models/order/order.model';
import roundAbnt from '../../formaters/roundAbnt';

interface DiscountData {
  value: number;
  type: 'PERCENTAGE' | 'VALUE';
}

const globalDiscountChange = (
  order: Order,
  discountData: DiscountData
): Order => {
  if (discountData.type === 'PERCENTAGE') {
    order.totais.descontos.cabecalho.valor = 0;
    order.totais.descontos.cabecalho.percentual = discountData.value;
    const globalDiscount = roundAbnt(
      (order.totais.produtos * discountData.value) / 100
    );
    order.totais.descontos.cabecalho.total = globalDiscount;

    if (order.totais.descontos.itens.total) {
      order.totais.descontos.total =
        order.totais.descontos.cabecalho.total +
        order.totais.descontos.itens.total;
    } else {
      order.totais.descontos.total = order.totais.descontos.cabecalho.total;
    }

    if (order.totais.acrescimos.total) {
      order.totais.total =
        order.totais.produtos +
        order.totais.acrescimos.total -
        order.totais.descontos.total;
      order.totais.total = roundAbnt(order.totais.total);
    } else {
      order.totais.total = order.totais.produtos - order.totais.descontos.total;
      order.totais.total = roundAbnt(order.totais.total);
    }
  } else {
    order.totais.descontos.cabecalho.percentual = 0;
    order.totais.descontos.cabecalho.valor = discountData.value;
    const globalIncrease = roundAbnt(discountData.value);
    order.totais.descontos.cabecalho.total = globalIncrease;

    if (order.totais.descontos.itens.total) {
      order.totais.descontos.total =
        order.totais.descontos.cabecalho.total +
        order.totais.descontos.itens.total;
    } else {
      order.totais.descontos.total = order.totais.descontos.cabecalho.total;
    }

    if (order.totais.acrescimos.total) {
      order.totais.total =
        order.totais.produtos +
        order.totais.acrescimos.total -
        order.totais.descontos.total;
      order.totais.total = roundAbnt(order.totais.total);
    } else {
      order.totais.total = order.totais.produtos - order.totais.descontos.total;
      order.totais.total = roundAbnt(order.totais.total);
    }
  }

  return order;
};

export default globalDiscountChange;
