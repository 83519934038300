import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Stack,
  Card,
  CardContent,
  Box,
  CircularProgress,
  Alert,
  AlertTitle,
} from "@mui/material";

import { useAppDispatch } from "../../store/hooks";
import { login } from "../../store/auth/auth-actions";

import { CAMPO_OBRIGATORIO } from "../../util/string/utilStrings";

import Colors from "../../constants/Colors";
import bg_image from "../../assets/imgs/bg.png";
import ivitech_logo from "../../assets/imgs/ivivenda_titulo.png";
import logo_branca from "../../assets/imgs/logoivitech.png";

interface loginBody {
  userName: string;
  password: string;
  tenant: string;
  uuid: number;
}

const LoginPage = (): JSX.Element => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [tenant, setTenant] = useState("");
  const [isFieldsEmpty, setIsFieldsEmpty] = useState({
    isUserNameEmpty: false,
    isPasswordEmpty: false,
    isTenantEmpty: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleTenantChange = (
    ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const emptyFields = { ...isFieldsEmpty };
    if (emptyFields.isTenantEmpty) {
      emptyFields.isTenantEmpty = false;
    }
    setIsFieldsEmpty({ ...emptyFields });
    setTenant(ev.target.value);
  };
  const handleUserNameChange = (
    ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const emptyFields = { ...isFieldsEmpty };
    if (emptyFields.isUserNameEmpty) {
      emptyFields.isUserNameEmpty = false;
    }
    setIsFieldsEmpty({ ...emptyFields });
    setUserName(ev.target.value);
  };
  const handlePasswordChange = (
    ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const emptyFields = { ...isFieldsEmpty };
    if (emptyFields.isPasswordEmpty) {
      emptyFields.isPasswordEmpty = false;
    }
    setIsFieldsEmpty({ ...emptyFields });
    setPassword(ev.target.value);
  };

  const handleEnterPress = (ev: React.KeyboardEvent<HTMLDivElement>) => {
    if (ev.key === "Enter") {
      submitLogin();
    }
  };

  const critiques = (): boolean => {
    const emptyFields = { ...isFieldsEmpty };
    if (!userName || !password || !tenant) {
      if (!userName) {
        emptyFields.isUserNameEmpty = true;
      }
      if (!password) {
        emptyFields.isPasswordEmpty = true;
      }
      if (!tenant) {
        emptyFields.isTenantEmpty = true;
      }
      setIsFieldsEmpty({ ...emptyFields });
      return true;
    }
    emptyFields.isPasswordEmpty = false;
    emptyFields.isTenantEmpty = false;
    emptyFields.isUserNameEmpty = false;
    setIsFieldsEmpty({ ...emptyFields });
    return false;
  };

  const submitLogin = async (): Promise<void> => {
    if (critiques()) return;
    setIsLoading(true);
    const tenantArray: string[] = tenant.split("@");
    // console.log(tenantArray[1]);
    const loginData: loginBody = {
      tenant: tenantArray[0],
      userName: userName,
      password: password,
      uuid: 1,
    };
    const body: string = JSON.stringify(loginData);

    try {
      await dispatch(login(body, tenantArray[1] ? tenantArray[1] : ""));
      setIsLoading(false);
      navigate("/main");
    } catch (err: any) {
      setErrorMessage(err.message as string);
      setIsLoading(false);
      setIsError(true);
    }
  };

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          backgroundImage: `url(${bg_image})`,
          backgroundSize: "cover",
          left: 0,
          top: 0,
          minHeight: "100%",
          minWidth: "100%",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Box
          mt={-15}
          mb={3}
          component={"img"}
          src={ivitech_logo}
          alt={"Logo Ivitech Tecnologia"}
        />
        <Card sx={{ width: { md: "20%", sm: "30%", xs: "50%" }, mb: 2 }}>
          <CardContent>
            <Stack spacing={2} width="100%" alignItems={"center"}>
              <TextField
                onKeyPress={handleEnterPress}
                fullWidth
                error={isFieldsEmpty.isTenantEmpty}
                placeholder="Empresa"
                value={tenant}
                type="text"
                onChange={handleTenantChange}
                size={"small"}
                color="success"
                label="Empresa"
                helperText={
                  isFieldsEmpty.isTenantEmpty ? CAMPO_OBRIGATORIO : ""
                }
              />
              <TextField
                onKeyPress={handleEnterPress}
                fullWidth
                error={isFieldsEmpty.isUserNameEmpty}
                placeholder="Nome de Usuário"
                value={userName}
                type="text"
                onChange={handleUserNameChange}
                size={"small"}
                color={"success"}
                label="Nome de Usuário"
                helperText={
                  isFieldsEmpty.isUserNameEmpty ? CAMPO_OBRIGATORIO : ""
                }
              />
              <TextField
                onKeyPress={handleEnterPress}
                fullWidth
                error={isFieldsEmpty.isPasswordEmpty}
                placeholder="Senha"
                value={password}
                type="password"
                onChange={handlePasswordChange}
                size={"small"}
                color="success"
                label="Senha"
                helperText={
                  isFieldsEmpty.isPasswordEmpty ? CAMPO_OBRIGATORIO : ""
                }
              />
              <Button
                onClick={submitLogin}
                disabled={isLoading}
                sx={{
                  backgroundColor: Colors.primary,
                  color: "white",
                  borderColor: Colors.primary,
                  "&:hover": {
                    backgroundColor: "green",
                    color: "white",
                    borderColor: "green",
                  },
                  "&:disabled": {
                    backgroundColor: "green",
                    color: "white",
                    borderColor: "green",
                  },
                }}
                fullWidth
                variant="outlined"
              >
                {isLoading ? (
                  <CircularProgress color="inherit" size={24} />
                ) : (
                  "Entrar"
                )}
              </Button>
            </Stack>
          </CardContent>
        </Card>
        {isError && (
          <Alert
            severity="error"
            sx={{ width: "50%" }}
            closeText={"Fechar mensagem"}
            onClose={() => setIsError(false)}
          >
            <AlertTitle>Falha no login</AlertTitle>
            {errorMessage}
          </Alert>
        )}
        {/* </div> */}

        <div
          style={{
            position: "absolute",
            bottom: "10px",
            textAlign: "center",
          }}
        >
          <Box
            mt={7}
            mb={1.5}
            component={"img"}
            src={logo_branca}
            alt={"Ivitech Tecnologia"}
          />
          <p
            style={{
              color: "#fff",
              fontFamily: "Poppins",
              marginTop: "-0.15rem",
              fontSize: 13,
            }}
          >
            Adequada à Lei Geral de Proteção de Dados.
          </p>
        </div>
      </Box>
    </>
  );
};

export default LoginPage;
