import { useState } from "react";
import {
  Card,
  CardContent,
  CardActionArea,
  Typography,
  Grid,
  Stack,
  IconButton,
} from "@mui/material";

import ShoppingBasketOutlinedIcon from "@mui/icons-material/ShoppingBasketOutlined";
import PercentIcon from "@mui/icons-material/Percent";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import DragHandleOutlinedIcon from "@mui/icons-material/DragHandleOutlined";

import { useAppSelector } from "../../../store/hooks";
import formatNumberToCurrency from "../../../util/formaters/formatNumberToCurrency";

import OrderDiscountModal from "../../../components/modals/order/OrderDiscountModal";
import OrderIncreaseModal from "../../modals/order/OrderIncreaseModal";

import Colors from "../../../constants/Colors";

const OrderCheckoutCard = () => {
  const order = useAppSelector((state) => state.order.order);
  const [openDiscountModal, setOpenDiscountModal] = useState(false);
  const [openIncreaseModal, setOpenIncreaseModal] = useState(false);
  const isReadOnly = useAppSelector(
    (state) => state.readability.isOrderReadOnly
  );

  return (
    <>
      <OrderDiscountModal
        openModal={openDiscountModal}
        toggle={() => setOpenDiscountModal(!openDiscountModal)}
      />
      <OrderIncreaseModal
        openModal={openIncreaseModal}
        toggle={() => setOpenIncreaseModal(!openIncreaseModal)}
      />
      <Grid item xs={12} md={12}>
        <Card>
          <CardContent>
            <Grid container spacing={1} alignItems={"center"}>
              <Grid item xs={12} md={12}>
                <Stack
                  direction={"row"}
                  spacing={1}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <IconButton
                      disabled
                      sx={{
                        "&:disabled": {
                          backgroundColor: Colors.iconButtonProductsBackground,
                          color: Colors.iconButtonProducts,
                        },
                      }}
                    >
                      <ShoppingBasketOutlinedIcon />
                    </IconButton>
                    <Typography variant="body2">Produtos</Typography>
                  </Stack>
                  <Stack>
                    <Typography variant="body2">
                      {formatNumberToCurrency(order.totais.produtos)}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={12} md={12}>
                <Stack
                  direction={"row"}
                  spacing={1}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <IconButton
                      disabled={isReadOnly}
                      onClick={() => setOpenDiscountModal(true)}
                      sx={{
                        backgroundColor: Colors.iconButtonDiscountsBackground,
                        color: Colors.iconButtonDiscounts,
                        "&:disabled": {
                          backgroundColor: Colors.iconButtonDiscountsBackground,
                          color: Colors.iconButtonDiscounts,
                        },
                      }}
                    >
                      <RemoveOutlinedIcon />
                    </IconButton>
                    <Typography variant="body2">Descontos</Typography>
                  </Stack>
                  <Stack>
                    {isReadOnly ? (
                      <Typography variant="body2">
                        {formatNumberToCurrency(
                          order.totais.descontos.total
                            ? order.totais.descontos.total
                            : 0
                        )}
                      </Typography>
                    ) : (
                      <CardActionArea
                        onClick={() => setOpenDiscountModal(true)}
                      >
                        <Typography variant="body2">
                          {formatNumberToCurrency(
                            order.totais.descontos.total
                              ? order.totais.descontos.total
                              : 0
                          )}
                        </Typography>
                      </CardActionArea>
                    )}
                  </Stack>
                </Stack>
              </Grid>
              {order.totais.totalIpi ? (
                <Grid item xs={12} md={12}>
                  <Stack
                    direction={"row"}
                    spacing={1}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                      <IconButton
                        disabled
                        sx={{
                          "&:disabled": {
                            backgroundColor: "#FFF6DD",
                            color: "#FFDC76",
                          },
                        }}
                      >
                        <PercentIcon />
                      </IconButton>
                      <Typography variant="body2">Total IPI</Typography>
                    </Stack>
                    <Stack>
                      <Typography variant="body2">
                        {formatNumberToCurrency(order.totais.totalIpi)}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              ) : (
                ""
              )}

              <Grid item xs={12} md={12}>
                <Stack
                  direction={"row"}
                  spacing={1}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <IconButton
                      disabled
                      sx={{
                        "&:disabled": {
                          backgroundColor: Colors.iconButtonTotalBackground,
                          color: Colors.iconButtonTotal,
                        },
                      }}
                    >
                      <DragHandleOutlinedIcon />
                    </IconButton>
                    <Typography variant="body2">Total</Typography>
                  </Stack>
                  <Stack>
                    <Typography variant="body2">
                      {formatNumberToCurrency(
                        order.totais.total ? order.totais.total : 0
                      )}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default OrderCheckoutCard;
