import { Link } from "react-router-dom";
import { Tooltip, Stack, IconButton } from "@mui/material";
import { VisibilityOutlined, Edit } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { StyleSheet, css } from "aphrodite";

import { useAppSelector } from "../../store/hooks";
import OrderSummary from "../../models/order/orderSummary.model";
import formatNumberToCurrencyNoSymbol from "../../util/formaters/formatNumberToCurrencyNoSymbol";

import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "../../styles/style.css";

interface Props {
  orders: OrderSummary[];
}

const OrdersListingTable = (props: Props) => {
  const { orders } = props;
  const configTenant = useAppSelector((state) => state.tenantConfiguration);

  let qtde = orders.length;

  const canEditOrder = (order: OrderSummary): boolean => {
    if (!order.situacaoPedido.id && order.transmissao.data) {
      return false;
    } else {
      return order.situacaoPedido.permiteEdicao;
    }
  };

  if (orders.length === 0) {
    return null;
  }

  console.log(orders);

  return (
    <Table className={css(styles.table)}>
      <Thead className="table_head">
        <Tr>
          <Th>Pedido IVIVenda</Th>
          <Th>Pedido {configTenant.nomeSistemaIntegrado}</Th>
          <Th>Cliente</Th>
          <Th>Emissão</Th>
          <Th>Previsão</Th>
          <Th>Total</Th>
          <Th>Status</Th>
          <Th>IPI</Th>
          <Th>Ação</Th>
        </Tr>
      </Thead>
      <Tbody>
        {orders.map((order) => {
          var parity = qtde % 2;
          qtde = qtde - 1;
          return (
            <Tr
              key={order.id}
              className={parity === 1 ? "odd_row" : "even_row"}
            >
              <Td className="table_number_cell">{order.id}</Td>
              <Td className="table_number_cell">
                {order.pedidoErp ? order.pedidoErp : " "}
              </Td>
              <Td className="table_text">{order.cliente.nome}</Td>
              <Td className="table_actions">{order.emissao.data}</Td>
              <Td className="table_actions">{order.previsao.data}</Td>
              <Td className="table_number_cell">
                {formatNumberToCurrencyNoSymbol(order.totalPedido)}
              </Td>
              <Td>
                <Stack
                  direction={"row"}
                  width={"100%"}
                  height={"100%"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  pl={0.5}
                  pr={0.5}
                >
                  <Stack>
                    <Tooltip
                      arrow
                      title={
                        order.situacaoPedido.nome
                          ? order.situacaoPedido.nome
                          : "Não recepcionado"
                      }
                    >
                      <div>
                        <IconButton
                          disabled
                          sx={{
                            "&:disabled": {
                              fontSize: 10,
                              backgroundColor: order.situacaoPedido.cor
                                ? order.situacaoPedido.cor
                                : "orange",
                              width: "40%",
                              height: "40%",
                            },
                          }}
                        />
                      </div>
                    </Tooltip>
                  </Stack>
                  {order.fasePedido.nome && (
                    <Stack>
                      <Tooltip arrow title={order.fasePedido.nome}>
                        <div>
                          <IconButton
                            disabled
                            sx={{
                              "&:disabled": {
                                fontSize: 10,
                                backgroundColor: order.fasePedido.cor
                                  ? order.fasePedido.cor
                                  : "black",
                                width: "40%",
                                height: "40%",
                              },
                            }}
                          />
                        </div>
                      </Tooltip>
                    </Stack>
                  )}
                </Stack>
              </Td>
              <Td className="table_actions">
                {order.totalIpi ? (
                  <CheckIcon color="success" fontSize="inherit" />
                ) : (
                  "-"
                )}
              </Td>
              <Td className="table_actions">
                <Stack
                  direction={"row"}
                  height={"100%"}
                  width={"100%"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  pl={0.5}
                  pr={0.5}
                >
                  <Stack>
                    <Tooltip arrow title="Ir para detalhes">
                      <Link
                        style={{
                          textDecoration: "none",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        to={`/vendas/detalhes`}
                        state={{
                          id: order.id,
                          readOnly: true,
                        }}
                      >
                        <VisibilityOutlined color="action" fontSize="inherit" />
                      </Link>
                    </Tooltip>
                  </Stack>
                  {canEditOrder(order) && (
                    <Stack>
                      <Tooltip arrow title={"Editar pedido"}>
                        <Link
                          style={{
                            textDecoration: "none",
                            display: "flex",
                            alignItems: "center",
                          }}
                          to={`/vendas/detalhes`}
                          state={{
                            id: order.id,
                            readOnly: false,
                          }}
                        >
                          <Edit htmlColor="#ff6600" fontSize="inherit" />
                        </Link>
                      </Tooltip>
                    </Stack>
                  )}
                </Stack>
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};

const styles = StyleSheet.create({
  table: {
    fontSize: 13,
    display: "table",
  },
  editButton: {
    color: "#ff6600",
  },
  numberCell: {
    justifyContent: "flex-end",
  },
});

export default OrdersListingTable;
