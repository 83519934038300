import { createApi } from '@reduxjs/toolkit/query/react';
// import env from '../env';

import OrderSummary from '../models/order/orderSummary.model';
import OrderSearch from '../models/requisitions/PaginatedOrderSearchRequisition.model';
import Order from '../models/order/order.model';
import CustomGetOrder from '../models/order/customGetOrder.model';

import dynamicBaseQuery from './dynamicBaseQuery';

interface GetOrders {
  pedidos: OrderSummary[];
  totalPages: number;
}

export const ordersApi = createApi({
  reducerPath: 'ordersApi',
  tagTypes: ['Orders'],
  refetchOnMountOrArgChange: true,
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    getOrders: builder.query<Order[], void>({
      query: () => '/pedido',
      providesTags: ['Orders'],
    }),
    getOrdersWithParams: builder.query<GetOrders, OrderSearch>({
      query: (params: OrderSearch) => {
        if (params.cliente.includes('/')) {
          params.cliente.replace('/', '%2f');
        }
        // console.log(params);
        return {
          url: `/pedido`,
          params: params,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
    }),
    getOrder: builder.query<Order, string>({
      query: (id: string) => ({
        url: `/pedido/${id}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }),
      providesTags: ['Orders'],
    }),
    getCustomOrder: builder.query<CustomGetOrder, string>({
      query: (id: string) => ({
        url: `/pedido/custom/${id}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }),
      providesTags: ['Orders'],
    }),
    addOrder: builder.mutation<void, Order>({
      query: (order: Order) => ({
        url: '/pedido',
        method: 'POST',
        body: order,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }),
      invalidatesTags: ['Orders'],
    }),
  }),
});

export const {
  useAddOrderMutation,
  useGetOrderQuery,
  useGetOrdersQuery,
  useGetOrdersWithParamsQuery,
  useGetCustomOrderQuery,
} = ordersApi;
