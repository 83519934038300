import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  Typography,
  CardContent,
  Grid,
  Stack,
  Tooltip,
} from '@mui/material';
import { Edit, VisibilityOutlined } from '@mui/icons-material';

import { useAppSelector } from '../../../store/hooks';

import Colors from '../../../constants/Colors';
import { Client } from '../../../models/client/client.model';

interface Props {
  client: Client;
}

const ClientCard = (props: Props): JSX.Element => {
  const {
    id,
    apelido,
    categoria,
    nome,
    tipoPessoaId: pessoa,
    cnpjCpf,
    rg,
    origem,
    pedidosEmitidos,
  } = props.client;
  const configTenant = useAppSelector((state) => state.tenantConfiguration);
  const [isClientEditable, setIsClientEditable] = useState(false);

  useEffect(() => {
    if (!!props.client) {
      const result = canEditClient();
      setIsClientEditable(result);
    }
  }, [props.client, configTenant]);

  const canEditClient = (): boolean => {
    if (!!configTenant.cadastroCliente.permiteEdicao) {
      return true;
    }

    const alreadyOrdered = pedidosEmitidos > 0;

    if (
      !configTenant.cadastroCliente.permiteEdicao &&
      !alreadyOrdered &&
      origem !== 'MTZ'
    ) {
      return true;
    }
    return false;
  };

  return (
    <Card
      sx={{
        height: '100%',
        marginTop: '2%',
        boxShadow: 3,
        mb: -2,
      }}
    >
      <CardContent>
        <Typography
          variant='body2'
          fontWeight={'bold'}
          color={Colors.primary}
          fontSize={16}
        >
          {apelido}
        </Typography>
        <Typography variant='body2'>{nome}</Typography>
        <Typography variant='body2'>{categoria}</Typography>
        {pessoa === 'J' ? (
          <Typography variant='body2' color={'gray'}>
            {cnpjCpf}
          </Typography>
        ) : (
          <Grid container>
            <Grid item xs={6} md={6}>
              <Typography variant='body2' color={'gray'}>
                {cnpjCpf}
              </Typography>
            </Grid>
            <Grid item xs={6} md={6} textAlign={'right'}>
              <Typography variant='body2' color={'gray'}>
                {rg ? rg : ''}
              </Typography>
            </Grid>
          </Grid>
        )}
        <Stack mt={1} direction={'row'} justifyContent={'right'}>
          <Stack
            direction={'row'}
            width={'15%'}
            justifyContent={
              !configTenant.cadastroCliente.permiteEdicao
                ? 'center'
                : 'space-between'
            }
          >
            <Stack>
              <Tooltip arrow title='Ir para detalhes'>
                <Link
                  style={{
                    textDecoration: 'none',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  to={`/clientes/detalhes`}
                  state={{
                    id: encodeURIComponent(id),
                    readOnly: true,
                  }}
                >
                  <VisibilityOutlined color='action' fontSize='inherit' />
                </Link>
              </Tooltip>
            </Stack>
            {isClientEditable && (
              <Stack>
                <Tooltip arrow title={'Editar cliente'}>
                  <Link
                    style={{
                      textDecoration: 'none',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    to={`/clientes/detalhes`}
                    state={{
                      id: encodeURIComponent(id),
                      readOnly: false,
                    }}
                  >
                    <Edit htmlColor='#ff6600' fontSize='inherit' />
                  </Link>
                </Tooltip>
              </Stack>
            )}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default ClientCard;
