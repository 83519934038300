import { useState, useEffect } from 'react';
import {
  Modal,
  Card,
  CardContent,
  TextField,
  Typography,
  Stack,
  Grid,
  Button,
} from '@mui/material';

import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import { orderActions } from '../../../store/order/order-slice';
import { hasBrokenTheLimit } from '../../../util/calculations/utilCalculations';
import formatNumberToCurrency from '../../../util/formaters/formatNumberToCurrency';
import formatDiscountValueString from '../../../util/formaters/formatDiscountValueString';

import Colors from '../../../constants/Colors';

interface Props {
  openModal: boolean;
  toggle: () => void;
}

const OrderDiscountModal = (props: Props) => {
  const order = useAppSelector((state) => state.order.order);
  const table = useAppSelector((state) => state.order.selectedPriceTable);

  const [percentageOrValue, setPercentageOrValue] = useState<
    'PERCENTAGE' | 'VALUE'
  >('VALUE');
  const [percentage, setPercentage] = useState(0);
  const [percentageString, setPercentageString] = useState('');
  const [value, setValue] = useState(0);
  const [valueString, setValueString] = useState('');
  const [helperText, setHelperText] = useState(<></>);
  const [validityErrors, setValidityErrors] = useState({
    discountPercentageError: false,
    discountValueError: false,
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (percentageOrValue === 'PERCENTAGE') {
      setValue(0);
      setValueString('');
    } else {
      setPercentage(0);
      setPercentageString('');
    }
    let errors = { ...validityErrors };
    errors.discountPercentageError = false;
    errors.discountValueError = false;
    setValidityErrors({ ...errors });
    setHelperText(<></>);
  }, [
    percentageOrValue,
    setValue,
    setValueString,
    setPercentage,
    setPercentageString,
  ]);

  const handleDiscountChange = (
    ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: 'PERCENTAGE' | 'VALUE'
  ) => {
    if (ev.target.value === '' || ev.target.value === '0') {
      if (type === 'PERCENTAGE') {
        if (validityErrors.discountPercentageError) {
          let errors = { ...validityErrors };
          errors.discountPercentageError = false;
          setValidityErrors({ ...errors });
        }
        setPercentage(0);
        setPercentageString(ev.target.value);
        setHelperText(<></>);
      } else {
        if (validityErrors.discountValueError) {
          let errors = { ...validityErrors };
          errors.discountValueError = false;
          setValidityErrors({ ...errors });
        }
        setValue(0);
        setValueString(ev.target.value);
        setHelperText(<></>);
      }
      return;
    }

    let floatValue = 0;
    let validForParsing = ev.target.value.match(/^(\d+)(?:(\.|,)\d{1,2})?$/);

    if (validForParsing) {
      if (ev.target.value.includes(',')) {
        let newValue = ev.target.value.replace(',', '.');
        floatValue = parseFloat(newValue);
      } else {
        floatValue = parseFloat(ev.target.value);
      }
      if (type === 'PERCENTAGE') {
        const { hasBroken, maximumDiscount, triedDiscount } = hasBrokenTheLimit(
          'PERCENTAGE',
          table.descontoMaximoPerc,
          floatValue,
          order.totais.descontos.itens.total || 0,
          order.totais.produtos
        );
        if (hasBroken) {
          let errors = { ...validityErrors };
          errors.discountPercentageError = true;
          setPercentageString(ev.target.value);
          setValidityErrors({ ...errors });
          setHelperText(
            <>
              O desconto total excede o desconto máximo da tabela.
              <br />
              Desconto máximo: {maximumDiscount.toLocaleString('pt-Br')}% <br />
              Desconto dado: {triedDiscount.toLocaleString('pt-Br')}%
            </>
          );
        } else {
          if (validityErrors.discountPercentageError) {
            let errors = { ...validityErrors };
            errors.discountPercentageError = false;
            setValidityErrors({ ...errors });
          }
          setHelperText(<></>);
          setPercentage(floatValue);
          setPercentageString(ev.target.value);
        }
      } else {
        const { hasBroken, maximumDiscount, triedDiscount } = hasBrokenTheLimit(
          'VALUE',
          table.descontoMaximoPerc,
          floatValue,
          order.totais.descontos.itens.total || 0,
          order.totais.produtos
        );
        if (hasBroken) {
          let errors = { ...validityErrors };
          errors.discountValueError = true;
          setValueString(ev.target.value);
          setValidityErrors({ ...errors });
          setHelperText(
            <>
              O desconto total excede o desconto máximo da tabela.
              <br />
              Desconto máximo: {maximumDiscount.toLocaleString('pt-Br')}% <br />
              Desconto dado: {triedDiscount.toLocaleString('pt-Br')}%
            </>
          );
        } else {
          if (validityErrors.discountValueError) {
            let errors = { ...validityErrors };
            errors.discountValueError = false;
            setValidityErrors({ ...errors });
          }
          setHelperText(<></>);
          setValue(floatValue);
          setValueString(ev.target.value);
        }
      }
    } else {
      if (type === 'PERCENTAGE') {
        let errors = { ...validityErrors };
        errors.discountPercentageError = true;
        setPercentageString(ev.target.value);
        setValidityErrors({ ...errors });
        setHelperText(<>O desconto informado é inválido</>);
      } else {
        let errors = { ...validityErrors };
        errors.discountValueError = true;
        setValueString(ev.target.value);
        setValidityErrors({ ...errors });
        setHelperText(<>O desconto informado é inválido</>);
      }
    }
  };

  const resetFields = () => {
    let errors = { ...validityErrors };
    errors.discountPercentageError = false;
    errors.discountValueError = false;
    setValidityErrors({ ...errors });
    setPercentageOrValue('VALUE');
    setPercentage(0);
    setPercentageString('');
    setValue(0);
    setValueString('');
    setHelperText(<></>);
  };

  const critiques = () => {
    const hasErrors =
      validityErrors.discountPercentageError ||
      validityErrors.discountValueError;

    if (hasErrors) {
      return true;
    }

    return false;
  };

  const applyDiscount = () => {
    if (critiques()) {
      return;
    }
    if (value) {
      dispatch(
        orderActions.setOrderGlobalDiscount({ value, type: percentageOrValue })
      );
    }
    if (percentage) {
      dispatch(
        orderActions.setOrderGlobalDiscount({
          value: percentage,
          type: percentageOrValue,
        })
      );
    }

    if (!value && !percentage) {
      dispatch(
        orderActions.setOrderGlobalDiscount({
          value: percentage,
          type: percentageOrValue,
        })
      );
    }

    props.toggle();
  };

  return (
    <Modal
      open={props.openModal}
      onClose={() => {
        resetFields();
        props.toggle();
      }}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Card sx={{ width: '30%', p: 2 }}>
        <CardContent>
          <Typography variant='body1'>
            Desconto sobre o valor total dos produtos
          </Typography>
          <Typography variant='body2'>
            O desconto informado será somado aos descontos já concedidos aos
            produtos
          </Typography>
          <Stack
            spacing={1}
            borderTop={1}
            borderBottom={1}
            mt={1}
            mb={4}
            borderColor={'gray'}
            pt={1}
            pb={1}
          >
            <Typography variant='body1' textAlign={'center'}>
              Outros descontos
            </Typography>
            <Stack justifyContent={'space-between'} direction={'row'}>
              <Typography variant={'body2'}>Itens</Typography>
              <Typography variant={'body2'}>
                {formatNumberToCurrency(
                  order.totais.descontos.itens.total
                    ? order.totais.descontos.itens.total
                    : 0
                )}
              </Typography>
            </Stack>
            <Stack justifyContent={'space-between'} direction={'row'}>
              <Typography variant={'body2'} fontWeight={'Bold'}>
                Total
              </Typography>
              <Typography variant={'body2'} fontWeight={'Bold'}>
                {formatNumberToCurrency(
                  order.totais.descontos.total
                    ? order.totais.descontos.total
                    : 0
                )}
              </Typography>
            </Stack>
          </Stack>
          <Grid
            container
            mb={2}
            mt={1}
            border={1}
            borderRadius={2}
            borderColor={'gray'}
            overflow={'hidden'}
            alignItems={'center'}
          >
            <Grid item xs={6} md={6}>
              <Button
                onClick={() => setPercentageOrValue('PERCENTAGE')}
                fullWidth
                sx={{
                  backgroundColor:
                    percentageOrValue === 'PERCENTAGE' ? Colors.primary : '',
                  color: percentageOrValue === 'PERCENTAGE' ? 'white' : 'black',
                  '&:hover': {
                    backgroundColor:
                      percentageOrValue === 'PERCENTAGE' ? Colors.primary : '',
                    color:
                      percentageOrValue === 'PERCENTAGE' ? 'white' : 'black',
                  },
                  height: '100%',
                }}
              >
                Em Percentual
              </Button>
            </Grid>
            <Grid item xs={6} md={6}>
              <Button
                onClick={() => setPercentageOrValue('VALUE')}
                fullWidth
                style={{ height: '100%' }}
                sx={{
                  backgroundColor:
                    percentageOrValue === 'VALUE' ? Colors.primary : '',
                  color: percentageOrValue === 'VALUE' ? 'white' : 'black',
                  '&:hover': {
                    backgroundColor:
                      percentageOrValue === 'VALUE' ? Colors.primary : '',
                    color: percentageOrValue === 'VALUE' ? 'white' : 'black',
                  },
                }}
              >
                Em dinheiro
              </Button>
            </Grid>
          </Grid>
          {percentageOrValue === 'PERCENTAGE' && (
            <TextField
              fullWidth
              error={validityErrors.discountPercentageError}
              size='small'
              label={'%'}
              value={percentageString}
              onChange={(ev) => handleDiscountChange(ev, percentageOrValue)}
              helperText={
                validityErrors.discountPercentageError ? helperText : ''
              }
            />
          )}
          {percentageOrValue === 'VALUE' && (
            <TextField
              fullWidth
              error={validityErrors.discountValueError}
              size='small'
              label={'R$'}
              value={valueString}
              onBlur={() =>
                setValueString(formatDiscountValueString(valueString))
              }
              onChange={(ev) => handleDiscountChange(ev, percentageOrValue)}
              helperText={validityErrors.discountValueError ? helperText : ''}
            />
          )}
          <Stack mt={6} justifyContent={'space-between'} direction={'row'}>
            <Button
              variant='outlined'
              color='success'
              onClick={() => applyDiscount()}
            >
              Confirmar
            </Button>
            <Button
              variant='outlined'
              color='error'
              onClick={() => {
                resetFields();
                props.toggle();
              }}
            >
              Cancelar
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default OrderDiscountModal;
