import { createApi } from '@reduxjs/toolkit/query/react';

import TenantConfig from '../models/tenant/tentantConfig.model';
import dynamicBaseQuery from './dynamicBaseQuery';

export const tenantConfigApi = createApi({
  reducerPath: 'tenantConfigApi',
  tagTypes: ['TenantConfig'],
  // refetchOnMountOrArgChange: true,
  // refetchOnFocus: true,
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    getTenantConfig: builder.query<TenantConfig, void>({
      query: () => ({
        url: '/configuracao',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }),
      providesTags: ['TenantConfig'],
    }),
  }),
});

export const { useGetTenantConfigQuery } = tenantConfigApi;
