import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import {
  AppsOutlined,
  PersonOutlined,
  FormatListBulletedOutlined,
  LocalAtm,
} from '@mui/icons-material';

import { useAppSelector } from '../../store/hooks';

interface Props {
  currentTab: number;
  changeTab: (nextTab: number) => void;
}

const muiStyle = {
  color: '#6a6d71',
  '&:hover': { color: '#6a6d71', backgroundColor: '#dfeaf6' },
  '&.Mui-selected': {
    color: '#6a6d71',
    backgroundColor: '#e6eef6',
  },
};

const TopTabNavigation = (props: Props) => {
  const order = useAppSelector((state) => state.order.order);

  const handleTopTabValueChange = (
    ev: React.SyntheticEvent<Element, Event>,
    newValue: number
  ): void => {
    ev.preventDefault();
    props.changeTab(newValue);
  };

  return (
    <>
      <Paper
        elevation={3}
        sx={{
          width: '100%',
          position: 'sticky',
          mt: -3,
        }}
      >
        <BottomNavigation
          showLabels={false}
          value={props.currentTab}
          onChange={(ev, newValue) => handleTopTabValueChange(ev, newValue)}
        >
          <BottomNavigationAction
            label={'Cabeçalho'}
            icon={<FormatListBulletedOutlined />}
            sx={{
              color: '#6a6d71',
              '&:hover': { color: '#6a6d71', backgroundColor: '#dfeaf6' },
              '&.Mui-selected': {
                color: '#6a6d71',
                backgroundColor: '#e6eef6',
              },
              fontSize: 5,
            }}
          />
          <BottomNavigationAction
            disabled={!order.previsao.data || !order.tipoId}
            label={'Cliente'}
            icon={<PersonOutlined />}
            sx={muiStyle}
          />
          <BottomNavigationAction
            disabled={!order.clienteId}
            label={'Produtos'}
            icon={<AppsOutlined />}
            sx={muiStyle}
          />
          <BottomNavigationAction
            disabled={!order.tabelaPrecoId || order.itens.length === 0}
            label={'Pagamento'}
            icon={<LocalAtm />}
            sx={muiStyle}
          />
        </BottomNavigation>
      </Paper>
    </>
  );
};
export default TopTabNavigation;
