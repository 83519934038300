import { Card, CardContent, Typography, Grid } from '@mui/material';

import ProductBalance from '../../../models/product/productBalance.model';
import Colors from '../../../constants/Colors';
import { Apps } from '@mui/icons-material';

interface Props {
  saldoproduto: ProductBalance[];
}

const ProductStockCard = (props: Props): JSX.Element => {
  const { saldoproduto } = props;
  const totalBalance = saldoproduto.reduce((acc, curr) => {
    return acc + curr.quantidade;
  }, 0);
  return (
    <Card sx={{ boxShadow: 3 }}>
      <CardContent>
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            md={12}
            sx={{ backgroundColor: Colors.titleRowWithBackgroundColor }}
            alignItems={'center'}
          >
            <Typography variant='body2' color={Colors.titleWithBackgroudColor}>
              <Apps fontSize={'inherit'} /> Saldo
            </Typography>
          </Grid>
          {saldoproduto.map((local) => {
            return (
              <Grid container mt={1}>
                <Grid item xs={6} md={6}>
                  <Typography variant='body2'>{local.nome}</Typography>
                </Grid>
                <Grid item xs={6} md={6} textAlign={'end'}>
                  <Typography variant='body2'>
                    {local.quantidade.toFixed(3).replace('.', ',')}
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
          <Grid container borderTop={1} borderColor={'lightgray'} mt={1}>
            <Grid item xs={6} md={6}>
              <Typography variant='body2' fontWeight={'bold'}>
                TOTAL
              </Typography>
            </Grid>
            <Grid item xs={6} md={6} textAlign={'end'}>
              <Typography variant='body2' fontWeight={'bold'}>
                {totalBalance.toFixed(3).replace('.', ',')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ProductStockCard;
