import { useState, useEffect } from 'react';
import { LocalizationProvider, DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { format, isValid } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  TextField,
  Autocomplete,
  Tooltip,
} from '@mui/material';
import { Search } from '@mui/icons-material';

import { orderQueryParamsActions } from '../../../store/queries/order-query-params-slice';
import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import { useGetPhasesQuery } from '../../../api/orderPhaseApi';
import Colors from '../../../constants/Colors';
import { useGetSituationsQuery } from '../../../api/orderSituationApi';
import Spinner from '../../layout/Spinner';
import OrderSituation from '../../../models/order/orderSituation.model';
import OrderPhase from '../../../models/order/orderPhase.model';

interface Props {
  inicialEmissionError: boolean;
  finalEmissionError: boolean;
  inicialPrevisionError: boolean;
  finalPrevisionError: boolean;
  toggleInicialEmissionError: (errorValue: boolean) => void;
  toggleInicialPrevisionError: (errorValue: boolean) => void;
  toggleFinalEmissionError: (errorValue: boolean) => void;
  toggleFinalPrevisionError: (errorValue: boolean) => void;
}

const OrderQueryParamsCard = (props: Props) => {
  const {
    finalEmissionError,
    inicialEmissionError,
    finalPrevisionError,
    inicialPrevisionError,
    toggleFinalEmissionError,
    toggleInicialEmissionError,
    toggleFinalPrevisionError,
    toggleInicialPrevisionError,
  } = props;
  const [inicialEmissionDate, setInicialEmissionDate] = useState<Date | null>(
    new Date(new Date().setDate(new Date().getDate() - 7))
  );
  const [finalEmissionDate, setFinalEmissionDate] = useState<Date | null>(
    new Date()
  );
  const [inicialEmissionErrorMessage, setInicialEmissionErrorMessage] =
    useState('');
  const [finalEmissionErrorMessage, setFinalEmissionErrorMessage] =
    useState('');
  const [inicialPrevisionDate, setInicialPrevisionDate] = useState<Date | null>(
    null
  );
  const [finalPrevisionDate, setFinalPrevisionDate] = useState<Date | null>(
    null
  );
  const [inicialPrevisionErrorMessage, setInicialPrevisionErrorMessage] =
    useState('');
  const [finalPrevisionErrorMessage, setFinalPrevisionErrorMessage] =
    useState('');
  const dispatch = useAppDispatch();
  const {
    cliente,
    emissaoFinal,
    emissaoInicial,
    pedidoId,
    previsaoFinal,
    previsaoInicial,
  } = useAppSelector((state) => state.orderQueryParams);

  const {
    isError: isPhaseError,
    isFetching: isPhaseFetching,
    isLoading: isPhaseLoading,
    data: phaseData,
  } = useGetPhasesQuery();

  const {
    isError: isSituationError,
    isFetching: isSituationFetching,
    isLoading: isSituationLoading,
    data: situationData,
  } = useGetSituationsQuery();

  const [selectedSituations, setSelectedSituations] = useState<
    OrderSituation[]
  >([]);

  const [selectedPhases, setSelectedPhases] = useState<OrderPhase[]>([]);

  const handleInicialEmissionDateChange = (date: Date | null): void => {
    let formattedDate = '';
    if (!date || !isValid(date) || date > new Date()) {
      if (!inicialEmissionError) toggleInicialEmissionError(true);
      setInicialEmissionErrorMessage('A data informada é inválida');
      return;
    } else if (finalEmissionDate && finalEmissionDate < date) {
      if (!inicialEmissionError) toggleInicialEmissionError(true);
      setInicialEmissionErrorMessage(
        'A data inicial deve ser menor que a data final'
      );
      return;
    } else {
      if (inicialEmissionError) toggleInicialEmissionError(false);
      formattedDate = format(date, 'dd/MM/yyyy');
      setInicialEmissionDate(date);
      setInicialEmissionErrorMessage('');
      dispatch(orderQueryParamsActions.changeInicialEmission(formattedDate));
    }
  };
  const handleInicialPrevisionDateChange = (date: Date | null): void => {
    let formattedDate = '';
    if (!date || !isValid(date)) {
      if (!inicialPrevisionError) toggleInicialPrevisionError(true);
      setInicialPrevisionErrorMessage('A data informada é inválida');
      return;
    } else if (finalPrevisionDate && finalPrevisionDate < date) {
      if (!inicialPrevisionError) toggleInicialPrevisionError(true);
      setInicialPrevisionErrorMessage(
        'A data inicial deve ser menor que a data final'
      );
      return;
    } else {
      if (inicialPrevisionError) toggleInicialPrevisionError(false);
      formattedDate = format(date, 'dd/MM/yyyy');
      setInicialPrevisionDate(date);
      setInicialPrevisionErrorMessage('');
      dispatch(orderQueryParamsActions.changeInicialPrevision(formattedDate));
    }
  };
  const handleFinalEmissionDateChange = (date: Date | null): void => {
    let formattedDate = '';
    if (!date || !isValid(date) || date > new Date()) {
      if (!finalEmissionError) toggleFinalEmissionError(true);
      setFinalEmissionErrorMessage('A data informada é inválida');
      return;
    } else if (inicialEmissionDate && date < inicialEmissionDate) {
      if (!finalEmissionError) toggleFinalEmissionError(true);
      setFinalEmissionErrorMessage(
        'A data inicial deve ser menor que a data final'
      );
      return;
    } else {
      if (finalEmissionError) toggleFinalEmissionError(false);
      formattedDate = format(date, 'dd/MM/yyyy');
      setFinalEmissionDate(date);
      setFinalEmissionErrorMessage('');
      dispatch(orderQueryParamsActions.changeFinalEmission(formattedDate));
    }
  };
  const handleFinalPrevisionDateChange = (date: Date | null): void => {
    let formattedDate = '';
    if (!date || !isValid(date)) {
      if (!finalPrevisionError) toggleFinalPrevisionError(true);
      setFinalPrevisionErrorMessage('A data informada é inválida');
      return;
    } else if (inicialPrevisionDate && date < inicialPrevisionDate) {
      if (!finalPrevisionError) toggleFinalPrevisionError(true);
      setFinalPrevisionErrorMessage(
        'A data inicial deve ser menor que a data final'
      );
      return;
    } else {
      if (finalPrevisionError) toggleFinalPrevisionError(false);
      formattedDate = format(date, 'dd/MM/yyyy');
      setFinalPrevisionDate(date);
      setFinalPrevisionErrorMessage('');
      dispatch(orderQueryParamsActions.changeFinalPrevision(formattedDate));
    }
  };

  useEffect(() => {
    if (!emissaoInicial && inicialEmissionDate) {
      let formattedDate = format(inicialEmissionDate, 'dd/MM/yyyy');
      dispatch(orderQueryParamsActions.changeInicialEmission(formattedDate));
    }
    if (!previsaoInicial && inicialPrevisionDate) {
      let formattedDate = format(inicialPrevisionDate, 'dd/MM/yyyy');
      dispatch(orderQueryParamsActions.changeInicialPrevision(formattedDate));
    }
    if (!emissaoFinal && finalEmissionDate) {
      let formattedDate = format(finalEmissionDate, 'dd/MM/yyyy');
      dispatch(orderQueryParamsActions.changeFinalEmission(formattedDate));
    }
    if (!previsaoFinal && finalPrevisionDate) {
      let formattedDate = format(finalPrevisionDate, 'dd/MM/yyyy');
      dispatch(orderQueryParamsActions.changeFinalPrevision(formattedDate));
    }
  }, [emissaoFinal, emissaoInicial, previsaoInicial, previsaoFinal, dispatch]);

  const handleSituationSelection = (
    ev: React.SyntheticEvent<Element, Event>,
    values: OrderSituation[]
  ) => {
    const newValues = values.map((value) => value.id);

    dispatch(orderQueryParamsActions.changeSituation(newValues));
    setSelectedSituations([...values]);
  };
  const handlePhaseSelection = (
    ev: React.SyntheticEvent<Element, Event>,
    values: OrderPhase[]
  ) => {
    const newValues = values.map((value) => value.id);
    //console.log(newValues);
    dispatch(orderQueryParamsActions.changePhase(newValues));
    setSelectedPhases([...values]);
  };

  const handleOrderNumberChange = (
    ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(orderQueryParamsActions.changeNumber(ev.target.value));
  };

  if (
    isPhaseFetching ||
    isPhaseLoading ||
    isSituationFetching ||
    isSituationLoading
  ) {
    return <Spinner />;
  }

  return (
    <Grid item xs={12} md={12}>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography
                sx={{
                  backgroundColor: Colors.titleRowWithBackgroundColor,
                  pl: 1,
                  pt: 0.75,
                  mt: -1,
                }}
                variant='body2'
                color={Colors.titleWithBackgroudColor}
              >
                <Search fontSize={'inherit'} /> Filtros
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Tooltip
                arrow
                title={`Caso deseje informar mais de um número de pedido,
                digite-os separando por vírgula
              `}
              >
                <TextField
                  id={'pedido-numero'}
                  fullWidth
                  label={'Número'}
                  value={pedidoId}
                  size={'small'}
                  onChange={handleOrderNumberChange}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={6} md={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                <DatePicker
                  label={'Emissão Inicial'}
                  maxDate={new Date()}
                  value={inicialEmissionDate}
                  onChange={(value) => handleInicialEmissionDateChange(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      helperText={inicialEmissionErrorMessage}
                      error={inicialEmissionError}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: 'dd/mm/aaaa',
                      }}
                    />
                  )}
                  InputProps={{
                    size: 'small',
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6} md={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                <DatePicker
                  label={'Emissão Final'}
                  maxDate={new Date()}
                  value={finalEmissionDate}
                  onChange={(value) => handleFinalEmissionDateChange(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      helperText={finalEmissionErrorMessage}
                      error={finalEmissionError}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: 'dd/mm/aaaa',
                      }}
                    />
                  )}
                  InputProps={{
                    size: 'small',
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6} md={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                <DatePicker
                  label={'Previsão Inicial'}
                  value={inicialPrevisionDate}
                  onChange={(value) => handleInicialPrevisionDateChange(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      helperText={inicialPrevisionErrorMessage}
                      error={inicialPrevisionError}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: 'dd/mm/aaaa',
                      }}
                    />
                  )}
                  InputProps={{
                    size: 'small',
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6} md={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                <DatePicker
                  label={'Previsão Final'}
                  value={finalPrevisionDate}
                  onChange={(value) => handleFinalPrevisionDateChange(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      helperText={finalPrevisionErrorMessage}
                      error={finalPrevisionError}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: 'dd/mm/aaaa',
                      }}
                    />
                  )}
                  InputProps={{
                    size: 'small',
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                label={'Cliente'}
                value={cliente}
                size={'small'}
                onChange={(ev) =>
                  dispatch(
                    orderQueryParamsActions.changeClientId(ev.target.value)
                  )
                }
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Autocomplete
                size='small'
                multiple
                id='situacoes'
                options={situationData ? situationData : []}
                getOptionLabel={(option) => option.nome}
                filterSelectedOptions
                value={selectedSituations}
                onChange={(ev, value) => handleSituationSelection(ev, value)}
                noOptionsText={'Sem opções'}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label='Situação'
                    placeholder='Selecione'
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Autocomplete
                size='small'
                multiple
                id='fases'
                options={phaseData ? phaseData : []}
                getOptionLabel={(option) => option.nome}
                filterSelectedOptions
                value={selectedPhases}
                onChange={(ev, value) => handlePhaseSelection(ev, value)}
                noOptionsText={'Sem opções'}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label='Fase'
                    placeholder='Selecione'
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default OrderQueryParamsCard;
