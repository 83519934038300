import { Grid } from "@mui/material";

import QueryModalWrapper from "./QueryModalWrapper";
import { useGetBillingDetailsQuery } from "../../../api/queriesApi";
import BILLING_QUERY_DETAILS from "../../../util/initializers/BillingQueriesDetailInitializer";

import Spinner from "../../layout/Spinner";
import BillingsQueryDetailsHeaderCard from "../../cards/queries/BillingsQueryDetailsHeaderCard";
import BillingQueryResultDetailsTable from "../../tables/BillingQueryResultDetailsTable";

interface Props {
  openModal: boolean;
  invoiceId: string;
  toggle: () => void;
}

const BillingQueryDetailsModal = (props: Props) => {
  const { invoiceId, openModal, toggle } = props;
  const { isError, isFetching, isLoading, data } = useGetBillingDetailsQuery(
    invoiceId,
    { skip: !invoiceId }
  );
  console.log(data);

  if (isFetching || isLoading) {
    return (
      <QueryModalWrapper
        openModal={openModal}
        toggle={toggle}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Spinner />
      </QueryModalWrapper>
    );
  }

  if (isError) {
    return (
      <QueryModalWrapper
        openModal={openModal}
        toggle={toggle}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <h2>Erro ao carregar dados do pedido</h2>
      </QueryModalWrapper>
    );
  }

  return (
    <QueryModalWrapper
      openModal={openModal}
      toggle={toggle}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Grid container spacing={1}>
        <BillingsQueryDetailsHeaderCard
          billing={data ? data : BILLING_QUERY_DETAILS}
        />
        <Grid item xs={12} md={12}>
          <div style={{ display: "flex", height: "100%" }}>
            <BillingQueryResultDetailsTable
              billing={data ? data : BILLING_QUERY_DETAILS}
            />
          </div>
        </Grid>
      </Grid>
    </QueryModalWrapper>
  );
};

export default BillingQueryDetailsModal;
