import Order from "../../../models/order/order.model";
import OrderItem from "../../../models/order/orderItem.model";
import roundAbnt from "../../formaters/roundAbnt";

const itemUpdateCalculation = (order: Order, newItem: OrderItem) => {
  const itemIndex = order.itens.findIndex((old) => old.id === newItem.id);
  const oldItem = order.itens.find((old) => {
    return old.id === newItem.id;
  });

  order.totais.descontos.cabecalho.total = 0;
  order.totais.acrescimos.cabecalho.total = 0;

  order.itens[itemIndex] = { ...newItem };

  if (oldItem) {
    if (
      oldItem.totais.acrescimos.total &&
      order.totais.acrescimos.itens.total
    ) {
      order.totais.acrescimos.itens.total -= oldItem.totais.acrescimos.total;
    }

    if (oldItem.totais.descontos.total && order.totais.descontos.itens.total) {
      order.totais.descontos.itens.total -= oldItem.totais.descontos.total;
    }

    // console.log(`Old Order: ${order.totais.totalIpi}`);
    // console.log(`Old IPI: ${oldItem.valorIpi}`);

    order.totais.produtos -= oldItem.totais.produtos;
    order.totais.totalIpi -= oldItem.valorIpi * oldItem.quantidade;

    if (order.totais.acrescimos.itens.total) {
      if (newItem.totais.acrescimos.total) {
        order.totais.acrescimos.itens.total += newItem.totais.acrescimos.total;
      }
    } else {
      order.totais.acrescimos.itens.total = newItem.totais.acrescimos.total;
    }

    if (order.totais.descontos.itens.total) {
      if (newItem.totais.descontos.total) {
        order.totais.descontos.itens.total += newItem.totais.descontos.total;
      }
    } else {
      order.totais.descontos.itens.total = newItem.totais.descontos.total;
    }
    order.totais.acrescimos.total = order.totais.acrescimos.itens.total;
    order.totais.descontos.total = order.totais.descontos.itens.total;
    order.totais.produtos += newItem.totais.produtos;
    order.totais.totalIpi += newItem.valorIpi * newItem.quantidade;

    // console.log(`New Order: ${order.totais.totalIpi}`);
    // console.log(`New IPI: ${newItem.valorIpi}`);

    const newTotal = order.itens.reduce((acc, currValue) => {
      return acc + currValue.totais.total;
    }, 0);

    order.totais.total = newTotal;
    order.totais.total = roundAbnt(order.totais.total);
  }

  return order;
};

export default itemUpdateCalculation;
