import { useState, useEffect } from "react";

import { Stack } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { StyleSheet, css } from "aphrodite";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import formatNumberToCurrency from "../../util/formaters/formatNumberToCurrency";
import OrderItem from "../../models/order/orderItem.model";
import ProductPriceTable from "../../models/product/productPriceTable.model";

import OrderItemEditing from "../modals/order/OrderItemEditing";
import OrderItemRemovalModal from "../modals/order/OrderItemRemovalModal";

import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "../../styles/style.css";
import { orderActions } from "../../store/order/order-slice";
import ORDER_ITEM_INITIALIZER from "../../util/initializers/OrderItemInitializer";

interface Props {
  priceTable: ProductPriceTable;
}

const OrderItemsTable = (props: Props) => {
  const dispatch = useAppDispatch();
  const [itemToBeModified, setItemToBeModified] = useState<OrderItem>(
    ORDER_ITEM_INITIALIZER
  );
  const [itemToBeRemoved, setItemToBeRemoved] = useState<OrderItem>(
    ORDER_ITEM_INITIALIZER
  );
  const [itemReference, setItemReference] = useState("");
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [openEditingModal, setOpenEditingModal] = useState(false);
  const items = useAppSelector((state) => state.order.order.itens);
  const isReadOnly = useAppSelector(
    (state) => state.readability.isOrderReadOnly
  );
  const itemsDetail = useAppSelector((state) => state.order.itemsDetail);

  let qtde = items.length;

  const removeItemHandler = (
    ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    item: OrderItem
  ) => {
    ev.preventDefault();
    setItemToBeRemoved({ ...item });
    setItemReference(
      `${item.produtoId} ${item.grade.grade2} ${item.grade.grade3}`
    );
    setOpenRemoveModal(true);
  };

  const editItemHandler = (
    ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    item: OrderItem
  ) => {
    ev.preventDefault();
    dispatch(orderActions.setItemToBeModified(item.id));
    setItemToBeModified({ ...item });
    setOpenEditingModal(true);
  };

  const returnItemName = (id: string): string => {
    const filteredItem = itemsDetail.filter((item) => item.id === id);
    return filteredItem[0].nome;
  };

  const teste = useAppSelector((state) => state.order);

  // useEffect(() => {
  //   console.log(items);
  //   console.log(teste);
  // }, [items]);

  if (items.length === 0) {
    return null;
  }

  console.log(items);

  return (
    <>
      <OrderItemRemovalModal
        item={itemToBeRemoved}
        reference={itemReference}
        openModal={openRemoveModal}
        toggle={() => setOpenRemoveModal(!openRemoveModal)}
      />
      <OrderItemEditing
        item={itemToBeModified}
        openModal={openEditingModal}
        priceTable={props.priceTable}
        toggle={() => setOpenEditingModal(!openEditingModal)}
      />
      <Table className={css(styles.table)}>
        <Thead className="table_head">
          <Tr>
            <Th>Referência</Th>
            <Th>Descrição</Th>
            <Th>Quantidade</Th>
            {items[0].valorIpi !== undefined && items[0].valorIpi !== null ? (
              <Th>Valor IPI</Th>
            ) : (
              ""
            )}
            {items[0].valorIpi !== undefined && items[0].valorIpi !== null ? (
              <Th>Pr. + IPI</Th>
            ) : (
              ""
            )}
            <Th>Pr. Bruto</Th>
            <Th>Desconto</Th>
            {/* <Th>Acréscimo</Th> */}
            <Th>Pr. Final</Th>
            <Th>Subtotal</Th>
            {!isReadOnly && <Th>Ação</Th>}
          </Tr>
        </Thead>
        <Tbody>
          {items.map((item) => {
            // console.log(item);
            var parity = qtde % 2;
            qtde = qtde - 1;
            return (
              <Tr
                key={item.id}
                className={parity === 1 ? "odd_row" : "even_row"}
              >
                <Td className="table_text">{`${item.produtoId}${
                  item.grade.grade2 ? item.grade.grade2 : ""
                }${item.grade.grade3 ? item.grade.grade3 : ""}`}</Td>
                <Td className="table_text">{returnItemName(item.produtoId)}</Td>
                <Td className="table_number_cell">{item.quantidade}</Td>
                {item.valorIpi !== undefined && item.valorIpi !== null ? (
                  <Td className="table_number_cell">
                    {formatNumberToCurrency(item.valorIpi)}
                  </Td>
                ) : (
                  ""
                )}
                {item.valorIpi !== undefined && item.valorIpi !== null ? (
                  <Td className="table_number_cell">
                    {formatNumberToCurrency(item.unitarioFinal + item.valorIpi)}
                  </Td>
                ) : (
                  ""
                )}
                <Td className="table_number_cell">{`R$ ${item.unitario.toLocaleString(
                  "pt-Br",
                  {
                    minimumFractionDigits: 2,
                  }
                )}`}</Td>
                <Td className="table_number_cell">
                  {item.totais.descontos.item.percentual
                    ? `${item.totais.descontos.item.percentual.toLocaleString(
                        "pt-Br",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}%`
                    : formatNumberToCurrency(
                        item.totais.descontos.item.valor || 0
                      )}
                </Td>
                {/* <Td>
                  {item.totais.acrescimos.item.percentual
                    ? `${item.totais.acrescimos.item.percentual}%`
                    : formatNumberToCurrency(
                        item.totais.acrescimos.item.valor || 0
                      )}
                </Td> */}
                <Td className="table_number_cell">
                  {formatNumberToCurrency(item.unitarioFinal)}
                </Td>
                <Td className="table_number_cell">
                  {formatNumberToCurrency(item.totais.total)}
                </Td>
                {!isReadOnly && (
                  <Td>
                    <Stack direction={"row"} justifyContent={"space-evenly"}>
                      <a href="#" onClick={(ev) => editItemHandler(ev, item)}>
                        <Edit sx={{ color: "#ff6600" }} fontSize="inherit" />
                      </a>
                      <a href="#" onClick={(ev) => removeItemHandler(ev, item)}>
                        <Delete color="error" fontSize="inherit" />
                      </a>
                    </Stack>
                  </Td>
                )}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </>
  );
};

const styles = StyleSheet.create({
  table: {
    fontSize: 13,
  },
  editButton: {
    color: "#ff6600",
  },
  numberCell: {
    justifyContent: "flex-end",
  },
});

export default OrderItemsTable;
