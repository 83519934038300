import {
  Grid,
  Card,
  CardContent,
  Typography,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  MenuItem,
} from "@mui/material";
import { AccountBalanceWalletOutlined } from "@mui/icons-material";

import { useGetOrderTypesQuery } from "../../../api/orderTypeApi";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import Colors from "../../../constants/Colors";
import { orderActions } from "../../../store/order/order-slice";

const OrderTypeCard = () => {
  const { data } = useGetOrderTypesQuery();
  // const [tipoPedido, setTipoPedido] = useState<string>('');
  const orderType = useAppSelector((state) => state.order.order.tipoId);
  // const isReadOnly = !useAppSelector(
  //   (state) => state.order.orderSituation.permiteEdicao
  // );
  const isReadOnly = useAppSelector(
    (state) => state.readability.isOrderReadOnly
  );

  const dispatch = useAppDispatch();

  const handleChange = (ev: SelectChangeEvent<string>) => {
    ev.preventDefault();
    dispatch(orderActions.setOrderTipoId(ev.target.value));
  };

  return (
    <Grid item xs={12} md={3}>
      <Card sx={{ height: "100%" }}>
        <CardContent>
          <Grid
            container
            spacing={1}
            direction={"row"}
            justifyContent={"right"}
          >
            <Grid item xs={12} md={12}>
              <Typography
                sx={{
                  backgroundColor: Colors.titleRowWithBackgroundColor,
                  pl: 1,
                  pt: 0.75,
                  mt: -1,
                }}
                variant="body2"
                color={Colors.titleWithBackgroudColor}
              >
                <AccountBalanceWalletOutlined fontSize={"inherit"} /> Tipo do
                pedido
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth color="success">
                <InputLabel
                  sx={{ marginTop: -0.9 }}
                  id="tipopedido-select-label"
                  color="success"
                >
                  Tipo Pedido *
                </InputLabel>
                <Select
                  disabled={isReadOnly}
                  fullWidth
                  value={orderType}
                  label="Tipo Pedido *"
                  onChange={(ev) => handleChange(ev)}
                  size={"small"}
                  color="success"
                >
                  {data?.map((orderType) => {
                    return (
                      <MenuItem key={orderType.id} value={orderType.id}>
                        {orderType.nome}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default OrderTypeCard;
