import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import City from '../../models/address/city.model';

interface State {
  cities: City[] | null;
}

const initialState: State = {
  cities: null,
};

const citiesSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    setCities(state: State, action: PayloadAction<City[]>) {
      state.cities = [...action.payload];
    },
    resetCities(state: State) {
      state.cities = null;
    },
  },
});

export const citiesActions = citiesSlice.actions;

export default citiesSlice;
