import { useState } from 'react';
import { Card, Typography, CardContent, Grid, TextField } from '@mui/material';

import { Email } from '@mui/icons-material';

import isEmailValid from '../../../util/validators/validateEmail';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { clientActions } from '../../../store/client/client-slice';
import Colors from '../../../constants/Colors';

interface Props {
  dados: {
    emailPrincipal: string;
    emailComercial: string;
    emailFinanceiro: string;
  };
}

const INVALID_EMAIL = 'O email informado é invalido';

const EmailsCard = (props: Props) => {
  const { emailComercial, emailFinanceiro, emailPrincipal } = props.dados;
  const [principal, setPrincipal] = useState<string>(emailPrincipal);
  const [comercial, setComercial] = useState<string>(emailComercial);
  const [financeiro, setFinanceiro] = useState<string>(emailFinanceiro);
  const [erroPrincipal, setErroPrincipal] = useState<boolean>(false);
  const [erroComercial, setErroComercial] = useState<boolean>(false);
  const [erroFinanceiro, setErroFinanceiro] = useState<boolean>(false);

  const isNotReadOnly = useAppSelector(
    (state) => state.tenantConfiguration.cadastroCliente.permiteEdicao
  );

  const isReadOnly = useAppSelector(
    (state) => state.readability.isClientReadOnly
  );

  const dispatch = useAppDispatch();

  const validateEmailPrincipal = (): void => {
    const validEmail: boolean = isEmailValid(principal);
    setErroPrincipal(!validEmail);
    dispatch(clientActions.changeEmailPrincipal(principal));
  };
  const validateEmailComercial = (): void => {
    const validEmail: boolean = isEmailValid(comercial);
    setErroComercial(!validEmail);
    dispatch(clientActions.changeEmailComercial(comercial));
  };
  const validateEmailFinanceiro = (): void => {
    const validEmail: boolean = isEmailValid(financeiro);
    setErroFinanceiro(!validEmail);
    dispatch(clientActions.changeEmailFinanceiro(financeiro));
  };

  return (
    <Card>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <Typography
              sx={{
                backgroundColor: Colors.titleRowWithBackgroundColor,
                pl: 1,
                pt: 0.75,
                mt: -1,
              }}
              variant='body2'
              color={Colors.titleWithBackgroudColor}
            >
              <Email fontSize={'inherit'} /> E-mail
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={isReadOnly}
              // inputProps={{ readOnly: !isNotReadOnly }}
              error={erroPrincipal && !!principal}
              onBlur={() => validateEmailPrincipal()}
              fullWidth
              placeholder='Principal'
              value={principal}
              type='email'
              onChange={(ev) => {
                setErroPrincipal(false);
                setPrincipal(ev.target.value);
              }}
              size={'small'}
              color='success'
              label='Principal'
              helperText={erroPrincipal && principal ? INVALID_EMAIL : ''}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={isReadOnly}
              // inputProps={{ readOnly: !isNotReadOnly }}
              error={erroComercial && !!comercial}
              onBlur={() => validateEmailComercial()}
              fullWidth
              placeholder='Comercial'
              value={comercial}
              type='email'
              onChange={(ev) => {
                setErroComercial(false);
                setComercial(ev.target.value);
              }}
              size={'small'}
              color='success'
              label='Comercial'
              helperText={erroComercial && comercial ? INVALID_EMAIL : ''}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={isReadOnly}
              // inputProps={{ readOnly: !isNotReadOnly }}
              error={erroFinanceiro && !!financeiro}
              onBlur={() => validateEmailFinanceiro()}
              fullWidth
              placeholder='Financeiro'
              value={financeiro}
              type='email'
              onChange={(ev) => {
                setErroFinanceiro(false);
                setFinanceiro(ev.target.value);
              }}
              size={'small'}
              color='success'
              label='Financeiro'
              helperText={erroFinanceiro && financeiro ? INVALID_EMAIL : ''}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default EmailsCard;
