import Endereco from '../../models/address/address.model';

const formatAddressToString = (address: Endereco): string => {
  const {
    sublocalidade,
    cep,
    localidade,
    complemento,
    logradouro,
    numero,
    uf,
  } = address;

  return `${logradouro}, ${numero} ${
    complemento ? '- ' + complemento : ''
  }\r\n${sublocalidade} - ${localidade} - ${uf}\r\n${cep}`;
};
export default formatAddressToString;
