import Goal from '../../models/queries/Goals.model';

const formatDataToChart = (goals: Goal[]) => {
  const data: any[] = [['Código', 'Meta', 'Vendas']];
  goals.forEach((goal) => {
    const newGoal = [];
    newGoal.push(goal.meta);
    newGoal.push(goal.valorMeta);
    newGoal.push(goal.venda.valorVenda);
    data.push(newGoal);
  });
  return data;
};

export default formatDataToChart;
