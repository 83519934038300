import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import OrderSituation from '../models/order/orderSituation.model';

import dynamicBaseQuery from './dynamicBaseQuery';

export const orderSituationApi = createApi({
  reducerPath: 'orderSituationApi',
  refetchOnMountOrArgChange: true,
  // refetchOnFocus: true,
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    getSituations: builder.query<OrderSituation[], void>({
      query: () => ({
        url: `/situacaopedido`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }),
    }),
    getSituation: builder.query<OrderSituation, string>({
      query: (id: string) => ({
        url: `/situacaopedido/${id}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }),
    }),
  }),
});

export const { useGetSituationsQuery, useGetSituationQuery } =
  orderSituationApi;
