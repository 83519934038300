const formatPhoneNumber = (number: string): string => {
  number = number.replace(/[^\d]+/g, '');
  switch (number.length) {
    case 8:
      return `${number.substring(0, 4)}-${number.substring(4)}`;
    case 9:
      return `${number.substring(0, 5)}-${number.substring(5)}`;
    case 10:
      return `(${number.substring(0, 2)}) ${number.substring(
        2,
        6
      )}-${number.substring(6)}`;
    case 11:
      return `(${number.substring(0, 2)}) ${number.substring(
        2,
        7
      )}-${number.substring(7)}`;
    default:
      return number;
  }
};

export default formatPhoneNumber;
