import { createApi } from '@reduxjs/toolkit/query/react';

import ProductPriceTable from '../models/product/productPriceTable.model';
import dynamicBaseQuery from './dynamicBaseQuery';

export const productPriceTableApi = createApi({
  reducerPath: 'productPriceTableApi',
  tagTypes: ['ProductPriceTable'],
  refetchOnMountOrArgChange: true,
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    getProductPriceTable: builder.query<ProductPriceTable[], string>({
      query: (idTabela) => ({
        url: `/tabelaprecoproduto/${idTabela}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }),
      providesTags: ['ProductPriceTable'],
    }),
    getAllTables: builder.query<ProductPriceTable[], void>({
      query: () => ({
        url: `/tabelaprecoproduto`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }),
      providesTags: ['ProductPriceTable'],
    }),
  }),
});

export const { useGetProductPriceTableQuery, useGetAllTablesQuery } =
  productPriceTableApi;
