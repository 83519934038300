import OrderForm from '../../models/order/orderForm.model';
import ORDER_INITIALIZER from './OrderInitializer';
import ORDER_ITEM_INITIALIZER from './OrderItemInitializer';
import ORDER_PHASE_INITIALIZER from './OrderPhaseInitializer';
import ORDER_SITUATION_INITIALIZER from './OrderSituationInitializer';
import PAYMENT_CONDITION_INITIALIZER from './PaymentConditionInitializer';
import PRODUCT_PRICE_TABLE_INITIALIZER from './ProductPriceTableInitializer';

const ORDER_FORM_INITIALIZER: OrderForm = {
  selectedClient: null,
  selectedPriceTable: PRODUCT_PRICE_TABLE_INITIALIZER,
  selectedPaymentCondition: PAYMENT_CONDITION_INITIALIZER,
  order: ORDER_INITIALIZER,
  itemToBeModified: ORDER_ITEM_INITIALIZER,
  orderSituation: ORDER_SITUATION_INITIALIZER,
  orderPhase: ORDER_PHASE_INITIALIZER,
  itemsDetail: [],
};

export default ORDER_FORM_INITIALIZER;
