import { Card, Typography, CardContent, Grid } from '@mui/material';
import { LocationOnOutlined } from '@mui/icons-material';

import { useAppSelector } from '../../../store/hooks';

import Colors from '../../../constants/Colors';

const OrderClientAddressCard = () => {
  const selectedClient = useAppSelector((state) => state.order.selectedClient);

  return (
    <Grid item xs={12} md={12}>
      <Card>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <Typography
                sx={{
                  backgroundColor: Colors.titleRowWithBackgroundColor,
                  pl: 1,
                  pt: 0.75,
                  mt: -1,
                }}
                variant='body2'
                color={Colors.titleWithBackgroudColor}
              >
                <LocationOnOutlined fontSize={'inherit'} /> Endereço
              </Typography>
            </Grid>
            {selectedClient && (
              <Grid item xs={12} md={12}>
                {!selectedClient.endereco.logradouro ? null : (
                  <>
                    <Typography variant='body2'>
                      {`${selectedClient.endereco.logradouro}, ${
                        selectedClient.endereco.numero
                      } ${
                        selectedClient.endereco.complemento
                          ? '- ' + selectedClient.endereco.complemento
                          : ''
                      }`}
                    </Typography>
                    <Typography variant='body2'>
                      {`${selectedClient.endereco.sublocalidade} - ${selectedClient.endereco.localidade} - ${selectedClient.endereco.uf}`}
                    </Typography>
                    <Typography variant='body2'>
                      {`${selectedClient.endereco.cep}`}
                    </Typography>
                  </>
                )}
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default OrderClientAddressCard;
