import { ReactNode } from 'react';
import { Modal, Card, CardContent, SxProps, Theme } from '@mui/material';

interface Props {
  openModal: boolean;
  toggle: () => void;
  sx: SxProps<Theme> | undefined;
  children?: ReactNode;
}

const QueryModalWrapper = (props: Props) => {
  const { openModal, sx, toggle, children } = props;

  return (
    <Modal open={openModal} onClose={toggle} sx={{ ...sx }}>
      <Card
        sx={{
          width: {
            md: '65%',
            sm: '80%',
            xs: '95%',
          },
          height: '80vh',
          backgroundColor: '#FaFaFa',
          overflowY: 'auto',
        }}
      >
        <CardContent>{children}</CardContent>
      </Card>
    </Modal>
  );
};

export default QueryModalWrapper;
