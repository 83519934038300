import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface firstState {
  token: string | null;
  userId: string | null;
}

// const MOCK_TOKEN: string = 'token';

const initialState: firstState = {
  token: localStorage.getItem('token'),
  userId: localStorage.getItem('userId'),
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(
      state: firstState,
      action: PayloadAction<{ token: string; id: string }>
    ) {
      state.token = action.payload.token;
      state.userId = action.payload.id;
    },
    logout(state: firstState) {
      state.token = null;
      state.userId = null;
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice;
