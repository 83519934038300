import { useState, useEffect } from "react";
import { LocalizationProvider, DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { format, isValid } from "date-fns";
import { ptBR } from "date-fns/locale";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Stack,
  TextField,
  Checkbox,
} from "@mui/material";
import { Search } from "@mui/icons-material";

import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import Colors from "../../../constants/Colors";
import { orderSearchActions } from "../../../store/search/order-search-slice";

interface Props {
  inicialEmissionError: boolean;
  finalEmissionError: boolean;
  toggleInicialEmissionError: (errorValue: boolean) => void;
  toggleFinalEmissionError: (errorValue: boolean) => void;
}

const OrderSearchCard = (props: Props) => {
  const {
    finalEmissionError,
    inicialEmissionError,
    toggleFinalEmissionError,
    toggleInicialEmissionError,
  } = props;
  const [inicialEmissionDate, setInicialEmissionDate] = useState<Date | null>(
    new Date(new Date().setDate(new Date().getDate() - 7))
  );
  const [finalEmissionDate, setFinalEmissionDate] = useState<Date | null>(
    new Date()
  );
  // const [inicialEmissionError, setInicialEmissionError] = useState(false);
  const [inicialEmissionErrorMessage, setInicialEmissionErrorMessage] =
    useState("");
  // const [finalEmissionError, setFinalEmissionError] = useState(false);
  const [finalEmissionErrorMessage, setFinalEmissionErrorMessage] =
    useState("");
  const dispatch = useAppDispatch();
  const {
    cliente: clientId,
    emissaoFinal: finalEmission,
    emissaoInicial: inicialEmission,
    pedidoId: number,
    rascunho: sketch,
  } = useAppSelector((state) => state.orderSearch);

  const handleInicialEmissionDateChange = (date: Date | null): void => {
    let formattedDate = "";
    if (!date || !isValid(date) || date > new Date()) {
      if (!inicialEmissionError) toggleInicialEmissionError(true);
      setInicialEmissionErrorMessage("A data informada é inválida");
      return;
    } else if (finalEmissionDate && finalEmissionDate < date) {
      if (!inicialEmissionError) toggleInicialEmissionError(true);
      setInicialEmissionErrorMessage(
        "A data inicial deve ser menor que a data final"
      );
      return;
    } else {
      if (inicialEmissionError) toggleInicialEmissionError(false);
      formattedDate = format(date, "dd/MM/yyyy");
      setInicialEmissionDate(date);
      setInicialEmissionErrorMessage("");
      dispatch(orderSearchActions.changeInicialEmission(formattedDate));
    }
  };
  const handleFinalEmissionDateChange = (date: Date | null): void => {
    let formattedDate = "";
    if (!date || !isValid(date) || date > new Date()) {
      if (!finalEmissionError) toggleFinalEmissionError(true);
      setFinalEmissionErrorMessage("A data informada é inválida");
      return;
    } else if (inicialEmissionDate && date < inicialEmissionDate) {
      if (!finalEmissionError) toggleFinalEmissionError(true);
      setFinalEmissionErrorMessage(
        "A data inicial deve ser menor que a data final"
      );
      return;
    } else {
      if (finalEmissionError) toggleFinalEmissionError(false);
      formattedDate = format(date, "dd/MM/yyyy");
      setFinalEmissionDate(date);
      setFinalEmissionErrorMessage("");
      dispatch(orderSearchActions.changeFinalEmission(formattedDate));
    }
  };

  useEffect(() => {
    if (!inicialEmission && inicialEmissionDate) {
      let formattedDate = format(inicialEmissionDate, "dd/MM/yyyy");
      dispatch(orderSearchActions.changeInicialEmission(formattedDate));
    }
    if (!finalEmission && finalEmissionDate) {
      let formattedDate = format(finalEmissionDate, "dd/MM/yyyy");
      dispatch(orderSearchActions.changeFinalEmission(formattedDate));
    }
  }, [
    finalEmission,
    inicialEmission,
    inicialEmissionDate,
    finalEmissionDate,
    dispatch,
  ]);

  return (
    <Grid item xs={12} md={12}>
      <Card>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <Typography
                sx={{
                  backgroundColor: Colors.titleRowWithBackgroundColor,
                  pl: 1,
                  pt: 0.75,
                  mt: -1,
                }}
                variant="body2"
                color={Colors.titleWithBackgroudColor}
              >
                <Search fontSize={"inherit"} /> Filtros
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                label={"Número"}
                value={number}
                size={"small"}
                onChange={(ev) =>
                  dispatch(orderSearchActions.changeNumber(ev.target.value))
                }
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                <DatePicker
                  label={"Emissão Inicial"}
                  maxDate={new Date()}
                  value={inicialEmissionDate}
                  onChange={(value) => handleInicialEmissionDateChange(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={inicialEmissionErrorMessage}
                      error={inicialEmissionError}
                      color="success"
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "dd/mm/aaaa",
                      }}
                      style={{ width: "100%", marginTop: 5 }}
                    />
                  )}
                  InputProps={{
                    size: "small",
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6} md={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                <DatePicker
                  label={"Emissão Final"}
                  maxDate={new Date()}
                  value={finalEmissionDate}
                  onChange={(value) => handleFinalEmissionDateChange(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={finalEmissionErrorMessage}
                      error={finalEmissionError}
                      color="success"
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "dd/mm/aaaa",
                      }}
                      style={{ width: "100%", marginTop: 5 }}
                    />
                  )}
                  InputProps={{
                    size: "small",
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                label={"Cliente"}
                value={clientId}
                size={"small"}
                onChange={(ev) =>
                  dispatch(orderSearchActions.changeClientId(ev.target.value))
                }
                style={{ marginTop: 5 }}
              />
            </Grid>
            {/* <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                label={'Produto'}
                value={productId}
                size={'small'}
                onChange={(ev) =>
                  dispatch(orderSearchActions.changeProductId(ev.target.value))
                }
              />
            </Grid> */}
            <Grid item xs={6} md={12}>
              <Stack
                direction={"row"}
                justifyContent={"space-around"}
                alignItems={"center"}
              >
                <Typography variant="body1">Rascunho</Typography>
                <Checkbox
                  color="success"
                  checked={!!sketch}
                  onChange={(ev) =>
                    dispatch(orderSearchActions.isSketch(ev.target.checked))
                  }
                />
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default OrderSearchCard;
