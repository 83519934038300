import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import OrderSearch from '../../models/requisitions/PaginatedOrderSearchRequisition.model';
import ORDER_SEARCH_INITIALIZER from '../../util/initializers/SearchFieldsInitializer';

const initialState = ORDER_SEARCH_INITIALIZER;

const orderSearchSlice = createSlice({
  initialState,
  name: 'orderSearch',
  reducers: {
    changeNumber(state: OrderSearch, action: PayloadAction<string>) {
      state.pedidoId = action.payload;
    },
    changeInicialEmission(state: OrderSearch, action: PayloadAction<string>) {
      state.emissaoInicial = action.payload;
    },
    changeFinalEmission(state: OrderSearch, action: PayloadAction<string>) {
      state.emissaoFinal = action.payload;
    },
    changeClientId(state: OrderSearch, action: PayloadAction<string>) {
      state.cliente = action.payload;
    },
    isSketch(state: OrderSearch, action: PayloadAction<boolean>) {
      state.rascunho = action.payload;
    },
    changePage(state: OrderSearch, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setSearchState(state: OrderSearch, action: PayloadAction<OrderSearch>) {
      // state = { ...action.payload };
      // console.log('Entrou no setSearchState');
      state.cliente = action.payload.cliente;
      state.emissaoFinal = action.payload.emissaoFinal;
      state.emissaoInicial = action.payload.emissaoInicial;
      state.page = action.payload.page;
      state.pedidoId = action.payload.pedidoId;
      state.rascunho = action.payload.rascunho;
    },
    resetOrderSearch() {
      return {
        ...initialState,
      };
    },
  },
});

export const orderSearchActions = orderSearchSlice.actions;

export default orderSearchSlice;
