import {
  Modal,
  Card,
  CardContent,
  Typography,
  Stack,
  Button,
  CircularProgress,
} from '@mui/material';
// import Spinner from '../../layout/Spinner';

import Colors from '../../../constants/Colors';

interface Props {
  openModal: boolean;
  toggle: () => void;
  handleChange: () => Promise<void>;
  isLoading: boolean;
  isError: boolean;
  toggleError: () => void;
}

const ConfirmTableChangeModal = (props: Props) => {
  if (props.isLoading) {
    return (
      <Modal
        open={props.openModal}
        onClose={props.toggle}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Card
          sx={{
            height: '30vh',
            width: { md: '40%', xs: '70%' },
          }}
        >
          <CardContent>
            <Stack
              justifyContent={'center'}
              alignItems={'center'}
              height={'100%'}
            >
              <CircularProgress sx={{ color: Colors.primary }} />
            </Stack>
          </CardContent>
        </Card>
      </Modal>
    );
  }

  if (props.isError) {
    return (
      <Modal
        open={props.openModal}
        onClose={props.toggle}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Card
          sx={{
            height: '30vh',
            width: { md: '40%', xs: '70%' },
          }}
        >
          <CardContent>
            <Stack spacing={2}>
              <Typography variant='h6' color={Colors.muiDanger}>
                Erro ao alterar tabela
              </Typography>
              <Typography variant='body2'>
                Verifique sua conexão com a internet e tente novamente, ou entre
                em contato com o administrador do sistema.
              </Typography>
              <Stack direction={'row'} justifyContent={'center'}>
                <Button
                  variant='outlined'
                  color='success'
                  onClick={() => {
                    props.toggleError();
                    props.toggle();
                  }}
                >
                  Ok
                </Button>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Modal>
    );
  }

  return (
    <Modal
      open={props.openModal}
      onClose={props.toggle}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Card
        sx={{
          height: '30vh',
          width: { md: '40%', xs: '70%' },
        }}
      >
        <CardContent>
          <Stack spacing={2}>
            <Typography variant='h6'>
              Deseja realmente alterar a tabela de preço?
            </Typography>
            <Typography variant='body2'>
              Ao alterar a tabela, todos os descontos e acréscimos serão
              revertidos.
            </Typography>
            <Stack direction={'row'} justifyContent={'space-around'}>
              <Button
                variant='outlined'
                color='success'
                onClick={() => props.handleChange()}
              >
                Confirmar
              </Button>
              <Button variant='outlined' color='error' onClick={props.toggle}>
                Cancelar
              </Button>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default ConfirmTableChangeModal;
