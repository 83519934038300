import { useState, useEffect } from "react";
import { LocalizationProvider, DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { format, isValid } from "date-fns";
import { ptBR } from "date-fns/locale";
import { Grid, Card, CardContent, Typography, TextField } from "@mui/material";
import { CalendarMonthOutlined } from "@mui/icons-material";

import Colors from "../../../constants/Colors";
import { orderActions } from "../../../store/order/order-slice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import formatDateToString from "../../../util/formaters/formatDateToString";
import formatStringToDate from "../../../util/formaters/formatStringToDate";

const OrderDeadline = () => {
  const orderDeadline = useAppSelector((state) => state.order.order.previsao);
  // const isReadOnly = !useAppSelector(
  //   (state) => state.order.orderSituation.permiteEdicao
  // );
  const isReadOnly = useAppSelector(
    (state) => state.readability.isOrderReadOnly
  );
  const [deadline, setDeadline] = useState<Date | null>(
    orderDeadline ? formatStringToDate(orderDeadline) : null
  );
  const [dateError, setDateError] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setDeadline(formatStringToDate(orderDeadline));
  }, [orderDeadline]);

  const handleDateChange = (date: Date | null): void => {
    let formattedDate = "";
    const dateTemp = new Date().setHours(0, 0, 0, 0);
    const dateWithoutHours = new Date(dateTemp);
    // console.log(dateWithoutHours, date);
    if (!date || !isValid(date) || date <= dateWithoutHours) {
      if (!dateError) setDateError(true);
      return;
    } else {
      if (dateError) setDateError(false);
      formattedDate = format(date, "dd/MM/yyyy");
      setDeadline(date);
      dispatch(
        orderActions.setPrevisao({
          data: formattedDate,
          hora: formatDateToString().hora,
        })
      );
    }
  };
  return (
    <Grid item xs={12} md={3}>
      <Card sx={{ height: "100%" }}>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <Typography
                sx={{
                  backgroundColor: Colors.titleRowWithBackgroundColor,
                  pl: 1,
                  pt: 0.75,
                  mt: -1,
                }}
                variant="body2"
                color={Colors.titleWithBackgroudColor}
              >
                <CalendarMonthOutlined fontSize={"inherit"} /> Previsão de
                entrega
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} justifyContent={"center"}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                <DatePicker
                  disabled={isReadOnly}
                  minDate={new Date()}
                  value={deadline}
                  onChange={(value) => handleDateChange(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={
                        dateError ? "A data informada é inválida" : ""
                      }
                      fullWidth
                      error={dateError}
                      color="success"
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "dd/mm/aaaa",
                      }}
                    />
                  )}
                  InputProps={{
                    size: "small",
                    readOnly: true,
                  }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default OrderDeadline;
