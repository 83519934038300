import { Link } from 'react-router-dom';
import { Stack, Tooltip } from '@mui/material';
import { Edit, VisibilityOutlined } from '@mui/icons-material';

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { StyleSheet, css } from 'aphrodite';

import { Client } from '../../models/client/client.model';
import { useAppSelector } from '../../store/hooks';

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import '../../styles/style.css';

interface Props {
  clients: Client[];
}

const ClientsListingTable = (props: Props) => {
  const { clients } = props;
  const configTenant = useAppSelector((state) => state.tenantConfiguration);

  let qtde = clients.length;

  const canEditClient = (client: Client): boolean => {
    if (!!configTenant.cadastroCliente.permiteEdicao) {
      return true;
    }

    const alreadyOrdered = client.pedidosEmitidos > 0;

    if (
      !configTenant.cadastroCliente.permiteEdicao &&
      !alreadyOrdered &&
      client.origem !== 'MTZ'
    ) {
      return true;
    }
    return false;
  };

  if (clients.length === 0) {
    return null;
  }

  return (
    <Table className={css(styles.table)}>
      <Thead className='table_head'>
        <Tr>
          <Th>Apelido</Th>
          <Th>Nome</Th>
          <Th>Categoria</Th>
          <Th>CPF/CNPJ</Th>
          <Th>RG</Th>
          <Th>Ações</Th>
        </Tr>
      </Thead>
      <Tbody>
        {clients.map((client) => {
          var parity = qtde % 2;
          qtde = qtde - 1;
          const isClientEditable = canEditClient(client);
          return (
            <Tr
              key={client.id}
              className={parity === 1 ? 'odd_row' : 'even_row'}
            >
              <Td className='table_text'>{client.apelido}</Td>
              <Td className='table_text'>{client.nome}</Td>
              <Td className='table_text'>{client.categoria}</Td>
              <Td className='table_number_cell'>{client.cnpjCpf}</Td>
              <Td className='table_number_cell'>
                {client.rg ? client.rg : ' '}
              </Td>
              <Td className='table_actions'>
                <Stack
                  direction={'row'}
                  height={'100%'}
                  width={'100%'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  pl={0.5}
                  pr={0.5}
                >
                  <Stack>
                    <Tooltip arrow title='Ir para detalhes'>
                      <Link
                        style={{
                          textDecoration: 'none',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        to={`/clientes/detalhes`}
                        state={{
                          id: encodeURIComponent(client.id),
                          readOnly: true,
                        }}
                      >
                        <VisibilityOutlined color='action' fontSize='inherit' />
                      </Link>
                    </Tooltip>
                  </Stack>
                  {isClientEditable && (
                    <Stack>
                      <Tooltip arrow title={'Editar cliente'}>
                        <Link
                          style={{
                            textDecoration: 'none',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                          to={`/clientes/detalhes`}
                          state={{
                            id: encodeURIComponent(client.id),
                            readOnly: false,
                          }}
                        >
                          <Edit htmlColor='#ff6600' fontSize='inherit' />
                        </Link>
                      </Tooltip>
                    </Stack>
                  )}
                </Stack>
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};

const styles = StyleSheet.create({
  table: {
    fontSize: 13,
    display: 'table',
  },
  editButton: {
    color: '#ff6600',
  },
  numberCell: {
    justifyContent: 'flex-end',
  },
});

export default ClientsListingTable;
