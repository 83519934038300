import ProductPriceTable from '../../models/product/productPriceTable.model';

const PRODUCT_PRICE_TABLE_INITIALIZER: ProductPriceTable = {
  condicoesPagamentoIds: [],
  descontoMaximoPerc: 0,
  exibirTabela: true,
  fator: 0,
  id: '',
  inativo: false,
  nome: '',
  tabelaBaseId: '',
};

export default PRODUCT_PRICE_TABLE_INITIALIZER;
