import { useState, useEffect, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

import { orderActions } from "../../store/order/order-slice";
import { readabilityActions } from "../../store/readability/readability-slice";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { useGetCustomOrderQuery } from "../../api/ordersApi";
import { exitPageActions } from "../../store/exitPage/exit-page-slice";
import usePrompt from "../../hooks/customNavigationHooks";

import OrderClient from "../../components/order/OrderClient";
import OrderHeader from "../../components/order/OrderHeader";
import OrderItems from "../../components/order/OrderItems";
import TopTabNavigation from "../../components/navigation/TopTab";
import OrderCheckout from "../../components/order/OrderCheckout";
import Spinner from "../../components/layout/Spinner";

interface LocationState {
  id: string;
  readOnly: boolean;
}

const SalesManagementPage = (): JSX.Element => {
  const location = useLocation();
  const locationState = location.state as LocationState;
  const canExit = useAppSelector((state) => state.exitPage.canExitSales);
  usePrompt(
    `Deseja sair da página? \nAs mudanças feitas serão perdidas.`,
    !canExit
  );
  const [skip, setSkip] = useState(true);
  const { data, isError, isFetching, isLoading, isSuccess } =
    useGetCustomOrderQuery(
      locationState && locationState.id ? locationState.id : "",
      { skip }
    );
  const dispatch = useAppDispatch();
  const [topTabValue, setTopTabValue] = useState<number>(0);
  const userId = useAppSelector((state) => state.auth.userId);
  const order = useAppSelector((state) => state.order);
  const selectedTable = order.order.tabelaPrecoId;
  const pedidoVendaConfig = useAppSelector(
    (state) => state.tenantConfiguration
  );

  useLayoutEffect(() => {
    if (locationState) {
      setSkip(false);
    }
  }, [locationState]);

  useLayoutEffect(() => {
    dispatch(
      exitPageActions.changeCanExitSales(
        !((locationState && !locationState.readOnly) || !locationState)
      )
    );
  }, [dispatch, locationState]);

  useLayoutEffect(() => {
    if (locationState && locationState.readOnly !== undefined) {
      dispatch(readabilityActions.setOrderReadability(locationState.readOnly));
    }
  }, [locationState, dispatch]);
  useEffect(() => {
    if (data) {
      dispatch(orderActions.setOrder(data.pedido));
      dispatch(orderActions.selectOrderClient(data.cliente));
      dispatch(
        orderActions.setPaymentCondition({
          acrescimoPerc: 0,
          descontoPerc: 0,
          id: data.pedido.condicaoPagamentoId,
          inativo: false,
          prazoMedio: 0,
          nome: data.condicaoPagamento.nome,
          parcelas: data.condicaoPagamento.parcelas,
          tipoDocumentoTitulo: {
            nome: data.condicaoPagamento.tipoDocumentoTitulo,
            codigo: "",
          },
        })
      );
      if (data.pedido.sistemaIntegrado.fasePedidoId) {
        dispatch(
          orderActions.setOrderPhase({
            cor: data.fasePedido.cor ? data.fasePedido.cor : null,
            id: data.pedido.sistemaIntegrado.fasePedidoId,
            nome: data.fasePedido.nome ? data.fasePedido.nome : "",
          })
        );
      }
      if (data.pedido.situacaoId) {
        dispatch(
          orderActions.setOrderSituation({
            id: data.pedido.situacaoId,
            cor: data.situacaoPedido.cor ? data.situacaoPedido.cor : "black",
            nome: data.situacaoPedido.nome ? data.situacaoPedido.nome : "",
            permiteEdicao: data.situacaoPedido.permiteEdicao
              ? data.situacaoPedido.permiteEdicao
              : true,
          })
        );
      }
      if (!data.pedido.situacaoId && !!data.pedido.transmissao.data) {
        dispatch(
          orderActions.setOrderSituation({
            cor: "orange",
            id: "-1",
            nome: "Não recepcionado",
            permiteEdicao: false,
          })
        );
      }
      dispatch(orderActions.setItemsDetail(data.produto));
    }
  }, [data, isSuccess, dispatch]);

  useEffect(() => {
    // console.log(order);
  }, [order]);

  useEffect(() => {
    if (!order.order.owner && !order.order.vendedorId) {
      dispatch(orderActions.setOrderVendedorIdOwner(userId as string));
    }
  }, [order.order.owner, order.order.vendedorId, dispatch, userId]);

  useEffect(() => {
    if (!selectedTable && pedidoVendaConfig.pedidoVenda.tabelaPrecoPadraoId) {
      dispatch(
        orderActions.setOrderPriceTable(
          pedidoVendaConfig.pedidoVenda.tabelaPrecoPadraoId
        )
      );
    }
  }, [selectedTable, dispatch, pedidoVendaConfig]);

  if (isError) {
    return <h2>Erro ao carregar dados do pedido</h2>;
  }

  if (isFetching || isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <TopTabNavigation
        currentTab={topTabValue}
        changeTab={(nextTab: number) => setTopTabValue(nextTab)}
      />

      {topTabValue === 0 && <OrderHeader />}
      {topTabValue === 1 && <OrderClient />}
      {topTabValue === 2 && <OrderItems />}
      {topTabValue === 3 && <OrderCheckout />}
    </>
  );
};

export default SalesManagementPage;
