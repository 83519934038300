import { useState } from "react";
import {
  Card,
  Typography,
  CardContent,
  CardActionArea,
  // Grid,
} from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";

import Colors from "../../../constants/Colors";
import { orderActions } from "../../../store/order/order-slice";
import { getPriceTablesFromArray } from "../../../store/order/order-async-actions";
// import Spinner from '../../layout/Spinner';
import ConfirmTableChangeModal from "../../modals/order/ConfirmTableChangeModal";

interface Props {
  id: string;
  nome: string;
  toggle: () => void;
}

const OrderTableCard = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isError, setIsError] = useState(false);
  const dispatch = useAppDispatch();
  const items = useAppSelector((state) => state.order.order.itens);

  const handleTableChange = async (): Promise<void> => {
    setIsLoading(true);
    let ids: string[] = [];
    if (items.length > 0) {
      items.forEach((item) => {
        ids.push(item.produtoId);
      });
    }
    try {
      const response = await getPriceTablesFromArray(ids, props.id);
      // console.log("Resposta getTables: ", response);
      dispatch(orderActions.handleTableChange([...response]));
      dispatch(orderActions.setOrderPriceTable(props.id));
      setIsLoading(false);
      props.toggle();
    } catch (err) {
      // console.log(err);
      setIsLoading(false);
      setIsError(true);
    }
  };

  return (
    <>
      <ConfirmTableChangeModal
        handleChange={handleTableChange}
        isLoading={isLoading}
        openModal={openModal}
        toggle={() => setOpenModal(!openModal)}
        isError={isError}
        toggleError={() => setIsError(false)}
      />
      <Card
        sx={{
          height: "100%",
          marginTop: "2%",
          marginBottom: "2%",
          boxShadow: 3,
          textAlign: "center",
        }}
      >
        <CardActionArea onClick={() => setOpenModal(true)}>
          <CardContent>
            <Typography
              variant="body2"
              fontWeight={"bold"}
              color={Colors.primary}
              fontSize={16}
            >
              {props.nome}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
};
export default OrderTableCard;
