import OrderItem from "../../models/order/orderItem.model";

const ORDER_ITEM_INITIALIZER: OrderItem = {
  grade: {
    grade2: "",
    grade3: "",
  },
  id: new Date().getTime(),
  produtoId: "",
  quantidade: 0,
  totais: {
    acrescimos: {
      condicaoPagamento: {
        percentual: 0,
        total: 0,
        valor: 0,
      },
      item: {
        percentual: 0,
        valor: 0,
        total: 0,
      },
      total: 0,
    },
    descontos: {
      condicaoPagamento: {
        percentual: 0,
        total: 0,
        valor: 0,
      },
      item: {
        percentual: 0,
        valor: 0,
        total: 0,
      },
      total: 0,
    },
    produtos: 0,
    total: 0,
  },
  unitarioFinal: 0,
  unitario: 0,
  percentualIpi: 0,
  valorIpi: 0,
};

export default ORDER_ITEM_INITIALIZER;
