import {
  Card,
  Typography,
  CardContent,
  CardActionArea,
  Grid,
  Stack,
  IconButton,
} from '@mui/material';

import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import RequestPageOutlinedIcon from '@mui/icons-material/RequestPageOutlined'; //boleto
import FeaturedPlayListOutlinedIcon from '@mui/icons-material/FeaturedPlayListOutlined'; //cheque,duplicata,outros
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined'; //tranferencia

import PaymentCondition from '../../../models/order/paymentConditions.model';
import { useAppDispatch } from '../../../store/hooks';
import { orderActions } from '../../../store/order/order-slice';

import Colors from '../../../constants/Colors';

interface Props {
  condition: PaymentCondition;
  toggle: () => void;
}

const OrderPaymentConditionCard = (props: Props) => {
  const dispatch = useAppDispatch();

  const setOrderPaymentCondition = () => {
    dispatch(orderActions.setPaymentCondition(props.condition));
    props.toggle();
  };

  const iconResolver = () => {
    if (
      props.condition.tipoDocumentoTitulo.codigo === '$$' ||
      props.condition.tipoDocumentoTitulo.codigo === 'DH'
    ) {
      return <LocalAtmIcon />;
    } else if (
      props.condition.tipoDocumentoTitulo.codigo === 'CC' ||
      props.condition.tipoDocumentoTitulo.codigo === 'CD'
    ) {
      return <CreditCardOutlinedIcon />;
    } else if (props.condition.tipoDocumentoTitulo.codigo === 'BO') {
      return <RequestPageOutlinedIcon />;
    } else if (props.condition.tipoDocumentoTitulo.codigo === 'TR') {
      return <CurrencyExchangeOutlinedIcon />;
    } else {
      return <FeaturedPlayListOutlinedIcon />;
    }
  };

  return (
    <Grid item xs={12} md={12} mt={1}>
      <Card>
        <CardActionArea onClick={() => setOrderPaymentCondition()}>
          <CardContent>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <IconButton
                disabled
                sx={{
                  '&:disabled': {
                    backgroundColor: Colors.iconButtonTotalBackground,
                    color: Colors.iconButtonTotal,
                  },
                }}
              >
                {iconResolver()}
              </IconButton>
              <Stack>
                <Typography variant='h6' color={'green'}>
                  {props.condition.nome}
                </Typography>
                <Typography variant='body2'>
                  {props.condition.tipoDocumentoTitulo.nome.toUpperCase()}{' '}
                  {props.condition.parcelas}x
                </Typography>
              </Stack>
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default OrderPaymentConditionCard;
