import { createApi } from '@reduxjs/toolkit/query/react';

import PaymentConditions from '../models/order/paymentConditions.model';
import dynamicBaseQuery from './dynamicBaseQuery';

export const paymentConditionsApi = createApi({
  reducerPath: 'paymentConditionsApi',
  tagTypes: ['PaymentConditions'],
  refetchOnMountOrArgChange: true,
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    getPaymentConditions: builder.query<PaymentConditions[], void>({
      query: () => ({
        url: '/condicaopagamento',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }),
      providesTags: ['PaymentConditions'],
    }),
    getPaymentCondition: builder.query<PaymentConditions, string>({
      query: (id: string) => ({
        url: `/condicaopagamento/${id}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }),
      providesTags: ['PaymentConditions'],
    }),
  }),
});

export const { useGetPaymentConditionsQuery, useGetPaymentConditionQuery } =
  paymentConditionsApi;
