import { Stack, Tooltip } from "@mui/material";
import { VisibilityOutlined } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { StyleSheet, css } from "aphrodite";

import ProductSummary from "../../models/product/productSummary.model";

import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "../../styles/style.css";

interface Props {
  products: ProductSummary[];
  toggle: (id: string) => void;
}

const ProductsListingTable = (props: Props) => {
  const { products, toggle } = props;
  // const configTenant = useAppSelector((state) => state.tenantConfiguration);

  let qtde = products.length;

  const handleModalToggle = (
    ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    id: string
  ) => {
    ev.preventDefault();
    toggle(id);
  };

  if (products.length === 0) {
    return null;
  }
  console.log(products);

  return (
    <Table className={css(styles.table)}>
      <Thead className="table_head">
        <Tr>
          <Th>Referência</Th>
          <Th>Descrição</Th>
          <Th>Saldo</Th>
          <Th>Preço</Th>
          <Th>IPI</Th>
          <Th>Ações</Th>
        </Tr>
      </Thead>
      <Tbody>
        {products.map((product) => {
          var parity = qtde % 2;
          qtde = qtde - 1;
          return (
            <Tr
              key={product.codigo}
              className={parity === 1 ? "odd_row" : "even_row"}
            >
              <Td className="table_text">{product.codigo}</Td>
              <Td className="table_text">{product.nome}</Td>
              <Td className="table_number_cell">
                {product.saldoTotal.toFixed(3).replace(".", ",")}{" "}
                {product.unidadeMedida}
              </Td>
              <Td className="table_number_cell">
                {product.precoTabelaPadrao.toFixed(2).replace(".", ",")}
              </Td>
              <Td className="table_actions">
                {product.percentualIpi ? (
                  <CheckIcon color="success" fontSize="inherit" />
                ) : (
                  "-"
                )}
              </Td>
              <Td className="table_actions">
                <Stack
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Tooltip arrow title={"Ir para detalhes"}>
                    <a
                      href="#"
                      onClick={(ev) => handleModalToggle(ev, product.codigo)}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <VisibilityOutlined color="action" fontSize="inherit" />
                    </a>
                  </Tooltip>
                </Stack>
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};

const styles = StyleSheet.create({
  table: {
    fontSize: 13,
    display: "table",
  },
});

export default ProductsListingTable;
