import { useNavigate } from 'react-router-dom';
import { Dialog, Button, DialogTitle } from '@mui/material';
import { StyleSheet, css } from 'aphrodite';

interface Props {
  open: boolean;
  toggle: () => void;
}

const BeforeInsertDialog = (props: Props): JSX.Element => {
  const navigate = useNavigate();
  return (
    <Dialog open={props.open} onClose={props.toggle}>
      <DialogTitle>
        Informe qual o tipo de cliente que deseja cadastrar
      </DialogTitle>
      <div className={css(styles.buttonContainer)}>
        <Button
          variant='outlined'
          onClick={() => navigate('/clientes/novo', { state: { pessoa: 'F' } })}
        >
          FÍSICA
        </Button>{' '}
        <Button
          variant='outlined'
          onClick={() => navigate('/clientes/novo', { state: { pessoa: 'J' } })}
        >
          JURÍDICA
        </Button>
      </div>
    </Dialog>
  );
};

export default BeforeInsertDialog;

const styles = StyleSheet.create({
  container: {
    padding: 100,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
});
