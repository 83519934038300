import {
  Card,
  Typography,
  CardContent,
  CardActionArea,
  Grid,
} from "@mui/material";

// import { Product } from '../../../models/product/product.model';
import ProductSummary from "../../../models/product/productSummary.model";
// import { useAppSelector } from '../../../store/hooks';

import Colors from "../../../constants/Colors";

interface Props {
  produto: ProductSummary;
  toggle: (id: string) => void;
}

const ProductCard = (props: Props): JSX.Element => {
  const { produto } = props;
  // const configTenant = useAppSelector((state) => state.tenantConfiguration);
  const totalBalance = produto.saldoTotal;

  let formatValue =
    Math.round(
      produto.precoTabelaPadrao * (produto.percentualIpi / 100) * 100
    ) / 100;
  let totalIPI =
    Math.round((produto.precoTabelaPadrao + formatValue) * 100) / 100;

  return (
    <Card
      sx={{
        height: "100%",
        marginTop: "2%",
        marginBottom: "2%",
        boxShadow: 3,
      }}
    >
      <CardActionArea
        onClick={() => props.toggle(produto.codigo)}
        sx={{ height: "100%" }}
      >
        <CardContent>
          <Grid container>
            <Grid item xs={6} md={6}>
              <Typography
                variant="body2"
                fontWeight={"bold"}
                color={Colors.primary}
                fontSize={16}
              >
                {produto.codigo}
              </Typography>
              <Typography variant="body2">{produto.nome}</Typography>
            </Grid>
            {produto.percentualIpi ? (
              <Grid item xs={6} md={6} textAlign={"right"}>
                <Typography
                  variant="body2"
                  fontWeight={"bold"}
                  color={"#808080"}
                  fontSize={16}
                >
                  IPI
                </Typography>
              </Grid>
            ) : (
              ""
            )}
          </Grid>

          <Grid container marginTop={"1rem"}>
            <Grid item xs={6} md={6}>
              <span style={{ display: "inline-flex", alignItems: "end" }}>
                <Typography
                  variant="body2"
                  color={Colors.stock}
                  fontWeight={"bold"}
                  fontSize={17}
                  mr={1}
                >
                  {totalBalance.toFixed(3).replace(".", ",")}
                </Typography>
                <Typography variant="body2" color={"gray"}>
                  {produto.unidadeMedida}
                </Typography>
              </span>
            </Grid>
            <Grid item xs={6} md={6} textAlign={"right"}>
              <span style={{ display: "inline-flex", alignItems: "end" }}>
                <Typography variant="body2" color={"gray"}>
                  R$
                </Typography>
                <Typography
                  variant="body2"
                  color={"blue"}
                  fontWeight={"bold"}
                  fontSize={17}
                  ml={1}
                >
                  {produto.percentualIpi && produto.percentualIpi !== 0
                    ? totalIPI.toFixed(2).replace(".", ",")
                    : produto.precoTabelaPadrao.toFixed(2).replace(".", ",")}
                </Typography>
              </span>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ProductCard;
