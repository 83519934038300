import { useEffect } from "react";
import { Pagination, Grid } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useGetBillingsQueriesWithParamsQuery } from "../../api/queriesApi";
import { billingParamsActions } from "../../store/queries/billings-query-params-slice";
import BillingQueryParams from "../../models/requisitions/PaginatedBillingQueryRequisition.model";
import BillingQueryResultTable from "../../components/tables/BillingQueryResultTable";
import BillingsQueryResultsTotalizerCard from "../../components/cards/queries/BillingsQueryResultsTotalizerCard";

import Colors from "../../constants/Colors";
import Spinner from "../../components/layout/Spinner";
import SearchFeedback from "../../components/feedback/SearchFeedback";

const BillingQueriesResultsPage = () => {
  const queryParams = useAppSelector((state) => state.billingQueryParams);
  const { isError, isFetching, isLoading, data } =
    useGetBillingsQueriesWithParamsQuery(queryParams);

  const dispatch = useAppDispatch();

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    dispatch(billingParamsActions.changePage(value));
  };

  useEffect(() => {
    if (!!localStorage.getItem("billingQueryParams")) {
      let newParams: any = localStorage.getItem("billingQueryParams");
      let formattedParams = JSON.parse(newParams) as BillingQueryParams;
      // console.log('formattedParams: ', formattedParams);
      dispatch(billingParamsActions.setQueryState(formattedParams));
    }
  }, [dispatch]);

  console.log(data);

  if (isLoading || isFetching) {
    return <Spinner />;
  }

  if (isError) {
    return (
      <>
        <h2>Erro ao carrgar dados dos pedidos</h2>
      </>
    );
  }

  if (data && data.notasFiscais.length === 0) {
    return (
      <>
        <SearchFeedback />
      </>
    );
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <BillingsQueryResultsTotalizerCard
            results={data ? data.notasFiscais : []}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <BillingQueryResultTable results={data ? data.notasFiscais : []} />
        </Grid>
      </Grid>
      {data && data?.totalPages > 1 && (
        <Pagination
          size="small"
          showFirstButton
          showLastButton
          page={queryParams.page}
          count={data?.totalPages}
          sx={{
            "& .MuiPaginationItem-root": {
              "&.Mui-selected": {
                background: Colors.mainLayoutAtiveBackground,
                color: "black",
              },
            },
            width: "100%",
          }}
          onChange={handleChange}
        />
      )}
    </>
  );
};

export default BillingQueriesResultsPage;
