import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CanExitPage {
  canExitSales: boolean;
  canExitClients: boolean;
}

const initialState: CanExitPage = {
  canExitClients: false,
  canExitSales: false,
};

const exitPageSlice = createSlice({
  initialState,
  name: 'exitPage',
  reducers: {
    changeCanExitSales(state: CanExitPage, action: PayloadAction<boolean>) {
      state.canExitSales = action.payload;
    },
    changeCanExitClients(state: CanExitPage, action: PayloadAction<boolean>) {
      state.canExitClients = action.payload;
    },
    resetState() {
      return {
        ...initialState,
      };
    },
  },
});

export const exitPageActions = exitPageSlice.actions;

export default exitPageSlice;
