import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';

import { useAppSelector } from '../../store/hooks';

import OrderClientDetailCard from '../cards/order/OrderClientDetailCard';
import OrderClientSelectionModal from '../modals/order/OrderClientSelectionModal';
import OrderClientAddressCard from '../cards/order/OrderClientAddressCard';

const OrderClient = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const orderClient = useAppSelector((state) => state.order.selectedClient);
  return (
    <>
      <OrderClientSelectionModal
        openModal={openModal}
        toggle={() => setOpenModal(!openModal)}
      />
      <Grid
        container
        justifyContent={'center'}
        alignItems={'center'}
        width={'100%'}
      >
        <Grid
          container
          mt={1}
          spacing={1}
          justifyContent={'center'}
          alignItems={'center'}
          width={{
            md: '40%',
            xs: '95%',
          }}
        >
          <OrderClientDetailCard toggleModal={() => setOpenModal(true)} />
          <OrderClientAddressCard />
        </Grid>
      </Grid>
    </>
  );
};

export default OrderClient;
