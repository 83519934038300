interface City {
  id: string;
  nome: string;
}

const getCityFromState = async (state: string): Promise<City[]> => {
  return new Promise<City[]>(async (resolve, reject) => {
    try {
      const cities: City[] = [];
      const response = await fetch(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios?orderBy=nome`
      );
      const resData = await response.json();
      resData.forEach((element: any) => {
        let city: City = {
          id: element.id,
          nome: element.nome,
        };
        cities.push(city);
      });
      resolve(cities);
    } catch (err) {
      reject('Erro no carregamento dos dados das cidades');
    }
  });
};

export default getCityFromState;
