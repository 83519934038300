const formatDateToProtocol = (userId: string | null): string => {
  if (!userId) return '';
  let hoje = new Date();
  let hojeAr = hoje.toISOString().split('T');
  let id: string =
    hojeAr[0].replace(new RegExp('-', 'g'), '') +
    '.' +
    hojeAr[1].substring(0, 8).replace(new RegExp(':', 'g'), '') +
    '.' +
    userId;
  return id;
};

export default formatDateToProtocol;
