import { Grid, Card, CardContent, Typography, TextField } from "@mui/material";
import Switch from "@mui/material/Switch";

import { NoteAltOutlined } from "@mui/icons-material";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import Colors from "../../../constants/Colors";
import { orderActions } from "../../../store/order/order-slice";

const OrderReserveCard = () => {
  const reserva = useAppSelector((state) => state.order.order.reserva);

  const isReadOnly = useAppSelector(
    (state) => state.readability.isOrderReadOnly
  );

  const dispatch = useAppDispatch();

  const handleReserveChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(orderActions.setReserve(ev.target.checked));
  };

  return (
    <Grid item xs={12} md={3}>
      <Card sx={{ justifyContent: "center" }}>
        <CardContent>
          <Grid
            container
            spacing={1}
            direction={"row"}
            justifyContent={"right"}
          >
            <Grid item xs={12} md={12}>
              <Typography
                sx={{
                  backgroundColor: Colors.titleRowWithBackgroundColor,
                  pl: 1,
                  pt: 0.75,
                  mt: -1,
                }}
                variant="body2"
                color={Colors.titleWithBackgroudColor}
              >
                <AccessTimeOutlinedIcon fontSize={"inherit"} /> Reserva
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="body2">Reservar pedido?</Typography>
              <Switch
                disabled={isReadOnly}
                checked={reserva}
                onChange={handleReserveChange}
                color="success"
                inputProps={{ "aria-label": "controlled" }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default OrderReserveCard;
