import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';

interface ApiUrl {
  apiUrl: string | null;
}

const initialState: ApiUrl = {
  apiUrl: localStorage.getItem('apiUrl'),
};

const apiUrlSlice = createSlice({
  name: 'apiUrl',
  initialState,
  reducers: {
    login(state: ApiUrl, action: PayloadAction<string>) {
      state.apiUrl = action.payload;
    },
    logout(state: ApiUrl) {
      state.apiUrl = null;
    },
  },
});

const selectApiUrl = (state: ApiUrl) => state.apiUrl;

export const selectUrl = createSelector(selectApiUrl, (url) => url);

export const apiUrlActions = apiUrlSlice.actions;

export default apiUrlSlice;
