interface DateTime {
  data: string;
  hora: string;
}

const formatDateToString = (): DateTime => {
  let dateString = new Date().toLocaleString();
  let dateArray = dateString.split(" ");

  return { data: dateArray[0].slice(0, -1), hora: dateArray[1] };
};

export default formatDateToString;
