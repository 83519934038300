import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  Grid,
} from '@mui/material';

import { useAppSelector } from '../../store/hooks';
import { Add, Search } from '@mui/icons-material';
import Colors from '../../constants/Colors';
import OrderSearchCard from '../../components/cards/order/OrderSearchCard';

const SalesPage = (): JSX.Element => {
  const navigate = useNavigate();
  const [initialEmissionError, setInitialEmissionError] = useState(false);
  const [finalEmissionError, setFinalEmissionError] = useState(false);

  const searchParams = useAppSelector((state) => state.orderSearch);

  const navigateToSearch = () => {
    if (initialEmissionError || finalEmissionError) return;
    const orderSearchParams = JSON.stringify(searchParams);
    localStorage.setItem('orderSearchParams', orderSearchParams);
    navigate('/vendas/pesquisar');
  };

  return (
    <>
      <Grid
        container
        justifyContent={'center'}
        alignItems={'center'}
        width={'100%'}
      >
        <Grid
          container
          mt={{ md: 1, xs: 1 }}
          spacing={1}
          justifyContent={'center'}
          alignItems={'center'}
          width={{
            md: '40%',
            xs: '95%',
          }}
        >
          <OrderSearchCard
            finalEmissionError={finalEmissionError}
            inicialEmissionError={initialEmissionError}
            toggleInicialEmissionError={(errorValue: boolean) =>
              setInitialEmissionError(errorValue)
            }
            toggleFinalEmissionError={(errorValue: boolean) =>
              setFinalEmissionError(errorValue)
            }
          />
        </Grid>
      </Grid>
      <Paper
        sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        <BottomNavigation showLabels>
          <BottomNavigationAction
            sx={{ color: Colors.primary }}
            label='Pesquisar'
            icon={<Search />}
            onClick={() => navigateToSearch()}
          />
          <BottomNavigationAction
            sx={{ color: Colors.primary }}
            label='Novo'
            icon={<Add />}
            onClick={() => navigate('/vendas/criar')}
          />
        </BottomNavigation>
      </Paper>
    </>
  );
};

export default SalesPage;
