import BillingQueryDetails from "../../models/queries/BillingQueryDetails.model";

const BILLING_QUERY_DETAILS: BillingQueryDetails = {
  cliente: {
    apelido: "",
    categoria: "",
    cnpjCpf: "",
    endereco: {
      cep: "",
      complemento: "",
      localidade: "",
      logradouro: "",
      numero: "",
      pontoReferencia: "",
      predioProprio: false,
      sublocalidade: "",
      tempoResidencia: 0,
      uf: "",
    },
    id: "",
    nome: "",
    tipoPessoaId: "F",
  },
  notaFiscal: {
    cancelamento: {
      data: "",
      hora: "",
    },
    clienteId: "",
    emissao: {
      data: "",
      hora: "",
    },
    id: "",
    itens: [],
    numeroPedido: "",
    owner: "",
    status: "",
    totais: {
      descontos: { total: 0 },
      produtos: 0,
      ipi: 0,
      frete: 0,
      outrasDespesas: 0,
      total: 0,
    },
    vendedorId: "",
  },
  produto: [],
};

export default BILLING_QUERY_DETAILS;
