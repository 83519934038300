import {
  Modal,
  Card,
  CardContent,
  Typography,
  Stack,
  Button,
} from "@mui/material";

import OrderItem from "../../../models/order/orderItem.model";

import { useAppSelector } from "../../../store/hooks";
import { useAppDispatch } from "../../../store/hooks";
import { orderActions } from "../../../store/order/order-slice";

interface Props {
  item: OrderItem;
  reference: string;
  openModal: boolean;
  toggle: () => void;
}

const OrderItemRemovalModal = (props: Props) => {
  const dispatch = useAppDispatch();

  const hasGlobalDiscount = !!useAppSelector(
    (state) => state.order.order.totais.descontos.cabecalho.total
  );

  const removeItemFromOrder = () => {
    dispatch(orderActions.removeItem(props.item));
    props.toggle();
  };

  return (
    <Modal
      open={props.openModal}
      onClose={props.toggle}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          height: hasGlobalDiscount ? "30vh" : "25vh",
          width: { md: "35%", xs: "70%" },
        }}
      >
        <CardContent>
          <Stack spacing={2}>
            <Typography variant="h6">
              Deseja realmente remover o item?
            </Typography>
            <Typography variant="body2">{props.reference}</Typography>
            {hasGlobalDiscount && (
              <Typography variant="body2">
                Ao remover o item os descontos globais serão zerados.
              </Typography>
            )}
            <Stack direction={"row"} justifyContent={"space-around"}>
              <Button
                variant="outlined"
                color="success"
                onClick={() => removeItemFromOrder()}
              >
                Sim
              </Button>
              <Button variant="outlined" color="error" onClick={props.toggle}>
                Não
              </Button>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default OrderItemRemovalModal;
