export const CHART_OPTIONS = {
  title: 'Metas x Vendas',
  vAxis: {
    format: 'currency',
  },
  seriesType: 'bars',
};

export const CHART_TOOLTIP_FORMATTERS: any = [
  {
    type: 'NumberFormat',
    column: 1,
    options: {
      groupingSymbol: '.',
      prefix: 'R$ ',
      decimalSymbol: ',',
    },
  },
  {
    type: 'NumberFormat',
    column: 2,
    options: {
      groupingSymbol: '.',
      prefix: 'R$ ',
      decimalSymbol: ',',
    },
  },
];

export const CHART_TEST_DATA = [
  ['Código', 'Vendas', 'Meta'],
  ['202112', 90000, 80000],
  ['202201', 65000, 70000],
  ['202202', 20000, 40000],
  ['202203', 60000, 55000],
  ['202204', 70000, 60000],
  ['202205', 5000, 70000],
];
