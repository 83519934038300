import { jsPDF } from "jspdf";
import "jspdf-autotable";

export function pdfSettings(
  pedidoID,
  logo,
  order,
  cliente,
  condPag,
  itemDt,
  sum,
  width,
  height
) {
  const doc = new jsPDF();
  doc.setFontSize(10);

  const dt = new Date();
  const formatCurrency = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  let logoTemplate;
  let cnpjCpf;

  if (cliente && cliente.cnpjCpf.length < 18) {
    cnpjCpf = "CPF:";
  } else {
    cnpjCpf = "CNPJ:";
  }

  const rows = [];

  const returnItemName = (id) => {
    const filteredItem = itemDt.filter((item) => item.id === id);
    return filteredItem[0].nome;
  };
  const returnItemUnt = (id) => {
    const filteredItem = itemDt.filter((item) => item.id === id);
    return filteredItem[0].unidadeMedida;
  };

  const dataRows = order.itens.map((pi) => {
    rows.push([
      pi.produtoId,
      returnItemName(pi.produtoId),
      pi.quantidade,
      returnItemUnt(pi.produtoId),
      formatCurrency.format(pi.unitarioFinal + pi.valorIpi).slice(3),
      formatCurrency.format(pi.unitario).slice(3),
      pi.valorIpi ? formatCurrency.format(pi.valorIpi).slice(3) : "-",
      formatCurrency.format(pi.totais.total).slice(3),
    ]);
  });

  const columnStyles = {
    0: {
      halign: "left",
    },
    1: {
      halign: "left",
    },
    2: {
      halign: "right",
    },
    3: {
      halign: "right",
    },
    4: {
      halign: "right",
    },
    5: {
      halign: "right",
    },
    6: {
      halign: "right",
    },
    7: {
      halign: "right",
    },
  };

  const addHeader = (doc) => {
    const pageCount = doc.internal.getNumberOfPages();

    doc.setFont(undefined, "bold");
    doc.setFontSize(9);

    for (var i = 1; i <= pageCount; i++) {
      if (logo) {
        doc.addImage(logo, "JPEG", 14, 4, 35, 14);
        doc.getImageProperties(logo);
      }

      doc.text(`PEDIDO: ${order.id}`.toUpperCase(), logo ? 105 : 14, 16, {
        align: logo ? "center" : "left",
      });

      doc.text(`Emissão: ${dt.toLocaleString()}`, 196, 16, {
        align: "right",
      });

      doc.setLineWidth(0.1);
      doc.line(14, 19, 196, 19);
    }
  };

  const addInfo = (doc) => {
    doc.setFont(undefined, "normal");
    doc.setFontSize(9);

    doc.text(`Nome: ${cliente.nome.toUpperCase()}`, 14, 25, {
      align: "left",
    });

    doc.text(
      `Endereço: ${cliente.endereco.logradouro.toUpperCase()}, ${
        cliente.endereco.numero
      } - ${cliente.endereco.sublocalidade.toUpperCase()}`,
      14,
      31,
      {
        align: "left",
      }
    );

    //* MESMA LINHA *//
    doc.text(`Cidade: ${cliente.endereco.localidade.toUpperCase()}`, 14, 37, {
      align: "left",
    });
    doc.text(
      `UF: ${cliente.endereco.uf}` + "       " + `CEP: ${cliente.endereco.cep}`,
      105,
      37,
      {
        align: "center",
      }
    );
    doc.text(`${cnpjCpf} ${cliente.cnpjCpf}`, 196, 37, {
      align: "right",
    });
    //**//

    //* MESMA LINHA *//
    doc.text(`Fone: `, 14, 43, {
      align: "left",
    });
    doc.text(`Vendedor: `, 105, 43, {
      align: "center",
    });
    doc.text(`CGF: `, 196, 43, {
      align: "right",
    });
    //**//

    doc.setLineWidth(0.1);
    doc.line(14, 47, 196, 47);
  };

  const addFooters = (doc) => {
    const pageCount = doc.internal.getNumberOfPages();

    doc.setFont(undefined, "normal");
    doc.setFontSize(8);

    doc.setLineWidth(0.1);
    doc.line(14, 284, 196, 284);

    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);

      doc.text("Página " + String(i) + " de " + String(pageCount), 14, 289, {
        align: "left",
      });
    }
  };

  doc.autoTable({
    body: [],
    startY: 47,
  });

  doc.autoTable({
    theme: "plain",
    startY: doc.lastAutoTable.finalY + 1,
    head: [
      [
        {
          content: "Código",
          styles: { halign: "left" },
        },
        {
          content: "Produto",
          styles: { halign: "left" },
        },
        {
          content: "Quantidade",
          styles: { halign: "right" },
        },
        {
          content: "U.M.",
          styles: { halign: "right" },
        },
        {
          content: "Preço + IPI",
          styles: { halign: "right" },
        },
        {
          content: "Preço",
          styles: { halign: "right" },
        },
        {
          content: "Vr. IPI",
          styles: { halign: "right" },
        },
        {
          content: "Sub Total",
          styles: { halign: "right" },
        },
      ],
    ],
    headStyles: {
      lineWidth: {
        bottom: 0.1,
      },
      lineColor: "#000",
    },
    body: rows,
    columnStyles: columnStyles,
    styles: { cellPadding: 1, fontSize: 9, marginBottom: 1 },
  });

  doc.setLineWidth(0.2);
  doc.line(
    14,
    doc.lastAutoTable.finalY + 0.6,
    196,
    doc.lastAutoTable.finalY + 0.6
  );

  doc.autoTable({
    theme: "plain",
    startY: doc.lastAutoTable.finalY + 2,
    head: [
      [
        {
          content: `Total de Itens: ${sum}`,
          styles: {
            halign: "left",
          },
        },
        {
          content: `Valor Total: ${formatCurrency
            .format(order.totais.produtos)
            .slice(3)}`,
          styles: {
            halign: "left",
          },
        },
        {
          content: `Total IPI: ${formatCurrency
            .format(order.totais.totalIpi)
            .slice(3)}`,
          styles: {
            halign: "left",
          },
        },
        {
          content: `Desconto: ${formatCurrency
            .format(order.totais.descontos.total)
            .slice(3)}`,
          styles: {
            halign: "center",
          },
        },
        {
          content: `A pagar: ${formatCurrency
            .format(order.totais.total)
            .slice(3)}`,
          styles: {
            halign: "right",
          },
        },
      ],
    ],
    styles: {
      cellPadding: 1,
      fontSize: 9,
      fontStyle: "normal",
      marginBottom: 1,
    },
  });

  doc.autoTable({
    theme: "plain",
    startY: doc.lastAutoTable.finalY + 0.2,
    head: [
      [
        {
          content: `Cond. Pagamento: ${condPag.nome}`,
          styles: {
            halign: "left",
          },
        },
      ],
    ],
    styles: {
      cellPadding: 1,
      fontSize: 9,
      fontStyle: "normal",
      marginBottom: 1,
    },
  });

  doc.autoTable({
    theme: "plain",
    startY: doc.lastAutoTable.finalY + 0.2,
    head: [
      [
        {
          content: `${order.observacao}`,
          styles: {
            halign: "left",
          },
        },
      ],
    ],
    styles: {
      cellPadding: 1,
      fontSize: 9,
      fontStyle: "normal",
      marginBottom: 1,
    },
  });

  addHeader(doc);
  addInfo(doc);
  addFooters(doc);
  doc.save(`${order.id}.pdf`);
}
