import { Modal, Card, CardContent, Grid } from "@mui/material";

import { useGetProductQuery } from "../../../api/productsApi";
import Spinner from "../../layout/Spinner";
import ProductDetailCard from "../../cards/product/ProductDetailCard";
import ProductPriceCard from "../../cards/product/ProductPriceCard";
import ProductStockCard from "../../cards/product/ProductStockCard";

interface Props {
  id: string;
  openModal: boolean;
  toggle: () => void;
}

const ProductDetailModal = (props: Props): JSX.Element => {
  const { data, isFetching, isLoading } = useGetProductQuery(props.id, {
    skip: !props.id,
  });

  if (isFetching || isLoading) {
    return <Spinner />;
  }

  console.log(data);

  return (
    <Modal
      open={props.openModal}
      onClose={props.toggle}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Card
        sx={{
          width: {
            md: "40%",
            sm: "70%",
            xs: "85%",
          },
          backgroundColor: "#FaFaFa",
        }}
      >
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <ProductDetailCard
                id={data ? data.codigo : ""}
                name={data ? data.nome : ""}
                unit={data ? data.unidadeMedida : ""}
              />
            </Grid>
            {data && data.precoProduto && data.precoProduto.length > 0 && (
              <Grid item xs={12} md={12}>
                <ProductPriceCard
                  precoproduto={data.precoProduto}
                  percentIpi={data.percentualIpi}
                />
              </Grid>
            )}
            {data && data.saldoProduto && data.saldoProduto.length > 0 && (
              <Grid item xs={12} md={12}>
                <ProductStockCard saldoproduto={data.saldoProduto} />
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default ProductDetailModal;
