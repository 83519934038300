import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { StyleSheet, css } from "aphrodite";

import formatNumberToCurrency from "../../util/formaters/formatNumberToCurrency";
import CustomGetOrder from "../../models/order/customGetOrder.model";

import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "../../styles/style.css";

interface Props {
  order: CustomGetOrder;
}

const OrderQueryResultsDetailsTable = (props: Props) => {
  const items = props.order.pedido.itens;
  const ipi = props.order.pedido.totais.totalIpi;
  const itemsDetail = props.order.produto;

  let qtde = items.length;

  const returnItemName = (id: string): string => {
    const filteredItem = itemsDetail.filter((item) => item.id === id);
    return filteredItem[0].nome;
  };

  if (items.length === 0) {
    return null;
  }

  return (
    <Table className={css(styles.table)}>
      <Thead className="table_head">
        <Tr>
          <Th>Referência</Th>
          <Th>Descrição</Th>
          <Th>Quantidade</Th>
          {ipi ? <Th>Valor IPI</Th> : ""}
          {ipi ? <Th>Pr. + IPI</Th> : ""}
          <Th>Pr. Bruto</Th>
          <Th>Desconto</Th>
          {/* <Th>Acréscimo</Th> */}
          <Th>Pr. Final</Th>
          <Th>Subtotal</Th>
        </Tr>
      </Thead>
      <Tbody>
        {items.map((item) => {
          var parity = qtde % 2;
          qtde = qtde - 1;
          return (
            <Tr key={item.id} className={parity === 1 ? "odd_row" : "even_row"}>
              <Td className="table_text">{`${item.produtoId}${
                item.grade.grade2 ? item.grade.grade2 : ""
              }${item.grade.grade3 ? item.grade.grade3 : ""}`}</Td>
              <Td className="table_text">{returnItemName(item.produtoId)}</Td>
              <Td className="table_number_cell">{item.quantidade}</Td>
              {ipi ? (
                <Td className="table_number_cell">
                  {item.valorIpi
                    ? `R$ ${item.valorIpi.toLocaleString("pt-Br", {
                        minimumFractionDigits: 2,
                      })}`
                    : "R$ 0,00"}
                </Td>
              ) : (
                ""
              )}
              {ipi ? (
                <Td className="table_number_cell">
                  {item.valorIpi
                    ? `R$ ${(item.unitario + item.valorIpi).toLocaleString(
                        "pt-Br",
                        {
                          minimumFractionDigits: 2,
                        }
                      )}`
                    : `R$ ${item.unitario.toLocaleString("pt-Br", {
                        minimumFractionDigits: 2,
                      })}`}
                </Td>
              ) : (
                ""
              )}
              <Td className="table_number_cell">{`R$ ${item.unitario.toLocaleString(
                "pt-Br",
                {
                  minimumFractionDigits: 2,
                }
              )}`}</Td>
              <Td className="table_number_cell">
                {item.totais.descontos.item.percentual
                  ? `${item.totais.descontos.item.percentual.toLocaleString(
                      "pt-Br",
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}%`
                  : formatNumberToCurrency(
                      item.totais.descontos.item.valor || 0
                    )}
              </Td>
              {/* <Td>
                  {item.totais.acrescimos.item.percentual
                    ? `${item.totais.acrescimos.item.percentual}%`
                    : formatNumberToCurrency(
                        item.totais.acrescimos.item.valor || 0
                      )}
                </Td> */}
              <Td className="table_number_cell">
                {item.unitarioFinal
                  ? formatNumberToCurrency(item.unitarioFinal)
                  : formatNumberToCurrency(item.unitario)}
              </Td>
              <Td className="table_number_cell">
                {formatNumberToCurrency(item.totais.total)}
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};

const styles = StyleSheet.create({
  table: {
    fontSize: 13,
  },
  editButton: {
    color: "#ff6600",
  },
  numberCell: {
    justifyContent: "flex-end",
  },
});

export default OrderQueryResultsDetailsTable;
