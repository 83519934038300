import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IsReadOnly {
  isClientReadOnly: boolean;
  isOrderReadOnly: boolean;
}

const initialState: IsReadOnly = {
  isClientReadOnly: true,
  isOrderReadOnly: false,
};

const readabilitySlice = createSlice({
  name: 'readability',
  initialState,
  reducers: {
    setClientReadability(state: IsReadOnly, action: PayloadAction<boolean>) {
      state.isClientReadOnly = action.payload;
    },
    setOrderReadability(state: IsReadOnly, action: PayloadAction<boolean>) {
      state.isOrderReadOnly = action.payload;
    },
    resetReadability() {
      return {
        ...initialState,
      };
    },
  },
});

export const readabilityActions = readabilitySlice.actions;

export default readabilitySlice;
