import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Button } from '@mui/material';
import { Search } from '@mui/icons-material';

import { useAppSelector } from '../../store/hooks';
import BillingsQueryParamsCard from '../../components/cards/queries/BillingsQueryParamsCard';

const BillingsQueriesFiltersPage = () => {
  const navigate = useNavigate();
  const [initialEmissionError, setInitialEmissionError] = useState(false);
  const [finalEmissionError, setFinalEmissionError] = useState(false);
  const [initialCancellationError, setInicialCancellationError] =
    useState(false);
  const [finalCancellationError, setFinalCancellationError] = useState(false);

  const queryParams = useAppSelector((state) => state.billingQueryParams);

  const navigateToResult = () => {
    const hasErrors =
      initialEmissionError ||
      finalEmissionError ||
      initialCancellationError ||
      finalCancellationError;

    if (hasErrors) return;
    console.log('entrou no navigateToResult: ', queryParams);
    const billingQueryParams = JSON.stringify(queryParams);
    localStorage.setItem('billingQueryParams', billingQueryParams);

    navigate('/consultas/faturamentos/resultado');
  };

  return (
    <Grid
      container
      justifyContent={'center'}
      alignItems={'center'}
      width={'100%'}
    >
      <Grid
        container
        spacing={2}
        justifyContent={'center'}
        alignItems={'center'}
        width={{
          md: '45%',
          xs: '95%',
        }}
      >
        <BillingsQueryParamsCard
          finalEmissionError={finalEmissionError}
          inicialEmissionError={initialEmissionError}
          finalCancellationError={finalCancellationError}
          inicialCancellationError={initialCancellationError}
          toggleInicialEmissionError={(errorValue: boolean) =>
            setInitialEmissionError(errorValue)
          }
          toggleFinalEmissionError={(errorValue: boolean) =>
            setFinalEmissionError(errorValue)
          }
          toggleInicialCancellationError={(errorValue: boolean) =>
            setInicialCancellationError(errorValue)
          }
          toggleFinalCancellationError={(errorValue: boolean) =>
            setFinalCancellationError(errorValue)
          }
        />
        <Grid item xs={12} md={12} textAlign={'center'}>
          <Button onClick={navigateToResult} variant={'outlined'}>
            <Search />
            Consultar
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BillingsQueriesFiltersPage;
