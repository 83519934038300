import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Button,
  CircularProgress,
  Grid,
  Typography,
  Alert,
  AlertTitle,
} from "@mui/material";

import {
  useMutateClientMutation,
  useGetClientQuery,
} from "../../api/clientsApi";
import { clientActions } from "../../store/client/client-slice";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { clientFormErrorActions } from "../../store/error/client-form-error-slice";
import { citiesActions } from "../../store/address/cities-slice";
import { exitPageActions } from "../../store/exitPage/exit-page-slice";
import { readabilityActions } from "../../store/readability/readability-slice";
import isAddressEmpty from "../../util/checkers/isAddressEmpty";
import getCityFromState from "../../util/getters/getCityFromState";
import usePrompt from "../../hooks/customNavigationHooks";

import Spinner from "../../components/layout/Spinner";
import Colors from "../../constants/Colors";
import Pessoa from "../../models/client/person.model";
import GeneralInformationCard from "../../components/cards/client/GeneralInformationCard";
import DocumentsCard from "../../components/cards/client/DocumentsCard";
import PhonesCard from "../../components/cards/client/PhonesCard";
import EmailsCard from "../../components/cards/client/EmailsCard";
import AddressCard from "../../components/cards/client/AddressCard";
import formatAddressToString from "../../util/formaters/formatAddressToString";
import Endereco from "../../models/address/address.model";
import NoClientFound from "../../components/feedback/NoClientFound";
import ClientMutationSuccess from "../../components/modals/client/ClientMutationSuccess";
import ClientMutationErrorModal from "../../components/modals/client/ClientMutationErrorModal";

interface StateType {
  pessoa: Pessoa;
  id: string;
  readOnly: boolean;
}

const placeholderAddress: Endereco = {
  sublocalidade: "",
  cep: "",
  localidade: "",
  codigoCidade: "",
  complemento: "",
  logradouro: "",
  numero: "",
  preenchimentoManual: false,
  uf: "",
};

const ClientManagementPage = () => {
  const location = useLocation();
  const state = location.state as StateType;
  const canExit = useAppSelector((state) => state.exitPage.canExitClients);
  usePrompt(
    `Deseja sair da página? \nAs mudanças feitas serão perdidas.`,
    !canExit
  );
  const skip = state && state.id ? false : true;
  const [isWaiting, setIsWaiting] = useState<boolean>(false);
  const [isMutationError, setIsMutationError] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const client = useAppSelector((state) => state.client);
  const errorsState = useAppSelector((state) => state.clientFormError);

  const dispatch = useAppDispatch();
  const { data, isFetching, isError, isLoading, error, isSuccess } =
    useGetClientQuery(state && state.id ? state.id : "null", { skip });
  const [mutateClient] = useMutateClientMutation();

  const resolvePageTitle = (): JSX.Element => {
    let title = "";
    if (state && state.id) {
      title = "Detalhes do Cliente";
    } else if (!state) {
      title = "";
    } else if (state && state.pessoa && state.pessoa === "F") {
      title = "Incluindo Pessoa Física";
    } else {
      title = "Incluindo Pessoa Jurídica";
    }
    return (
      <Typography variant="h5" fontWeight={"bold"}>
        {title}
      </Typography>
    );
  };

  const getCities = useCallback(
    async (uf: string): Promise<void> => {
      try {
        const response = await getCityFromState(uf);
        dispatch(citiesActions.setCities(response));
      } catch (err) {
        // console.log(err);
      }
    },
    [dispatch]
  );

  useLayoutEffect(() => {
    if (isSuccess) {
      dispatch(clientActions.setFullClient(data));
    }
  }, [isSuccess, data, dispatch]);

  useLayoutEffect(() => {
    dispatch(exitPageActions.changeCanExitClients(!(state && !state.readOnly)));
  }, [dispatch, state]);

  useLayoutEffect(() => {
    if (state && state.id && data) {
      getCities(data.endereco.padrao.uf);
    }
  }, [data, state, getCities]);

  useLayoutEffect(() => {
    if (state && state.readOnly !== undefined) {
      dispatch(readabilityActions.setClientReadability(state.readOnly));
    } else {
      dispatch(readabilityActions.setClientReadability(false));
    }
  }, [state, dispatch]);

  useEffect(() => {
    if (state && state.pessoa) {
      dispatch(clientActions.changePessoa(state.pessoa));
    }
  });

  const critiques = (): boolean => {
    const commonFields =
      !client.nome ||
      !client.categoriaId ||
      !client.fone.principal ||
      !client.endereco.padrao;
    let isCpfOrCnpjEmpty = false;
    if (state.pessoa === "F" && !client.documentacao.cnpjCpf) {
      isCpfOrCnpjEmpty = true;
    }
    if (state.pessoa === "J" && !client.documentacao.cnpjCpf) {
      isCpfOrCnpjEmpty = true;
    }
    if (commonFields || isCpfOrCnpjEmpty) {
      if (state.pessoa === "F" && !client.documentacao.cnpjCpf) {
        dispatch(clientFormErrorActions.cpfErrorSetter(true));
      }
      if (state.pessoa === "J" && !client.documentacao.cnpjCpf) {
        dispatch(clientFormErrorActions.cnpjErrorSetter(true));
      }
      if (!client.nome) {
        dispatch(clientFormErrorActions.nameErrorSetter(true));
      }
      if (!client.categoriaId) {
        dispatch(clientFormErrorActions.categoryErrorSetter(true));
      }
      if (!client.fone.principal) {
        dispatch(clientFormErrorActions.mainPhoneErrorSetter(true));
      }
      if (isAddressEmpty(client.endereco.padrao)) {
        dispatch(clientFormErrorActions.mainAddressErrorSetter(true));
      }
      return true;
    }
    return false;
  };

  const sendClientToDatabase = async (): Promise<void> => {
    setIsWaiting(true);
    if (critiques() || Object.values(errorsState).includes(true)) {
      setIsWaiting(false);
      return;
    }
    try {
      console.log(client);
      await mutateClient(client).unwrap();
      setIsWaiting(false);
      dispatch(exitPageActions.changeCanExitClients(true));
      dispatch(clientActions.resetClient());
      setOpenSuccessModal(true);
    } catch (err) {
      //console.log(err);
      setIsMutationError(true);
      setIsWaiting(false);
    }
  };

  if (!state || isError || error) {
    return (
      <>
        <Grid
          container
          width={"100%"}
          justifyContent={"right"}
          mt={1}
          spacing={1}
        >
          <Grid item xs={10} md={11}>
            <Typography variant="h1">{resolvePageTitle()}</Typography>
          </Grid>
        </Grid>
        <NoClientFound />
      </>
    );
  }

  if (isFetching || isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <ClientMutationSuccess openModal={openSuccessModal} />
      <ClientMutationErrorModal
        openModal={isMutationError}
        toggle={() => setIsMutationError(!isMutationError)}
      />
      <Grid container spacing={1} alignItems={"center"}>
        <Grid item xs={10} md={11} justifyContent={"center"}>
          {resolvePageTitle()}
        </Grid>
      </Grid>
      <Grid
        container
        width={"100%"}
        mt={{ md: 0, xs: 2 }}
        spacing={2}
        justifyContent={"center"}
      >
        <Grid item xs={12} md={12}>
          <GeneralInformationCard
            pessoa={
              state && state.id && data && data.tipoPessoaId
                ? data.tipoPessoaId
                : state.pessoa
            }
            dados={{
              apelido: state && state.id && data ? data.apelido : "",
              nome: state && state.id && data ? data.nome : "",
              categoria: state && state.id && data ? data.categoriaId : "",
              nascimento: state && state.id && data ? data.dataNascimento : "",
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DocumentsCard
            person={
              state && state.id && data && data.tipoPessoaId
                ? data.tipoPessoaId
                : state.pessoa
            }
            dados={{
              cpf: state && state.id && data ? data.documentacao.cnpjCpf : "",
              cnpj: state && state.id && data ? data.documentacao.cnpjCpf : "",
              inscricaoEstadual:
                state && state.id && data
                  ? data.documentacao.inscricaoEstadual
                  : "",
              inscricaoMunicipal:
                state && state.id && data
                  ? data.documentacao.inscricaoMunicipal
                  : "",
              rg: state && state.id && data ? data.documentacao.rg : "",
            }}
            readOnly={state && state.id && data ? true : false}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <PhonesCard
            dados={{
              telefonePrincipal:
                state && state.id && data ? data.fone.principal : "",
              telefoneSecundario:
                state && state.id && data ? data.fone.secundario : "",
              celular: state && state.id && data ? data.fone.celular : "",
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <EmailsCard
            dados={{
              emailPrincipal:
                state && state.id && data ? data.email.principal : "",
              emailComercial:
                state && state.id && data ? data.email.comercial : "",
              emailFinanceiro:
                state && state.id && data ? data.email.financeiro : "",
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <AddressCard
            enderecoEntrega={
              state &&
              state.id &&
              data &&
              !isAddressEmpty(data.endereco.entrega)
                ? formatAddressToString(data.endereco.entrega)
                : ""
            }
            enderecoPrincipal={
              state && state.id && data && !isAddressEmpty(data.endereco.padrao)
                ? formatAddressToString(data.endereco.padrao)
                : ""
            }
            enderecoPrincipalObjeto={
              state && state.id && data && data.endereco.padrao
                ? { ...data.endereco.padrao }
                : { ...placeholderAddress }
            }
            enderecoEntregaObjeto={
              state && state.id && data && data.endereco.entrega
                ? { ...data.endereco.entrega }
                : { ...placeholderAddress }
            }
          />
        </Grid>
        {state && !state.readOnly && (
          <Grid item xs={12} md={12} textAlign={"center"}>
            <Button
              variant="outlined"
              color="success"
              sx={{
                "&:disabled": {
                  color: Colors.muiPrimary,
                },
              }}
              disabled={isWaiting}
              onClick={() => sendClientToDatabase()}
            >
              {isWaiting ? <CircularProgress size={20} /> : "Confirmar"}
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ClientManagementPage;
