import { useState, useEffect } from "react";
import {
  Modal,
  Card,
  CardContent,
  Typography,
  Stack,
  Button,
  Grid,
  TextField,
} from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { orderActions } from "../../../store/order/order-slice";
import OrderItem from "../../../models/order/orderItem.model";
import ProductPriceTable from "../../../models/product/productPriceTable.model";
import formatNumberToCurrency from "../../../util/formaters/formatNumberToCurrency";
import formatDiscountValueString from "../../../util/formaters/formatDiscountValueString";
import roundAbnt from "../../../util/formaters/roundAbnt";
import { percentageOf } from "../../../util/calculations/utilCalculations";
import formatNumberToCurrencyNoSymbol from "../../../util/formaters/formatNumberToCurrencyNoSymbol";
import validateNullDiscount from "../../../util/validators/validateNullDiscount";
import { set } from "lodash";

interface Props {
  item: OrderItem;
  priceTable: ProductPriceTable;
  openModal: boolean;
  toggle: () => void;
}

type Dirty = "PERCENTAGE" | "VALUE" | null;

const OrderItemEditing = (props: Props) => {
  const { priceTable } = props;

  const hasGlobalDiscount = !!useAppSelector(
    (state) => state.order.order.totais.descontos.cabecalho.total
  );
  const item = useAppSelector((state) => state.order.itemToBeModified);
  const [itemMeasurementUnit, setItemMeasurementUnit] = useState("");
  // const [itemToBeModified, setItemToBeModified] = useState<OrderItem>(item);
  const table = priceTable;
  const [quantityString, setQuantityString] = useState(
    item.quantidade.toLocaleString()
  );
  const [quantity, setQuantity] = useState(item.quantidade);
  const unitPrice = item.unitario;
  const [discountValueString, setDiscountValueString] = useState(
    item.totais.descontos.item.valor
      ? formatNumberToCurrency(item.totais.descontos.item.valor)
      : ""
  );
  const [discountPercentageString, setDiscountPercentageString] = useState(
    item.totais.descontos.item.percentual
      ? `${item.totais.descontos.item.percentual}`
      : ""
  );
  const [increaseValueString, setIncreaseValueString] = useState(
    item.totais.acrescimos.item.valor
      ? formatNumberToCurrency(item.totais.acrescimos.item.valor)
      : ""
  );
  const [increasePercentageString, setIncreasePercentageString] = useState(
    item.totais.acrescimos.item.percentual
      ? `${item.totais.acrescimos.item.percentual}`
      : ""
  );
  const [discountValue, setDiscountValue] = useState(
    item.totais.descontos.item.valor ? item.totais.descontos.item.valor : 0
  );
  const [discountPercentage, setDiscountPercentage] = useState(
    item.totais.descontos.item.percentual
      ? item.totais.descontos.item.percentual
      : 0
  );
  const [increaseValue, setIncreaseValue] = useState(
    item.totais.acrescimos.item.valor ? item.totais.acrescimos.item.valor : 0
  );
  const [increasePercentage, setIncreasePercentage] = useState(
    item.totais.acrescimos.item.percentual
      ? item.totais.acrescimos.item.percentual
      : 0
  );
  const [dirty, setDirty] = useState<Dirty>(null);
  const [finalPriceDirty, setFinalPriceDirty] = useState(false);
  const [finalPrice, setFinalPrice] = useState(
    item.unitarioFinal ? item.unitarioFinal : 0
  );
  const [valIpi, setValIpi] = useState(item.valorIpi);
  const [finalPriceString, setFinalPriceString] = useState(
    item.unitarioFinal ? `${item.unitarioFinal}` : ""
  );
  const [finalPriceHelperText, setFinalPriceHelperText] = useState("");
  const [subtotal, setSubtotal] = useState(item.totais.total);
  const [validityErrors, setValidityErrors] = useState({
    quantityError: false,
    finalPriceError: false,
    discountValueError: false,
    discountPercentageError: false,
    increaseValueError: false,
    increasePercentageError: false,
  });

  const [discountHelperText, setDiscountHelperText] = useState("");

  const itemsDetail = useAppSelector((state) => state.order.itemsDetail);

  const dispatch = useAppDispatch();

  useEffect(() => {
    // setValIpi(item.valorIpi);
  }, []);

  useEffect(() => {
    setItemMeasurementUnit(returnItemMeasurementUnit(item.produtoId));
  }, [item, setItemMeasurementUnit]);

  // useEffect(() => {
  //   let firstSubtotal = unitPrice * quantity;
  //   if (
  //     quantity &&
  //     !discountPercentage &&
  //     !discountValue &&
  //     !increasePercentage &&
  //     !increaseValue
  //   ) {
  //     setSubtotal(quantity * unitPrice);
  //   } else if (quantity && discountPercentage) {
  //     let newValue = firstSubtotal - (firstSubtotal * discountPercentage) / 100;
  //     setSubtotal(parseFloat(newValue.toFixed(2)));
  //   } else if (quantity && discountValue) {
  //     let newValue = firstSubtotal - discountValue;
  //     setSubtotal(parseFloat(newValue.toFixed(2)));
  //   } else if (quantity && increasePercentage) {
  //     let newValue = firstSubtotal + (firstSubtotal * increasePercentage) / 100;
  //     setSubtotal(parseFloat(newValue.toFixed(2)));
  //   } else if (quantity && increaseValue) {
  //     let newValue = firstSubtotal + increaseValue;
  //     setSubtotal(parseFloat(newValue.toFixed(2)));
  //   } else {
  //     setDiscountPercentage(0);
  //     setDiscountPercentageString('');
  //     setIncreasePercentage(0);
  //     setIncreasePercentageString('');
  //     setDiscountValue(0);
  //     setDiscountValueString('');
  //     setIncreaseValue(0);
  //     setIncreaseValueString('');
  //     setSubtotal(unitPrice);
  //   }
  // }, [
  //   quantity,
  //   unitPrice,
  //   setSubtotal,
  //   discountPercentage,
  //   discountValue,
  //   increasePercentage,
  //   increaseValue,
  // ]);

  useEffect(() => {
    // setValIpi(item.valorIpi);

    if (finalPriceDirty) {
      setSubtotal(finalPrice * quantity);
      return;
    }
    let firstSubtotal = roundAbnt(quantity * unitPrice);
    if (
      quantity &&
      !discountPercentage &&
      !discountValue &&
      !increasePercentage &&
      !increaseValue
    ) {
      setSubtotal(firstSubtotal);
    } else if (quantity && discountPercentage) {
      let newPrice =
        unitPrice - roundAbnt(unitPrice * (discountPercentage / 100));
      let newValue = newPrice * quantity;
      setFinalPrice(newPrice);
      setValIpi(Math.round(newPrice * item.percentualIpi * 100) / 100);
      setFinalPriceString(formatNumberToCurrencyNoSymbol(newPrice));
      setSubtotal(roundAbnt(newValue));
    } else if (quantity && discountValue) {
      let newPrice = unitPrice - discountValue;
      let newValue = newPrice * quantity;
      setFinalPrice(newPrice);
      setValIpi(Math.round(newPrice * item.percentualIpi * 100) / 100);
      setFinalPriceString(formatNumberToCurrencyNoSymbol(newPrice));
      setSubtotal(roundAbnt(newValue));
    } else if (quantity && increasePercentage) {
      let newValue =
        firstSubtotal + roundAbnt((firstSubtotal * increasePercentage) / 100);
      setSubtotal(parseFloat(newValue.toFixed(2)));
    } else if (quantity && increaseValue) {
      let newValue = firstSubtotal + increaseValue;
      setSubtotal(parseFloat(newValue.toFixed(2)));
    } else {
      setFinalPriceDirty(false);
      setDirty(null);
      setDiscountPercentage(0);
      setDiscountPercentageString("");
      setIncreasePercentage(0);
      setIncreasePercentageString("");
      setDiscountValue(0);
      setDiscountValueString("");
      setIncreaseValue(0);
      setIncreaseValueString("");
      setSubtotal(unitPrice);
      setFinalPrice(unitPrice);
      setValIpi(Math.round(unitPrice * item.percentualIpi * 100) / 100);
      setFinalPriceString(formatNumberToCurrencyNoSymbol(unitPrice));
    }
  }, [
    quantity,
    unitPrice,
    setSubtotal,
    discountPercentage,
    discountValue,
    increasePercentage,
    increaseValue,
  ]);

  useEffect(() => {
    // console.log(item);
    setQuantityString(item.quantidade.toLocaleString());
    setQuantity(item.quantidade);
    setDiscountValue(
      item.totais.descontos.item.valor ? item.totais.descontos.item.valor : 0
    );
    setDiscountValueString(
      item.totais.descontos.item.valor
        ? item.totais.descontos.item.valor.toFixed(2).replace(".", ",")
        : ""
    );
    setDiscountPercentage(
      item.totais.descontos.item.percentual
        ? item.totais.descontos.item.percentual
        : 0
    );
    setDiscountPercentageString(
      item.totais.descontos.item.percentual
        ? `${item.totais.descontos.item.percentual.toLocaleString("pt-Br", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        : ""
    );
    setIncreaseValue(
      item.totais.acrescimos.item.valor ? item.totais.acrescimos.item.valor : 0
    );
    setIncreaseValueString(
      item.totais.acrescimos.item.valor
        ? item.totais.acrescimos.item.valor.toFixed(2).replace(".", ",")
        : ""
    );
    setIncreasePercentage(
      item.totais.acrescimos.item.percentual
        ? item.totais.acrescimos.item.percentual
        : 0
    );
    setIncreasePercentageString(
      item.totais.acrescimos.item.percentual
        ? `${item.totais.acrescimos.item.percentual}`
        : ""
    );
    setFinalPrice(item.unitarioFinal);
    setFinalPriceString(`${item.unitarioFinal}`);
    setSubtotal(item.totais.total);
  }, [item]);

  const onCancel = () => {
    setValidityErrors({
      quantityError: false,
      discountValueError: false,
      discountPercentageError: false,
      increaseValueError: false,
      increasePercentageError: false,
      finalPriceError: false,
    });
    dispatch(orderActions.resetItemToBeModified());
  };

  const handleQuantityChange = (
    ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    ev.preventDefault();
    let currErrors = { ...validityErrors };
    let newValue = ev.target.value;
    if (newValue === "") {
      currErrors.quantityError = true;
      setQuantity(0);
      setValidityErrors({ ...currErrors });
      setQuantityString("");
      return;
    }

    if (newValue.match(/^(\d+)(?:(\.|,)\d{1,3})?$/)) {
      if (currErrors.quantityError) {
        currErrors.quantityError = false;
        setValidityErrors({ ...currErrors });
      }
      if (newValue.includes(",")) {
        let valueToParse = newValue.replace(",", ".");
        setQuantity(parseFloat(valueToParse));
      } else {
        setQuantity(parseFloat(newValue));
      }
    } else {
      currErrors.quantityError = true;
      setValidityErrors({ ...currErrors });
    }
    setQuantityString(newValue);
    setValIpi(item.valorIpi);
  };

  const critiques = (): boolean => {
    const hasErrors: boolean = Object.values(validityErrors).some(
      (ele) => ele === true
    );
    if (!quantity || hasErrors) {
      return true;
    }
    return false;
  };

  const commitChanges = () => {
    if (critiques()) return;
    const modifiedItem: OrderItem = {
      grade: { ...item.grade },
      id: item.id,
      produtoId: item.produtoId,
      quantidade: quantity,
      totais: {
        acrescimos: {
          condicaoPagamento: {
            percentual: 0,
            total: 0,
            valor: 0,
          },
          item: {
            percentual: increasePercentage,
            valor: increaseValue,
            total: increasePercentage
              ? (unitPrice * quantity * increasePercentage) / 100
              : increaseValue,
          },
          total: increasePercentage
            ? (unitPrice * quantity * increasePercentage) / 100
            : increaseValue,
        },
        descontos: {
          condicaoPagamento: {
            percentual: 0,
            total: 0,
            valor: 0,
          },
          item: {
            percentual: discountPercentage,
            valor: discountValue,
            total: discountValue
              ? roundAbnt(discountValue * quantity)
              : (unitPrice - finalPrice) * quantity,
          },
          total: discountValue
            ? roundAbnt(discountValue * quantity)
            : (unitPrice - finalPrice) * quantity,
        },
        produtos: unitPrice * quantity,
        total: subtotal,
      },
      unitarioFinal: finalPrice,
      unitario: item.unitario,
      percentualIpi: item.percentualIpi,
      valorIpi: valIpi,
    };
    dispatch(orderActions.editItem(modifiedItem));
    props.toggle();
  };

  const handleFinalPriceChange = (
    ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setDirty("PERCENTAGE");
    setFinalPriceDirty(true);
    let floatValue = 0;
    let validForParsing = ev.target.value.match(/^(\d+)(?:(\.|,)\d{1,2})?$/);
    if (!validForParsing) {
      if (!validityErrors.finalPriceError) {
        let errors = { ...validityErrors };
        errors.finalPriceError = true;
        setValidityErrors({ ...errors });
      }
      setFinalPriceHelperText("Preço informado inválido");
      setFinalPriceString(ev.target.value);
      return;
    }

    floatValue = parseFloat(ev.target.value.replace(",", "."));

    if (floatValue > unitPrice) {
      if (!validityErrors.finalPriceError) {
        let errors = { ...validityErrors };
        errors.finalPriceError = true;
        setValidityErrors({ ...errors });
      }
      setFinalPriceHelperText("Preço informado superior ao preço bruto");
      setFinalPriceString(ev.target.value);
      return;
    }

    let discountInValue = unitPrice - floatValue;
    let discountInPercentage = percentageOf(discountInValue, unitPrice);

    if (discountInPercentage > table.descontoMaximoPerc) {
      if (!validityErrors.finalPriceError) {
        let errors = { ...validityErrors };
        errors.finalPriceError = true;
        setValidityErrors({ ...errors });
      }
      setFinalPriceHelperText(`O desconto concedido (${discountInPercentage}%)
      é maior que o desconto máximo da tabela(${table.descontoMaximoPerc}%)`);
      setFinalPriceString(ev.target.value);
      return;
    }

    let realDiscountPercentage = (discountInValue / unitPrice) * 100;
    realDiscountPercentage = parseFloat(realDiscountPercentage.toFixed(8));

    if (validityErrors.finalPriceError) {
      let errors = { ...validityErrors };
      errors.finalPriceError = false;
      setValidityErrors({ ...errors });
    }
    setFinalPriceHelperText("");
    setFinalPriceString(ev.target.value);
    setFinalPrice(floatValue);
    setValIpi(Math.round(floatValue * item.percentualIpi * 100) / 100);
    setDiscountValueString(formatNumberToCurrencyNoSymbol(discountInValue));
    setDiscountValue(discountInValue);
    setDiscountPercentageString(discountInPercentage.toLocaleString());
    setDiscountPercentage(realDiscountPercentage);

    return;
  };

  const handleDiscountChange = (
    ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: "percentage" | "value"
  ) => {
    setFinalPriceHelperText("");
    setFinalPriceDirty(false);
    if (type === "percentage" && discountValue) {
      setDiscountValue(0);
      setDiscountValueString("");
    }

    if (type === "value" && discountPercentage) {
      setDiscountPercentage(0);
      setDiscountPercentageString("");
    }

    if (ev.target.value === "") {
      if (type === "percentage") {
        if (validityErrors.discountPercentageError) {
          let errors = { ...validityErrors };
          errors.discountPercentageError = false;
          setValidityErrors({ ...errors });
        }
        if (!discountValue) {
          setFinalPrice(unitPrice);
          setValIpi(Math.round(unitPrice * item.percentualIpi * 100) / 100);
          setFinalPriceString(formatNumberToCurrencyNoSymbol(unitPrice));
        }
        setDiscountPercentage(0);
        setDiscountPercentageString(ev.target.value);
      } else {
        if (validityErrors.discountValueError) {
          let errors = { ...validityErrors };
          errors.discountValueError = false;
          setValidityErrors({ ...errors });
        }
        if (!discountPercentage) {
          setFinalPrice(unitPrice);
          setValIpi(Math.round(unitPrice * item.percentualIpi * 100) / 100);
          setFinalPriceString(formatNumberToCurrencyNoSymbol(unitPrice));
        }
        setDiscountValue(0);
        setDiscountValueString(ev.target.value);
      }
      setDirty(null);
      setDiscountHelperText("");
      return;
    }
    if (validateNullDiscount(ev.target.value)) {
      if (type === "percentage") {
        if (validityErrors.discountPercentageError) {
          let errors = { ...validityErrors };
          errors.discountPercentageError = false;
          setValidityErrors({ ...errors });
        }
      } else {
        if (validityErrors.discountValueError) {
          let errors = { ...validityErrors };
          errors.discountValueError = false;
          setValidityErrors({ ...errors });
        }
      }
      setFinalPrice(unitPrice);
      setValIpi(Math.round(unitPrice * item.percentualIpi * 100) / 100);
      setDiscountValue(0);
      setDiscountValueString(ev.target.value);
      setDiscountPercentage(0);
      setDiscountPercentageString(ev.target.value);
      setFinalPriceString(formatNumberToCurrencyNoSymbol(unitPrice));
      setDirty(null);
      setDiscountHelperText("");
      return;
    }

    let floatValue = 0;
    let validForParsing = ev.target.value.match(/^(\d+)(?:(\.|,)\d{1,2})?$/);

    if (validForParsing) {
      if (ev.target.value.includes(",")) {
        let newValue = ev.target.value.replace(",", ".");
        floatValue = parseFloat(newValue);
      } else {
        floatValue = parseFloat(ev.target.value);
      }
      if (type === "percentage") {
        setDirty("PERCENTAGE");
        if (floatValue > table.descontoMaximoPerc || floatValue > 100) {
          let errors = { ...validityErrors };
          errors.discountPercentageError = true;
          setDiscountPercentageString(ev.target.value);
          setValidityErrors({ ...errors });
          setDiscountHelperText(
            `O desconto informado é maior que o desconto máximo da tabela (${table.descontoMaximoPerc})%`
          );
        } else {
          if (validityErrors.discountPercentageError) {
            let errors = { ...validityErrors };
            errors.discountPercentageError = false;
            setValidityErrors({ ...errors });
          }
          setDiscountPercentage(floatValue);
          setDiscountPercentageString(ev.target.value);
          setDiscountHelperText("");
        }
      } else {
        setDirty("VALUE");
        if (
          floatValue >= roundAbnt(unitPrice) ||
          percentageOf(floatValue, roundAbnt(unitPrice)) >
            roundAbnt(table.descontoMaximoPerc)
        ) {
          let errors = { ...validityErrors };
          errors.discountValueError = true;
          setDiscountValueString(ev.target.value);
          setValidityErrors({ ...errors });
          setDiscountHelperText(
            `O desconto informado (${percentageOf(
              floatValue,
              roundAbnt(unitPrice)
            )}%) é maior que o desconto máximo da tabela (${
              table.descontoMaximoPerc
            }%)`
          );
        } else {
          if (validityErrors.discountValueError) {
            let errors = { ...validityErrors };
            errors.discountValueError = false;
            setValidityErrors({ ...errors });
          }
          setDiscountHelperText("");
          setDiscountValue(floatValue);
          setDiscountValueString(ev.target.value);
        }
      }
    } else {
      if (type === "percentage") {
        setDirty("PERCENTAGE");
        let errors = { ...validityErrors };
        errors.discountPercentageError = true;
        setDiscountPercentageString(ev.target.value);
        setValidityErrors({ ...errors });
        setDiscountHelperText("O desconto informado é inválido");
      } else {
        setDirty("VALUE");
        let errors = { ...validityErrors };
        errors.discountValueError = true;
        setDiscountValueString(ev.target.value);
        setValidityErrors({ ...errors });
        setDiscountHelperText("O desconto informado é inválido");
      }
    }
  };

  const handleIncreaseChange = (
    ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: "percentage" | "value"
  ) => {
    let errors = { ...validityErrors };

    if (ev.target.value === "") {
      if (type === "percentage") {
        if (validityErrors.increasePercentageError) {
          errors.increasePercentageError = false;
          setValidityErrors({ ...errors });
        }
        if (quantity) {
          setSubtotal(quantity * unitPrice);
        } else {
          setSubtotal(unitPrice);
        }
        setIncreasePercentage(0);
        setIncreasePercentageString(ev.target.value);
      } else {
        if (validityErrors.increaseValueError) {
          errors.increaseValueError = false;
          setValidityErrors({ ...errors });
        }
        if (quantity) {
          setSubtotal(quantity * unitPrice);
        } else {
          setSubtotal(unitPrice);
        }
        setIncreaseValue(0);
        setIncreaseValueString(ev.target.value);
      }
      return;
    }
    let floatValue = 0;
    let validForParsing = ev.target.value.match(/^(\d+)(?:(\.|,)\d{1,2})?$/);

    if (validForParsing) {
      if (ev.target.value.includes(",")) {
        let newValue = ev.target.value.replace(",", ".");
        floatValue = parseFloat(newValue);
      } else {
        floatValue = parseFloat(ev.target.value);
      }
      if (type === "percentage") {
        if (validityErrors.increasePercentageError) {
          errors.increasePercentageError = false;
          setValidityErrors({ ...errors });
        }
        setIncreasePercentage(floatValue);
        setIncreasePercentageString(ev.target.value);
      } else {
        if (validityErrors.increaseValueError) {
          errors.increaseValueError = false;
          setValidityErrors({ ...errors });
        }
        setIncreaseValue(floatValue);
        setIncreaseValueString(ev.target.value);
      }
    } else {
      if (type === "percentage") {
        errors.increasePercentageError = true;
        setIncreasePercentageString(ev.target.value);
        setValidityErrors({ ...errors });
      } else {
        errors.increaseValueError = true;
        setIncreaseValueString(ev.target.value);
        setValidityErrors({ ...errors });
      }
    }
  };

  const returnItemMeasurementUnit = (id: string): string => {
    const filteredItem = itemsDetail.filter((item) => {
      // console.log(item.id, id);
      return item.id === id;
    });
    // console.log('Filtered Item: ', filteredItem);
    if (filteredItem.length === 0) {
      return "";
    } else {
      return filteredItem[0].unidadeMedida;
    }
  };

  return (
    <Modal
      open={props.openModal}
      onClose={() => {
        onCancel();
        props.toggle();
      }}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          height: { md: hasGlobalDiscount ? "33vh" : "30vh", sx: "80vh" },
          width: { md: "50%", xs: "70%" },
        }}
      >
        <CardContent>
          <Stack spacing={2}>
            <Typography variant="h6">
              Editando o item{" "}
              {`${item.produtoId} ${item.grade.grade2} ${item.grade.grade3}`}
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={6} md={4}>
                <TextField
                  fullWidth
                  type={"text"}
                  size={"small"}
                  label={"Quantidade"}
                  value={!quantityString ? "" : quantityString}
                  error={validityErrors.quantityError}
                  onChange={(ev) => handleQuantityChange(ev)}
                  helperText={
                    validityErrors.quantityError
                      ? "Quantidade informada inválida"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  disabled
                  fullWidth
                  size={"small"}
                  label={"U.M."}
                  value={itemMeasurementUnit}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField
                  fullWidth
                  size={"small"}
                  label={"Pr.Unit"}
                  disabled
                  value={formatNumberToCurrency(unitPrice)}
                />
              </Grid>
              {!!quantity && (
                <>
                  <Grid item xs={6} md={3}>
                    <TextField
                      disabled={dirty === "VALUE"}
                      size={"small"}
                      label={"Desc %"}
                      error={validityErrors.discountPercentageError}
                      value={discountPercentageString}
                      onChange={(ev) => handleDiscountChange(ev, "percentage")}
                      helperText={discountHelperText}
                      sx={{ marginTop: 1 }}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TextField
                      disabled={dirty === "PERCENTAGE"}
                      size={"small"}
                      label={"Desc R$"}
                      error={validityErrors.discountValueError}
                      value={discountValueString}
                      onBlur={() =>
                        setDiscountValueString(
                          formatDiscountValueString(discountValueString)
                        )
                      }
                      onChange={(ev) => handleDiscountChange(ev, "value")}
                      helperText={discountHelperText}
                      sx={{ marginTop: 1 }}
                    />
                  </Grid>
                  {/* <Grid item xs={12} md={3}>
                    <TextField
                      disabled={
                        !!increaseValue ||
                        !!discountPercentage ||
                        !!discountValue
                      }
                      size={'small'}
                      label={'Acresc %'}
                      error={validityErrors.increasePercentageError}
                      value={increasePercentageString}
                      onChange={(ev) => handleIncreaseChange(ev, 'percentage')}
                      helperText={
                        validityErrors.increasePercentageError
                          ? 'Acréscimo inválido'
                          : ''
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      disabled={
                        !!increasePercentage ||
                        !!discountPercentage ||
                        !!discountValue
                      }
                      size={'small'}
                      label={'Acresc R$'}
                      error={validityErrors.increaseValueError}
                      value={increaseValueString}
                      onChange={(ev) => handleIncreaseChange(ev, 'value')}
                      helperText={
                        validityErrors.increaseValueError
                          ? 'Acréscimo inválido'
                          : ''
                      }
                    />
                  </Grid> */}
                  <Grid item xs={6} md={3}>
                    <TextField
                      error={validityErrors.finalPriceError}
                      size={"small"}
                      label={"Pr. Final R$"}
                      value={finalPriceString}
                      onChange={handleFinalPriceChange}
                      helperText={finalPriceHelperText}
                      onBlur={() =>
                        setFinalPriceString(
                          formatNumberToCurrencyNoSymbol(finalPrice)
                        )
                      }
                      sx={{ marginTop: 1 }}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TextField
                      size={"small"}
                      label={"Subtotal"}
                      disabled
                      value={formatNumberToCurrency(subtotal)}
                      sx={{ marginTop: 1 }}
                    />
                  </Grid>
                </>
              )}
            </Grid>
            {hasGlobalDiscount && (
              <Typography variant="body2">
                Ao confirmar, os descontos globais serão zerados.
              </Typography>
            )}
            <Stack direction={"row"} justifyContent={"space-around"}>
              <Button
                variant="outlined"
                color="success"
                onClick={() => commitChanges()}
              >
                Confirmar
              </Button>
              <Button
                variant="outlined"
                color="error"
                onClick={() => {
                  onCancel();
                  props.toggle();
                }}
              >
                Cancelar
              </Button>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default OrderItemEditing;
