import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Util {
  isDrawerOpen: boolean;
}

const initialState: Util = {
  isDrawerOpen: false,
};

const utilSlice = createSlice({
  name: 'util',
  initialState,
  reducers: {
    toggleIsDrawerOpen(state: Util, action: PayloadAction<boolean>) {
      state.isDrawerOpen = action.payload;
    },
  },
});

export const utilActions = utilSlice.actions;

export default utilSlice;
