import { useState, useEffect } from 'react';
import {
  Modal,
  Card,
  CardContent,
  Pagination,
  Grid,
  Typography,
  IconButton,
  TextField,
} from '@mui/material';
import { Search } from '@mui/icons-material';

import { useGetProductsQuery } from '../../../api/productsApi';

import OrderItemChoosingHelperCard from '../../cards/order/OrderItemChoosingHelperCard';
import ModalSearchFeedback from '../../feedback/ModalSearchFeedback';
import Spinner from '../../layout/Spinner';
import Colors from '../../../constants/Colors';

interface Props {
  openModal: boolean;
  addProduct: (productId: string) => Promise<void>;
  filterParam: string;
  toggle: () => void;
}

const OrderItemChoosingHelperModal = (props: Props) => {
  const { addProduct, filterParam, openModal, toggle } = props;
  const [searchValue, setSearchValue] = useState('');
  const [filter, setFilter] = useState(encodeURIComponent(filterParam));
  const [pageNumber, setPageNumber] = useState(1);
  const { isError, isFetching, isLoading, data } = useGetProductsQuery(
    {
      filter,
      pageNumber,
    },
    { skip: !openModal }
  );

  useEffect(() => {
    setPageNumber(1);
    setFilter(encodeURIComponent(filterParam));
    setSearchValue('');
  }, [openModal, setPageNumber]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNumber(value);
  };

  const renderState = () => {
    if (data && data.produtos.length > 0) {
      return data?.produtos.map((produto) => {
        return (
          <>
            <Grid item xs={12} md={12}>
              <OrderItemChoosingHelperCard
                key={produto.codigo}
                addProduct={addProduct}
                produto={produto}
              />
            </Grid>
          </>
        );
      });
    } else {
      return <ModalSearchFeedback />;
    }
  };

  if (isFetching || isLoading) {
    return (
      <Modal
        open={openModal}
        onClose={toggle}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Card sx={{ height: '70vh', width: '25%' }}>
          <CardContent>
            <Spinner />
          </CardContent>
        </Card>
      </Modal>
    );
  }
  if (isError) {
    return (
      <Modal
        open={props.openModal}
        onClose={props.toggle}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Card sx={{ height: '70vh', width: '25%' }}>
          <CardContent>
            <h2>Erro ao carregar dados</h2>
          </CardContent>
        </Card>
      </Modal>
    );
  }

  return (
    <Modal
      open={props.openModal}
      onClose={props.toggle}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Card
        sx={{
          height: '70vh',
          overflowY: 'scroll',
          width: { md: '35%', xs: '70%' },
        }}
      >
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <Typography variant='h6'>
                Produtos similares a '{filter}'
              </Typography>
            </Grid>
            <Grid item xs={10} md={10}>
              <TextField
                fullWidth
                value={searchValue}
                placeholder={'Pesquisar'}
                type='search'
                onChange={(ev) => setSearchValue(ev.target.value)}
                color='success'
                label='Pesquisar'
                size='small'
                sx={{ mb: 1 }}
                onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    setFilter(searchValue);
                    setPageNumber(1);
                  }
                }}
              />
            </Grid>
            <Grid item xs={2} md={2} textAlign={'center'}>
              <IconButton
                sx={{
                  backgroundColor: Colors.primary,
                  '&:focus': {
                    backgroundColor: Colors.primary,
                  },
                  '&:hover': {
                    backgroundColor: 'green',
                  },
                  boxShadow: 3,
                }}
                onClick={() => {
                  setFilter(searchValue);
                  setPageNumber(1);
                }}
              >
                <Search sx={{ color: 'white' }} />
              </IconButton>
            </Grid>
            {renderState()}
            <Grid item xs={12} md={12} mt={1}>
              <Pagination
                size='small'
                showFirstButton
                showLastButton
                page={pageNumber}
                count={data?.totalPages}
                sx={{
                  '& .MuiPaginationItem-root': {
                    '&.Mui-selected': {
                      background: Colors.mainLayoutAtiveBackground,
                      color: 'black',
                    },
                  },
                  width: '100%',
                }}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default OrderItemChoosingHelperModal;
