import CustomGetOrder from '../../models/order/customGetOrder.model';
import ORDER_INITIALIZER from './OrderInitializer';
import SUMMARIZED_CLIENT_INITIALIZER from './SummarizedClientInitializer';

const CUSTOM_GET_ORDER_INITIALIZER: CustomGetOrder = {
  cliente: SUMMARIZED_CLIENT_INITIALIZER,
  condicaoPagamento: {
    nome: '',
    parcelas: 0,
    tipoDocumentoTitulo: '',
  },
  fasePedido: {
    cor: '',
    nome: '',
  },
  pedido: ORDER_INITIALIZER,
  produto: [],
  situacaoPedido: {
    cor: '',
    nome: '',
    permiteEdicao: false,
  },
  tipoPedido: { nome: '' },
};

export default CUSTOM_GET_ORDER_INITIALIZER;
