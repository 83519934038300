import axios from 'axios';

import { activateFirebaseConfig } from '../../api/firebaseApi';
import { apiUrlActions } from '../api/api-url-slice';
import { authActions } from './auth-slice';

export const login = (loginData: string, suffix: string) => {
  return async (dispatch: any): Promise<void> => {
    try {
      const API_URL = await activateFirebaseConfig(suffix);
      localStorage.setItem('apiUrl', `${API_URL}/web`);
      window.apiUrl = `${API_URL}/web`;
      dispatch(apiUrlActions.login(`${API_URL}/web`));
      //console.log('Teste com lógica nova de login: ', API_URL);
      const response = await axios.post(
        `${API_URL}/app/access/login`,
        loginData,
        {
          timeout: 5000,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      );

      dispatch(
        authActions.login({
          token: response.data.token,
          id: response.data.id,
        })
      );
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('userId', response.data.id);
    } catch (err: any) {
      // console.log(err.message);
      let errorMessage = 'Erro no login';
      if (err.message && err.message === 'Network Error') {
        errorMessage = `Verifique sua conexão com a internet,
                        ou entre em contato com o administrador do sistema`;
      }
      if (
        err.message &&
        err.message === 'Request failed with status code 401'
      ) {
        errorMessage = 'Usuário ou senha incorretos';
      }
      throw new Error(errorMessage);
    }
  };
};

export const logout = () => {
  return async (dispatch: any): Promise<void> => {
    localStorage.clear();
    dispatch(apiUrlActions.logout());
    dispatch(authActions.logout());
  };
};
