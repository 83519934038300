import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  Typography,
  CardContent,
  Grid,
  Stack,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  PersonOutlineOutlined,
  CloudUploadOutlined,
  Edit,
  VisibilityOutlined,
} from "@mui/icons-material";

// import SITUACAO_PEDIDO from '../../../data/situacaoPedidoData';
import OrderSummary from "../../../models/order/orderSummary.model";

import Colors from "../../../constants/Colors";
import formatNumberToCurrency from "../../../util/formaters/formatNumberToCurrency";

interface Props {
  order: OrderSummary;
}

const OrderSummaryCard = (props: Props) => {
  const { order } = props;
  const [situationColor, setSituationColor] = useState("");
  const [situationText, setSituationText] = useState("");

  const canEditOrder = (order: OrderSummary): boolean => {
    if (!order.situacaoPedido.id && order.transmissao.data) {
      return false;
    } else {
      return order.situacaoPedido.permiteEdicao;
    }
  };

  useEffect(() => {
    if (
      !order.situacaoPedido.id &&
      !order.situacaoPedido.cor &&
      order.transmissao.data
    ) {
      setSituationColor("orange");
    }
    if (order.situacaoPedido.cor) {
      setSituationColor(order.situacaoPedido.cor);
    }

    if (!order.situacaoPedido.nome) {
      setSituationText("Não recepcionado");
    } else {
      setSituationText(order.situacaoPedido.nome);
    }
  }, [order]);

  return (
    <Grid item xs={12} md={4} key={order.id}>
      <Card
        sx={{
          height: "100%",
          // marginTop: '2%',
          // marginBottom: '2%',
          boxShadow: 3,
        }}
      >
        <CardContent>
          <Grid container width={"100%"}>
            <Grid item xs={12} md={12} mb={1}>
              <Stack
                direction={"row"}
                spacing={1}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Stack spacing={1} direction={"row"}>
                  <IconButton
                    disabled
                    sx={{
                      "&:disabled": {
                        backgroundColor: Colors.iconButtonTotalBackground,
                        color: Colors.iconButtonTotal,
                        height: 35,
                      },
                    }}
                  >
                    <PersonOutlineOutlined />
                  </IconButton>
                  <Stack>
                    <Typography variant="body2">
                      {order.cliente.apelido}
                    </Typography>
                    <Typography variant="body2" color={"gray"}>
                      {order.cliente.nome} - {order.cliente.id}
                    </Typography>
                  </Stack>
                </Stack>
                <Grid>
                  {order.totalIpi ? (
                    <Grid item xs={6} md={6} textAlign={"right"}>
                      <Typography
                        variant="body2"
                        fontWeight={"bold"}
                        color={"#808080"}
                        fontSize={16}
                      >
                        IPI
                      </Typography>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              </Stack>
            </Grid>
            <Grid item xs={12} md={12} mb={1} pb={1}>
              <Stack direction={"row"} spacing={1} alignItems={"center"}>
                <IconButton
                  disabled
                  sx={{
                    "&:disabled": {
                      backgroundColor:
                        order.situacaoPedido.id === "0"
                          ? Colors.iconButtonDiscountsBackground
                          : Colors.iconButtonTotalBackground,
                      color:
                        order.situacaoPedido.id === "0"
                          ? Colors.iconButtonDiscounts
                          : Colors.iconButtonTotal,
                    },
                  }}
                >
                  <CloudUploadOutlined />
                </IconButton>
                <Stack>
                  {order.transmissao.data && order.transmissao.hora && (
                    <Typography variant="body2" color={"gray"}>
                      Transmitido em {order.transmissao.data}{" "}
                      {order.transmissao.hora}
                    </Typography>
                  )}
                  <Typography variant="body2" color={situationColor}>
                    {situationText}
                  </Typography>
                  {order.fasePedido.id && (
                    <Typography
                      variant="body2"
                      color={order.fasePedido.cor ? order.fasePedido.cor : ""}
                    >
                      Fase do pedido -{" "}
                      {order.fasePedido.nome ? order.fasePedido.nome : ""}
                    </Typography>
                  )}
                </Stack>
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              borderTop={1}
              borderBottom={1}
              pt={1}
              pb={1}
              borderColor={"gray"}
            >
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography variant="body2">{order.emissao.data}</Typography>
                <Typography variant="body2" fontWeight={"Bold"}>
                  {formatNumberToCurrency(order.totalPedido)}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={12}>
              <Stack mt={1} direction={"row"} justifyContent={"right"}>
                <Stack
                  direction={"row"}
                  width={"15%"}
                  justifyContent={
                    !canEditOrder(order) ? "center" : "space-between"
                  }
                >
                  <Stack>
                    <Tooltip arrow title="Ir para detalhes">
                      <Link
                        style={{
                          textDecoration: "none",
                          display: "flex",
                          alignItems: "center",
                        }}
                        to={`/vendas/detalhes`}
                        state={{
                          id: order.id,
                          readOnly: true,
                        }}
                      >
                        <VisibilityOutlined color="action" fontSize="inherit" />
                      </Link>
                    </Tooltip>
                  </Stack>
                  {canEditOrder(order) && (
                    <Stack>
                      <Tooltip arrow title={"Editar cliente"}>
                        <Link
                          style={{
                            textDecoration: "none",
                            display: "flex",
                            alignItems: "center",
                          }}
                          to={`/vendas/detalhes`}
                          state={{
                            id: order.id,
                            readOnly: false,
                          }}
                        >
                          <Edit htmlColor="#ff6600" fontSize="inherit" />
                        </Link>
                      </Tooltip>
                    </Stack>
                  )}
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default OrderSummaryCard;
