import {
  Card,
  Typography,
  CardContent,
  CardActionArea,
  Grid,
} from '@mui/material';
import { PersonOutline } from '@mui/icons-material';

import { useAppSelector } from '../../../store/hooks';

import Colors from '../../../constants/Colors';
// import { Client } from '../../../models/client.model';

interface Props {
  toggleModal: () => void;
}

const OrderClientDetailCard = (props: Props) => {
  const selectedClient = useAppSelector((state) => state.order.selectedClient);
  // const isReadOnly = !useAppSelector(
  //   (state) => state.order.orderSituation.permiteEdicao
  // );

  const isReadOnly = useAppSelector(
    (state) => state.readability.isOrderReadOnly
  );

  return (
    <Grid item xs={12} md={12}>
      <Card>
        {isReadOnly ? (
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <Typography
                  sx={{
                    backgroundColor: Colors.titleRowWithBackgroundColor,
                    pl: 1,
                    pt: 0.75,
                    mt: -1,
                  }}
                  variant='body2'
                  color={Colors.titleWithBackgroudColor}
                >
                  <PersonOutline fontSize={'inherit'} /> Cliente
                </Typography>
              </Grid>
              {selectedClient && (
                <Grid item xs={12} md={12}>
                  <Typography variant='h6'>{selectedClient.apelido}</Typography>
                  <Typography variant='body2' color={'gray'}>
                    {selectedClient.nome}
                  </Typography>
                  <Typography variant='body2' color={'gray'}>
                    {selectedClient.id}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </CardContent>
        ) : (
          <CardActionArea onClick={props.toggleModal}>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                  <Typography
                    sx={{
                      backgroundColor: Colors.titleRowWithBackgroundColor,
                      pl: 1,
                      pt: 0.75,
                      mt: -1,
                    }}
                    variant='body2'
                    color={Colors.titleWithBackgroudColor}
                  >
                    <PersonOutline fontSize={'inherit'} /> Cliente
                  </Typography>
                </Grid>
                {selectedClient && (
                  <Grid item xs={12} md={12}>
                    <Typography variant='h6'>
                      {selectedClient.apelido}
                    </Typography>
                    <Typography variant='body2' color={'gray'}>
                      {selectedClient.nome}
                    </Typography>
                    <Typography variant='body2' color={'gray'}>
                      {selectedClient.id}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </CardActionArea>
        )}
      </Card>
    </Grid>
  );
};

export default OrderClientDetailCard;
