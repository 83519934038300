import {
  Card,
  Typography,
  CardContent,
  CardActionArea,
  Grid,
  Stack,
  IconButton,
} from '@mui/material';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';

import { useAppSelector } from '../../../store/hooks';

import Colors from '../../../constants/Colors';

interface Props {
  toggleModal: () => void;
}

const OrderPaymentConditionSelectionCard = (props: Props) => {
  const selectedPaymentCondition = useAppSelector(
    (state) => state.order.selectedPaymentCondition
  );
  // const isReadOnly = !useAppSelector(
  //   (state) => state.order.orderSituation.permiteEdicao
  // );

  const isReadOnly = useAppSelector(
    (state) => state.readability.isOrderReadOnly
  );

  const hasCondition = !!selectedPaymentCondition.id;

  return (
    <Grid item xs={12} md={12} mt={1}>
      <Card>
        {isReadOnly && hasCondition ? (
          <CardContent>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <IconButton
                disabled
                sx={{
                  '&:disabled': {
                    backgroundColor: Colors.iconButtonTotalBackground,
                    color: Colors.iconButtonTotal,
                  },
                }}
              >
                <PointOfSaleIcon />
              </IconButton>
              <Stack>
                <Typography variant='body2' color={'gray'} fontWeight={'Bold'}>
                  Pagamento
                </Typography>
                <Typography variant='body2' color={Colors.primary}>
                  {selectedPaymentCondition.nome}
                </Typography>
                <Typography variant='body2' fontWeight={'Bold'}>
                  {selectedPaymentCondition.tipoDocumentoTitulo.nome.toUpperCase()}
                </Typography>
                <Typography variant='body2'>
                  Parcelado em {selectedPaymentCondition.parcelas}x
                </Typography>
              </Stack>
            </Stack>
          </CardContent>
        ) : (
          <CardActionArea onClick={props.toggleModal}>
            <CardContent>
              {hasCondition ? (
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                  <IconButton
                    disabled
                    sx={{
                      '&:disabled': {
                        backgroundColor: Colors.iconButtonTotalBackground,
                        color: Colors.iconButtonTotal,
                      },
                    }}
                  >
                    <PointOfSaleIcon />
                  </IconButton>
                  <Stack>
                    <Typography
                      variant='body2'
                      color={'gray'}
                      fontWeight={'Bold'}
                    >
                      Pagamento
                    </Typography>
                    <Typography variant='body2' color={Colors.primary}>
                      {selectedPaymentCondition.nome}
                    </Typography>
                    <Typography variant='body2' fontWeight={'Bold'}>
                      {selectedPaymentCondition.tipoDocumentoTitulo.nome.toUpperCase()}
                    </Typography>
                    <Typography variant='body2'>
                      Parcelado em {selectedPaymentCondition.parcelas}x
                    </Typography>
                  </Stack>
                </Stack>
              ) : (
                <Stack
                  direction={'row'}
                  spacing={1}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <IconButton
                    disabled
                    sx={{
                      '&:disabled': {
                        backgroundColor: Colors.iconButtonDiscountsBackground,
                        color: Colors.iconButtonDiscounts,
                      },
                    }}
                  >
                    <ReportProblemOutlinedIcon />
                  </IconButton>
                  <Typography
                    variant='body1'
                    fontWeight={'Bold'}
                    color={'GrayText'}
                  >
                    Selecione uma forma de pagamento
                  </Typography>
                </Stack>
              )}
            </CardContent>
          </CardActionArea>
        )}
      </Card>
    </Grid>
  );
};

export default OrderPaymentConditionSelectionCard;
