function roundAbnt(nValor: any) {
  const nDecimais = 2;
  var nRetorno = nValor;
  let spl = nValor.toString().split('.');
  var cDecimais = spl[1];
  var nSubsequente = nDecimais;

  if (nDecimais < 1) {
    return parseInt(nRetorno);
  }

  if (!cDecimais || cDecimais.length <= nDecimais) {
    return parseFloat(nRetorno);
  }

  //Se a casa decimal SUBSEQUENTE for DIFERENTE de 5
  if (
    cDecimais.substr(nSubsequente, 1) > '5' ||
    cDecimais.substr(nSubsequente, 1) < '5'
  ) {
    nRetorno = nRetorno.toFixed(2); //Math.round((nRetorno + 0.00001) * 100) / 100; //ARREDONDA
  }
  //Se a casa decimal SUBSEQUENTE for IGUAL a 5
  else if (cDecimais.substr(nSubsequente, 1) === '5') {
    //Se a casa decimal que será CONSERVADA, for IMPAR
    if (cDecimais.substr(nDecimais - 1, 1) % 2 !== 0) {
      nRetorno = nRetorno.toFixed(2); //Math.round((nRetorno + 0.00001) * 100) / 100; //ARREDONDA
    }
    //Se a casa decimal que será CONSERVADA, for PAR
    //Se APÓS a casa decimal SUBSEQUENTE, houver ALGUM algarismo MAIOR que ZERO
    else if (cDecimais.substr(nSubsequente + 1, 1) > 0) {
      nRetorno = nRetorno.toFixed(2); //Math.round((nRetorno + 0.00001) * 100) / 100; //ARREDONDA
    }
    //Se APÓS a casa decimal SUBSEQUENTE, não houver NENHUM outro algarismo ou TODOS forem iguais a ZERO
    else {
      //TRUNCA (Esse é o único momento em que o "arredondamento ABNT" se diferencia do "arredondamento normal")
      nRetorno = Truncate(nValor, nDecimais);
    }
  }
  return parseFloat(nRetorno);
}

export function roundAbnt8Places(nValor: any) {
  const nDecimais = 8;
  var nRetorno = nValor;
  let spl = nValor.toString().split('.');
  var cDecimais = spl[1];
  var nSubsequente = nDecimais;

  if (nDecimais < 1) {
    return parseInt(nRetorno);
  }

  if (!cDecimais || cDecimais.length <= nDecimais) {
    return parseFloat(nRetorno);
  }

  //Se a casa decimal SUBSEQUENTE for DIFERENTE de 5
  if (
    cDecimais.substr(nSubsequente, 1) > '5' ||
    cDecimais.substr(nSubsequente, 1) < '5'
  ) {
    nRetorno = nRetorno.toFixed(2); //Math.round((nRetorno + 0.00001) * 100) / 100; //ARREDONDA
  }
  //Se a casa decimal SUBSEQUENTE for IGUAL a 5
  else if (cDecimais.substr(nSubsequente, 1) === '5') {
    //Se a casa decimal que será CONSERVADA, for IMPAR
    if (cDecimais.substr(nDecimais - 1, 1) % 2 !== 0) {
      nRetorno = nRetorno.toFixed(2); //Math.round((nRetorno + 0.00001) * 100) / 100; //ARREDONDA
    }
    //Se a casa decimal que será CONSERVADA, for PAR
    //Se APÓS a casa decimal SUBSEQUENTE, houver ALGUM algarismo MAIOR que ZERO
    else if (cDecimais.substr(nSubsequente + 1, 1) > 0) {
      nRetorno = nRetorno.toFixed(2); //Math.round((nRetorno + 0.00001) * 100) / 100; //ARREDONDA
    }
    //Se APÓS a casa decimal SUBSEQUENTE, não houver NENHUM outro algarismo ou TODOS forem iguais a ZERO
    else {
      //TRUNCA (Esse é o único momento em que o "arredondamento ABNT" se diferencia do "arredondamento normal")
      nRetorno = Truncate(nValor, nDecimais);
    }
  }
  return parseFloat(nRetorno);
}

function Truncate(nValor: any, nDecimais: number) {
  var nRetorno = nValor;
  let spl = nValor.toString().split('.');
  var cDecimais = spl[1];

  if (nDecimais < 1) {
    return parseInt(nRetorno);
  }

  if (cDecimais.length <= nDecimais) {
    return nRetorno;
  }

  //Pega a parte inteira do número e concatena com a substring sem alterar, pois é PAR e vai manter!
  nRetorno = parseInt(nValor.toString()) + '.' + cDecimais.substr(0, nDecimais);
  nRetorno = parseFloat(nRetorno);

  return nRetorno;
}

export default roundAbnt;
