import { useEffect, useState } from "react";
import { Card, CardContent, Typography, Stack } from "@mui/material";

import { useAppSelector } from "../../../store/hooks";
import formatNumberToCurrency from "../../../util/formaters/formatNumberToCurrency";

const OrderTotalsCard = () => {
  const itemsQuantity = useAppSelector(
    (state) => state.order.order.itens
  ).length;
  const orderTotal = useAppSelector((state) => state.order.order.totais.total);

  const itemsAmount = useAppSelector((state) => state.order.order.itens);
  const itemUnit = useAppSelector((state) => state.order.itemsDetail);

  let totalUnd: any = [];
  let undAnt: any = String;
  const valoresTabela: any = [];

  itemUnit.forEach((element: any) => {
    if (undAnt === "" || element.id !== undAnt) {
      undAnt = element.id;
      totalUnd.push({
        produto: element.id,
        unidade: element.unidadeMedida,
        qntd: 0,
      });
    }
  });

  itemsAmount.forEach((element: any) => {
    totalUnd.forEach((und: any) => {
      if (und.produto === element.produtoId) {
        und.qntd = und.qntd + element.quantidade;
      }
    });
  });

  //Gera o array de objetos pronto para ser lançado na tela, com unidade e quantidade total
  totalUnd.forEach((element: any, index: any) => {
    const map: any = [];

    totalUnd.forEach((result: any) => {
      if (result.unidade === totalUnd[index].unidade) {
        map.push(result);
      }
    });

    valoresTabela.push({
      unidade: element.unidade,
      qntd: map.map(amount).reduce(sum),
    });
  });
  //

  //Filtra valores repetidos no array de objetos
  let valoresFiltrados = valoresTabela.reduce((unique: any, o: any) => {
    if (
      !unique.some(
        (obj: any) => obj.unidade === o.unidade && obj.qntd === o.qntd
      )
    ) {
      unique.push(o);
    }
    return unique;
  }, []);
  //

  function amount(item: any) {
    return item.qntd;
  }
  function sum(prev: any, next: any) {
    return prev + next;
  }

  return (
    <Card sx={{ boxShadow: 2 }}>
      <CardContent
        sx={{
          mt: -0.5,
          padding: 0.5,
          "&:last-child": {
            paddingBottom: 0.25,
          },
        }}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
        >
          <Typography variant="body2" fontWeight={"Bold"}>
            Total de itens: {itemsQuantity}
          </Typography>
          <Typography variant="body2" fontWeight={"Bold"}>
            Subtotal do pedido: {formatNumberToCurrency(orderTotal)}
          </Typography>
        </Stack>

        <Stack
          direction={"row"}
          justifyContent={"left"}
          alignItems={"left"}
          width={"100%"}
          style={{ marginTop: 10 }}
        >
          <Typography
            variant="body2"
            fontWeight={"Bold"}
            style={{ marginRight: 5 }}
          >
            Total
          </Typography>
          {valoresFiltrados.map((result: any) => {
            return (
              <Typography
                variant="body2"
                fontWeight={"Bold"}
                style={{ marginRight: 10 }}
              >
                {result.unidade}: {result.qntd}
              </Typography>
            );
          })}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default OrderTotalsCard;
