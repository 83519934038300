import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import TenantConfig from '../../models/tenant/tentantConfig.model';

const initialState: TenantConfig = {
  grade: {
    nomeGrade2: '',
    nomeGrade3: '',
    somenteNumerico: true,
    tamanhoMaximoGrade2: 6,
    tamanhoMaximoGrade3: 2,
  },
  pedidoVenda: {
    aceitaItemDuplicado: false,
    ocultarDesconto: false,
    tipoPedidoPadraoId: '',
    tabelaPrecoPadraoId: '',
    situacaoPedidoInicial: '',
    ocultarProdutoSemSaldo: true,
    ocultarQuantidadeTotal: false,
    aceitaMultiplasCondicoesPagamento: true,
  },
  cadastroCliente: {
    campos: {},
    permiteEdicao: true,
    permiteCadastro: true,
  },
  cadastroProduto: {
    campos: {},
  },
  nomeSistemaIntegrado: '',
};

const tenantSlice = createSlice({
  name: 'tenant',
  initialState,
  reducers: {
    setTenantConfig(state: TenantConfig, action: PayloadAction<TenantConfig>) {
      state.cadastroCliente = { ...action.payload.cadastroCliente };
      state.cadastroProduto = { ...action.payload.cadastroProduto };
      state.grade = { ...action.payload.grade };
      state.nomeSistemaIntegrado = action.payload.nomeSistemaIntegrado;
      state.pedidoVenda = { ...action.payload.pedidoVenda };
    },
  },
});

export const tenantActions = tenantSlice.actions;

export default tenantSlice;
