import { Modal, Alert, AlertTitle, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface Props {
  openModal: boolean;
  isRascunho: boolean;
}

const OrderSaveSuccessModal = (props: Props) => {
  const navigate = useNavigate();

  const handleEnterPress = (ev: React.KeyboardEvent<HTMLDivElement>) => {
    if (ev.key === 'Enter') {
      navigate('/vendas');
    }
  };

  return (
    <Modal
      open={props.openModal}
      onClose={() => navigate('/vendas')}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onKeyPress={(ev) => handleEnterPress(ev)}
    >
      <Alert
        sx={{
          width: { md: '35%', sm: '65%', xs: '80%' },
          alignItems: 'center',
        }}
        action={<Button onClick={() => navigate('/vendas')}>Ok</Button>}
      >
        <AlertTitle>Sucesso</AlertTitle>
        {props.isRascunho
          ? 'Rascunho salvo com sucesso'
          : 'Pedido realizado com sucesso'}
      </Alert>
    </Modal>
  );
};

export default OrderSaveSuccessModal;
