import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { tenantActions } from '../../store/tenant/tenant-slice';
import { useGetTenantConfigQuery } from '../../api/tenantConfigApi';
import { useAppSelector, useAppDispatch } from '../../store/hooks';

const AuthContainer = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { data } = useGetTenantConfigQuery();
  const isAuthenticated = useAppSelector((state) => state.auth.token);
  const configTenant = useAppSelector(
    (state) => state.tenantConfiguration.pedidoVenda.tabelaPrecoPadraoId
  );
  const hasApiUrl = localStorage.getItem('apiUrl');
  // useEffect(() => {
  //   console.log('data no auth container: ', data);
  // }, [data]);

  useEffect(() => {
    if (configTenant === '' && data) {
      // console.log('entrou no use Effect do auth container');
      dispatch(tenantActions.setTenantConfig(data));
    }
  }, [configTenant, data, dispatch]);

  if (!isAuthenticated || !hasApiUrl) {
    localStorage.clear();
    return <Navigate to={'/'} replace />;
  }
  return children;
};

export default AuthContainer;
