import { Box } from "@mui/material";

import ivitech_logo_big from "../../assets/imgs/ivivenda_titulo_big.png";

const MainPage = (): JSX.Element => {
  return (
    <>
      <Box
        width={"100%"}
        height={"60vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box
          component={"img"}
          src={ivitech_logo_big}
          alt={"Logo IVIVenda"}
          width={{ xs: "90%", sm: "50%", md: "auto" }}
        />
      </Box>
    </>
  );
};

export default MainPage;
