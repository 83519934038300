const formatDiscountValueString = (value: string) => {
  if (!value) {
    return '';
  }
  const newValue = parseFloat(value.replace(',', '.'));
  // console.log(value, newValue);
  return newValue.toFixed(2).replace('.', ',');
};

export default formatDiscountValueString;
