import { Modal, Alert, AlertTitle, Button } from '@mui/material';

interface Props {
  openModal: boolean;
  toggle: () => void;
}

const ClientMutationErrorModal = (props: Props) => {
  const { openModal, toggle } = props;

  const handleEnterPress = (ev: React.KeyboardEvent<HTMLDivElement>) => {
    if (ev.key === 'Enter') {
      toggle();
    }
  };

  return (
    <Modal
      open={openModal}
      onClose={toggle}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onKeyPress={(ev) => handleEnterPress(ev)}
    >
      <Alert
        sx={{
          width: { md: '35%', sm: '65%', xs: '80%' },
          alignItems: 'center',
        }}
        action={
          <Button variant='outlined' color='error' onClick={toggle}>
            Ok
          </Button>
        }
        onClose={toggle}
        severity='error'
      >
        <AlertTitle>Erro</AlertTitle>
        Houve um erro ao alterar/salvar cliente.{'\n'}
        Verifique sua conexão com a internet ou entre em contato com o
        administrador do sistema
      </Alert>
    </Modal>
  );
};

export default ClientMutationErrorModal;
