import _ from 'lodash';

import Endereco from '../../models/address/address.model';

const isAddressEmpty = (address: Endereco): boolean => {
  const valuesArray = _.values(address);
  const isArrayEmpty = valuesArray.every((element) => !element);
  return isArrayEmpty;
};

export default isAddressEmpty;
