import { useState } from "react";
import { Tooltip, Stack, IconButton, Typography } from "@mui/material";
import { VisibilityOutlined } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { StyleSheet, css } from "aphrodite";

import { useAppSelector } from "../../store/hooks";
import OrderSummary from "../../models/order/orderSummary.model";
import OrderQueryTooltip from "../helpers/OrderQueryTooltip";
import formatNumberToCurrencyNoSymbol from "../../util/formaters/formatNumberToCurrencyNoSymbol";

import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "../../styles/style.css";
import OrderQueryDetailsModal from "../modals/queries/OrderQueryDetailsModal";
import { orderBy } from "lodash";

interface Props {
  orders: OrderSummary[];
}

const OrderQueryResultTable = (props: Props) => {
  const { orders } = props;
  const configTenant = useAppSelector((state) => state.tenantConfiguration);

  const [openModal, setOpenModal] = useState(false);
  const [modalId, setModalId] = useState("");

  const zeroCurrency = "0,00";

  let qtde = orders.length;

  const handleModalToggle = (
    ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    id: string
  ) => {
    ev.preventDefault();
    setModalId(id);
    setOpenModal(true);
  };

  if (orders.length === 0) {
    return null;
  }

  console.log(orders);

  return (
    <>
      <OrderQueryDetailsModal
        openModal={openModal}
        orderId={modalId}
        toggle={() => setOpenModal(!openModal)}
      />
      <Table className={css(styles.table)}>
        <Thead className="table_head">
          <Tr>
            <Th>Pedido IVIVenda</Th>
            <Th>Pedido {configTenant.nomeSistemaIntegrado}</Th>
            <Th>Emissão</Th>
            <Th>Previsão</Th>
            <Th>Cliente</Th>
            <Th>Status</Th>
            <Th>Vr. Bruto</Th>
            <Th>% Desc</Th>
            <Th>Vr. Desc</Th>
            <Th>Vr. Líquido</Th>
            <Th>IPI</Th>
            <Th>Ação</Th>
          </Tr>
        </Thead>
        <Tbody>
          {orders.map((order) => {
            var parity = qtde % 2;
            qtde = qtde - 1;
            return (
              <Tr
                key={order.id}
                className={parity === 1 ? "odd_row" : "even_row"}
              >
                <Td className="table_number_cell">{order.id}</Td>
                <Td className="table_number_cell">
                  {order.pedidoErp ? order.pedidoErp : " "}
                </Td>
                <Td className="table_actions">{order.emissao.data}</Td>
                <Td className="table_actions">{order.previsao.data}</Td>
                <Td className="table_text">{order.cliente.nome}</Td>
                <Td>
                  <Stack
                    direction={"row"}
                    width={"100%"}
                    height={"100%"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    pl={0.5}
                    pr={0.5}
                  >
                    <Stack>
                      <Tooltip
                        arrow
                        title={
                          order.situacaoPedido.nome
                            ? order.situacaoPedido.nome
                            : "Não recepcionado"
                        }
                      >
                        <div>
                          <IconButton
                            disabled
                            sx={{
                              "&:disabled": {
                                fontSize: 10,
                                backgroundColor: order.situacaoPedido.cor
                                  ? order.situacaoPedido.cor
                                  : "orange",
                                width: "40%",
                                height: "40%",
                              },
                            }}
                          />
                        </div>
                      </Tooltip>
                    </Stack>
                    {order.fasePedido.nome && (
                      <Stack>
                        <Tooltip arrow title={order.fasePedido.nome}>
                          <div>
                            <IconButton
                              disabled
                              sx={{
                                "&:disabled": {
                                  fontSize: 10,
                                  backgroundColor: order.fasePedido.cor
                                    ? order.fasePedido.cor
                                    : "black",
                                  width: "40%",
                                  height: "40%",
                                },
                              }}
                            />
                          </div>
                        </Tooltip>
                      </Stack>
                    )}
                  </Stack>
                </Td>
                <Td className="table_number_cell">
                  {order.totalBruto
                    ? formatNumberToCurrencyNoSymbol(order.totalBruto)
                    : zeroCurrency}
                </Td>
                <Td className="table_number_cell">
                  {order.descontoPerc
                    ? formatNumberToCurrencyNoSymbol(order.descontoPerc)
                    : zeroCurrency}
                </Td>
                <Td className="table_number_cell">
                  <Tooltip arrow title={<OrderQueryTooltip />}>
                    <Typography
                      fontSize={13}
                      color={
                        (order.descontoCabecalho && !order.descontoItens) ||
                        !order.descontoGeral
                          ? "black"
                          : order.descontoItens && !order.descontoCabecalho
                          ? "blue"
                          : "red"
                      }
                    >
                      {order.descontoGeral
                        ? formatNumberToCurrencyNoSymbol(order.descontoGeral)
                        : zeroCurrency}
                    </Typography>
                  </Tooltip>
                </Td>
                <Td className="table_number_cell">
                  {formatNumberToCurrencyNoSymbol(order.totalPedido)}
                </Td>
                <Td className="table_actions">
                  {order.totalIpi ? (
                    <CheckIcon color="success" fontSize="inherit" />
                  ) : (
                    "-"
                  )}
                </Td>
                <Td className="table_actions">
                  <Stack
                    direction={"row"}
                    height={"100%"}
                    width={"100%"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Tooltip arrow title="Ir para detalhes">
                      <a
                        href="#"
                        onClick={(ev) => handleModalToggle(ev, order.id)}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <VisibilityOutlined color="action" fontSize="inherit" />
                      </a>
                    </Tooltip>
                  </Stack>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </>
  );
};

const styles = StyleSheet.create({
  table: {
    fontSize: 13,
    display: "table",
  },
  editButton: {
    color: "#ff6600",
  },
  numberCell: {
    justifyContent: "flex-end",
  },
});

export default OrderQueryResultTable;
