import OrderSearch from '../../models/requisitions/PaginatedOrderSearchRequisition.model';

const ORDER_SEARCH_INITIALIZER: OrderSearch = {
  page: 1,
  cliente: '',
  emissaoFinal: '',
  emissaoInicial: '',
  pedidoId: '',
  rascunho: false,
};

export default ORDER_SEARCH_INITIALIZER;
