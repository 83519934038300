import { Typography, Grid } from '@mui/material';
import { SentimentVeryDissatisfied } from '@mui/icons-material';

import Colors from '../../constants/Colors';

const ModalSearchFeedback = () => {
  return (
    <Grid
      container
      width={'100%'}
      height={'50vh'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Grid
        item
        xs={12}
        md={12}
        height={{ md: '20%', xs: '50%' }}
        display={'inline-flex'}
        textAlign={'center'}
        justifyContent={'center'}
        alignItems={'center'}
        bgcolor={'lightyellow'}
        borderRadius={5}
        boxShadow={1}
        color={Colors.noResultFont}
      >
        <Typography>
          Sua pesquisa não retornou nenhum resultado{'\xa0'}
        </Typography>
        <Typography>
          <SentimentVeryDissatisfied />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ModalSearchFeedback;
