import Order from '../../../models/order/order.model';
import roundAbnt from '../../formaters/roundAbnt';

interface IncreaseData {
  value: number;
  type: 'PERCENTAGE' | 'VALUE';
}

const globalIncreaseChange = (
  order: Order,
  increaseData: IncreaseData
): Order => {
  if (increaseData.type === 'PERCENTAGE') {
    order.totais.acrescimos.cabecalho.valor = 0;
    order.totais.acrescimos.cabecalho.percentual = increaseData.value;
    const globalIncrease = roundAbnt(
      (order.totais.produtos * increaseData.value) / 100
    );
    order.totais.acrescimos.cabecalho.total = globalIncrease;

    if (order.totais.acrescimos.itens.total) {
      order.totais.acrescimos.total =
        order.totais.acrescimos.cabecalho.total +
        order.totais.acrescimos.itens.total;
    } else {
      order.totais.acrescimos.total = order.totais.acrescimos.cabecalho.total;
    }

    if (order.totais.descontos.total) {
      order.totais.total =
        order.totais.produtos +
        order.totais.acrescimos.total -
        order.totais.descontos.total;
      order.totais.total = roundAbnt(order.totais.total);
    } else {
      order.totais.total =
        order.totais.produtos + order.totais.acrescimos.total;
      order.totais.total = roundAbnt(order.totais.total);
    }
  } else {
    order.totais.acrescimos.cabecalho.percentual = 0;
    order.totais.acrescimos.cabecalho.valor = increaseData.value;
    const globalIncrease = roundAbnt(increaseData.value);
    order.totais.acrescimos.cabecalho.total = globalIncrease;

    if (order.totais.acrescimos.itens.total) {
      order.totais.acrescimos.total =
        order.totais.acrescimos.cabecalho.total +
        order.totais.acrescimos.itens.total;
    } else {
      order.totais.acrescimos.total = order.totais.acrescimos.cabecalho.total;
    }

    if (order.totais.descontos.total) {
      order.totais.total =
        order.totais.produtos +
        order.totais.acrescimos.total -
        order.totais.descontos.total;
      order.totais.total = roundAbnt(order.totais.total);
    } else {
      order.totais.total =
        order.totais.produtos + order.totais.acrescimos.total;
      order.totais.total = roundAbnt(order.totais.total);
    }
  }

  return order;
};

export default globalIncreaseChange;
