import { createApi } from '@reduxjs/toolkit/query/react';

import { Product } from '../models/product/product.model';
import ProductSummary from '../models/product/productSummary.model';
import PaginatedRequisition from '../models/requisitions/PaginatedRequisition';
import dynamicBaseQuery from './dynamicBaseQuery';

interface GetProducts {
  produtos: ProductSummary[];
  totalPages: number;
}

export const productsApi = createApi({
  reducerPath: 'productsApi',
  tagTypes: ['Products'],
  // refetchOnFocus: true,
  refetchOnMountOrArgChange: true,
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    getProducts: builder.query<GetProducts, PaginatedRequisition>({
      query: (reqParams: PaginatedRequisition) => {
        let req;
        if (!reqParams.filter) {
          req = { page: `${reqParams.pageNumber}` };
        } else {
          req = { page: `${reqParams.pageNumber}`, filtro: reqParams.filter };
        }
        // console.log(req);
        return {
          url: `/produto`,
          params: req,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      providesTags: ['Products'],
    }),
    getProduct: builder.query<Product, string>({
      query: (id) => ({
        url: `/produto/resumo/${id}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }),
      providesTags: ['Products'],
    }),
    addProduct: builder.mutation<void, Product>({
      query: (product) => ({
        url: '/produtos',
        method: 'POST',
        body: product,
      }),
      invalidatesTags: ['Products'],
    }),
  }),
});

export const {
  useGetProductsQuery,
  useGetProductQuery,
  useAddProductMutation,
} = productsApi;
