import { useState } from 'react';
import {
  Modal,
  Card,
  CardContent,
  TextField,
  Pagination,
  Grid,
  IconButton,
} from '@mui/material';

import { useGetClientsQuery } from '../../../api/clientsApi';

import ModalSearchFeedback from '../../feedback/ModalSearchFeedback';
import Spinner from '../../layout/Spinner';
import OrderClientCard from '../../cards/order/OrderClientCard';
import Colors from '../../../constants/Colors';
import { Search } from '@mui/icons-material';

interface Props {
  openModal: boolean;
  toggle: () => void;
}

const OrderClientSelectionModal = (props: Props) => {
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState<string | null>(null);
  const { data, isError, isFetching, isLoading } = useGetClientsQuery({
    pageNumber: page,
    filter: filter,
  });
  const [searchValue, setSearchValue] = useState<string>('');

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const renderState = () => {
    if (data && data.clientes.length > 0) {
      return data?.clientes.map((client) => {
        return (
          <>
            <Grid key={client.id + 1} item xs={12} md={12}>
              <OrderClientCard
                key={client.id}
                closeModal={props.toggle}
                client={client}
              />
            </Grid>
          </>
        );
      });
    } else {
      return <ModalSearchFeedback />;
    }
  };

  if (isFetching || isLoading) {
    return (
      <Modal
        open={props.openModal}
        onClose={props.toggle}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Card sx={{ height: '70vh', width: '25%' }}>
          <CardContent>
            <Spinner />
          </CardContent>
        </Card>
      </Modal>
    );
  }
  if (isError) {
    return (
      <Modal
        open={props.openModal}
        onClose={props.toggle}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Card sx={{ height: '70vh', width: '25%' }}>
          <CardContent>
            <h2>Erro ao carregar dados</h2>
          </CardContent>
        </Card>
      </Modal>
    );
  }

  return (
    <Modal
      open={props.openModal}
      onClose={props.toggle}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Card
        sx={{
          height: '70vh',
          overflowY: 'scroll',
          width: { md: '35%', xs: '70%' },
        }}
      >
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={10} md={10}>
              <TextField
                fullWidth
                value={searchValue}
                placeholder={'Pesquisar'}
                type='search'
                onChange={(ev) => setSearchValue(ev.target.value)}
                color='success'
                label='Pesquisar'
                size='small'
                sx={{ mb: 1 }}
                onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    setFilter(searchValue);
                    setPage(1);
                  }
                }}
              />
            </Grid>
            <Grid item xs={2} md={2} textAlign={'center'}>
              <IconButton
                sx={{
                  backgroundColor: Colors.primary,
                  '&:focus': {
                    backgroundColor: Colors.primary,
                  },
                  '&:hover': {
                    backgroundColor: 'green',
                  },
                  boxShadow: 3,
                }}
                onClick={() => {
                  setFilter(searchValue);
                  setPage(1);
                }}
              >
                <Search sx={{ color: 'white' }} />
              </IconButton>
            </Grid>
            {renderState()}
            <Grid item xs={12} md={12} mt={1}>
              <Pagination
                size='small'
                showFirstButton
                showLastButton
                page={page}
                count={data?.totalPages}
                sx={{
                  '& .MuiPaginationItem-root': {
                    '&.Mui-selected': {
                      background: Colors.mainLayoutAtiveBackground,
                      color: 'black',
                    },
                  },
                  width: '100%',
                }}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default OrderClientSelectionModal;
