import { Card, CardContent, Typography, Grid, Stack, Box } from "@mui/material";
import {
  ArticleOutlined,
  CloudUploadOutlined,
  PersonOutlined,
  LocalAtmOutlined,
  ReceiptOutlined,
} from "@mui/icons-material";

import Colors from "../../../constants/Colors";

import BillingQueryDetails from "../../../models/queries/BillingQueryDetails.model";
import { useAppSelector } from "../../../store/hooks";
import formatNumberToCurrency from "../../../util/formaters/formatNumberToCurrency";

interface Props {
  billing: BillingQueryDetails;
}

const BillingsQueryDetailsHeaderCard = (props: Props) => {
  const { billing } = props;
  const configTenant = useAppSelector((state) => state.tenantConfiguration);

  return (
    <Grid item xs={12} md={12} position={"sticky"} top={0} zIndex={1}>
      <Box
        sx={{
          backgroundColor: "white",
        }}
      >
        <Stack spacing={0.5}>
          <Stack direction={"row"} spacing={0.5}>
            <Card sx={{ width: "40%" }}>
              <CardContent
                sx={{
                  padding: 0.5,
                  "&:last-child": {
                    paddingBottom: 0.25,
                  },
                }}
              >
                <Typography
                  sx={{
                    backgroundColor: Colors.titleRowWithBackgroundColor,
                    pl: 1,
                  }}
                  variant="body2"
                  color={Colors.titleWithBackgroudColor}
                >
                  <ArticleOutlined fontSize={"inherit"} /> Número da nota fiscal
                </Typography>
                <Stack
                  direction={{ md: "row", sm: "row", xs: "column" }}
                  justifyContent={{
                    md: "space-between",
                    sm: "space-between",
                    xs: "",
                  }}
                >
                  <Typography variant={"body2"}>
                    {billing.notaFiscal.id}
                  </Typography>
                  {!!billing.notaFiscal.numeroPedido && (
                    <Typography variant={"body2"}>
                      <strong style={{ color: Colors.primary }}>
                        Pedido {configTenant.nomeSistemaIntegrado}
                      </strong>{" "}
                      : {billing.notaFiscal.numeroPedido}
                    </Typography>
                  )}
                </Stack>
              </CardContent>
            </Card>
            <Card sx={{ width: "60%" }}>
              <CardContent
                sx={{
                  padding: 0.5,
                  "&:last-child": {
                    paddingBottom: 0.25,
                  },
                }}
              >
                <Typography
                  sx={{
                    backgroundColor: Colors.titleRowWithBackgroundColor,
                    pl: 1,
                  }}
                  variant="body2"
                  color={Colors.titleWithBackgroudColor}
                >
                  <ReceiptOutlined fontSize={"inherit"} /> Status da nota fiscal
                </Typography>
                <Stack
                  direction={{ md: "row", sm: "row", xs: "column" }}
                  justifyContent={{
                    md: "space-between",
                    sm: "space-between",
                    xs: "",
                  }}
                >
                  <Typography
                    variant="body2"
                    color={
                      billing.notaFiscal.status === "Faturada"
                        ? Colors.primary
                        : "red"
                    }
                  >
                    <strong>Situação</strong> : {billing.notaFiscal.status}{" "}
                    {billing.notaFiscal.cancelamento.data
                      ? `em ${billing.notaFiscal.cancelamento.data}`
                      : ""}
                  </Typography>
                  <Typography variant="body2">
                    <strong style={{ color: Colors.primary }}>
                      Data de emissão
                    </strong>{" "}
                    : {billing.notaFiscal.emissao.data}
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          </Stack>
          <Card>
            <CardContent
              sx={{
                padding: 0.5,
                "&:last-child": {
                  paddingBottom: 0.25,
                },
              }}
            >
              <Typography
                sx={{
                  backgroundColor: Colors.titleRowWithBackgroundColor,
                  pl: 1,
                }}
                variant="body2"
                color={Colors.titleWithBackgroudColor}
              >
                <PersonOutlined fontSize={"inherit"} /> Cliente
              </Typography>
              <Stack
                direction={{ md: "row", sm: "row", xs: "column" }}
                justifyContent={{
                  md: "space-between",
                  sm: "space-between",
                  xs: "",
                }}
              >
                <Typography variant="body2">
                  <strong style={{ color: Colors.primary }}>Nome</strong> :{" "}
                  {billing.cliente.nome}
                </Typography>
              </Stack>
              <Stack
                direction={{ md: "row", sm: "row", xs: "column" }}
                justifyContent={{
                  md: "space-between",
                  sm: "space-between",
                  xs: "",
                }}
              >
                <Typography variant="body2">
                  <strong style={{ color: Colors.primary }}>CPF/CNPJ</strong> :{" "}
                  {billing.cliente.cnpjCpf}
                </Typography>
                {!!billing.cliente.endereco.localidade && (
                  <Typography variant="body2">
                    <strong style={{ color: Colors.primary }}>Cidade-UF</strong>{" "}
                    : {billing.cliente.endereco.localidade}-
                    {billing.cliente.endereco.uf}
                  </Typography>
                )}
              </Stack>
            </CardContent>
          </Card>
          <Card>
            <CardContent
              sx={{
                padding: 0.5,
                "&:last-child": {
                  paddingBottom: 0.25,
                },
              }}
            >
              <Typography
                sx={{
                  backgroundColor: Colors.titleRowWithBackgroundColor,
                  pl: 1,
                }}
                variant="body2"
                color={Colors.titleWithBackgroudColor}
              >
                <LocalAtmOutlined fontSize={"inherit"} /> Totais
              </Typography>
              <Stack
                direction={{ md: "row", sm: "row", xs: "column" }}
                justifyContent={{
                  md: "space-between",
                  sm: "space-between",
                  xs: "",
                }}
              >
                <div>
                  <Typography variant="body2">
                    <strong style={{ color: Colors.primary }}>Itens</strong> :{" "}
                    {billing.notaFiscal.itens.length}
                  </Typography>
                  <Typography variant="body2">
                    <strong style={{ color: Colors.primary }}>
                      Vr. Produtos
                    </strong>{" "}
                    :{" "}
                    {formatNumberToCurrency(billing.notaFiscal.totais.produtos)}
                  </Typography>
                </div>
                <div>
                  <Typography variant="body2">
                    <strong style={{ color: Colors.primary }}>Vr. Desc</strong>{" "}
                    :{" "}
                    {billing.notaFiscal.totais.descontos.total
                      ? formatNumberToCurrency(
                          billing.notaFiscal.totais.descontos.total
                        )
                      : "R$ 0,00"}
                  </Typography>
                  <Typography variant="body2">
                    <strong style={{ color: Colors.primary }}>
                      Vr. Outras
                    </strong>{" "}
                    :{" "}
                    {billing.notaFiscal.totais.outrasDespesas
                      ? formatNumberToCurrency(
                          billing.notaFiscal.totais.outrasDespesas
                        )
                      : "R$ 0,00"}
                  </Typography>
                </div>
                <div>
                  <Typography variant="body2">
                    <strong style={{ color: Colors.primary }}>Vr. Frete</strong>{" "}
                    :{" "}
                    {billing.notaFiscal.totais.frete
                      ? formatNumberToCurrency(billing.notaFiscal.totais.frete)
                      : "R$ 0,00"}
                  </Typography>
                  <Typography variant="body2">
                    <strong style={{ color: Colors.primary }}>Vr. IPI</strong> :{" "}
                    {billing.notaFiscal.totais.ipi
                      ? formatNumberToCurrency(billing.notaFiscal.totais.ipi)
                      : "R$ 0,00"}
                  </Typography>
                </div>

                <Typography variant="body2">
                  <strong style={{ color: Colors.primary }}>
                    Vr. Total da Nota
                  </strong>{" "}
                  : {formatNumberToCurrency(billing.notaFiscal.totais.total)}
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </Box>
    </Grid>
  );
};

export default BillingsQueryDetailsHeaderCard;
