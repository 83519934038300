import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Visualization {
  visualization: 'TABLE' | 'CARD';
}

const initialState: Visualization = {
  visualization: localStorage.getItem('visualization')
    ? (localStorage.getItem('visualization') as 'TABLE' | 'CARD')
    : 'TABLE',
};

const visualizationSlice = createSlice({
  name: 'visualization',
  initialState,
  reducers: {
    changeVisualization(
      state: Visualization,
      action: PayloadAction<'TABLE' | 'CARD'>
    ) {
      localStorage.setItem('visualization', action.payload);
      state.visualization = action.payload;
    },
  },
});

export const visualizationActions = visualizationSlice.actions;

export default visualizationSlice;
