interface DateTime {
  data: string;
  hora: string;
}

const formatStringToDate = (date: DateTime): Date => {
  const dateStringArray = date.data.split('/');
  let newDateString = `${dateStringArray[2]}-${dateStringArray[1]}-${dateStringArray[0]}T${date.hora}`;
  return new Date(newDateString);
};
export default formatStringToDate;
