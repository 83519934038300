import { Grid, Card, CardContent, Typography } from '@mui/material';
import { CloudUploadOutlined } from '@mui/icons-material';

import { useAppSelector } from '../../../store/hooks';

import Colors from '../../../constants/Colors';

const OrderSituationCard = () => {
  const orderSituation = useAppSelector((state) => state.order.orderSituation);
  const orderPhase = useAppSelector((state) => state.order.orderPhase);

  return (
    <Grid item xs={12} md={3}>
      <Card sx={{ height: '100%' }}>
        <CardContent>
          <Grid container spacing={1} justifyContent={'right'}>
            <Grid item xs={12} md={12}>
              <Typography
                sx={{
                  backgroundColor: Colors.titleRowWithBackgroundColor,
                  pl: 1,
                  pt: 0.75,
                  mt: -1,
                }}
                variant='body2'
                color={Colors.titleWithBackgroudColor}
              >
                <CloudUploadOutlined fontSize={'inherit'} /> Status do Pedido
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant='body2' color={orderSituation.cor}>
                Situação do pedido - {orderSituation.nome}
              </Typography>
            </Grid>
            {orderPhase.id && (
              <Grid item xs={12} md={12}>
                <Typography
                  variant='body2'
                  color={orderPhase.cor ? orderPhase.cor : Colors.primary}
                >
                  Fase do Pedido - {orderPhase.nome}
                </Typography>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default OrderSituationCard;
