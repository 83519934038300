import { Dialog, Button, DialogTitle } from '@mui/material';
// import { useDispatch } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';

// import { AppDispatch } from '../../store';
import { logout } from '../../../store/auth/auth-actions';
import { useAppDispatch } from '../../../store/hooks';

interface Props {
  open: boolean;
  toggle: () => void;
}

const LogoutModal = (props: Props): JSX.Element => {
  const dispatch = useAppDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <Dialog open={props.open} onClose={props.toggle}>
      <DialogTitle>Deseja realmente sair do sistema?</DialogTitle>
      <div className={css(styles.buttonContainer)}>
        <Button variant='outlined' color='success' onClick={handleLogout}>
          Sim
        </Button>{' '}
        <Button variant='outlined' color='error' onClick={props.toggle}>
          Cancelar
        </Button>
      </div>
    </Dialog>
  );
};

export default LogoutModal;

const styles = StyleSheet.create({
  container: {
    padding: 100,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: '3%',
  },
});
