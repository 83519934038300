const isValidCPF = (cpf: string): boolean => {
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false;
  let cpfNumber: number[] = [];
  cpfNumber = cpf.split('').map((el) => +el);
  const rest = (count: number) =>
    ((cpfNumber
      .slice(0, count - 12)
      .reduce((soma, el, index) => soma + el * (count - index), 0) *
      10) %
      11) %
    10;
  return rest(10) === cpfNumber[9] && rest(11) === cpfNumber[10];
};

export default isValidCPF;
