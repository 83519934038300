import { Grid } from '@mui/material';

import { useGetCustomOrderQuery } from '../../../api/ordersApi';
import CUSTOM_GET_ORDER_INITIALIZER from '../../../util/initializers/CustomGetOrderInitializer';
import OrderQueryDetailsHeaderCard from '../../cards/queries/OrderQueryDetailsHeaderCard';
import Spinner from '../../layout/Spinner';
import OrderQueryResultsDetailsTable from '../../tables/OrderQueryResultsDetailsTable';
import QueryModalWrapper from './QueryModalWrapper';

interface Props {
  openModal: boolean;
  orderId: string;
  toggle: () => void;
}

const OrderQueryDetailsModal = (props: Props) => {
  const { orderId, openModal, toggle } = props;
  const { isError, isFetching, isLoading, data } = useGetCustomOrderQuery(
    orderId,
    { skip: !orderId }
  );

  if (isFetching || isLoading) {
    return (
      <QueryModalWrapper
        openModal={openModal}
        toggle={toggle}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Spinner />
      </QueryModalWrapper>
    );
  }

  if (isError) {
    return (
      <QueryModalWrapper
        openModal={openModal}
        toggle={toggle}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <h2>Erro ao carregar dados do pedido</h2>
      </QueryModalWrapper>
    );
  }

  return (
    <QueryModalWrapper
      openModal={openModal}
      toggle={toggle}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Grid container spacing={1}>
        <OrderQueryDetailsHeaderCard
          order={data ? data : CUSTOM_GET_ORDER_INITIALIZER}
        />
        <Grid item xs={12} md={12}>
          <div style={{ display: 'flex', height: '100%' }}>
            <OrderQueryResultsDetailsTable
              order={data ? data : CUSTOM_GET_ORDER_INITIALIZER}
            />
          </div>
        </Grid>
      </Grid>
    </QueryModalWrapper>
  );
};

export default OrderQueryDetailsModal;
