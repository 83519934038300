import { useLayoutEffect, useState } from 'react';
import { Chart } from 'react-google-charts';
import { Grid } from '@mui/material';

import { useAppSelector } from '../../store/hooks';
import {
  CHART_TOOLTIP_FORMATTERS,
  CHART_OPTIONS,
} from '../../util/chart/chartUtil';
import { useGetGoalsQuery } from '../../api/queriesApi';
import Spinner from '../../components/layout/Spinner';
import formatDataToChart from '../../util/formaters/formatDataToChart';
import GoalsQueryResultTable from '../../components/tables/GoalsQueryResultTable';
import useWindowSize from '../../hooks/useWindowSize';

const GoalsQueriesFiltersPage = () => {
  const userId = useAppSelector((state) => state.auth.userId);
  const isDrawerOpen = useAppSelector((state) => state.util.isDrawerOpen);
  const { isError, isFetching, isLoading, data } = useGetGoalsQuery(
    userId ? userId : '',
    { skip: !userId }
  );
  const [chartData, setChartData] = useState<any[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [key, setKey] = useState(new Date().getTime());

  const size = useWindowSize();

  useLayoutEffect(() => {
    if (data) {
      const newData = formatDataToChart(data.vendas);
      setChartData([...newData]);
      setIsDataLoading(false);
    }
  }, [data, setIsDataLoading]);

  useLayoutEffect(() => {
    setKey(new Date().getTime());
  }, [size.width, setKey, isDrawerOpen]);

  if (isFetching || isDataLoading || isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return <h2>Error ao carregar dados das metas</h2>;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Chart
          key={key}
          chartType='ColumnChart'
          width='100%'
          height='300px'
          data={chartData}
          chartLanguage={'pt-BR'}
          options={CHART_OPTIONS}
          formatters={CHART_TOOLTIP_FORMATTERS}
          loader={<Spinner />}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <GoalsQueryResultTable results={data ? data.vendas : []} />
      </Grid>
    </Grid>
  );
};

export default GoalsQueriesFiltersPage;
