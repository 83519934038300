import roundAbnt from '../formaters/roundAbnt';

interface BrokenLimit {
  hasBroken: boolean;
  percentageGiven: number;
  percentageLeft: number;
  maximumDiscount: number;
  triedDiscount: number;
}

export const percentageOf = (value: number, total: number): number => {
  const percentageValue = (value / total) * 100;
  return roundAbnt(percentageValue);
};

export const hasBrokenTheLimit = (
  type: 'PERCENTAGE' | 'VALUE',
  maximumDiscount: number,
  value: number,
  discountGiven: number,
  subtotal: number
): BrokenLimit => {
  if (type === 'PERCENTAGE') {
    const percentageValue = roundAbnt((value * subtotal) / 100);
    const newPercentage = percentageOf(
      percentageValue + discountGiven,
      subtotal
    );
    const discountGivenPercentage = percentageOf(discountGiven, subtotal);
    const discountLeft = roundAbnt(maximumDiscount) - discountGivenPercentage;
    if (newPercentage > maximumDiscount) {
      return {
        hasBroken: true,
        percentageGiven: discountGivenPercentage,
        maximumDiscount,
        percentageLeft: discountLeft,
        triedDiscount: newPercentage,
      };
    } else {
      return {
        hasBroken: false,
        percentageGiven: discountGivenPercentage,
        maximumDiscount,
        percentageLeft: discountLeft,
        triedDiscount: newPercentage,
      };
    }
  } else {
    const newPercentage = percentageOf(value + discountGiven, subtotal);
    const discountGivenPercentage = percentageOf(discountGiven, subtotal);
    const discountLeft = roundAbnt(maximumDiscount) - discountGivenPercentage;
    if (newPercentage > maximumDiscount) {
      return {
        hasBroken: true,
        percentageGiven: discountGivenPercentage,
        maximumDiscount,
        percentageLeft: discountLeft,
        triedDiscount: newPercentage,
      };
    } else {
      return {
        hasBroken: false,
        percentageGiven: discountGivenPercentage,
        maximumDiscount,
        percentageLeft: discountLeft,
        triedDiscount: newPercentage,
      };
    }
  }
};
