import * as React from "react";
import { Outlet, Link, useMatch } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import LogoutModal from "../modals/alert/LogoutDialog";
import Colors from "../../constants/Colors";

import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { visualizationActions } from "../../store/visualization/visualization-slice";
import { orderSearchActions } from "../../store/search/order-search-slice";
import { orderQueryParamsActions } from "../../store/queries/order-query-params-slice";
import { billingParamsActions } from "../../store/queries/billings-query-params-slice";
import { utilActions } from "../../store/util/util-slice";
import DrawerItemsList from "./DrawerItemsList";

import { MaterialUISwitch } from "./MaterialUISwitch";
import { Drawer, DrawerHeader } from "./Drawer";
import { AppBar } from "./AppBar";

import "../../assets/styles/muiComponents.css";

export default function MainLayout() {
  const navigate = useNavigate();
  const matchClients = !!useMatch("/clientes/:slug");
  const matchSales = !!useMatch("/vendas/:slug");
  const matchedSalesCreation = !!useMatch("/vendas/detalhes");
  const matchedOrderQuery = !!useMatch("/consultas/pedidos/resultado");
  const matchedBillingQuery = !!useMatch("/consultas/faturamentos/resultado");

  const theme = useTheme();
  const [open, setOpen] = React.useState<boolean>(true);
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [openQuerySubMenu, setOpenQuerySubmenu] = React.useState(false);

  const visualization = useAppSelector(
    (state) => state.visualization.visualization
  );
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    setTimeout(() => {
      dispatch(utilActions.toggleIsDrawerOpen(open));
    }, 500);
  }, [open, dispatch]);

  const handleNavigation = () => {
    if (matchClients) {
      localStorage.removeItem("orderSearchParams");
      navigate("/clientes");
    } else if (matchedSalesCreation) {
      navigate("/vendas/pesquisar");
    } else if (matchedOrderQuery) {
      dispatch(orderQueryParamsActions.resetOrderQuery());
      localStorage.removeItem("orderQueryParams");
      navigate("/consultas/pedidos");
    } else if (matchedBillingQuery) {
      // console.log('Entrou no matchedBillingQuery: ', matchedBillingQuery);
      dispatch(billingParamsActions.resetState());
      localStorage.removeItem("billingQueryParams");
      navigate("/consultas/faturamentos");
    } else {
      dispatch(orderSearchActions.resetOrderSearch());
      localStorage.removeItem("orderSearchParams");
      navigate("/vendas");
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleSwitchChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (ev.target.checked) {
      dispatch(visualizationActions.changeVisualization("TABLE"));
    } else {
      dispatch(visualizationActions.changeVisualization("CARD"));
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <LogoutModal
        open={openDialog}
        toggle={() => setOpenDialog(!openDialog)}
      />
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={{ backgroundColor: Colors.primary, height: "2.5rem" }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
              cursor: "pointer",
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            <Link
              to={"/main"}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              IVIVenda-Web
            </Link>
          </Typography>
          <Tooltip
            arrow
            title={
              visualization === "TABLE"
                ? "Visualização em tabela ativa"
                : "Visualização em cartão ativa"
            }
          >
            <MaterialUISwitch
              sx={{
                position: "absolute",
                right: {
                  md: "9%",
                  sm: "13%",
                  xs: "18%",
                },
              }}
              checked={visualization === "TABLE"}
              onChange={(ev) => handleSwitchChange(ev)}
            />
          </Tooltip>
          {(matchClients ||
            matchSales ||
            matchedOrderQuery ||
            matchedBillingQuery) && (
            <IconButton
              onClick={() => handleNavigation()}
              focusRipple
              size="small"
              sx={{
                backgroundColor: "white",
                "&:focus": {
                  backgroundColor: "white",
                },
                "&:hover": {
                  backgroundColor: "#e0e0e0",
                },
                boxShadow: 3,
                position: "absolute",
                right: "3%",
              }}
            >
              <ArrowBackIcon sx={{ color: Colors.primary, fontSize: 18 }} />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton
            onClick={handleDrawerClose}
            style={{
              position: "absolute",
              top: "1px",
              marginRight: 4,
              cursor: "pointer",
            }}
          >
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <DrawerItemsList
          open={open}
          openDialog={openDialog}
          openQuerySubMenu={openQuerySubMenu}
          toggleDialog={() => setOpenDialog(true)}
          toggleQueryMenu={() => setOpenQuerySubmenu(!openQuerySubMenu)}
        />
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box>
  );
}
