import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import ClientFormErrors from '../../models/client/clientFormErrors.model';

const initialState: ClientFormErrors = {
  categoryError: false,
  cnpjError: false,
  cpfError: false,
  mainAddressError: false,
  mainPhoneError: false,
  nameError: false,
  datePickerError: false,
};

const clientFormErrorSlice = createSlice({
  name: 'clientFormError',
  initialState,
  reducers: {
    categoryErrorSetter(
      state: ClientFormErrors,
      action: PayloadAction<boolean>
    ) {
      state.categoryError = action.payload;
    },
    cnpjErrorSetter(state: ClientFormErrors, action: PayloadAction<boolean>) {
      state.cnpjError = action.payload;
    },
    cpfErrorSetter(state: ClientFormErrors, action: PayloadAction<boolean>) {
      state.cpfError = action.payload;
    },
    mainAddressErrorSetter(
      state: ClientFormErrors,
      action: PayloadAction<boolean>
    ) {
      state.mainAddressError = action.payload;
    },
    mainPhoneErrorSetter(
      state: ClientFormErrors,
      action: PayloadAction<boolean>
    ) {
      state.mainPhoneError = action.payload;
    },
    nameErrorSetter(state: ClientFormErrors, action: PayloadAction<boolean>) {
      state.nameError = action.payload;
    },
    datePickerErrorSetter(
      state: ClientFormErrors,
      action: PayloadAction<boolean>
    ) {
      state.datePickerError = action.payload;
    },
    resetErrors(state: ClientFormErrors) {
      state.categoryError = false;
      state.cnpjError = false;
      state.cpfError = false;
      state.datePickerError = false;
      state.mainAddressError = false;
      state.mainPhoneError = false;
      state.nameError = false;
    },
  },
});

export const clientFormErrorActions = clientFormErrorSlice.actions;

export default clientFormErrorSlice;
