const Colors = {
  primary: '#2CBA8D',
  stock: '#B8860B',
  mainLayoutAtiveBackground: '#e6eef6',
  mainLayoutHoverBackground: '#dfeaf6',
  mainLayoutFontColor: '#6a6d71',
  titleWithBackgroudColor: '#356859',
  titleRowWithBackgroundColor: '#B9E4C9',
  defaultPriceTable: '#EEE8AA',
  noResultBackgroud: '#EEE8AA',
  noResultFont: '#B8860B',
  muiPrimary: '#1769aa',
  muiDanger: '#d32f2f',
  iconButtonProductsBackground: '#C6E2FF',
  iconButtonProducts: '#4682B4',
  iconButtonDiscountsBackground: '#FFE8DC',
  iconButtonDiscounts: '#EA9E99',
  iconButtonTotalBackground: '#EAF3E7',
  iconButtonTotal: '#2A8C00',
};

export default Colors;
