import {
  Modal,
  Card,
  CardContent,
  Typography,
  Button,
  Stack,
} from '@mui/material';

interface Props {
  openModal: boolean;
  addItem: () => void;
  cancelAddition: () => void;
}

const ItemDuplicityAlertModal = (props: Props) => {
  return (
    <Modal
      open={props.openModal}
      onClose={props.cancelAddition}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Card
        sx={{
          height: '20vh',
          width: { md: '35%', xs: '70%' },
        }}
      >
        <CardContent>
          <Stack spacing={2}>
            <Typography variant='h6'>
              Item duplicado, deseja continuar?
            </Typography>
            <Stack direction={'row'} justifyContent={'space-around'}>
              <Button variant='outlined' onClick={() => props.addItem()}>
                Sim
              </Button>
              <Button variant='outlined' onClick={() => props.cancelAddition()}>
                Não
              </Button>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default ItemDuplicityAlertModal;
