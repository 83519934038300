import BillingQueryParams from '../../models/requisitions/PaginatedBillingQueryRequisition.model';

const BILLING_QUERY_PARAMS: BillingQueryParams = {
  cancelamentoFinal: '',
  cancelamentoInicial: '',
  emissaoFinal: '',
  emissaoInicial: '',
  notaFiscalId: '',
  page: 1,
  situacao: [],
  cliente: '',
};

export default BILLING_QUERY_PARAMS;
