import {
  Card,
  CardContent,
  Typography,
  Grid,
  Stack,
  Tooltip,
  Box,
} from "@mui/material";
import {
  ArticleOutlined,
  CloudUploadOutlined,
  PersonOutlined,
  LocalAtmOutlined,
} from "@mui/icons-material";

import Colors from "../../../constants/Colors";

import CustomGetOrder from "../../../models/order/customGetOrder.model";
import { useAppSelector } from "../../../store/hooks";
import formatNumberToCurrency from "../../../util/formaters/formatNumberToCurrency";
import formatNumberToCurrencyNoSymbol from "../../../util/formaters/formatNumberToCurrencyNoSymbol";
import OrderQueryTooltip from "../../helpers/OrderQueryTooltip";
import { useEffect } from "react";

interface Props {
  order: CustomGetOrder;
}

const OrderQueryDetailsHeaderCard = (props: Props) => {
  const { order } = props;
  const configTenant = useAppSelector((state) => state.tenantConfiguration);
  console.log(order);

  const returnDiscountColor = () => {
    const orderDiscountsTotals = order.pedido.totais.descontos;
    if (
      (!!orderDiscountsTotals.cabecalho.total &&
        !orderDiscountsTotals.itens.total) ||
      !orderDiscountsTotals.total
    ) {
      return "black";
    }
    if (
      !!orderDiscountsTotals.itens.total &&
      !orderDiscountsTotals.cabecalho.total
    ) {
      return "blue";
    }
    return "red";
  };

  const itemsAmount = order.pedido.itens;
  const itemUnit = order.produto;

  let totalUnd: any = [];
  let undAnt: any = String;
  const valoresTabela: any = [];

  itemUnit.forEach((element: any) => {
    if (undAnt === "" || element.id !== undAnt) {
      undAnt = element.id;
      totalUnd.push({
        produto: element.id,
        unidade: element.unidadeMedida,
        qntd: 0,
      });
    }
  });

  itemsAmount.forEach((element: any) => {
    totalUnd.forEach((und: any) => {
      if (und.produto === element.produtoId) {
        und.qntd = und.qntd + element.quantidade;
      }
    });
  });

  //Gera o array de objetos pronto para ser lançado na tela, com unidade e quantidade total
  totalUnd.forEach((element: any, index: any) => {
    const map: any = [];

    totalUnd.forEach((result: any) => {
      if (result.unidade === totalUnd[index].unidade) {
        map.push(result);
      }
    });

    valoresTabela.push({
      unidade: element.unidade,
      qntd: map.map(amount).reduce(sum),
    });
  });
  //

  //Filtra valores repetidos no array de objetos
  let valoresFiltrados = valoresTabela.reduce((unique: any, o: any) => {
    if (
      !unique.some(
        (obj: any) => obj.unidade === o.unidade && obj.qntd === o.qntd
      )
    ) {
      unique.push(o);
    }
    return unique;
  }, []);
  //

  function amount(item: any) {
    return item.qntd;
  }
  function sum(prev: any, next: any) {
    return prev + next;
  }

  useEffect(() => {
    console.log(itemsAmount);
    console.log(itemUnit);
    console.log(totalUnd);
    console.log(valoresFiltrados);
  }, []);

  return (
    <Grid item xs={12} md={12} position={"sticky"} top={0} zIndex={1}>
      <Box
        sx={{
          backgroundColor: "white",
        }}
      >
        <Stack spacing={0.5}>
          <Stack direction={"row"} spacing={0.5}>
            <Card sx={{ width: "40%" }}>
              <CardContent
                sx={{
                  padding: 0.5,
                  "&:last-child": {
                    paddingBottom: 0.25,
                  },
                }}
              >
                <Typography
                  sx={{
                    backgroundColor: Colors.titleRowWithBackgroundColor,
                    pl: 1,
                  }}
                  variant="body2"
                  color={Colors.titleWithBackgroudColor}
                >
                  <ArticleOutlined fontSize={"inherit"} /> Protocolo
                </Typography>
                <Stack
                  direction={{ md: "row", sm: "row", xs: "column" }}
                  justifyContent={{
                    md: "space-between",
                    sm: "space-between",
                    xs: "",
                  }}
                >
                  <Typography variant={"body2"}>{order.pedido.id}</Typography>
                  {!!order.pedido.sistemaIntegrado.numeroPedido && (
                    <Typography variant={"body2"}>
                      <strong style={{ color: Colors.primary }}>
                        Pedido {configTenant.nomeSistemaIntegrado}
                      </strong>{" "}
                      : {order.pedido.sistemaIntegrado.numeroPedido}
                    </Typography>
                  )}
                </Stack>
              </CardContent>
            </Card>
            <Card sx={{ width: "60%" }}>
              <CardContent
                sx={{
                  padding: 0.5,
                  "&:last-child": {
                    paddingBottom: 0.25,
                  },
                }}
              >
                <Typography
                  sx={{
                    backgroundColor: Colors.titleRowWithBackgroundColor,
                    pl: 1,
                  }}
                  variant="body2"
                  color={Colors.titleWithBackgroudColor}
                >
                  <CloudUploadOutlined fontSize={"inherit"} /> Status do pedido
                </Typography>
                <Stack
                  direction={{ md: "row", sm: "row", xs: "column" }}
                  justifyContent={{
                    md: "space-between",
                    sm: "space-between",
                    xs: "",
                  }}
                >
                  <Typography
                    variant="body2"
                    color={
                      order.situacaoPedido.cor
                        ? order.situacaoPedido.cor
                        : "orange"
                    }
                  >
                    <strong>Situação</strong> :{" "}
                    {order.situacaoPedido.nome
                      ? order.situacaoPedido.nome
                      : "Não recepcionado"}
                  </Typography>
                  {!!order.fasePedido.nome && (
                    <Typography
                      variant="body2"
                      color={
                        order.fasePedido.cor ? order.fasePedido.cor : "black"
                      }
                    >
                      <strong>Fase</strong> :{" "}
                      {order.fasePedido.nome ? order.fasePedido.nome : ""}
                    </Typography>
                  )}
                </Stack>
              </CardContent>
            </Card>
          </Stack>
          <Card>
            <CardContent
              sx={{
                padding: 0.5,
                "&:last-child": {
                  paddingBottom: 0.25,
                },
              }}
            >
              <Typography
                sx={{
                  backgroundColor: Colors.titleRowWithBackgroundColor,
                  pl: 1,
                }}
                variant="body2"
                color={Colors.titleWithBackgroudColor}
              >
                <PersonOutlined fontSize={"inherit"} /> Cliente
              </Typography>
              <Stack
                direction={{ md: "row", sm: "row", xs: "column" }}
                justifyContent={{
                  md: "space-between",
                  sm: "space-between",
                  xs: "",
                }}
              >
                <Typography variant="body2">
                  <strong style={{ color: Colors.primary }}>Nome</strong> :{" "}
                  {order.cliente.nome}
                </Typography>
              </Stack>
              <Stack
                direction={{ md: "row", sm: "row", xs: "column" }}
                justifyContent={{
                  md: "space-between",
                  sm: "space-between",
                  xs: "",
                }}
              >
                <Typography variant="body2">
                  <strong style={{ color: Colors.primary }}>CPF/CNPJ</strong> :{" "}
                  {order.cliente.cnpjCpf}
                </Typography>
                {!!order.cliente.endereco.localidade && (
                  <Typography variant="body2">
                    <strong style={{ color: Colors.primary }}>Cidade-UF</strong>{" "}
                    : {order.cliente.endereco.localidade}-
                    {order.cliente.endereco.uf}
                  </Typography>
                )}
              </Stack>
            </CardContent>
          </Card>
          <Card>
            <CardContent
              sx={{
                padding: 0.5,
                "&:last-child": {
                  paddingBottom: 0.25,
                },
              }}
            >
              <Typography
                sx={{
                  backgroundColor: Colors.titleRowWithBackgroundColor,
                  pl: 1,
                }}
                variant="body2"
                color={Colors.titleWithBackgroudColor}
              >
                <LocalAtmOutlined fontSize={"inherit"} /> Totais
              </Typography>
              <Stack
                direction={{ md: "row", sm: "column", xs: "column" }}
                justifyContent={{
                  md: "space-between",
                  sm: "space-between",
                  xs: "",
                }}
              >
                {/* <div> */}
                <Typography variant="body2">
                  <strong style={{ color: Colors.primary }}>Itens</strong> :{" "}
                  {order.pedido.itens.length}
                </Typography>
                <Typography variant="body2">
                  <strong style={{ color: Colors.primary }}>Vr. Bruto</strong> :{" "}
                  {formatNumberToCurrency(order.pedido.totais.produtos)}
                </Typography>
                {/* </div> */}
                {/* <div> */}
                <Typography variant="body2">
                  <strong style={{ color: Colors.primary }}>% Desc</strong> :{" "}
                  {order.pedido.totais.descontos.cabecalho.percentual
                    ? formatNumberToCurrencyNoSymbol(
                        order.pedido.totais.descontos.cabecalho.percentual
                      )
                    : "0,00"}
                </Typography>
                <Tooltip arrow title={<OrderQueryTooltip />}>
                  <Typography variant="body2" color={returnDiscountColor()}>
                    <strong style={{ color: Colors.primary }}>Vr. Desc</strong>{" "}
                    :{" "}
                    {order.pedido.totais.descontos.total
                      ? formatNumberToCurrency(
                          order.pedido.totais.descontos.total
                        )
                      : formatNumberToCurrency(0)}
                  </Typography>
                </Tooltip>
                {/* </div> */}
                {/* <div> */}
                <Typography variant="body2">
                  <strong style={{ color: Colors.primary }}>Vr. Líquido</strong>{" "}
                  : {formatNumberToCurrency(order.pedido.totais.total)}
                </Typography>
                {order.pedido.totais.totalIpi ? (
                  <Typography variant="body2">
                    <strong style={{ color: Colors.primary }}>Vr. IPI</strong> :{" "}
                    {order.pedido.totais.totalIpi
                      ? formatNumberToCurrency(order.pedido.totais.totalIpi)
                      : "R$ 0,00"}
                  </Typography>
                ) : (
                  ""
                )}
                {/* </div> */}

                {valoresFiltrados.map((result: any) => {
                  return (
                    <Typography variant="body2">
                      <strong style={{ color: Colors.primary }}>
                        {result.unidade}
                      </strong>{" "}
                      : {result.qntd}
                    </Typography>
                  );
                })}
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </Box>
    </Grid>
  );
};

export default OrderQueryDetailsHeaderCard;
