import { useState, useEffect } from 'react';
import { Grid, Card, CardContent, Stack, Typography } from '@mui/material';

import OrderSummary from '../../../models/order/orderSummary.model';
import formatNumberToCurrency from '../../../util/formaters/formatNumberToCurrency';

interface Props {
  orders: OrderSummary[];
}

const OrdersQueryResultsTotalizersCard = (props: Props) => {
  const { orders } = props;
  const [numberOfOrders, setNumberOfOrders] = useState(orders.length);
  const [ordersTotal, setOrdersTotal] = useState(0);

  useEffect(() => {
    setNumberOfOrders(orders.length);
    const total = orders.reduce((acc, currValue) => {
      return acc + currValue.totalPedido;
    }, 0);
    setOrdersTotal(total);
  }, [orders, setNumberOfOrders, setOrdersTotal]);

  return (
    <Grid item xs={12} md={12}>
      <Card sx={{ width: '100%', boxShadow: 2 }}>
        <CardContent
          sx={{
            mt: -0.5,
            padding: 0.5,
            '&:last-child': {
              paddingBottom: 0.25,
            },
          }}
        >
          <Stack
            direction={'row'}
            width={'100%'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Typography variant='body2' fontWeight={'Bold'} mt={1}>
              Total de pedidos : {numberOfOrders}
            </Typography>
            <Typography variant='body2' fontWeight={'Bold'} mt={1}>
              Valor total dos pedidos : {formatNumberToCurrency(ordersTotal)}
            </Typography>
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default OrdersQueryResultsTotalizersCard;
