import axios from 'axios';
import _ from 'lodash';

// import { orderActions } from './order-slice';
import env from '../../env';

import ProductPrice from '../../models/product/productPrice.model';
import NewPrices from '../../models/order/newPrices.model';
import { Product } from '../../models/product/product.model';
// import OrderItems from '../../models/order/orderItem.model';

export const returnApiUrl = () => localStorage.getItem('apiUrl') as string;

export const getPriceTablesFromArray = async (
  ids: string[],
  tableId: string
): Promise<NewPrices[]> => {
  return new Promise<NewPrices[]>(async (resolve, reject) => {
    const data: NewPrices[] = [];
    const uniqueIds = _.uniq(ids);
    // console.log(uniqueIds);
    const API_URL = returnApiUrl();
    for (const id of uniqueIds) {
      try {
        const response = await axios.get(`${API_URL}/produto/resumo/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        const filter: ProductPrice[] = response.data.precoProduto.filter(
          (table: ProductPrice) => {
            return table.tabelaPrecoId === tableId;
          }
        );
        const prices: NewPrices = {
          id: response.data.codigo,
          newPrice: filter[0],
        };
        data.push(prices);
      } catch (err) {
        // console.log(err);
        reject('Ouve um erro ao carregar os dados dos itens');
      }
    }
    resolve(data);
  });
};

export const searchItem = async (id: string): Promise<Product> => {
  return new Promise<Product>(async (resolve, reject) => {
    const API_URL = returnApiUrl();
    try {
      const response = await axios.get(`${API_URL}/produto/resumo/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      resolve(response.data);
    } catch (err) {
      reject(err);
    }
  });
};
