import { useLayoutEffect, useState } from 'react';
import {
  Modal,
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  Typography,
} from '@mui/material';

import UFS from '../../../data/ufsData';
import Endereco from '../../../models/address/address.model';
import Colors from '../../../constants/Colors';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { clientActions } from '../../../store/client/client-slice';
import getCityFromState from '../../../util/getters/getCityFromState';
import validateCep from '../../../util/validators/validateCep';
import getAddressFromCep from '../../../util/getters/getAddressFromCep';
import { clientFormErrorActions } from '../../../store/error/client-form-error-slice';

interface Props {
  open: boolean;
  toggle: () => void;
  readAddress?: (address: Endereco) => void;
  setAddress: (address: string) => void;
  addressType: 'Principal' | 'para Entrega';
  endereco: Endereco;
}

interface City {
  id: string;
  nome: string;
}

interface AddressValidity {
  logradouro: boolean;
  numero: boolean;
  bairro: boolean;
  cidade: boolean;
  uf: boolean;
  cep: boolean;
  codigoCidade: boolean;
}

const ClientAdressModal = (props: Props) => {
  const citiesArray = useAppSelector((state) => state.cities.cities);
  const { endereco } = props;
  const [error, setError] = useState<string | null>(null);
  // const [isLoading, setIsLoading] = useState<boolean>(false);
  const [logradouro, setLogradouro] = useState<string>(endereco.logradouro);
  const [numero, setNumero] = useState<string>(endereco.numero);
  const [complemento, setComplemento] = useState<string>(endereco.complemento);
  const [bairro, setBairro] = useState<string>(endereco.sublocalidade);
  const [cidade, setCidade] = useState<string>(endereco.localidade);
  const [uf, setUf] = useState<string>(endereco.uf);
  const [cep, setCep] = useState<string>(endereco.cep);
  const [codigoCidade, setCodigoCidade] = useState<string>(
    endereco.codigoCidade
  );
  const [searched, setSearched] = useState<boolean>(false);
  const [cities, setCities] = useState<City[] | null>(citiesArray);
  const [getCitiesError, setGetCitiesError] = useState<string | null>(null);
  const [erroCamposObrigatorios, setErroCamposObrigatorios] = useState<
    string | null
  >(null);
  const [isFieldsNotFilled, setIsFieldsNotFilled] = useState<AddressValidity>({
    logradouro: false,
    numero: false,
    bairro: false,
    cep: false,
    cidade: false,
    codigoCidade: false,
    uf: false,
  });
  const [preenchimentoManual, setPreenchimentoManual] =
    useState<boolean>(false);

  const mainAddressError = useAppSelector(
    (state) => state.clientFormError.mainAddressError
  );

  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    if (!cities && !!citiesArray) {
      setCities(citiesArray);
    }
  }, [cities, citiesArray]);

  const getAddress = async (): Promise<void> => {
    let validatedCep: string = '';
    // setIsLoading(true);
    const cepValidation = validateCep(cep);
    if (cepValidation.state) {
      if (!preenchimentoManual) setPreenchimentoManual(true);
      // setIsLoading(false);
      setError(cepValidation.message);
      setSearched(false);
      return;
    }
    validatedCep = cepValidation.cep.replace(/\D/g, '');
    setCep(cepValidation.cep);
    try {
      const response = await getAddressFromCep(validatedCep);
      setLogradouro(response.logradouro);
      setBairro(response.bairro);
      setCidade(response.localidade);
      setUf(response.uf);
      setCodigoCidade(response.codigoCidade);
      setNumero('');
      setComplemento('');
      setCities([{ id: response.codigoCidade, nome: response.localidade }]);
      if (preenchimentoManual) setPreenchimentoManual(false);
      setSearched(true);
      setError(null);
    } catch (err) {
      if (!preenchimentoManual) setPreenchimentoManual(true);
      setSearched(false);
      const errorFromPromise = err as string;
      setError(errorFromPromise);
    }
    // setIsLoading(false);
  };

  const forwardEndereco = () => {
    if (critiques()) {
      return;
    }

    const formattedAddress = `${logradouro}, ${numero} ${
      complemento ? '- ' + complemento : ''
    }\r\n${bairro} - ${cidade} - ${uf}\r\n${cep}`;
    const enderecoCompleto: Endereco = {
      logradouro,
      numero,
      complemento,
      sublocalidade: bairro,
      localidade: cidade,
      uf,
      cep: cep.replace(/\D/g, ''),
      codigoCidade,
      preenchimentoManual,
    };

    if (props.addressType === 'Principal') {
      if (mainAddressError) {
        dispatch(clientFormErrorActions.mainAddressErrorSetter(false));
      }
      dispatch(clientActions.changeEnderecoPrincipal(enderecoCompleto));
    }

    if (props.addressType === 'para Entrega') {
      dispatch(clientActions.changeEnderecoEntrega(enderecoCompleto));
    }
    props.setAddress(formattedAddress);
    props.toggle();
  };

  const handleUFChange = async (ev: any): Promise<void> => {
    ev.preventDefault();
    try {
      const citiesArray = await getCityFromState(ev.target.value);
      setCodigoCidade('');
      setCidade('');
      setCities(citiesArray);
    } catch (err) {
      setGetCitiesError(err as string);
    }
    setUf(ev.target.value);
  };

  const handleCityChange = (ev: any): void => {
    ev.preventDefault();
    let values = JSON.parse(ev.target.value);
    setCidade(values.nome);
    setCodigoCidade(values.id);
  };

  const cleanAllFields = (): void => {
    setBairro('');
    setCep('');
    setCidade('');
    setCities(null);
    setCodigoCidade('');
    setComplemento('');
    setError(null);
    setGetCitiesError(null);
    setLogradouro('');
    setNumero('');
    setUf('');
    setSearched(false);
    setPreenchimentoManual(false);
  };

  const critiques = (): boolean => {
    let filledState: AddressValidity = { ...isFieldsNotFilled };
    filledState.logradouro = !logradouro.trim();
    filledState.bairro = !bairro.trim();
    filledState.cidade = !cidade;
    filledState.codigoCidade = !codigoCidade;
    filledState.numero = !numero.trim();
    filledState.cep = !cep.trim();
    filledState.uf = !uf;

    setIsFieldsNotFilled({ ...filledState });

    if (
      !logradouro.trim() ||
      !bairro.trim() ||
      !cidade ||
      !codigoCidade ||
      !numero.trim() ||
      !cep.trim()
    ) {
      setErroCamposObrigatorios('O preencimento deste campo é obrigatório');
      return true;
    } else {
      return false;
    }
  };

  const resolveHelperText = (): string => {
    if (isFieldsNotFilled.cep) {
      return erroCamposObrigatorios as string;
    } else if (error) {
      return error as string;
    } else {
      return '';
    }
  };

  return (
    <Modal
      open={props.open}
      onClose={props.toggle}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Card sx={{ width: '50%' }}>
        <CardContent>
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              md={12}
              sx={{ backgroundColor: Colors.titleRowWithBackgroundColor }}
              alignItems={'center'}
              textAlign={'center'}
              ml={1}
            >
              <Typography
                variant='body2'
                fontSize={20}
                color={Colors.titleWithBackgroudColor}
              >
                Endereço {props.addressType}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                required
                error={!!error || isFieldsNotFilled.cep}
                onBlur={() => {
                  setCities(null);
                  setCodigoCidade('');
                  setCidade('');
                  setUf('');
                  getAddress();
                }}
                fullWidth
                placeholder='CEP'
                value={cep}
                type='text'
                onChange={(ev) => {
                  let isNotFilledState = { ...isFieldsNotFilled };
                  isNotFilledState.cep = false;
                  setIsFieldsNotFilled({ ...isNotFilledState });
                  setCep(ev.target.value);
                  setError(null);
                }}
                size={'small'}
                color='success'
                label='CEP'
                helperText={resolveHelperText()}
                inputProps={{ maxLength: 8 }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                required
                error={isFieldsNotFilled.logradouro}
                fullWidth
                placeholder='Logradouro'
                value={logradouro}
                type='text'
                onChange={(ev) => {
                  let isNotFilledState = { ...isFieldsNotFilled };
                  isNotFilledState.logradouro = false;
                  setIsFieldsNotFilled({ ...isNotFilledState });
                  if (!preenchimentoManual) setPreenchimentoManual(true);
                  setLogradouro(ev.target.value);
                }}
                size={'small'}
                color='success'
                label='Logradouro'
                helperText={
                  isFieldsNotFilled.logradouro ? erroCamposObrigatorios : ''
                }
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                error={isFieldsNotFilled.numero}
                required
                fullWidth
                placeholder='Número'
                value={numero}
                type='text'
                onChange={(ev) => {
                  let isNotFilledState = { ...isFieldsNotFilled };
                  isNotFilledState.numero = false;
                  setIsFieldsNotFilled({ ...isNotFilledState });
                  setNumero(ev.target.value);
                }}
                size={'small'}
                color='success'
                label='Número'
                helperText={
                  isFieldsNotFilled.numero ? erroCamposObrigatorios : ''
                }
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                placeholder='Complemento'
                value={complemento}
                type='text'
                onChange={(ev) => setComplemento(ev.target.value)}
                size={'small'}
                color='success'
                label='Complemento'
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                error={isFieldsNotFilled.bairro}
                required
                fullWidth
                placeholder='Bairro'
                value={bairro}
                type='text'
                onChange={(ev) => {
                  let isNotFilledState = { ...isFieldsNotFilled };
                  isNotFilledState.bairro = false;
                  if (!preenchimentoManual) setPreenchimentoManual(true);
                  setIsFieldsNotFilled({ ...isNotFilledState });
                  setBairro(ev.target.value);
                }}
                size={'small'}
                color='success'
                label='Bairro'
                helperText={
                  isFieldsNotFilled.bairro ? erroCamposObrigatorios : ''
                }
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl
                fullWidth
                error={isFieldsNotFilled.uf}
                color={'success'}
              >
                <InputLabel
                  id='uf-select-label'
                  error={isFieldsNotFilled.uf}
                  color={'success'}
                >
                  UF *
                </InputLabel>
                <Select
                  required
                  readOnly={searched}
                  labelId='uf-select-label'
                  id='uf-select'
                  fullWidth
                  label={'UF *'}
                  value={uf}
                  onChange={(ev) => {
                    let isNotFilledState = { ...isFieldsNotFilled };
                    isNotFilledState.uf = false;
                    setIsFieldsNotFilled({ ...isNotFilledState });
                    handleUFChange(ev);
                  }}
                  size={'small'}
                  color={'success'}
                >
                  {UFS.map((uf) => {
                    return (
                      <MenuItem key={uf.sigla} value={uf.sigla}>
                        {uf.sigla}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={10}>
              <FormControl
                fullWidth
                color='success'
                error={isFieldsNotFilled.cidade}
              >
                <InputLabel
                  id='city-select-label'
                  error={isFieldsNotFilled.cidade}
                  color={'success'}
                >
                  Cidade *
                </InputLabel>
                <Select
                  labelId='city-select-label'
                  id='uf-select'
                  fullWidth
                  label={'Cidade *'}
                  value={
                    codigoCidade && cidade && cities
                      ? JSON.stringify({ id: codigoCidade, nome: cidade })
                      : ''
                  }
                  onChange={(ev) => {
                    let isNotFilledState = { ...isFieldsNotFilled };
                    isNotFilledState.cidade = false;
                    setIsFieldsNotFilled({ ...isNotFilledState });
                    handleCityChange(ev);
                  }}
                  size={'small'}
                  readOnly={searched}
                  color={'success'}
                >
                  {cities?.map((city) => {
                    let cityString = JSON.stringify(city);
                    return (
                      <MenuItem key={city.id} value={cityString}>
                        {city.nome}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText sx={{ color: Colors.muiDanger }}>
                  {getCitiesError ? getCitiesError : ''}
                </FormHelperText>
                <FormHelperText sx={{ color: Colors.muiDanger }}>
                  {isFieldsNotFilled.uf || isFieldsNotFilled.cidade
                    ? erroCamposObrigatorios
                    : ''}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={4} md={4} textAlign={'center'} mt={1}>
              <Button variant='outlined' onClick={() => cleanAllFields()}>
                Limpar Campos
              </Button>
            </Grid>
            <Grid item xs={4} md={4} textAlign={'center'} mt={1}>
              <Button variant='outlined' onClick={props.toggle}>
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={4} md={4} textAlign={'center'} mt={1}>
              <Button variant='outlined' onClick={() => forwardEndereco()}>
                Ok
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Modal>
  );
};
export default ClientAdressModal;
