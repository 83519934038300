import { createApi } from '@reduxjs/toolkit/query/react';

import OrderType from '../models/order/orderType.model';
import dynamicBaseQuery from './dynamicBaseQuery';

export const orderTypeApi = createApi({
  reducerPath: 'orderTypeApi',
  tagTypes: ['OrderType'],
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    getOrderTypes: builder.query<OrderType[], void>({
      query: () => ({
        url: '/tipopedido',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }),
      providesTags: ['OrderType'],
    }),
  }),
});

export const { useGetOrderTypesQuery } = orderTypeApi;
