import { createApi } from '@reduxjs/toolkit/query/react';

import OrderFase from '../models/order/orderPhase.model';

import dynamicBaseQuery from './dynamicBaseQuery';

export const orderPhaseApi = createApi({
  reducerPath: 'orderPhaseApi',
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    getPhases: builder.query<OrderFase[], void>({
      query: () => ({
        url: `/fasepedido`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }),
    }),
    getPhase: builder.query<OrderFase, string>({
      query: (id: string) => ({
        url: `/fasepedido/${id}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }),
    }),
  }),
});

export const { useGetPhasesQuery, useGetPhaseQuery } = orderPhaseApi;
