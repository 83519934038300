const validateNullDiscount = (discount: string): boolean => {
  console.log(discount);

  if (
    discount === '0' ||
    discount === '0,0' ||
    discount === '0.0' ||
    discount === '0,00' ||
    discount === '0.00'
  ) {
    return true;
  }

  return false;
};

export default validateNullDiscount;
